import React, { useContext, useState } from 'react';
import LottieGreenCheck from '../Lottie Animation Comp/LottieGreenCheck';
import ErrorLottie from '../Lottie Animation Comp/ErrorLottie';
import { IoCloseCircleOutline } from "react-icons/io5";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { getQRCode, makePayment } from '../../api/signalsAPI';
import { FaSpinner } from "react-icons/fa";
import { useEffect } from 'react';
import { CartContext } from '../../context/CartContext';
import axios from 'axios';
import { URL } from '../../config';

    
    const ConfirmModal = ({ setModalVisible, isVisible, onClose, message, data, type, clearCart,totalAmount}) => {
      console.log('data',data,totalAmount)
  // const combinedPrice = data?.reduce((total, item) => total + parseFloat(item.price), 0);
  const authUser = useAuthUser();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [cartPrice, setCartPrice] = useState(totalAmount);
  


  const payHandler = async () => {
    setLoading(true);
     
    const resp = await makePayment(authUser?.token, authUser?.email, data, type);
    if (resp?.data?.message === "Payment processed successfully") {
      setAccount(resp?.data?.address);
      const address = await getQRCode(resp?.data?.address);
      if (address) {
      const resp = await setQrCode(address.qrCode);
      
        console.log("resp",resp)
        setIsSuccess(true);
        setLoading(false);
        if(cartPrice !== undefined){
        clearCart()
        }

      }
    } else {
      alert('error');
      setLoading(false);
    }
  };
// useEffect(()=>{
//   setCartPrice(totalAmount)
// },[totalAmount])
  if (!isVisible) return null;

  console.log("cartPrice",cartPrice)

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
      {loading && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
          <FaSpinner className="animate-spin text-white" size={40} />
        </div>
      )}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
        <IoCloseCircleOutline size={30} style={{ cursor: "pointer", position: "absolute", top: "5%", right: "2%" }} onClick={() => { setModalVisible(!isVisible); setIsSuccess(false) }} />
       
        {isSuccess  ? (
          <div className="text-center" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', flexDirection: "column" }}>
            <h2 className="text-2xl font-bold mb-4">Checkout</h2>
            <p className='mb-3 bg-yellow-300' style={{ padding: "3px" }}>{account}</p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
              <div className='w-[60%] text-start'>
                <p className='text-center font-bold mb-4'>How to make payment</p>
                <p className='mb-2' style={{ fontSize: "13px" }}>1: Open Playstore in your phone.</p>
                <p className='mb-2' style={{ fontSize: "13px" }}>2: Download investorszon App.</p>
                <p className='mb-2' style={{ fontSize: "13px" }}>3: Scan from App.</p>
                <p>Amount: <span className='font-bold'>${data?.price ? data?.price : data?.planPrice ? data?.planPrice : cartPrice ? cartPrice : totalAmount}</span></p>
              </div>
              <div className='w-[40%]' style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', flexDirection: "column" }}>
                <p style={{ fontSize: "13px" }}>Scan to pay</p>
                <img style={{ width: "150px", height: "150px" }} src={qrCode} alt="qr-code" />
                <button
                  onClick={() => alert("verify payment")}
                  className="bg-blue-500 text-white p-[5px] text-[15px] rounded hover:bg-blue-700"
                >
                  Verify Payment
                </button>
              </div>
            </div>
          </div>
        ) : 
        (
          <div className="text-center">
            {Array.isArray(data) ? (
              data.map((item, index) => (
                <div key={index} className="mb-6">
                  <h2 className="text-2xl font-bold mb-4">{item?.title ? item?.title : item?.planType ? item?.planType : item?.planName}</h2>
                  <p className="mb-4">{item?.description ? item?.description : item?.planDescription}</p>
                 
                </div>
              ))
              
            ) 
            : (
              <>
                <h2 className="text-2xl font-bold mb-4 capitalize">{data?.title ? data?.title : data?.planType ? data?.planType : data?.planName}</h2>
                <p className="mb-6">{data?.description ? data?.description : data?.planDescription}</p>
                
              </>
              )
              }
              <button
              onClick={payHandler}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Pay Now
            </button>
          </div>

            )
        }


        {/* {isSuccess && data?.planPrice === '0'  ? (
          <div className="text-center" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', flexDirection: "column" }}>
            <h2 className="text-2xl font-bold mb-4">Signal Subsribed successfully</h2>
          </div>
        ) : (
          <div className="text-center">
            {Array.isArray(data) ? (
              data.map((item, index) => (
                <div key={index} className="mb-6">
                  <h2 className="text-2xl font-bold mb-4">{item?.title ? item?.title : item?.planType ? item?.planType : item?.planName}</h2>
                  <p className="mb-4">{item?.description ? item?.description : item?.planDescription}</p>
                </div>
              ))
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4 capitalize">{data?.title ? data?.title : data?.planType ? data?.planType : data?.planName}</h2>
                <p className="mb-6">{data?.description ? data?.description : data?.planDescription}</p>
                <button
              onClick={payHandler}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Pay Now
            </button>
              </>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ConfirmModal;
