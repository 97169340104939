import React, { useEffect, useState } from "react";
import { CiDiscount1 } from "react-icons/ci";
import { FaRegCreditCard, FaStar, FaStarHalf, FaLock } from "react-icons/fa";
import { IoGlobeSharp } from "react-icons/io5";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { URL } from "../../config";
import { fetchModuleProgress } from "../../api/CourseApi";

const HeroMyPurchased= ({ data}) => {
  console.log("****moduleInfodata***",data)
  const { id } = useParams();
  console.log("****id***",id)

  const [userProgressDetails, setUserProgressDetails] = useState([]);
  const authUser = useAuthUser();
 const navigate = useNavigate();

 const percentage = data?.percentage % 1 === 0 
 ? `${data?.percentage}%` 
 : `${data?.percentage.toFixed(2).replace(/\.00$/, '')}%`

  // useEffect(() => {
  //   const userHandler = async () => {
  //     const token = authUser?.token;
  //     const response = await axios.get(`${URL}/progress/progressview/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     console.log("axd", response);
  //     setUserProgressDetails(
  //       response?.data?.progress?.moduleContentItemId
  //         ? response?.data?.progress?.moduleContentItemId
  //         : 0
  //     );
  //   };

  //   if (authUser?.token) {
  //     userHandler();
  //   }
  // }, [id, authUser]);

  const completed =
    data?.Contents?.flatMap((course) =>
      course?.Items.findIndex((item) => item.id === userProgressDetails)
    )[0] ?? -1;
  const totalCompleted = completed + 1;

  const radius = 90;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (data?.percentage / 100) * circumference;


  const downloadHandler =(id)=>{
    navigate(`/my-module-certificate/${id}`,{state:{id:data?.id}})
  };
 
  

  return (
    <div className="flex justify-start">
      <div className="text-white w-[50%] bg-[#090E34] ml-[3%]">
        <h1 className="text-3xl text-bolder mt-[5%] mb-8">{data?.title}</h1>
        <p className="mb-8">{data?.description}</p>
        <div className="flex align-center justify-start mb-4">
          <p className="mr-3 bg-blue-500 px-1.5 mb-0">Bestseller</p>
          <div className="flex mr-3 items-center ">
            <p>4.7</p>
            <div className="flex items-center">
              <FaStar className="ml-2 mr-2" color="yellow" />
              <FaStar color="yellow" className="mr-2" />
              <FaStar className="mr-2" color="yellow" />
              <FaStarHalf color="yellow" />
            </div>
          </div>
        </div>
        <p className="mb-4">
          Created by{" "}
          <NavLink className="text-blue-500" to="#">
            Investors Zon
          </NavLink>
        </p>
        <div className="flex items-center">
          <p className="mr-4 flex items-center">
            <CiDiscount1 className="mr-2" /> Last updated 03/2024
          </p>
          <p className="mr-4 flex items-center">
            <IoGlobeSharp className="mr-2" />
            English
          </p>
        </div>
      </div>

      <div className="mr-[20%]">
        <h2 className="font-bold text-3xl mt-8 text-white text-center">
          Progress
        </h2>
        <div className="flex flex-col items-center">
          {!authUser || !data?.purchased ? (
            <div className="group p-4 cursor-pointer transform transition-transform duration-300 hover:scale-125" style={{ borderRadius: "50%", width: "100%" }}>
              <NavLink to="/signin">
                <FaLock size={50} color="gray" />
              </NavLink>
              <div className="cursor-pointer gap-4 absolute inset-0 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
                <NavLink
                  to={`/signin`}
                  className="text-white font-bold text-center py-2 w-[90%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110"
                >
                  Unlock
                </NavLink>
              </div>
            </div>
          ) : (
            <div className="relative mt-8">
              <svg
                className="transform -rotate-90 w-40 h-40"
                viewBox="0 0 200 200"
              >
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="gray"
                  strokeWidth="15"
                  fill="none"
                />
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="gray"
                  strokeWidth="15"
                  fill="none"
                  strokeDasharray={circumference}
                  strokeDashoffset={offset}
                  strokeLinecap="round"
                />
              </svg>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="text-2xl font-bold text-gray-700">
                {percentage}
                </div>
                <div className="text-sm text-gray-500">
                  {userProgressDetails?.total}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {percentage === '100%' && (
      <button onClick={()=>downloadHandler(id)} className="bg-green-700 h-fit p-3 text-white rounded">Download Certificate</button>)}
    </div>
  );
};

export default HeroMyPurchased;
