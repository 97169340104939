import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { forgotPassword, resetPassword } from "../api/CourseApi";
import LottieAnimation from "./Lottie Animation Comp/lottie";
import LottieGreenCheck from "./Lottie Animation Comp/LottieGreenCheck";
import { IoCloseCircleOutline } from "react-icons/io5";
import ErrorLottie from "./Lottie Animation Comp/ErrorLottie";
import "./Forgot.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [resetData, setResetData] = useState({
    resetToken: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [msgNotification, setMsgNotification] = useState(false);
  const [msg, setMsg] = useState("");
  const [resetFormError, setResetFormError] = useState(false);
  const [resetErrorMsg, setResetErrorMsg] = useState("");
  const [usernotfound, setusernotfound] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleResetInputChange = (event) => {
    const { name, value } = event.target;
    setResetData({ ...resetData, [name]: value });
  
    let errorMsg = {};
  
    if (name === "resetToken") {
      if (isNaN(value) || value.length > 5) {
        errorMsg.resetToken = "Please enter a valid number with a maximum length of 5.";
      } else {
        errorMsg.resetToken = "";
      }
    } 
  
    if (name === "newPassword") {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
      if (!passwordRegex.test(value)) {
        errorMsg.newPassword = "Password must be at least 8 characters long, include one uppercase letter, and one special character.";
      } else {
        errorMsg.newPassword = "";
      }
    }
  
    if (name === "confirmPassword") {
      if (value !== resetData.newPassword) {
        errorMsg.confirmPassword = "Passwords do not match.";
      } else {
        errorMsg.confirmPassword = "";
      }
    }
  
    setResetErrorMsg(errorMsg);
    setResetFormError(Object.values(errorMsg).some(msg => msg));
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.email) {
      setErrorMsg("Please Enter Valid Email!");
      setFormError(true);
      return;
    }
    setLoading(true);
    try {
      const response = await forgotPassword(formData.email);
      if (response.status === 200) {
        console.log("resp", response);
        setSuccess(true);
        setSuccessMsg({
          message: "Please check email for token",
          icon: (
            <div
              className="loader-container w-[140px]"
              ref={loadingContainer}
            ></div>
          ),
        });
      } else {
        setSuccess(false);
        setusernotfound(true);
        setSuccessMsg({
          message: response.response.data.error,
          icon: (
            <div
              className="loader-container w-[140px]"
              ref={loadingContainer}
            ></div>
          ),
        });
      }
    } catch (error) {
      console.error("Error:", error.response.data.error);
      setErrorMsg({
        message: error?.response?.data?.error,
        icon: "Sahil",
      });
      setFormError(true);
    }
    setLoading(false);
  };

  const handleResetSubmit = async () => {
    if (!resetData.newPassword || !resetData.confirmPassword) {
      setResetErrorMsg("Please fill out all password fields.");
      setResetFormError(true);
      return;
    }

    if (resetData.newPassword !== resetData.confirmPassword) {
      setResetErrorMsg("Passwords do not match.");
      setResetFormError(true);
      return;
    }

    const tokenInt = parseInt(resetData.resetToken);

    const completeData = {
      email: formData.email,
      resetToken: tokenInt,
      newPassword: resetData.newPassword,
      confirmPassword: resetData.confirmPassword,
    };

    setLoading(true);
    try {
      const resp = await resetPassword(completeData);
      if (resp.status === 200) {
        console.log("Reset Password Complete Data:", resp);
        setMsgNotification(true);
        setMsg({ message: resp?.data?.message, icon: <LottieGreenCheck /> });
        setShowResetPopup(false);
        // Reset all form states
        setFormData({ email: "" });
        setResetData({ resetToken: "", newPassword: "", confirmPassword: "" });
        setFormError(false);
        setResetFormError(false);
      } else {
        setMsgNotification(true);
        setMsg({
          message: resp?.response?.data?.error,
          icon: <LottieAnimation />,
        });
        setShowResetPopup(false);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setMsgNotification(true);
      setMsg({ message: error, icon: <LottieAnimation /> });
      setShowResetPopup(false);
    }
    setLoading(false);
  };

  const loadingContainer = useRef(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Components/Lottie Animation Comp/ForgetEmailAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("overlay")) {
      setSuccess(false);
      setShowResetPopup(false);
      setMsgNotification(false);
    }
  };
  useEffect(() => {
    if (msg?.message === "Password reset successfully") {
      setTimeout(() => {
        navigate("/signin");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
  }, [msg?.message, navigate]);

  return (
    <div className="mt-[10%]">
      {loading && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <FaSpinner className="animate-spin text-white" size={40} />
        </div>
      )}
      <div className="container mx-auto max-w-[525px] px-4 sm:px-6 md:px-8 mt-[14%] mb-16">
        <div className="w-full mx-auto bg-white dark:bg-dark-2 py-8 px-6 rounded-lg border hover:shadow-md text-center">
          <div className="mb-6">
            <h2 className="font-bold text-2xl">Forgot Password</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
              />
            </div>
            {formError && <div className="mb-4 text-red-600">{errorMsg}</div>}
            <div className="mb-6">
              <button
                type="submit"
                className="w-full px-4 py-3 text-base font-medium text-white transition duration-300 ease-in-out bg-primary border border-transparent rounded-md cursor-pointer hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-primary dark:focus:ring-dark-2"
              >
                {loading ? "Signing In..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {success && (
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 overlay"
          onClick={handleOutsideClick}
        >
          <div className="popup-enter-token w-[40vw] h-[48vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative">
            <IoCloseCircleOutline
              size={30}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "8%",
                right: "2%",
              }}
              onClick={() => setSuccess(!success)}
            />
            <div className="flex flex-col items-center justify-center mb-4 text-white">
              <h2 className="text-2xl text-black font-semibold mb-4">
                {successMsg.message}
              </h2>
              <p>
                {successMsg.message === "Please check email for token" ? (
                  <LottieGreenCheck />
                ) : (
                  <ErrorLottie />
                )}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {successMsg.message === "Please check email for token" && (
                <button
                  className="bg-blue-700 text-white hover:bg-blue-400 py-2 mr-5 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setSuccess(false);
                    setShowResetPopup(true);
                  }}
                >
                  Enter Token
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {usernotfound && (
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 overlay"
          onClick={handleOutsideClick}
        >
          <div className=" forgot-password-popup w-[40vw] h-[48vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative">
            <IoCloseCircleOutline
              size={30}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "8%",
                right: "2%",
              }}
              onClick={() => setusernotfound(!usernotfound)}
            />
            <div className="flex flex-col items-center justify-center mb-4 text-white">
              <h2 className="text-2xl text-black font-semibold mb-4">
                {successMsg.message}
              </h2>
              <p>
                {successMsg.message === "Please check email for token" ? (
                  <LottieGreenCheck />
                ) : (
                  <ErrorLottie />
                )}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {successMsg.message === "Please check email for token" && (
                <button
                  className="bg-blue-700 text-white hover:bg-blue-400 py-2 mr-5 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setShowResetPopup(true);
                  }}
                >
                  Enter Token
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {showResetPopup && (
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 overlay"
          onClick={handleOutsideClick}
        >
          {loading && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          )}
          <div className="popupthree w-[40vw] h-[70vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative">
            <h2 className="text-2xl text-black font-semibold mt-3 mb-4">
              Reset Password
            </h2>
            <IoCloseCircleOutline
              size={30}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "5%",
                right: "2%",
              }}
              onClick={() => {
                setShowResetPopup(!showResetPopup);
                setResetData({
                  resetToken: "",
                  newPassword: "",
                  confirmPassword: "",
                });
              }}
            />

            <div className="mb-4">
              <input
                type="text"
                name="resetToken"
                placeholder="Enter Code"
                value={resetData.resetToken}
                onChange={handleResetInputChange}
                maxLength="5"
                className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
              />
              {resetErrorMsg.resetToken && (
                <div className="text-red-600">{resetErrorMsg.resetToken}</div>
              )}
            </div>

            <div className="mb-4">
              <input
                type="password"
                name="newPassword"
                placeholder="Enter Password"
                value={resetData.newPassword}
                onChange={handleResetInputChange}
                className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
              />
              {resetErrorMsg.newPassword && (
                <div className="text-red-600">{resetErrorMsg.newPassword}</div>
              )}
            </div>

            <div className="mb-3">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={resetData.confirmPassword}
                onChange={handleResetInputChange}
                className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
              />
              {resetErrorMsg.confirmPassword && (
                <div className="text-red-600">
                  {resetErrorMsg.confirmPassword}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="bg-blue-700 text-white hover:bg-blue-400 py-2 mr-5 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleResetSubmit}
                disabled={
                  !resetData.resetToken ||
                  !resetData.newPassword ||
                  !resetData.confirmPassword ||
                  resetData.newPassword !== resetData.confirmPassword
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {msgNotification && (
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 overlay"
          onClick={handleOutsideClick}
        >
          <div className="popuptwo w-[40vw] h-[52vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative">
            <IoCloseCircleOutline
              size={30}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "10%",
                right: "3%",
              }}
              onClick={() => {
                setMsgNotification(!msgNotification);
                navigate("/signin");
              }}
            />
            <div className="flex flex-col items-center justify-center mb-4 text-white">
              <h2 className="text-2xl text-black font-semibold mt-2 mb-4">
                {msg.message}
              </h2>
              <p>
                {msg.message === "Invalid or expired reset token" ? (
                  <LottieAnimation />
                ) : (
                  <LottieGreenCheck />
                )}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {msg.message === "Invalid or expired reset token" && (
                <button
                  className="bg-blue-700 text-white hover:bg-blue-400 py-2 mr-5 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setMsgNotification(!msgNotification);
                    setShowResetPopup(true);
                  }}
                >
                  Re-enter Token
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
