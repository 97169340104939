import React, { useEffect } from 'react';
import Mutual from '../assets/mutual-fund.webp'
import Qutation from './QutationComp/Qutation';
import SubscribeNow from './SubscribeNowComp/SubscribeNow';
import Buttoncard from './HomePageSimpleCard/Buttoncard';
import "../Components/ForexMarketPages/CurrencyParis.css"
import Forexmarketcard from './ForexmarketCardComp/ForexMarketCard';
import Title from './TitleComp/Title';
import MarketTitle from './TitleComp/MarketTitle';
import Accordian from './AccordianComp/Accordian';
import { FaCheckCircle } from 'react-icons/fa';

const MutualFund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const CurrencyData = [
    {
      header: `Types of Mutual Funds:`,
      paragraph:
        " There are several types of mutual funds, each with its own investment objectives, strategies, and risk levels. Understanding these types can help you choose the right fund for your financial goals. ",



    },
    {
      header: `Equity Funds:`,
      list: [{
        heading: "Objective:",
        descp: "  Capital appreciation through investments in stocks."
      },
      {
        heading: "Strategy:",
        descp: "Invests primarily in equities, targeting growth stocks, value stocks, or a mix of both."
      },
      {
        heading: "Risk Level:",
        descp: "Higher risk with the potential for higher returns."
      },
      ]
    },
    {
      header: `Bond Funds:`,
      list: [{
        heading: "Objective:",
        descp: " Income generation through investments in bonds."
      },
      {
        heading: "Strategy:",
        descp: "Invests in government, corporate, municipal, or international bonds to provide regular interest income."
      },
      {
        heading: "Risk Level:",
        descp: "Lower risk compared to equity funds, with moderate returns."
      },
      ]
    },
    {
      header: `Balanced Funds:`,
      list: [{
        heading: "Objective:",
        descp: "A mix of income and capital appreciation."
      },
      {
        heading: "Strategy:",
        descp: " Invests in a combination of stocks, bonds, and other securities to balance risk and return."
      },
      {
        heading: "Risk Level:",
        descp: " Moderate risk with balanced returns."
      },
      ]
    },
    {
      header: `Money Market Funds:`,
      list: [{
        heading: "Objective:",
        descp: "Preservation of capital with minimal risk."
      },
      {
        heading: "Strategy:",
        descp: "Invests in short-term, high-quality debt securities such as Treasury bills and commercial paper."
      },
      {
        heading: "Risk Level:",
        descp: "  Lowest risk with modest returns."
      },
      ]
    },
    {
      header: `Index Funds:`,
      list: [{
        heading: "Objective:",
        descp: "Replicate the performance of a specific market index."
      },
      {
        heading: "Strategy:",
        descp: "Invests in the same securities as the index it tracks, such as the S&P 500"
      },
      {
        heading: "Risk Level:",
        descp: " Varies depending on the index but typically offers broad market exposure and lower management fees."
      },
      ]
    },
    {
      header: `Specialty Funds:`,
      list: [{
        heading: "Objective:",
        descp: "Focused investment strategy in specific sectors or themes."
      },
      {
        heading: "Strategy:",
        descp: " Invests in sectors like technology, healthcare, or renewable energy, or follows themes like ESG (Environmental, Social, and Governance) investing."
      },
      {
        heading: "Risk Level:",
        descp: " Higher risk due to concentrated exposure."
      },
      ]
    },
  ];

  const CurrencyDataone = [

    {
      heading: `Diversification`,
      description: ` Spreads investments across a wide range of securities, reducing risk and enhancing potential returns.`,

    },
    {
      heading: `Professional Management`,

      description: `Managed by experienced portfolio managers who conduct in-depth research and analysis to make informed investment decisions.`,

    },
    {
      heading: `Accessibility`,

      description: `Provides access to a variety of asset classes and markets that may be difficult for individual investors to reach on their own.`,

    },
    {
      heading: `Liquidity`,

      description: `Easy to buy and sell shares, providing investors with liquidity and flexibility to manage their investments.`,

    },
    {
      heading: "Affordability ",
      description: "Allows investors to start with relatively small amounts of money, making it accessible to a wide range of investors."

    },
    {
      heading: "Regular Income ",
      description: "Some mutual funds, particularly bond and income funds, offer regular dividend or interest payments, providing a steady income stream."

    },

  ];
  const titledatatwo = [
    { header: "Latest News and Trends", descp: "Stay updated with the latest developments in the mutual fund industry. Our news section covers." }
  ]
  const CurrencyDatatwo = [
    {
      header: "Fund Performance ",
      text: "Analysis of top-performing funds and their investment strategies."
    },
    {
      header: "Market Trends ",
      text: "Insights into market conditions and how they impact mutual funds."
    },
    {
      header: "Regulatory Changes  ",
      text: "Updates on regulatory policies affecting mutual funds."
    },
    {
      header: "Fund Manager Changes  ",
      text: "Information on key changes in fund management teams and their potential impact on fund performance."
    },


  ]

  const CurrencyDatathree = [

    {
      heading: `Investment Objectives`,

      description: `Define your financial goals, whether it’s capital growth, income generation, or a balanced approach.`,

    },
    {
      heading: `Risk Tolerance`,

      description: `Assess your risk appetite and choose funds that match your comfort level with market fluctuations.`,

    },
    {
      heading: `Fund Performance`,

      description: `Review historical performance, but also consider consistency and risk-adjusted returns.`,

    },
    {
      heading: `Fees and Expenses`,

      description: `Understand the cost structure, including management fees, expense ratios, and any sales charges.`,

    },
    {
      heading: `Fund Manager Experience`,

      description: `Evaluate the track record and expertise of the fund managers.`,

    },
    {
      heading: `Investment Horizon`,

      description: `Align the fund’s investment strategy with your time frame, whether short-term, medium-term, or long-term.`,

    },
    {
      heading: "Analysis and Forecasts ",
      description: " Our expert analysts provide comprehensive analysis and forecasts to help you make informed decisions about mutual fund investments."

    },
  ]

  const forexdata = [
    {
      heading: "Historical Performance ",
      description: "Review past performance data, including returns, volatility, and risk-adjusted performance metrics like Sharpe Ratio and Alpha."
    },
    {
      heading: "Benchmark Comparison  ",
      description: "Compare fund performance against relevant benchmarks to assess how well the fund is managed."
    },
    {
      heading: `Sector and Industry Analysis`,

      description: "Understand how different sectors and industries contribute to the fund’s performance and their potential for future growth."
    },
    {
      heading: `Economic and Market Outlook`,

      description: "Gain insights into the broader economic and market conditions that could impact mutual fund performance."

    },
    {
      heading: `Weekly Insights and Strategic Recommendations`,

      description: "Receive weekly updates and strategic recommendations tailored to your investment goals and market conditions."

    },

  ]


  const buttondata = [
    { title: "Explore Mutual Funds", descp: "", button: "SIGN UP" }
  ]
  const titledata = [
    { header: "Benefits of Investing in Mutual Funds", descp: "Mutual funds offer several advantages that make them an attractive option for many investors." }
  ]
  const titledataone = [
    { header: "How to Choose the Right Mutual Funds", descp: "Selecting the right mutual fund involves considering several factors to align with your investment goals and risk tolerance." }
  ]
  const markettitledataone = [
    { header: "Overview", descp: "            Mutual funds are investment vehicles that pool money from multiple investors to purchase a diversified portfolio of stocks, bonds, or other securities. Managed by professional fund managers, mutual funds offer investors the opportunity to diversify their investments, achieve professional management, and gain access to a broad range of asset classes. They are ideal for both novice and experienced investors seeking a balanced approach to investment.        " }
  ]
  return (
    <div className='mt-[140px]'>
      <div className=" container mx-auto mt-[10%]  relative ">
        <div className="relative ">
          <img
            src={Mutual}
            alt="Paris Financial District"
            className="w-full h-auto currencyparis-image"
            style={{ height: "75vh" }}
          />
        </div>
      </div>
      <div className='w-4/5 mx-auto mt-24' >
        <div className="container mx-auto mb-4 ">
          <MarketTitle data={markettitledataone} />
        </div>
        <div className="container mx-auto flex gap-[2%] justify-between">
          <div className="w-[59%] mb-12 currency-inner-content	">
            {CurrencyData.map((val, index) => {
              return (
                <div key={index}>
                  <h2 className=" mt-8 font-bold text-xl">{val.header}</h2>
                  <p className=" mb-2 mt-4 w-full	text-base text-body-color dark:text-dark-6">{val.paragraph}</p>
                  {val?.list?.map((val, index) => {
                    return (
                      <>
                        <h2 className="  font-bold text-lg" style={{ display: "inline" }} >{val?.heading}</h2>
                        <div className="Course-list flex justify-start w-[100%]">
                          <p className="Course-heading w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-0.5" style={{ marginRight: "10px" }} /> </p>
                          <p className="course-paragraph mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">
                            {val?.descp} </p>
                        </div>
                      </>
                    )
                  })}
                  {/* <li className="text-lg mb-2  w-full	"> {val.list} </li> */}
                </div>
              );
            })}
          </div>
          <div className="w-[39%]	currency-banner">
            <SubscribeNow />
            <Qutation />
          </div>
        </div>
        <Title data={titledatatwo} />
        <Accordian data={CurrencyDatatwo} />
        <div className="container mx-auto flex justify- mt-4">
          <div className="w-[60%] currency-inner-content	">
            <div className="w-[100%] 	">
              {CurrencyDatathree.map((val, index) => {
                return (
                  <div key={index}>
                    <h2 className=" mt-8 font-bold text-xl">{val.title}</h2>
                    <h2 className=" mt-4 font-bold text-xl">{val.header}</h2>
                    <p className=" mb-2  w-full text-base text-body-color dark:text-dark-6	">{val.paragraph}</p>
                    {val?.list?.map((val, index) => {
                      return (
                        <>
                          <div className="Course-list flex justify-start w-[100%]">
                            <p className="Course-heading w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center" style={{ marginRight: "10px" }} /> </p>
                            <p className="course-paragraph mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">

                              {val?.descp} </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className=''>
          <Title data={titledataone} />
          <Forexmarketcard data={CurrencyDatathree} />
          <Title data={titledata} />
          <Forexmarketcard data={CurrencyDataone} />
          <Forexmarketcard data={forexdata} />
        </div>
        <div className="">
          <Buttoncard data={buttondata} />
        </div>
        {/* Additional content can be added here to reach around 1000 words */}
        <div className="container mx-auto flex justify-between">
          <div className="w-3/5		">
            <div className="flex justify-between items-center px-4 py-2">
            </div>
          </div>
          <div className="w-2/5	">
          </div>
        </div>
      </div>
    </div>
  )
}

export default MutualFund
