import React, { useEffect, useState } from "react";
import SubscribeNow from "../SubscribeNowComp/SubscribeNow";
import BannerWidget from "../Baner.wedgit";
import Buttoncard from "../HomePageSimpleCard/Buttoncard";
import "./CurrencyParis.css"
import TopStories from "../WidgetComp/TopStoriesWidget";
import { FaSpinner } from "react-icons/fa";
import Forexmarketcard from "../ForexmarketCardComp/ForexMarketCard";
import Title from "../TitleComp/Title";
import Forexiconcard from "../ForexmarketCardComp/ForexIconCard";
import { CiFaceSmile } from "react-icons/ci";
import { BsGraphUp } from "react-icons/bs";
import { CiStopSign1 } from "react-icons/ci";
import MarketTitle from "../TitleComp/MarketTitle";
import { FaCheckCircle } from "react-icons/fa";

const CurrencyParis = () => {
  window.scroll(0, 0)

 
 

  const CurrencyData = [
    {
      header: `Latest News and Trends:`,
      paragraph:
        "Stay informed with real-time updates on global economic events, central bank policies, geopolitical developments, and other factors influencing currency movements. The FOREX market is highly sensitive to various economic indicators such as GDP growth rates, employment figures, inflation rates, and interest rate decisions. Our news section delivers timely information on: ",
      list: `Major economic releases and their impact on currency pairs.`,
    },
    {
      list: `Central bank meetings and policy announcements from institutions like the Federal Reserve, European Central Bank, Bank of Japan, and others.`,
    },
    {
      list: `Geopolitical events such as elections, trade agreements, and conflicts that can cause market volatility.`,
    },
    { list: `Trends in global trade and investment flows.` },
  ];
  const formcarddata = [
    { heading: "EUR/USD: Euro/US Dollar", description: "The EUR/USD pair is the most traded currency pair in the world, representing the two largest economies. It is highly liquid and often influenced by economic data from the Eurozone and the US, as well as policy decisions from the ECB and the Fed." },
    { heading: "GBP/USD: British Pound/US Dollar", description: " Known as Cablethis pair is influenced by economic performance in the UK and the US. Brexit developments, UK economic indicators, and Bank of England policies play a significant role in its movements." },
    { heading: "USD/JPY: US Dollar/Japanese Yen", description: "The USD/JPY pair is a popular choice for carry trade strategies due to the historically low-interest rates in Japan. It is sensitive to US economic data, interest rate differentials, and risk sentiment in the global markets." },
    { heading: "USD/CHF: US Dollar/Swiss Franc", description: "Often referred to as a safe-haven pair, the USD/CHF is influenced by global risk sentiment. The Swiss Franc tends to appreciate during times of economic uncertainty, while US economic data and Fed policies also impact this pair." },
  ]
  const CurrencyDataone = [


    {
      header: `Analysis and Forecasts:`,
      list: [
        {
          descp: `Our expert analysts provide detailed technical and fundamental analysis to help you make informed trading decisions. Access weekly forecasts, trend analysis, and strategic insights tailored to your trading style and objectives.`,
        },
      ],
    },
    {
      header: `Technical Analysis:`,
      list: [
        {
          descp: `Chart patterns and technical indicators: Identify key support and resistance levels, trendlines, moving averages, and oscillators such as RSI and MACD.`,
        },
        {
          descp: `Candlestick patterns: Understand price action and market psychology through patterns like Doji, Hammer, and Engulfing.`,
        },
        {
          descp: `Fibonacci retracements and extensions: Predict potential reversal points and target levels.`,
        },
      ],
    },
    {
      header: `Fundamental Analysis:`,
      list: [
        {
          descp: `Economic indicators: Analyze the impact of GDP growth, employment data, inflation, and trade balances on currency values.`,
        },
        {
          descp: `Interest rate differentials: Understand how changes in interest rates affect currency pairs and carry trade strategies`,
        },
        {
          descp: `Sentiment analysis: Gauge market sentiment through indicators like the Commitment of Traders (COT) report and various sentiment indices.`,
        },
      ],
    },
    {
      header: `Weekly Forecasts and Strategic Insights:`,
      list: [
        {
          descp: `Weekly outlooks: Get a snapshot of the upcoming week’s key events and potential market movers.`,
        },
        {
          descp: `Strategic insights: Learn about different trading strategies, risk management techniques, and how to develop a trading plan tailored to your goals.`,
        },
      ],
    },
  ];
  
  const buttondata = [
    { title: "Subscribe for FOREX Updates", descp: "", button: "SIGN UP " }
  ]
  const titledata = [
    { header: "Key Currency Pairs" }
  ]
  const Homedata = [

    {
      icon: <CiFaceSmile size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Keep It Simple",
      description: `Don’t overcomplicate your trading. While there is a lot of information to process, maintaining a straightforward approach will help you implement your learning effectively. Focus on a few key strategies and indicators rather than trying to master everything at once. As you gain experience, you can gradually expand your knowledge and refine your techniques.`,
    },
    {
      icon: <BsGraphUp size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Practice Your Trading Plan",
      description: `Test your trading plan using a demo account. This allows you to experience real market conditions without risking your own money. A demo account helps you understand how your strategies perform in different market scenarios and enables you to make adjustments as needed. Practicing with a demo account builds confidence and improves your decision-making skills.`,
    },
    {
      icon: <CiStopSign1 size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px", }} />,
      heading: "Know When to Stop",
      description: `Avoid greed and manage your trades carefully. Stick to a safer approach to prevent significant losses and protect your investment. Set clear profit and loss limits for each trade and adhere to them. It's important to recognize when a trade is not going as planned and exit the position to minimize losses. Successful traders are disciplined and know when to take profits and cut losses.`,
      // descp2: `By following these forex trading tips and investing time in learning, you'll be better equipped to navigate the forex market and achieve success as a trader. The forex market offers numerous opportunities for those willing to invest the time and effort to understand its complexities. With dedication, practice, and continuous learning, you can develop the skills needed to become a successful forex trader.`,
    },

  ]
  const titledataone = [
    { header: "Important Forex Trading Tips" }
  ]
  const markettitledata = [
    { header: "Overview", descp: "The Foreign Exchange (FOREX) market is the largest and most liquid financial market in the world, where currencies are traded 24/7. With a daily trading volume exceeding $6 trillion, it offers vast opportunities for traders and investors. This global marketplace is the backbone of international trade and investment, providing essential mechanisms for hedging currency risks and facilitating global commerce.Every day, trillions of dollars are exchanged in the forex market, an ever-evolving industry where everyone has to start somewhere. This guide delves into the essentials of forex trading for beginners, offering the best tips, fundamental terms, and training resources to help you navigate this dynamic market. Forex trading might seem daunting at first with its numbers, charts, pips, and jargon. It's normal to feel overwhelmed when you start seeing these elements. However, understanding how to analyze this information comes with time and experience. Success in forex trading doesn't come instantly but develops as you gain more knowledge and practice. This article covers key forex trading tips for beginners, setting you on the path to becoming a proficient trader." },
    { header: "Why Forex Seems Difficult ?", descp: "The forex market is immense, with daily trades exceeding a trillion dollars. This vast scale often makes people think that only wealthy individuals with extensive resources can trade forex. However, modern technologies have made forex trading accessible to everyone. The difference between seasoned traders and beginners lies in their expertise, not the number of resources or tools they have. Success in forex trading is more about knowledge and timing rather than the scale of operation." },
    { header: "Understanding Forex Trading Basics", descp: "Forex trading fundamentally involves buying and selling currencies. The goal is to buy a currency at a lower price and sell it at a higher price, or vice versa. Predicting the direction of currency values accurately can lead to profitable trades. While the concept is simple, the execution requires understanding market trends and making informed decisions.Success in forex trading is attainable for anyone with the right preparation. Proper research and learning about forex trading basics are crucial before starting. Investing time in learning about forex is an investment in your future success. This knowledge will make it easier to navigate the market and reduce the challenges faced by beginners." },
    { header: "Why Consider Forex Trading Training ?", descp: "Trading forex is an opportunity to invest your money wisely. Inflation decreases the value of money over time, making it essential to find ways to grow your wealth. Learning the basics of forex trading and starting to invest can help you make a profit instead of watching your savings lose value." },
    { header: "Learning About Currency Pairs", descp: "In forex trading, transactions are conducted using currency pairs. Each pair consists of a base currency and a quote currency. For example, in the EUR/USD pair, EUR is the base currency, and USD is the quote currency. The first currency in the pair is the one you are buying or selling, while the second currency is the one you are using to make the transaction." }
  ]

  
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false); // Set loader to false after some time (simulating loading completion)
    }, 2000); // Adjust timeout as per your needs
  }, []);
  return (
    <div className="mt-[140px]">
      <div className=" container mx-auto mt-[10%]  relative ">
       

        <div className=" container h-[50vh] w-full mt-4 mb-4 currency-widget ">
          {loader ? (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          ) : (
            <div className=" banner-widget first-widget">
              <BannerWidget />
            </div>

          )
          }
        </div>
        <div className="market-title-div">
          <MarketTitle data={markettitledata} />

        </div>
      </div>





      <div className="container mx-auto flex gap-[2%] justify-between">
        <div className="w-[59%] currency-inner-content	">
          {CurrencyDataone.map((val, index) => {
            return (
              <div>
                <h2 className=" mt-8 font-bold text-xl">{val.title}</h2>
                <h2 className=" mt-4 font-bold text-xl">{val.header}</h2>
                <p className="text-lg mb-2  w-full text-base text-body-color dark:text-dark-6 	">{val.paragraph}</p>
                {val?.list?.map((val, index) => {
                  return (
                    <>
                      <div className="flex justify-start w-[100%] Course-list ">
                        <p className="w-[5%] Course-heading"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-1" style={{ marginRight: "10px" }} /> </p>
                        <p className=" course-paragraph mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">

                          {val?.descp} </p>
                      </div>
                    </>
                  );
                })}
              </div>
            );
          })}
          {CurrencyData.map((val, index) => {
            return (
              <div>
                <h2 className=" mt-4 font-bold text-xl">{val.header}</h2>
                <p className="mb-2 mt-4  w-full text-base text-body-color dark:text-dark-6	">{val.paragraph}</p>
                <div className="Course-list flex justify-start w-[100%] gap:'2%'">
                  <p className="Course-heading w-[4%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-1" style={{ marginRight: "10px" }} /> </p>
                  <p className="course-paragraph mb-2  w-[94%] text-base text-body-color dark:text-dark-6 flex justify-between	">

                    {val?.list} </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-[39%] currency-banner	">
          <SubscribeNow />
          {/* <Qutation /> */}
          <div className="banner-widget mb-6">
            <TopStories />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex  mt-4">
        <div className="w-[100%] currency-inner-content	">
          <Title data={titledata} />
          <Forexmarketcard data={formcarddata} />
          <Title data={titledataone} />
          <Forexiconcard data={Homedata} />
        </div>
      </div>
      <div className="">
        <Buttoncard data={buttondata} />
      </div> 
    </div>
  );
};

export default CurrencyParis;
