import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

const LottieAnimation = () => {
  const loadingContainer = useRef(null);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true, // Ensure the animation loops
      autoplay: true,
      animationData: require("../Lottie Animation Comp/ErrorAnimation.json"),
    });

    return () => {
      animationInstance.destroy(); // Cleanup animation on component unmount
    };
  }, []);

  return (
    <div>
      <div className="loader-container w-[140px]" ref={loadingContainer}></div>
    </div>
  );
};

export default LottieAnimation;
