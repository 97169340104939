import React from 'react'

const HeaderComp = ({data}) => {
  return (
    <div>
        <div class="-mx-4 flex flex-wrap justify-center">
          <div class="w-full px-4">
            <div class="mx-auto  max-w-[485px] text-center">
              <span class="mb-2  sm:text-xl block text-lg font-semibold text-primary">
                {data.header}
              </span>
              <h2
                class="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
              >
                {data.title}
              </h2>
              <p class="text-base text-body-color dark:text-dark-6">
                {data.description}
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default HeaderComp