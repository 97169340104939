import React, { useEffect, useState } from 'react'
import Marketinsight from "../../assets/MarketInsight.jpeg"
import Title from '../TitleComp/Title'
import { VscGraph } from "react-icons/vsc";
import { FaSpinner, FaStar, FaSearch } from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import { FaTelegramPlane } from "react-icons/fa";
import Testimonials from '../Testominal/Testominal';
import MarketHeatmapwidget from '../WidgetComp/MarketHeatmapwidget';
import Featurecard from '../FeatureCardComp/Featurecard';
import "./Marketinsight.css"

const MarketHeatmap = () => {
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false); // Set loader to false after some time (simulating loading completion)
        }, 2000); // Adjust timeout as per your needs
    }, []);
    const data = [
        {
            header: "Visualize Market Trends",
            descp1: `Visualize market trends and identify trading opportunities with INVESTORS ZON Market Heatmap. Our Market Heatmap provides a graphical representation of price movements and market sentiment across various asset classes, allowing you to quickly assess market conditions and make informed trading decisions.`
        }
    ]
    const FourTrading = [
        { header: "Key Features of the Market Heatmap" }
    ]
    const datatwo = [
        { icon: <VscGraph size={40} color='white' className='text-white' />, title: "Visual Representation", descp: "Our Market Heatmap displays price movements and market sentiment using color-coded heatmaps, making it easy to visualize trends and patterns across different asset classes. Whether you're interested in forex, stocks, commodities, or cryptocurrencies, our heatmap offers a clear and intuitive way to monitor market activity." },
        { icon: <FaStar size={40} color='white' className='text-white' />, title: "Real-time Data ", descp: "Access real-time market data and updates to stay informed about the latest price movements and market trends. Our Market Heatmap provides instant updates and refreshes, ensuring that you have access to the most current information and insights. " },
        { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Customizable Filters ", descp: "Customize your Market Heatmap to focus on specific asset classes, market sectors, or timeframes that are most relevant to your trading strategy. With customizable filters, you can tailor the heatmap to suit your individual preferences and objectives, allowing you to identify trading opportunities with precision and accuracy." },
        { icon: <HiSupport size={40} color='white' className='text-white' />, title: "User-friendly Interface", descp: "Our Market Heatmap features a user-friendly interface that is easy to navigate and understand, even for novice traders. With intuitive controls and interactive features, our heatmap provides a seamless and engaging user experience, enabling you to monitor market trends with ease and efficiency.        " },
    ]
    const buttondata = [
        { title: " Join INVESTORS ZON and Unlock the Potential of Market Heatmap Today", descp: " Visualize market trends and identify trading opportunities with INVESTORS ZON  Market Heatmap. Whether you're a beginner or an experienced trader, our heatmap offers valuable insights and intelligence to help you make informed trading decisions and stay ahead of the curve. Explore  INVESTORS ZON  Market Heatmap today and gain a deeper understanding of market dynamics and trends..", icon: <FaTelegramPlane size={20} />, button: "    SIGN UP" }
    ]
    return (
        <div>
            <div className=" container mx-auto mt-[8%]  relative ">
                <div className="relative ">
                    <img
                        src={Marketinsight}
                        alt="Paris Financial District "
                        className="w-full h-auto "
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className=" inner-text text-6xl font-bold text-white p-4">
                            Market Heatmap
                        </h1>
                    </div>
                </div>
                {data.map((val, index) => {
                    return (
                        <div key={index} className="container mx-auto mb-4 ">
                            <h2 className=" mt-8 font-bold text-2xl ">
                                {val.header}
                            </h2>
                            <p className="text-base text-body-color dark:text-dark-6">
                                {val.descp1}
                            </p>
                        </div>
                    )
                })}
                <div className="mb-6 ">
                    {loader ? (
                        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                            <FaSpinner className="animate-spin text-white" size={40} />
                        </div>
                    ) : (
                        <MarketHeatmapwidget />
                    )
                    }
                </div >
                <div className=''>
                    <Title data={FourTrading} />
                    <Featurecard data={datatwo} />
                    <Buttoncard data={buttondata} />
                    <Testimonials />
                </div>
            </div>
        </div>
    )
}
export default MarketHeatmap
