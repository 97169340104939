import React from 'react'
import Marketinsight from "../../assets/MarketInsight.jpeg"
import Marketinsightwidget from '../WidgetComp/Marketinsightwidget'
import Title from '../TitleComp/Title'
import { VscGraph } from "react-icons/vsc";
import { FaStar } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import { FaTelegramPlane } from "react-icons/fa";
import Testimonials from '../Testominal/Testominal';
import Featurecard from '../FeatureCardComp/Featurecard';
import "./Marketinsight.css";
const MarketInsight = () => {
    const data = [
        {
            header: "Gain Valuable Intelligence",
            descp1: ` Explore INVESTOR ZON Market Insights to gain valuable intelligence and stay informed about the latest developments in the financial markets. Our Market Insights provide comprehensive analysis, commentary, and forecasts across a wide range of asset classes, including forex, stocks, commodities, and cryptocurrencies. Whether you're a seasoned investor or just starting out, our insights offer the information you need to make informed trading decisions and stay ahead of the curve.`
        }
    ]
    const FourTrading = [
        { header: "Key Features of Market Insights" }
    ]
    
      const datatwo = [
        { icon: <VscGraph size={40} color='white' className='text-white' />, title: "In-depth Analysis", descp: "Our team of experienced analysts provides in-depth analysis and commentary on market trends, price movements, and key drivers affecting asset prices. From technical analysis to fundamental analysis, our insights cover a variety of perspectives to help you understand market dynamics and identify potential trading opportunities.  " },
        { icon: <FaStar size={40} color='white' className='text-white' />, title: "Expert Commentary ", descp: "Gain insights from industry experts and thought leaders who share their perspectives on market trends, economic events, and geopolitical developments. Our expert commentary provides valuable insights and actionable recommendations to help you navigate the complex world of financial markets. " },
        { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Forecasting ", descp: "Access forecasts and projections for various asset classes, including currency pairs, stock indices, commodity prices, and cryptocurrency markets. Our forecasts are based on rigorous analysis and research, providing you with valuable insights into potential market movements and trends." },
        { icon: <HiSupport size={40} color='white' className='text-white' />, title: "Educational Content ", descp: "Enhance your trading knowledge and skills with our educational content, which covers a wide range of topics, including technical analysis, fundamental analysis, risk management, and trading psychology. Whether you're a beginner or an experienced trader, our educational content provides valuable insights and guidance to help you succeed in the financial markets." },
      ]
      const buttondata = [
        {title:" Join INVESTORS ZON and Unlock the Potential of Market Insights Today ",descp:"Explore INVESTORS ZON Market Insights today and gain access to valuable intelligence that can help you make better trading decisions and achieve your financial goals.",icon: <FaTelegramPlane  size={20} />,button:"   SIGN UP"}

      ]
    return (
        <div>
            <div className=" container mx-auto mt-[8%]  relative ">
                <div className="relative mb-6 ">
                    <img
                        src={Marketinsight}
                        alt="Paris Financial District "
                        className="w-full h-auto "
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className="inner-text text-6xl font-bold text-white p-4">
                            Market Insights
                        </h1>
                    </div>
                </div>
                {data.map((val, index) => {
                    return (
                        <div className="container mx-auto mb-4 ">
                            <h2 className=" mt-8 font-bold text-2xl mb-4 ">
                                {val.header}
                            </h2>
                            <p className="text-base text-body-color mb-8 dark:text-dark-6">
                                {val.descp1}
                            </p>
                        </div>
                    )
                })}
                <div className="mb-6">
                    <Marketinsightwidget />
                </div >
                <div className=''>
                    <Title data={FourTrading} />
                    {/* <Star data={dataone} /> */}
                    <Featurecard data = {datatwo}/>
                    <Buttoncard data={buttondata} />
                    <Testimonials/>
                </div>
            </div>
        </div>
    )
}
export default MarketInsight
