import axios from 'axios';
import { URL } from '../config';

export const fetchCourse = async (courseData) => {
  try {
    const response = await axios(`${URL}/courses`);
    return response.data;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

export const fetchModulesByCourseId = async (id) => {
    try {
      const response = await axios(`${URL}/modules-by-cid/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    }
  };

  export const fetchModulesContentById = async (id) => {
    try {
      const response = await axios(`${URL}/modules-by-cid/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    }
  };

  export const fetchPublishedCourses =async()=>{
    try {
      const resp = await axios(`${URL}/published-courses`);
      return resp.data;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    }
    
  };

  export const fetchModuleContentByModuleId =async(token,id)=>{
    try {
      const resp = await axios(`${URL}/modules-content-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    }
    
  };

  export const fetchPublicModuleContentByModuleId =async(id)=>{
    try {
      const resp = await axios(`${URL}/public-modules-content-by-id/${id}`);
      return resp.data;
    } catch (error) {
      console.error('Error creating course:', error);
      throw error;
    }
    
  };

  export const fetchModuleByModuleId = async (token,id) => {
    try {
      const response = await axios(`${URL}/modules/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };

  export const fetchPublicModuleByModuleId = async (id) => {
    try {
      const response = await axios(`${URL}/public-modules/${id}`,
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };


  export const fetchSubscribedModuleByModuleId = async (token,id) => {
    try {
      const response = await axios(`${URL}/subscribed-modules/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching susbcribed module:', error);
      throw error;
    }
  };
  

  export const fetchModuleItemsById = async (token,id) => {
    try {
      const response = await axios(`${URL}/modules-content-item-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };

  export const fetchPublicModuleItemsById = async (id) => {
    try {
      const response = await axios(`${URL}/public-modules-content-item-by-id/${id}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };

  export const setStatusSkip = async (id) => {
    try {
      const response = await axios.post(`${URL}/item-skipped/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };
  export const setStatusView = async (id) => {
    try {
      const response = await axios.post(`${URL}/item-completed/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };

  export const fetchCompleteItemId = async (id) => {
    try {
      const response = await axios(`${URL}/track-module-progress/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching module:', error);
      throw error;
    }
  };

export const fetchUserProgress = async(token,id,contentId,moduleId,courseId) =>{
  
  try {
    const response = await axios.post(`${URL}/progress/complete`,{
      "moduleContentItemId":id,
      "moduleContentId":contentId,
      "moduleId":moduleId,
      "courseId":courseId
  } ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    return error;
  }
}


export const VerifyEmail =async(Email,OTP) =>{
  try{
    const response = await axios.post(`${URL}/auth/verify-email`, {

    "email":Email,
    "verificationCode":OTP
    });
    return response;
  }catch (error){
    console.error('Error Verify  Email:', error);
    return error;
  }
}

export const forgotPassword = async(data) =>{
  try {
    const response = await axios.post(`${URL}/auth/forgot-password`,{
      email:`${data}`
    });
    console.log("resp",response.data)
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}

export const resetPassword = async(data) =>{
  try {
    const response = await axios.post(`${URL}/auth/reset-password`,data);
    console.log("resp",response)
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}

export const buyCourse = async(id, token) =>{
  
  try {
    const response = await axios.post(`${URL}/course/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    console.log("resp",response.data)
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}

export const myCourses =async(token)=>{
  try {
    const resp = await axios(`${URL}/my-courses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
  
};


export const mySignalSubscription =async() =>{
  try {
    const resp = await axios(`${URL}/subscriptions-public`);
    console.log("mrj",resp)
    return resp.data;
  } catch (error) {
    console.log(`Error Subscription:`, error);
    throw error;
    
  }
}

export const resendVerificationCode = async(data) =>{
  try {
    const response = await axios.post(`${URL}/auth/resend-verification-code`,{email:data});
    console.log("resp",response)
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}








export const fetchUserCourseSubscription =async(subscriptionId,token)=>{  
    try {
      const response = await axios.post(`${URL}/user-subscribe-course-subscription`,{
        subscriptionId
    } ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } );
      return response;
    } catch (error) {
      return error;
    }
};

export const fetchUserCoursePurchaseStatus =async(token)=>{  
  try {
    const response = await axios.get(`${URL}/fetch-user-course-subscription`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    return error;
  }
};



export const fetchUserBuyCourseInfo =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/fetch-user-buy-status/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (error) {
    console.error('Error fetching Data:', error);
    throw error;
  }
  
};

export const fetchModuleProgress =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/module/${id}/progress`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching Data:', error);
    throw error;
  }
  
};

export const fetchCourseProgress =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/course/${id}/progress`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching Data:', error);
    throw error;
  }
  
};

export const updateModuleProgress =async(token,courseId,moduleId)=>{  
  try {
    const response = await axios.post(`${URL}/update-module-progress`,{
      courseId,moduleId
  } ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during module progress",error)
    return error;
  }
};

export const fetchPublishedModules =async()=>{
  try {
    const resp = await axios(`${URL}/modules`);
    return resp;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
  
};


export const buyModule = async(moduleId, token) =>{
  
  try {
    const response = await axios.post(`${URL}/module/${moduleId}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}

export const MyModuleInfo = async(token) =>{
  
  try {
    const response = await axios(`${URL}/my-modules`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error",error)
    return error;
  }
}

export const downloadCourseCertificate =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/user-course-certificate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching course certificate info:', error);
    throw error;
  }
  
}


export const downloadSusbcribedCourseCertificate =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/user-susbcribed-course-certificate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching course certificate info:', error);
    throw error;
  }
  
}

export const fetchPurchasedModule =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/purchased-module/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching purchased module:', error);
    throw error;
  }
  
}

export const downloadModuleCertificate =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/user-module-certificate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching course certificate info:', error);
    throw error;
  }
  
}

export const fetchAllDetailsUsingContentItemId =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/get-deatils-by-contentItemId/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching info:', error);
    throw error;
  }
  
}


export const fetchModulePercentage =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/module-percentage/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching  module percentage :', error);
    throw error;
  }
  
}


export const fetchBuyModules =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/buy-modules`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching buying  module  :', error);
    throw error;
  }
  
}


export const fetchSubscribedCourses =async(token)=>{
  try {
    const resp = await axios(`${URL}/my-subscribed-courses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Resp fetch susbcription",resp)
    return resp;
  } catch (error) {
    console.error('Error fetching subscribed courses:', error);
    throw error;
  }
  
}

export const fetchSubscribedCourseFullDetails=async(token,id)=>{
  try {
    const resp = await axios(`${URL}/fetch-full-course-deatils/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  } catch (error) {
    console.error('Error fetching subscribed courses info:', error);
    throw error;
  }
  
}


export const fetchUserSubsriptionProgress = async(token,id,contentId,moduleId,courseId) =>{
  
  try {
    const response = await axios.post(`${URL}/progress/subscription-complete`,{
      "moduleContentItemId":id,
      "moduleContentId":contentId,
      "moduleId":moduleId,
      "courseId":courseId
  } ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    return error;
  }
}


export const fetchSubscribedModulesByCourseId = async (token,id) => {
  try {
    const response = await axios(`${URL}/subscribed-modules-by-cid/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};


export const mySubscribedCourses =async(token)=>{
  try {
    const resp = await axios(`${URL}/my-subscribed-courses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
  
};

export const updateSubscribedModuleProgress =async(token,courseId,moduleId)=>{  
  try {
    const response = await axios.post(`${URL}/update-subriced-course-module-progress`,{
      courseId,moduleId
  } ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during module progress",error)
    return error;
  }
};

export const getUserSubscribedCourses =async(token)=>{  
  try {
    const response = await axios.get(`${URL}/get-user-subscribed-courses`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's susbcribed courses",error)
    return error;
  }
};

//get user module progress associated to individual courseID
export const getUserModuleProgress =async(token,courseId)=>{  
  try {
    const response = await axios.post(`${URL}/fetch-course-module-progress`,{courseId},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's module progress",error)
    return error;
  }
};

export const getUserCompletedModules =async(token)=>{  
  try {
    const response = await axios(`${URL}/fetch-completed-modules`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's completed modules",error)
    return error;
  }
};

export const getUserCompleteCourses =async(token)=>{  
  try {
    const response = await axios(`${URL}/fetch-user-completed-courses`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's completed courses",error)
    return error;
  }
};


export const updateUserProfile =async(token,data)=>{  
  try {
    const response = await axios.put(`${URL}/user-update`,data ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during update user profile",error)
    return error;
  }
};

export const getUserSubscribedSignalDetails =async(token)=>{  
  try {
    const response = await axios(`${URL}/my-subscriptions`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's subscribed signals",error)
    return error;
  }
};

export const getUserDetails =async(token)=>{  
  try {
    const response = await axios(`${URL}/user-profile`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user's subscribed signals",error)
    return error;
  }
};

export const updateUserPassword =async(token,data)=>{  
  try {
    const response = await axios.put(`${URL}/user-change-password`,data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during update Paswword",error)
    return error;
  }
};

export const fetchUserTransactionsDetails =async(token)=>{  
  try {
    const response = await axios(`${URL}/view-transactions`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching user transctions",error)
    return error;
  }
};

export const deleteUserById =async(token)=>{  
  try {
    const response = await axios.put(`${URL}/user-delete`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during uer deletion",error)
    return error;
  }
};

export const getSignalNotifications =async(token)=>{  
  try {
    const response = await axios(`${URL}/get-signal-notifications`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during fetching signal notification",error)
    return error;
  }
};

export const buyCourses =async(token,data)=>{  
  try {
    const response = await axios.post(`${URL}/buy-courses`,data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during buying courses",error)
    return error;
  }
};


export const fetchUserProgressContentItemId = async (token,id) => {
  try {
    const response = await axios(`${URL}/fetch-completed-contentItemId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching module:', error);
    throw error;
  }
};


export const fetchModuleItemIdStatus =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/fetch-status-contentItemId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp;
  } catch (error) {
    console.error('Error fetching status:', error);
    throw error;
  }
  
};

export const fetchModuleContentInfoByItemId =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/fetch-content-items-details/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    console.error('Error fetching result:', error);
    throw error;
  }
}

export const fetchUserSubscriptionProgressContentItemId = async (token,id) => {
  try {
    const response = await axios(`${URL}/fetch-subscribed-completed-contentItemId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching module:', error);
    throw error;
  }
};


export const fetchSubscribedModuleContentInfoByItemId =async(token,id)=>{
  try {
    const resp = await axios(`${URL}/fetch-subscribed-content-items-details/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    console.error('Error fetching result:', error);
    throw error;
  }
}