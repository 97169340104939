import React from 'react'
import CurrencyParis from '../ForexMarketPages/CurrencyParis'

const ByAssestsClass = () => {
  window.scroll(0,0)
  return (
    <div>
       <CurrencyParis/>
    </div>
  )
}

export default ByAssestsClass
