import React, { useEffect, useState } from 'react'
import { NavLink, useParams ,useNavigate, useLocation} from 'react-router-dom'
import Logo from '../../assets/0-3.jpeg';
import { Button } from "@mui/material";
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchAllDetailsUsingContentItemId, fetchModuleContentInfoByItemId, fetchModuleItemIdStatus, fetchUserProgress, fetchUserProgressContentItemId } from '../../api/CourseApi';
import "./Videoblog.css"
const VideoBlog = () => {
    const { slug } = useParams();
    window.scroll(0, 0)
    const location = useLocation();
    const { content } = location.state || {};


    // console.log("video",content)
    
            

    

    const authUser = useAuthUser();


    

    return (
        <div className='w-full relative mt-[140px] main-video-div'>
            <div className='w-[90%] m-auto'>
            <video className='video-div' style={{height:"500px", width:"100%"}} loop autoPlay>
                    <source
                      src={`https://investorszon.com${content.video}`}
                      type="video/mp4"
                    />
                  </video>
            </div>
            <div className='mt-[70px] p-5 w-[90%] m-auto'>
             <div dangerouslySetInnerHTML={{ __html: content?.body }} />
            {/*{!isCompleted && (
                    <Button style={{ marginRight: "10px" }} className="h-[6vh] w-[10vw] mt-12" variant="contained" onClick={progressHandler}>Complete</Button>
                )} */}
            </div>
        </div>
    );
}

export default VideoBlog;
