import React from 'react'
import HeaderComp from '../SubComponents/HeaderComp'
import MarketCourseCard from '../SubComponents/MarketCourseCard'
import Image from '../../assets/EconimicCalendar.png';
import Imageq from '../../assets/Marketinsight.avif';
import Image3 from '../../assets/Newsanalysis.webp';
import Image4 from '../../assets/TradingCal.png';
import Image5 from '../../assets/trading-img.jpg';
const Tools = () => {
    window.scroll(0, 0)
    const courseone = [
      { subscription: "Read More", href: "/market-insight", img: Imageq, title: "  Market Insight ", description: " Market Insight provides a comprehensive analysis of current trends, economic indicators, and investment opportunities, empowering informed decision-making for investors.      " },
      { subscription: "Read More", href: "/economic-calendar", img: Image, title: "Economic calendar", description: "An Economic Calendar highlights key financial events, announcements, and indicators, guiding investors and analysts in understanding market movements and economic trends.    " },
      { subscription: "Read More", href: "/news-and-analysis", img: Image3, title: "News and Analysis", description: "News and Analysis provide insights into market trends, economic developments, and financial events, helping investors make informed decisions and stay updated on current affairs." },
      { subscription: "Read More", href: "/trading-calculator", img: Image4, title: " Trading Calculator", description: "Trading Calculator assists traders in calculating potential profits, losses, and risks associated with various trades, helping to optimize trading strategies and manage investment portfolios effectively.       ," },
      { subscription: "Read More", href: "/market-heatmap", img: Image5, title: "Market Heatmap", description: "Market Heatmap offers a comprehensive, visual overview of market performance, highlighting trends and movements across various sectors" },
    ]
    const headerdata = { header: " Tools", title: "Explore Different Tools", description: "Trading tools include essential resources like charting software, risk calculators, economic calendars, and news aggregators. These tools are pivotal for refining trading strategies and staying informed about market trends." }
  return (
    <div>
      <div className='w-4/5 mx-auto mt-24 mt-[140px] ' >
        <div className=' mt-[10%] mb-8'>
          <HeaderComp data={headerdata} /> 
        </div>
        <MarketCourseCard data={courseone} />
      </div>
    </div>
  )
}
export default Tools
