import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchModuleItemsById, fetchPublicModuleItemsById,fetchModuleContentByModuleId, fetchPublicModuleContentByModuleId, fetchUserProgressContentItemId } from "../../api/CourseApi";
import { Button } from "@mui/material";
import { FaBook } from "react-icons/fa6";
import { MdLock } from "react-icons/md";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import "./AccordianComp.css";
import { IoClose } from 'react-icons/io5';
import BuyAnimation from "../Lottie Animation Comp/BuyAnimation";
import ConfirmModal from "../Modals/confirmed";
import { IoCloseCircleOutline } from "react-icons/io5";






const AccordionItem = ({
  header,
  id,
  isUserProgress,
  setTotalModuleItemsCount,
  isPurchased,
  IdToFind,
  info
}) => {
  const [active, setActive] = useState(false);
  const [moduleItemData, setModuleItemData] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);

  const authUser = useAuthUser();
  const fetchModuleItemsInfo = async () => {
    const resp = await fetchModuleItemsById(authUser?.token, id);
    setModuleItemData(resp);
    setTotalModuleItemsCount(resp.length);
  };
  const InfoAlert = () => {
    return (
      <div className="py-2 bg-white dark:bg-dark absolute t-5">
        <div className="container">
          <div className="shadow-1 dark:bg-dark-2 flex w-full rounded-lg border-l-[6px] border-transparent bg-white px-7 py-8 md:p-9">
            <div className="bg-cyan-light-2/30 text-cyan-dark mr-5 flex h-[34px]  items-start justify-center rounded-md">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.99998 0.506256C4.3031 0.506256 0.506226 4.30313 0.506226 9.00001C0.506226 13.6969 4.3031 17.5219 8.99998 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 8.99998 0.506256ZM8.99998 16.2563C5.00623 16.2563 1.77185 12.9938 1.77185 9.00001C1.77185 5.00626 5.00623 1.77188 8.99998 1.77188C12.9937 1.77188 16.2562 5.03438 16.2562 9.02813C16.2562 12.9938 12.9937 16.2563 8.99998 16.2563Z"
                  fill="#0B76B7"
                />
                <path
                  d="M10.125 7.65001H7.87496C7.53746 7.65001 7.22809 7.93126 7.22809 8.29688V13.9219C7.22809 14.2594 7.50934 14.5688 7.87496 14.5688H10.125C10.4625 14.5688 10.7718 14.2875 10.7718 13.9219V8.29688C10.7718 7.93126 10.4625 7.65001 10.125 7.65001ZM9.50621 13.275H8.52184V8.91563H9.50621V13.275Z"
                  fill="#0B76B7"
                />
                <path
                  d="M8.99996 3.45938C8.04371 3.45938 7.22809 4.24688 7.22809 5.23126C7.22809 6.21563 8.01559 7.00313 8.99996 7.00313C9.98434 7.00313 10.7718 6.21563 10.7718 5.23126C10.7718 4.24688 9.95621 3.45938 8.99996 3.45938ZM8.99996 5.70938C8.71871 5.70938 8.49371 5.48438 8.49371 5.20313C8.49371 4.92188 8.71871 4.69688 8.99996 4.69688C9.28121 4.69688 9.50621 4.92188 9.50621 5.20313C9.50621 5.48438 9.28121 5.70938 8.99996 5.70938Z"
                  fill="#0B76B7"
                />
              </svg>
            </div>
            <div className="w-full">
              <h5 className="mb-3 text-lg font-semibold text-cyan-dark">
                Information
              </h5>
              <p className="text-base leading-relaxed text-body-color dark:text-dark-6">
              In order to view this content inside ,you need to purchase the module or cousrse .
              </p>
            </div>
            <button onClick={()=>setModalVisible(false) }><IoCloseCircleOutline size={20}/></button>
          </div>
        </div>
      </div>
    );
  };

  const fetchPublicModuleItemsInfo = async () => {
    const resp = await fetchPublicModuleItemsById(id);
    setModuleItemData(resp);
    setTotalModuleItemsCount(resp.length);
  };

  useEffect(() => {
    if (authUser) {
      fetchModuleItemsInfo();
    } else {
      fetchPublicModuleItemsInfo();
    }
  }, []);
  console.log("moduleitemdata",moduleItemData)

  const navigate = useNavigate();
  const handleToggle = () => {
    setActive(!active);
  };

  const viewHandler = async (val) => {
    // console.log("+++++++++++++++++++++++clg",val)
    navigate(`/previewCourse/${val.slug}`,{ state: { id:val?.id } });
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setModalVisible(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadingContainer = useRef(null);

  const getStatus = (itemId, currentIndex, data) => {
    if (IdToFind === null || IdToFind === undefined) {
      return currentIndex === 0 ? "start" : "disabled";
    }

    console.log("id to find",IdToFind)

    const paramIndex = data.findIndex(item => item.id === IdToFind);
    if (currentIndex <= paramIndex) {
      return 'done';
    } else if (itemId <= IdToFind) {
      return 'done';
    } else if (currentIndex === (paramIndex + 1)) {
      return 'start';
    } else {
      return 'disabled';
    }
  };

  const handlePurchaseCourse = async (val) => {
    if (!authUser) {
      navigate("/signin");
    } else {
    
      handlePurchase();
    }
  };

  const handlePurchase = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    // navigate("/dashboard");
  };
  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/BuyAnimation.json"),
    });

    return () => {
      animationInstance.destroy();
    };
  }, []);
console.log(getStatus, "!!!!!!!!!")
  return (
    <div className="flex items-center w-[100%] justify-between main-Accordion">
      <div className="mb-4 w-[80%] main-Accordion rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
        <button className="faq-btn flex w-full text-left" onClick={handleToggle}>
          <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
            <svg
              className={`fill-primary stroke-primary duration-200 ease-in-out ${
                active ? "rotate-180" : ""
              }`}
              width="17"
              height="10"
              viewBox="0 0 17 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                fill=""
                stroke=""
              />
            </svg>
          </div>
          <div className="w-full">
            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
              {header}
            </h4>
            {authUser && <button>{isUserProgress ? "InProgress" : "Start"}</button>}
          </div>
        </button>

        <div
          className={`main-title pl-[62px] duration-200 ease-in-out ${
            active ? "block" : "hidden"
          }`}
        >
          <div className="title p-5">
            {moduleItemData.map((val, index) => (
              <div key={index} className="flex items-center justify-start w-[100%] mb-3 bg-white-600">
                <p className="text-lg mr-4 flex items-center w-[10%] justify-start">
                  <FaBook className="mr-4 text-blue-800" />
                 
                </p>
                <p className="text-lg mr-4 flex items-center w-[90%] justify-start">
                {val.title}
                </p>
                <div className="flex button-div">
                  {!isPurchased && (
                    <Button
                      style={{ marginRight: "10px" }}
                      className="h-[4vh] w-[8vw] lock"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      <MdLock size={30} className="inner-button-div mr-2" /> Unlock
                    </Button>
                  )}
                  {authUser && isPurchased && (
                    <Button
                      style={{
                        marginRight: "10px",
                        background: getStatus(val.id, index, moduleItemData) === "done"
                          ? "blue"
                          : getStatus(val.id, index, moduleItemData) === "start"
                          ? "green"
                          : "gray",
                      }}
                      className="h-[4vh] w-[6vw]"
                      variant="contained"
                      disabled={getStatus(val.id, index, moduleItemData) === "disabled"}
                      onClick={() => viewHandler(val)}
                    >
                      {getStatus(val.id, index, moduleItemData) === "done"
                        ? "View"
                        : getStatus(val.id, index, moduleItemData) === "start"
                        ? "Start"
                        : "Disabled"}
                    </Button>
                  )}
                  {open && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg w-1/4 flex flex-col items-center justify-center relative">
      <div className="p-4 border-b w-full flex justify-between items-center">
        <h2 className="text-lg font-semibold">Unlock Module</h2>
        <button
          className="text-gray-700"
          onClick={handleClose}
        >
          <IoClose size={24} />
        </button>
      </div>
      <div className="w-[200px] flex items-center justify-center">
        <div className="loader-container" ref={loadingContainer}></div>
   
      </div>
      <div className="p-4 w-full border-t flex justify-end">
        <button
          className="bg-blue-500 text-white rounded px-4 py-2"
          onClick={handlePurchaseCourse()}
        >
            Buy Now
        
        </button>
      </div>
    </div>
  </div>
)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <ConfirmModal
      setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        type={'module'}
        data={info}
      /> */}

{isModalVisible && (
  <InfoAlert/>
  )
}

    </div>
  );
};

const AccordionComp = ({ id, setTotalModuleItemsCount, isPurchased,info }) => {
  const [moduleContentList, setModuleContentList] = useState([]);
  const [idToFind, setIdToFind] = useState(null);
  const authUser = useAuthUser();

  const fetchModuleItems = async () => {
    const resp = await fetchModuleContentByModuleId(authUser?.token, id);
    setModuleContentList(resp);
  };

  const fetchPublicModuleItems = async () => {
    const resp = await fetchPublicModuleContentByModuleId(id);
    setModuleContentList(resp);
  };

  const fetchItemIds = async () => {
    try {
      const resp = await fetchUserProgressContentItemId(authUser?.token, id);
      if (resp.status === 400 && resp.message === "Progress not found") {
        console.error("Error: Progress not found");
      } else {
        setIdToFind(resp.moduleContentItemId);
      }
    } catch (error) {
      console.error("Error fetching item IDs:", error);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchModuleItems();
      fetchItemIds();
    } else {
      fetchPublicModuleItems();
    }
  }, [id]);

  return (
    <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] text-center lg:mb-20">
              <h2 className="mb-4 text-3xl text-left font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                Module Content
              </h2>
              <p className="text-base text-left w-[60%] text-body-color dark:text-dark-6">
                This course offers a comprehensive overview of key concepts,
                theories, and practical applications in the subject area. It
                covers fundamental principles, advanced techniques, and
                real-world examples, ensuring students gain a solid
                understanding and hands-on experience. Suitable for beginners
                and advanced learners.
              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4 w-[65%] main-Accordion">
          <div className="px-4">
            {moduleContentList?.map((val, index) => (
              <AccordionItem
                key={index}
                header={val.title}
                id={val.id}
                isUserProgress={val?.inUserProgress}
                setTotalModuleItemsCount={setTotalModuleItemsCount}
                isPurchased={isPurchased}
                IdToFind={idToFind}
                info={info}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.36" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default AccordionComp;
