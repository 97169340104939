import lottie from "lottie-web";
import React, { useEffect, useState, useRef } from "react";
import { NavLink } from 'react-router-dom';

import Registerform from './registerationformComp/Registerform';
import "./Signup.css"

const Signup = () => {
  window.scroll(0, 0)
    const loadingContainers = useRef(null);
  useEffect(() => {
   lottie.loadAnimation({
      container: loadingContainers.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/SignupRightAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);
  return (
    <>
      <div className=' mt-[5%] w-full flex justify-between item-center main-signup'>
        <div className='w-[50%] m-auto lg:w-[60%] inner-signup'>
          <section className=" py-[8%] lg:py-[3%] dark:bg-dark mt-[3%] mb-[3%]">
            <div className="container">
              <div className=" w-[100%] flex flex-wrap -mx-4 signup-div">
                <div className="w-[100%] px-4 inner-signup">
                  <div
                    className="wow fadeInUp relative max-w-[100%] overflow-hidden rounded-xl border hover:shadow-lg bg-white dark:bg-dark-2 py-4 px-8 text-center sm:px-12 md:px-[60px] main-form-div"
                    data-wow-delay=".15s">
                    <div className= " w-[100%] mb-10 text-center">
                      <a href="javascript:void(0)" className="mx-auto inline-block max-w-[260px]">
                        <h2 className="font-bold text-3xl Signup-div ">Sign Up Account</h2>
                        {/* <img src={logo} alt="logo" className="" /> */}
                      </a>
                    </div>


                    <Registerform />



                    <p className="text-base text-body-color dark:text-dark-6 already-account">
                      <span className="text-base text-body-color dark:text-dark-6 ">Already have an account?  </span>
                      <NavLink to="/signin" className="text-primary hover:underline">  Login</NavLink>
                    </p>
                    <div>

                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='w-[50%]  lg:w-[40%] lottie-div  '>
          <div className="loader-container mt-24 w-[80%]  m-auto" ref={loadingContainers}></div>
        </div>
      </div>
    </>
  );
};

export default Signup;