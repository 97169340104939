import React from 'react'
import TradingAbout from '../About copy/TradingsignalAbout'
import Cta from '../CTA/Cta'
import Testominal from "../Testominal/Testominal";
import Header from '../Header/Header';
import Faq from '../Faq/Faq';

const   TradingSignal = () => {
  window.scroll(0,0)
  const TradingSignalone =[
    {title:"Seasoned and trustworthy market analysts with extensive experience.    ",button:"View Services", description:"We are a seasoned team of analysts providing top-notch reports on Crypto and Forex markets. Our analyses empower you to learn and independently make informed investment decisions, ensuring the best options for your investments.    "}
  ]
  const TradingSignaltwo =[
    {header:"Signals",subheader:" Investor  has helped over 150000 Happy Clients.", testimonial:"We have been providing our services to Investors & Businesses for more than 5 years and we have helped over 80 thousand Happy Customers so far. We have more than 10 years of experience in Crypto and Forex Markets. Our Goal is to offer You Quality Professional Services and with all our knowledge, efforts and experience guide you to become a formed investor.    "}
  ]
  const TradingSignalthree  =[
{subtitle:"Unlock the Advantages of Investors zon",
  description:"Discover the advantages that Investors zon brings to your financial journey:"

}

  ]
  const TradingSignalfour   =[
    {title:"Live Telegram alerts.    ",button:"View Services", description:"WWe provide analysis exclusively through our Free and Premium Telegram channels, ensuring that you receive live alerts and timely information. Most of our content is shared between 10 AM and 10 PM (UTC+1), allowing clients worldwide to benefit from our updates.    "}
  ]
  return (
    <>
   
      <TradingAbout/>
      {/* <Cta data={TradingSignalone} /> */}
      {/* <Testominal  data ={TradingSignaltwo}/> */}
      <Faq data={TradingSignalthree}/>

      {/* <Cta data={TradingSignalfour} /> */}

    </>
  )
}

export default TradingSignal
