import lottie from "lottie-web";
import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { FaRegCheckCircle, FaSpinner } from "react-icons/fa";
import { URL } from "../../config";
import { IoCloseCircleOutline, IoCloseSharp } from "react-icons/io5";
import { MdError } from "react-icons/md";
import { VerifyEmail, resendVerificationCode } from "../../api/CourseApi";
import "./style.css"
import ErrorLottie from "../Lottie Animation Comp/ErrorLottie";
import LottieGreenCheck from "../Lottie Animation Comp/LottieGreenCheck";
const Signin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [showModal, setShowModal] = useState(false); // Define showModal state
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [otpCode, setOtpCode] = useState();
  const [verifyEmail, setverifyEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [loader, setLoader] = useState(false);
  const [enterVerificationCode, setEnterVerificationCode] = useState(false);

  const signIn = useSignIn()
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.email || !formData.password) {
      setErrorMsg("Please Enter Valid credentails!")
      setFormError(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${URL}/auth/login`, formData);
      // console.log("resp", response)
      if (
        signIn({
          auth: {
            token: `${response.data.token}`,
          },
          userState: { name: `${response.data.name}`, uid: response.data.uid, token: `${response.data.token}`, role: response.data.role, email: response.data.email },
        })
      ) {

        const redirectTo = localStorage.getItem('redirectAfterLogin') || '/dashboard';
        localStorage.removeItem('redirectAfterLogin');

        navigate(redirectTo);
      } else {

        alert("Error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.response.data.error);
      if (error.response.data.error === "Email is not verified") {
        setEmailNotVerified(true);
        setLoading(false)
        return;
      }
      setErrorMsg(error?.response?.data?.error)
      setFormError(true)
      setShowPopup(true);
    }
    setLoading(false);
  };


  const [otp, setOtp] = useState(Array(5).fill("")); // Array with 6 empty strings
  const inputRefs = useRef([]); // Array of refs for each input field

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const index = inputRefs.current.indexOf(target);

    if (key === "Backspace" || key === "Delete") {
      e.preventDefault();
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        return newOtp;
      });
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target);

    if (target.value) {
      setOtp((prevOtp) => {
        const newOtp = [
          ...prevOtp.slice(0, index),
          target.value,
          ...prevOtp.slice(index + 1),
        ];
        const otpString = newOtp.join(''); // Convert array to string
        setOtpCode(Number(otpString)); // Use otpString as needed
        return newOtp;
      });

      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };


  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    // e.preventDefault();
    // const text = e.clipboardData.getData("text");
    // if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
    //   return;
    // }
    // const digits = text.split("");
    // setOtp(digits);
  };

  const verifyHandler = async () => {
    setEmailNotVerified(false)
    setverifyEmail(true)
  };

  const verifyEmailHandler = async () => {
    setLoader(true)
    try {
      const resp = await VerifyEmail(formData.email, otpCode);
      if (resp.status === 200) {
        setSuccess(true)
        setSuccessMsg({ message: resp.data.message })
        setLoader(false)
        setEnterVerificationCode(false)
      } else {
        setSuccess(true)
        setSuccessMsg({ message: resp.response.data.error })
        setLoader(false)
        setEnterVerificationCode(false)
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoader(false)
    setEmailNotVerified(false)
  }
  // useEffect(() => {
  //   const isValid = formData.email !== "" && formData.password !== "";
  //   setIsFormValid(isValid);
  // }, [formData]);

  // console.log("otpCode ", otpCode, formData.email)

  const resendCodeHandler = async () => {
    setLoader(true)
    const resp = await resendVerificationCode(formData.email);
    if (resp.status === 201) {
      setLoader(false)
      setEnterVerificationCode(true)
    }
    else {
      setLoader(false)
      setSuccess(true)
      setSuccessMsg({ message: resp.response.data.error, icon: <MdError size={30} color="red" /> })
    }
    // setEmailNotVerified(false)
  };
  const loadingContainer = useRef(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/SigninAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);


  const loadingContainers = useRef(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: loadingContainers.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/SigninRightAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);
  return (
    <>
      <div className="flex mt-[10.5%] h-[70vh] w-[80vw] m-auto justify-center gap-0 main-signin">
        <div className=" w-[50%] h-[70%] pt-14 inner-sign-div">
          {loading && (
            // <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-100 bg-opacity-50">
            //                 <div className="loader-container  " ref={loadingContainer}></div>

            // </div>

            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          )}

          {loader && (

            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          )}

          {emailNotVerified && (
            <div className=" fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <div className=" main-verify-pending w-[40vw] h-[33vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
                <IoCloseCircleOutline onClick={() => setEmailNotVerified(false)} size={30} style={{ float: "right", cursor: "pointer" }} />
                <div className=" verify-pending flex flex-col items-center justify-center  text-white">
                  {/* <FaFaceSmileBeam color='green' className='mb-2' size={30} /> */}

                  <h2 className="text-2xl text-black font-semibold">Your Email Verification Pending</h2>
                  <p className="text-lg text-gray-600 mt-3 ">Please Verify Your Email</p>
                </div>
                <div className='flex justify-center mt-5'>
                  <button
                    className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={verifyHandler} >
                    Verify Email
                  </button>
                  <button onClick={resendCodeHandler} className='border border-gray-500 rounded px-3 py-2 ml-5'>Resend Code</button>
                </div>
              </div>
            </div>
          )}
          {verifyEmail && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <div className="Sigin-in-verify-code w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
                <IoCloseCircleOutline onClick={() => setverifyEmail(false)} size={30} style={{ float: "right", cursor: "pointer" }} />
                <div className="flex flex-col items-center justify-center  text-white">
                  {/* <FaFaceSmileBeam color='green' className='mb-2' size={30} /> */}

                  <h2 className="text-2xl text-black font-semibold"> Please Enter Verification Code </h2>
                </div>

                <section className="bg-white py-5 dark:bg-dark ">
                  <div className="container  flex items-center justify-center">
                    <form id="otp-form" className="flex gap-2">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={handleInput}
                          onKeyDown={handleKeyDown}
                          onFocus={handleFocus}
                          onPaste={handlePaste}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className="input-box-otp shadow-xs flex w-[64px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
                        />
                      ))}
                    </form>
                  </div>
                </section>

                <div className='flex justify-center mt-2'>
                  <button
                    className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={verifyEmailHandler}
                  >

                    Submit
                  </button>

                </div>
              </div>
            </div>
          )}
          {success && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <div className="Sigin-in-verify-code w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
                <IoCloseCircleOutline onClick={() => { setSuccess(false); setverifyEmail(false); setEmailNotVerified(false); }} size={30} style={{ float: "right", cursor: "pointer" }} />
                <div className=" flex flex-col items-center justify-center  text-white  mt-2">
                  <h2 className="text-2xl text-black font-semibold text-center">{successMsg.message}</h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {successMsg.message === "Invalid verification code" ? <ErrorLottie /> : <LottieGreenCheck />}
                </div>
              </div>
            </div>
          )}

          {enterVerificationCode && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <div className=" Sigin-in-verify-code-resend w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
                <IoCloseCircleOutline onClick={() => { setEnterVerificationCode(false); setEmailNotVerified(false) }} size={30} style={{ float: "right", cursor: "pointer" }} />
                <div className="flex flex-col items-center justify-center  text-white">
                  {/* <FaFaceSmileBeam color='green' className='mb-2' size={30} /> */}

                  <h2 className="text-2xl text-black font-semibold"> Please Enter Verification Code </h2>
                </div>

                <section className="bg-white py-5 dark:bg-dark ">
                  <div className="container  flex items-center justify-center">
                    <form id="otp-form" className="flex gap-2">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={handleInput}
                          onKeyDown={handleKeyDown}
                          onFocus={handleFocus}
                          onPaste={handlePaste}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className="input-box-otp shadow-xs flex w-[64px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
                        />
                      ))}
                    </form>
                  </div>
                </section>

                <div className='flex justify-center mt-2'>
                  <button
                    className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={verifyEmailHandler}
                  >

                    Submit
                  </button>
                </div>
              </div>
            </div>





          )}

          <section className="py-[3%]  -mt-6">
            {formError && (
              <div className="absolute right-0 bottom-[15%] z-20 w-full max-w-xs p-4 mx-auto bg-white rounded-lg shadow text-gray-500 dark:text-red-400 dark:bg-red-800">
                <div className="flex items-center space-x-2 mb-2">
                  <MdError color="red" size={30} />
                  <span className="text-sm font-medium">{errorMsg}</span>
                </div>
                <button
                  onClick={() => setFormError(false)}
                  className="absolute top-3 right-2 mt-1 mr-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  <IoCloseSharp size={20} />
                </button>
              </div>
            )}



            <div className="container flex justify-center max-w-[525px]  sm:px-6 md:px-8 form-div ">
              <div className=" w-full mx-auto bg-white dark:bg-dark-2 py-8 px-6 rounded-lg border hover:shadow-md text-center ">
                <div className="mb-6">
                  <h2 className="font-bold text-3xl uppercase">Login</h2>
                  {/* <img src={logo} alt="logo" className="mx-auto max-w-[160px]" /> */}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 text-base transition bg-transparent border rounded-md outline-none border-gray-300 dark:border-dark-3 text-body-color dark:text-dark-6 placeholder-gray-400 dark:placeholder-dark-4 focus:border-primary dark:focus:border-primary focus:shadow-outline-primary dark:focus:shadow-outline-dark"
                    />
                  </div>
                  <div className="mb-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-3 text-base font-medium text-white transition duration-300 ease-in-out bg-primary border border-transparent rounded-md cursor-pointer hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-primary dark:focus:ring-dark-2"
                    >
                      {loading ? "Signing In..." : "Sign In"}
                    </button>
                  </div>
                </form>
                <NavLink
                  to='/forgot-password'
                  className="inline-block mb-2 text-base text-blue-400  hover:underline cursor-pointer"
                >
                  Forgot Password?
                </NavLink>
                <p className="text-base text-gray-600 dark:text-body-secondary">
                  Not a member yet?{" "}
                  <NavLink
                    to="/signup"
                    className="text-blue-500 hover:underline dark:text-blue-400"
                  >
                    Sign Up
                  </NavLink>
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className=" w-[50%] sigin-banner ">
          <div className=" loader-container w-[100%] h-[100%]  " ref={loadingContainers}></div>

        </div>
      </div>
    </>
  );
};

export default Signin;
