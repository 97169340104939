import React, { useEffect, useState } from 'react'
import Marketinsight from "../../assets/MarketInsight.jpeg"
import Title from '../TitleComp/Title'
import { VscGraph } from "react-icons/vsc";
import { FaStar,FaSearch } from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import { FaTelegramPlane } from "react-icons/fa";
import Testimonials from '../Testominal/Testominal';
import EconomicCalendarwigdet from '../WidgetComp/EconomicCalendarwigdet';
import Featurecard from '../FeatureCardComp/Featurecard';
import "./Marketinsight.css"
import { FaSpinner } from "react-icons/fa";

const EconomicCalendar = () => {
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoader(false); // Set loader to false after some time (simulating loading completion)
        }, 2000); // Adjust timeout as per your needs
    }, []);
    const data = [
        {
            header: "Stay Ahead of Key Events",
            descp1: `Stay ahead of important economic events and announcements with Investors Zon Economic Calendar. Our Economic Calendar provides a comprehensive overview of key economic indicators, central bank meetings, and other events that may impact the financial markets. Whether you're a short-term trader or a long-term investor, our Economic Calendar helps you plan your trading strategy accordingly and avoid unexpected market volatility.`
        }
    ]
    const FourTrading = [
        { header: "Key Features of the Economic Calendar" }
    ]
    const datatwo = [
        { icon: <VscGraph size={40} color='white' className='text-white' />, title: "Comprehensive Coverage", descp: "Our Economic Calendar covers a wide range of economic indicators, including GDP growth, inflation rates, employment figures, interest rate decisions, and more. With comprehensive coverage of key economic events, you can stay informed about the factors driving market movements and trends." },
        { icon: <FaStar size={40} color='white' className='text-white' />, title: "Real-time Updates ", descp: "Get real-time updates on economic events and announcements as they happen. Our Economic Calendar provides instant notifications and alerts for important events, ensuring that you never miss a market-moving development. " },
        { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Filtering Options ", descp: "Customize your Economic Calendar to focus on specific countries, regions, or economic indicators that are most relevant to your trading strategy. With filtering options, you can tailor the calendar to suit your individual needs and preferences." },
        { icon: <HiSupport size={40} color='white' className='text-white' />, title: "Historical Data", descp: "Access historical data and charts for past economic events, allowing you to analyze trends and patterns over time. By studying historical data, you can gain valuable insights into how economic events have impacted the financial markets in the past and make more informed trading decisions in the future." },
    ]
    const buttondata = [
        { title: " Join INVESTORS ZON and Unlock the Potential of Economic Calendar Today ", descp: "Stay ahead of key economic events and announcements with Investors Zon Economic Calendar. Whether you're a beginner or an experienced trader, our Economic Calendar provides valuable insights and intelligence to help you navigate the financial markets with confidence.", icon: <FaTelegramPlane size={20} />, button: "    SIGN UP" }
    ]
    return (
        <div>
            <div className=" main-market-insight-image-div container mx-auto mt-[8%]  relative ">
                <div className="relative ">
                    <img
                        src={Marketinsight}
                        alt="Paris Financial District "
                        className="w-full h-auto "
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className="inner-text text-6xl font-bold text-white p-4">
                            Economic Calendar
                        </h1>
                    </div>
                </div>
                {data.map((val, index) => {
                    return (
                        <div key={index} className="container mx-auto mb-6 ">
                        <h2 className=" mt-8 mb-4 font-bold text-2xl ">
                            {val.header}
                        </h2>
                        <p className="text-base text-body-color dark:text-dark-6">
                            {val.descp1}
                        </p>
                    </div>
                    )
                })}
                <div className=" mb-4">
                    {loader ? (
                        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                            <FaSpinner className="animate-spin text-white" size={40} />
                        </div>
                    ) : (
                        <EconomicCalendarwigdet />)
                    }
                </div >
                <div className=''>
                    <Title data={FourTrading} />
                    <Featurecard data={datatwo} />
                    <Buttoncard data={buttondata} />
                    <Testimonials />
                </div>
            </div>``
        </div>
    )
}
export default EconomicCalendar
