
import React, { useState,useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import Footer from '../Footer/Footer';
import Header from '../Navbar/HeaderComp/Header';
import Nav from '../Nav/Nav';
import { getSignalNotifications } from '../../api/CourseApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut'
import { FaSpinner } from "react-icons/fa";
import Spinner from './Spinner';
import LoaderContext from './LoaderContext';

const Common = ({ children }) => {
  const [isForex, setIsForex] = useState(true);
  const location = useLocation(); // Get the current location using useLocation hook
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const authUser = useAuthUser();
  const signOut = useSignOut();
  if (authUser) {
    if (authUser.role && authUser.role !== "user") {
      signOut();
      window.location.reload()


    }

  }

  if (!authUser) {
    const excludePaths = ['/signin', '/signup'];
    const excludePrefixes = ['/single-course-details', '/single-module-details'];
    const path = location.pathname;
    // console.log("Current path:", path);

    const isExcluded = excludePaths.includes(path) || excludePrefixes.some(prefix => {
      const startsWithPrefix = path.startsWith(prefix);
      return startsWithPrefix;
    });
    // console.log("Is excluded:", isExcluded);

    if (!isExcluded) {
      localStorage.setItem('redirectAfterLogin', path);
    }
  }

  const fetchSignalNotifications = async () => {
    const notifications = await getSignalNotifications(authUser?.token);
    console.log("notifications", notifications)
  };


  useEffect(() => {
    // Check if the pathname is "/abc"
    if (location.pathname === "/dashboard") {
      setIsForex(false);
    } else {
      setIsForex(true);
    }
  }, [location.pathname]);



  useEffect(() => {
    if (authUser) {
      fetchSignalNotifications()
    }
  }, [authUser])


  
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 3000); 
  }, []);
  return (
    <>

      {isLoading ? (

        <Spinner />
      ) : (
        <>


          <Header />
          <Nav />
          {children}
          <Footer />

        </>)
      }

    </>
  );
};

export default Common;
