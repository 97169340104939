import React from 'react'
import { NavLink } from 'react-router-dom'

const Cta = ({data}) => {
  window.scroll(0,0)
  return (
    <>
    <section
      className="relative z-10 overflow-hidden bg-primary py-20 lg:py-[115px]"
    >
     {data?.map((val, index) => {
      return(
      <div key={index} className="container mx-auto">
        <div className="relative overflow-hidden ">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[570px] text-center">
                <h2
                  className="mb-2.5 text-3xl font-bold text-white md:text-[38px] md:leading-[1.44]"
                >
                  <span>
                    {/* What Are You Looking For? */}
                    {val.title}</span>
                  <span className="text-3xl font-normal md:text-[40px]">
                    {/* Get Started Now */}
                    {val.header}
                  </span>
                </h2>
                <p
                  className="mx-auto mb-6 max-w-[515px] text-base leading-[1.5] text-white"
                >
                  {/* There are many variations of passages of Lorem Ipsum but the
                  majority have suffered in some form.  */}
                  {val.description}
                </p>
                {val.button &&
                <NavLink
                   target='_blank'
                         to="https://investment.investorszon.com/ "
                  className="inline-block rounded-md border border-transparent hover:bg-blue-500 hover:text-white bg-white px-7 py-3 text-base font-medium text-black transition "
                >
                  
                  {val.button}
                  {/* Start using Play */}
                
                </NavLink>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    )}
      <div>
        <span className="absolute left-0 top-0">
          <svg
            width="495"
            height="470"
            viewBox="0 0 495 470"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="55"
              cy="442"
              r="138"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="50"
            />
            <circle
              cx="446"
              r="39"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="20"
            />
            <path
              d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z"
              stroke="white"
              stroke-opacity="0.08"
              stroke-width="12"
            />
          </svg>
        </span>
        <span className="absolute bottom-0 right-0">
          <svg
            width="493"
            height="470"
            viewBox="0 0 493 470"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="462"
              cy="5"
              r="138"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="50"
            />
            <circle
              cx="49"
              cy="470"
              r="39"
              stroke="white"
              stroke-opacity="0.04"
              stroke-width="20"
            />
            <path
              d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z"
              stroke="white"
              stroke-opacity="0.06"
              stroke-width="13"
            />
          </svg>
        </span>
      </div>
      
     
    </section>
      
    </>
  )
}

export default Cta
