import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner, FaRegCheckCircle } from "react-icons/fa";
import { VerifyEmail } from '../../../api/CourseApi';
import { URL } from '../../../config';
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdOutlineError } from 'react-icons/md';
import "./Registerform.css";
import LottieGreenCheck from '../../Lottie Animation Comp/LottieGreenCheck';
import countrycode from "./Countrycode.json";
import Country from './Country.json';
import ErrorLottie from '../../Lottie Animation Comp/ErrorLottie';

const validationSchema = yup.object().shape({
  name: yup.string().matches(/^[A-Za-z\s]+$/, 'Enter valid name').required('Name is required').max(35, 'Name cannot exceed 35 characters').min(2, 'Name must be at least 2 characters')   ,
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string()
    .min(8, 'Password must be 8 characters')
    .matches(/[A-Z]/, 'Password must have at least one uppercase letter')
    .matches(/[0-9]/, 'Password must have at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must have at least one special character')
    .required('Password is required'),  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  countryCode: yup.string().required('Country code is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  gender: yup.string().required('Gender is required'),
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
});

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showNewPopup, setShowNewPopup] = useState(false);
  const [showNestedPopup, setShowNestedPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [otpCode, setOtpCode] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [reenterOtp, setReenterOtp] = useState(false);
  const [Registersucess, setRegistersucess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    gender: '',
    address: '',
    country: '', 
  });

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
    
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    setFormData(data);
  
    data.phoneNumber = `${data.phoneNumber}`;

    try {
      const response = await axios.post(`${URL}/auth/register`, data);
      setTimeout(() => {
        setLoading(false);
        setShowPopup(true);
       
        // navigate('/signin'); // You can navigate after the popup is closed
      }, 3000);
    } catch (error) {
      setLoading(false);
      setShowNewPopup(true);
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  };

  const RegisterVerifyEmail = async () => {
    console.log(formData.email, "formData");
  };

  const verifyHandler = async () => {
    setIsVerify(true);
    try {
      const resp = await VerifyEmail(formData.email, otpCode);
      if (resp.status === 200) {
        console.log("success");
        setSuccessMsg(true);
        setSuccessMessage({
          message: "Verified Successfully",
          icon: <FaRegCheckCircle color='green' size={30} />
        });
      } else {
        setSuccessMsg(true);
        setSuccessMessage({
          message: "Invalid Verification Code",
          icon: <MdOutlineError color='red' size={30} />
        });
        setReenterOtp(true);
      }
    } catch (error) {
      console.log("fail");
      setSuccessMsg(true);
      setSuccessMessage({
        message: "Verification code doesn't match",
        icon: <MdOutlineError color='red' size={30} />
      });
      setReenterOtp(true);
    } finally {
      setIsVerify(false);
    }
  };

  const [otp, setOtp] = useState(Array(5).fill('')); // Array with 5 empty strings
  const inputRefs = useRef([]); // Array of refs for each input field

  useEffect(() => {
    const otpString = otp.join('');
    setOtpCode(otpString);
  }, [otp]);

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const index = inputRefs.current.indexOf(target);

    if (key === "Backspace" || key === "Delete") {
      e.preventDefault();
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        return newOtp;
      });
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    const index = inputRefs.current.indexOf(e.target);

    if (/^[0-9]$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        return newOtp;
      });
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setOtp(digits);
  };

  const handleClose = async () => {
    setSuccessMsg(false);
    setShowNestedPopup(false);
    if (successMessage.message === "Verified Successfully") {
      navigate('/signin');
    }
  };

  const handleReenterOtp = () => {
    setSuccessMsg(false);
    setShowNestedPopup(true);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (successMessage?.message === "Verified Successfully") {
      setTimeout(() => {
        navigate('/signin');
      }, 3000); // 3000 milliseconds = 3 seconds
    }
  }, [successMessage?.message, navigate]);

  return (
    <div className='w-[100%] '>
      {loading && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="flex items-center justify-center">
            <FaSpinner className="animate-spin text-white" size={40} />
          </div>
        </div>
      )}

      {isVerify && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 9999999 }}>
          <div className="flex items-center justify-center">
            <FaSpinner className="animate-spin text-white" size={40} />
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50  ">
          <div className="  popup-register w-[40vw] h-[60vh] mx-auto bg-white text-black p-6 rounded-lg shadow-lg">
            <IoCloseCircleOutline size={30} style={{ float: "right", cursor: "pointer" }} onClick={() => setShowPopup(false)} />
            <div className="flex  items-center justify-center mb-4  text-white">
              <h2 className="text-2xl text-black font-semibold">Verification Email Sent</h2>
            </div>
            <div className='w-fit m-auto mb-5   rounded-[50%] p-2'>
              <LottieGreenCheck />
            </div>
            <p className="text-gray-600 text-lg mb-4 text-center">
              Please verify your email
            </p>
            <div className='flex justify-center'>
              <button
                className="bg-blue-500 text-white hover:bg-blue-900 hover:text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  setShowPopup(false)
                  setShowNestedPopup(true)
                  RegisterVerifyEmail(true)
                }}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      )}
      {showNewPopup && (
        <div id="toast-success" className="absolute right-0 top-0 flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-red-400 dark:bg-red-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-gay-500 bg-red-100 rounded-lg dark:bg-red-500 dark:text-red-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal"> Email Already exist </div>
          <button type="button" onClick={() => { setShowNewPopup(false) }} className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
          </button>
        </div>
      )}
      {showNestedPopup && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className=" popupone-register-otp w-[400px] h-[250px] mx-auto bg-white p-6 rounded-lg shadow-lg" >
            <IoCloseCircleOutline onClick={() => setShowNestedPopup(false)} size={30} style={{ float: "right", cursor: "pointer" }} />
            <div className="flex flex-col items-center justify-center  text-white">
              <h2 className="text-2xl text-black font-semibold"> Please Enter Verification Code </h2>
            </div>
            <section className="   bg-white py-5 dark:bg-dark ">
              <div className="  container  flex items-center justify-center">
                <form id="otp-form" className="  flex gap-2">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={handleInput}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onPaste={handlePaste}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className=" code shadow-xs flex w-[64px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
                    />
                  ))}
                </form>
              </div>
            </section>
            <div className='flex justify-center mt-2'>
              <button
                className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={verifyHandler} disabled={isVerify} >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {successMsg && (
        successMessage.message === "Verified Successfully"  ? <><div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
        <div className=" Verify-Success-popup    w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative"  >
          <IoCloseCircleOutline onClick={handleClose} size={30} style={{ float: "right", cursor: "pointer" }} />
          <div className="flex flex-col items-center justify-center mb-4 text-white">
            <h2 className="text-2xl text-black font-semibold  mb-4">{successMessage.message}</h2>
            {successMessage.message === "Verified Successfully" ? <LottieGreenCheck /> : <ErrorLottie />}
              {/* { successMessage.message !== "Verified Successfully"  && reenterOtp && (
              <button
                className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
                onClick={handleReenterOtp}
              >
                Re-enter OTP
              </button>
            )} */}
          </div>
        </div>
      </div></> :<><div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="popupone w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg relative" >
            <IoCloseCircleOutline onClick={handleClose} size={30} style={{ float: "right", cursor: "pointer" }} />
            <div className="flex flex-col items-center justify-center mb-4 text-white">
              <h2 className="text-2xl text-black font-semibold  mb-4">{successMessage.message}</h2>
              {successMessage.message === "Verified Successfully" ? <LottieGreenCheck /> : <ErrorLottie />}
                { successMessage.message !== "Verified Successfully"  && reenterOtp && (
                <button
                  className="bg-blue-600 text-white hover:bg-blue-400  py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
                  onClick={handleReenterOtp}
                >
                  Re-enter OTP
                </button>
              )}
            </div>
          </div>
        </div></>
        
      )}



      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap w-[100%] justify-center gap-4 main-register">
        <div className="mb-[19px] w-[22vw] h-[50px] inner-input-field">
          <input
            type="text"
            {...register('name')}
            placeholder="Name"
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.name ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          />
          {errors.name && <p className="mt-1 text-red-500 text-start text-sm">{errors.name.message}</p>}
        </div>
        <div className="mb-[19px] w-[22vw] h-[50px] inner-input-field">
          <input
            type="email"
            {...register('email')}
            placeholder="Email"
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.email ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          />
          {errors.email && <p className="mt-1 text-red-500 text-start text-sm">{errors.email.message}</p>}
        </div>
        <div className="mb-[22px] w-[22vw] inner-input-field">
          <input
            type="password"
            {...register('password')}
            placeholder="Password"
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.password ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          />
          {errors.password && <p className="text-red-500 text-start text-sm">{errors.password.message}</p>}
        </div>
        <div className="mb-[19px] w-[22vw] inner-input-field">
          <input
            type="password"
            {...register('confirmPassword')}
            placeholder="Confirm Password"
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.confirmPassword ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          />
          {errors.confirmPassword && <p className="text-red-500 text-start text-sm">{errors.confirmPassword.message}</p>}
        </div>
        <div className="mb-[19px] w-[22vw] inner-input-fields flex flex-col">
          <div className="flex reg-phone">
            <select
              {...register('countryCode', {
                required: 'Country code is required',
              })}
              className={`phone w-[22vw] h-[50px] px-3 py-3 text-base transition bg-transparent border rounded-l-md outline-none ${errors.countryCode ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
            >
              <option value="" >Select Code</option>
              {countrycode.map(country => (
                <option key={country.value} value={country.value}>{country.label}</option>
              ))}
            </select>
            {errors.countryCode && <p className="text-red-500 text-start text-sm">{errors.countryCode.message}</p>}
            <div className="phone-input flex flex-col">
              <input
                type="text"
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: 'Phone number must be exactly 10 digits'
                  },
                })}
                placeholder="Phone Number"
                maxLength={10}
                className={`phoneinput w-[15vw] inner-input-field px-5 py-3 text-base transition bg-transparent border-t border-b border-r rounded-r-md outline-none ${errors.phoneNumber ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
              />
              {errors.phoneNumber && <p className="text-red-500 text-start text-sm">{errors.phoneNumber.message}</p>}
            </div>
          </div>
        </div>
        <div className="mb-[19px] w-[22vw] inner-input-field">
          <select
            {...register('gender')}
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.gender ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          >
            <option value="" >Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {errors.gender && <p className="text-red-500 text-start text-sm">{errors.gender.message}</p>}
        </div>
        <div className="mb-[19px] w-[22vw] inner-input-field">
          <input
            type="text"
            {...register('address')}
            placeholder="Address"
            className={`inner-input-field inners-div w-[22vw] px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.address ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          />
          {errors.address && <p className="text-red-500 text-start text-sm">{errors.address.message}</p>}
        </div>
        <div className="mb-[19px] w-[22vw] inner-input-field">
          <select
            {...register('country')}
            className={`inner-input-field inners-div w-[22vw]  px-5 py-3 text-base transition bg-transparent border rounded-md outline-none ${errors.country ? 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
          >
            <option value="" >Select Country</option>
            {Country.map(country => (
              <option key={country.code} value={country.name}>{country.name}</option>
            ))}
          </select>
          {errors.country && <p className="text-red-500 text-start text-sm">{errors.country.message}</p>}
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="exampleCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="mr-2 w-[20px] h-[20px]"
          />
          <label htmlFor="exampleCheckbox" className='text-gray-500'>I agree to the <NavLink to="/terms-of-service" className="text-blue-500">Terms of Service </NavLink>, 
           <NavLink to="/cookie-privacy-policy" className="text-blue-500"> Privacy Policy</NavLink> , 
           and            <NavLink to="" className="text-blue-500">  Legal Terms</NavLink>.</label>

        </div>
        <div className="mb-9 w-[80%] inner-input-field m-auto">
          <button
            type="submit"
            disabled={!isChecked}
            className="last-reg inner-input-field inners-div w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark"
         style={{cursor: !isChecked ? 'not-allowed' : 'pointer'}}
         >
            Create An Account
            </button>
        </div>
      </form>
    </div>
  );
}

export default RegisterForm;
