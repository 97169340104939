import React from 'react'
import Marketinsight from "../../assets/MarketInsight.jpeg"
import Title from '../TitleComp/Title'
import { VscGraph } from "react-icons/vsc";
import { FaStar, FaSearch} from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import { FaTelegramPlane } from "react-icons/fa";
import Testimonials from '../Testominal/Testominal';
import TopStories from '../WidgetComp/TopStoriesWidget';
import Featurecard from '../FeatureCardComp/Featurecard';
import "./Marketinsight.css"    
const TradingCalculator = () => {
    const data = [
        {
            header: "Calculate Your Trades with Precision",
            descp1: `Optimize your trading strategy and manage your risk with INVESTORS ZON Trading Calculator. Our Trading Calculator provides valuable tools and features to help you calculate key trading metrics, such as position size, risk exposure, potential profit and loss, and more. Whether you're a novice trader or an experienced investor, our Trading Calculator offers the precision and accuracy you need to make informed trading decisions.`
        }
    ]
    const FourTrading = [
        { header: "Key Features of the Trading Calculator" }
    ]
      const datatwo = [
        { icon: <VscGraph size={40} color='white' className='text-white' />, title: "Position Size Calculator", descp: "Determine the optimal position size for your trades based on your risk tolerance, account size, and stop-loss levels. Our Position Size Calculator helps you manage your risk and ensure that each trade is properly sized to align with your trading strategy and objectives." },
        { icon: <FaStar size={40} color='white' className='text-white' />, title: "Risk Management Tools ", descp: "Assess your risk exposure and potential profit and loss with our risk management tools. Our Trading Calculator allows you to calculate your risk-reward ratio, risk percentage per trade, and maximum loss per trade, helping you maintain disciplined risk management practices. " },
        { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Profit and Loss Calculator ", descp: "Estimate your potential profit and loss for individual trades or trading strategies with our Profit and Loss Calculator. Our calculator takes into account factors such as entry price, exit price, position size, and currency pair, allowing you to assess the potential profitability of your trades before execution." },
        { icon: <HiSupport size={40} color='white' className='text-white' />, title: "Pip Value Calculator", descp: "Calculate the value of a pip for various currency pairs and contract sizes with our Pip Value Calculator. Our calculator helps you understand the monetary value of price movements in the forex market, enabling you to better manage your trades and optimize your trading strategy." },
      ]
      const buttondata = [
        {title:" Join INVESTORS ZON and Unlock the Potential of Trading Calculator Today ",descp:"Optimize your trading strategy and manage your risk with CW BANK's Trading Calculator. Whether you're a beginner or an experienced trader, our calculator offers valuable tools and features to help you make informed trading decisions and achieve your financial goals.",icon: <FaTelegramPlane  size={20} />,button:"   SIGN UP"}
      ]
    return (
        <div>
            <div className=" container mx-auto mt-[8%]  relative ">
                <div className="relative ">
                    <img
                        src={Marketinsight}
                        alt="Paris Financial District "
                        className="w-full h-auto "
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className=" inner-text text-6xl font-bold text-white p-4">
                        Trading Calculator
                        </h1>
                    </div>
                </div>
                {data.map((val, index) => {
                    return (
                        <div key={index} className="container mx-auto mb-4 ">
                        <h2 className=" mt-8 font-bold text-2xl ">
                            {val.header}
                        </h2>
                        <p className="text-base text-body-color dark:text-dark-6">
                            {val.descp1}
                        </p>
                    </div>
                    )
                })}
                <div className=" flex justify-center">
                    <TopStories />
                </div >
                <div className='mt-4'>
                    <Title data={FourTrading} />
                    <Featurecard data={datatwo} />
                    <Buttoncard data={buttondata} />
                    <Testimonials/>
                </div>
            </div>
        </div>
    )
}
export default TradingCalculator
