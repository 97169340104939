import React, { useEffect,useState } from 'react';
import Shape from "../../assets/shape-1.svg";
import Shape1 from "../../assets/shape-3.svg";
import investiologo from "../../assets/investors zon.png";
import investiologoo from "../../assets/investors zon c.png";
import investiologoonewlight from "../../assets/Footerlogo.png";
import FooterWedgit from '../Footer.wedgit';
import { Link, NavLink } from 'react-router-dom';
import "./Footer.css";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { fetchPublishedCourses } from '../../api/CourseApi';

const Footer = () => {
  const [courseList, setCourseList] = useState([]);

  const fetchCoursesData = async () => {
    const resp = await fetchPublishedCourses();
    setCourseList(resp)
  };

  useEffect(() => {
    fetchCoursesData()
  }, [])


  useEffect(() => {
    
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = `
      {
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500 Index"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100 Cash CFD"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR to USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "light",
        "locale": "en"
        
      }
    `;

    const targetDiv = document.getElementsByClassName('tradingview-widget-container__widget')[0];
    targetDiv.appendChild(script);

    return () => {
      // targetDiv.removeChild(script);
    };
  }, []);

  return (
    <>
      <footer
        className="wow fadeInUp relative z-10 bg-[#090E34] pt-20 lg:pt-[100px] mb-[40px]"
        data-wow-delay=".2s"
      >
        <div className="container">
          <div className="-mx-4 flex flex-wrap w-[100%] main-footer">
            <div className=' w-[25%] left-inner-div'>
              <div className="w-[100%] px-4 ">

                <span className="absolute top-0 left-0 z-[-1]"><img alt="" src={Shape} /></span>


                <div className="mb-10 w-full">
                  <NavLink
                    to="/"
                    className="mb-6 inline-block max-w-[160px]"
                  >
                    <img
                      src={investiologoonewlight}
                      alt="logo"
                      className=" max-w-[200px] h-[80]"
                    />
                  </NavLink>
                  <p className="mb-8 w-[270px] paragraph text-base text-gray-7">
                    We create digital experiences for brands and companies by using
                    technology.
                  </p>
                  <ul>
                  
                    </ul>
                  <div className="-mx-3 flex items-center">
                    <NavLink
                      to="javascript:void(0)"
                      className="px-3 text-gray-7 hover:text-white"
                    >

                    </NavLink>
                    <NavLink
                      to="javascript:void(0)"
                      className="px-3 text-gray-7 hover:text-white"
                    >

                    </NavLink>
                    <NavLink
                      to="javascript:void(0)"
                      className="px-3 text-gray-7 hover:text-white"
                    >

                    </NavLink>
                    <NavLink
                      to="javascript:void(0)"
                      className="px-3 text-gray-7 hover:text-white"
                    >

                    </NavLink>
                  </div>
                </div>
              </div>
            </div>


            <div className=' flex flex-wrap w-[75%] right-footter-div'>
              <div className="w-[20%] px-4 footer-inner-div ">
                <div className="mb-10 w-full">
                  <h4 className="mb-9 text-lg font-semibold text-white">Market</h4>
                  <ul>
                  <li>
                      <NavLink
                        to="/market"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                       Market
                      </NavLink>

                    </li>
                    <li>
                      <NavLink
                        to="/forex-market"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Forex Market
                      </NavLink>

                    </li>
                    <li>
                      <NavLink
                        to="/stock-market"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Stock Market
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/mutual-fund"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Mutual  Funds
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/commodity-market"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Commodity  Market
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-[20%] px-4 footer-inner-div">
                <div className="mb-10 w-full">
                  <h4 className="mb-9 text-lg font-semibold text-white">Courses</h4>
                  <ul>
                    <li>
                      <NavLink
                        to="/school-of-investors-zon"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >

                        School of Investors Zon                  </NavLink>
                    </li>
                    <li>
                     {courseList.length > 0 ?
                            courseList.slice(0, 2).map((val, index) => {
                              const someState = { id: val.id };
                              return <div className="w-[100%] text-black  rounded ">
                                <Link
                                state={someState}
                                  to={`/single-course-details/${val.slug}`}
                                  className="mb-3 inline-block text-base text-gray-7 hover:text-primary   "
                                >
                                 
                                  {val.title}
                                </Link>
                                
                              </div>
                            })
                            :
                            < div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                              <Link
                                to="#"
                                className="mb-3 inline-block text-base text-gray-7 hover:text-primary  "
                              >
                                
                                Comming Soon
                              </Link>
                              <p className="mx-5  text-xs  text-gray-500">
                                Comming Soon
                              </p>
                            </div>
                          }
                    </li>
                    <li>
                      <NavLink
                        to="/my-certificates"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Verify Certificates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/course-subscription-plan"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Course Subscription Plan
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-[20%] px-4 footer-inner-div">
                <div className="mb-10 w-full">
                  <h4 className="mb-9 text-lg font-semibold text-white">
                    Signals
                  </h4>
                  <ul>
                  <li>
                      <NavLink
                        to="/signals"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Trading Signals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/free-paid-crypto-signals"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Free Crypto Forex & Stocks Signals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/signals-subscription"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Subscription Plan
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/signal-blog-video"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Signal Blogs and Videos
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/signal-annoucements"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Signal Announcements
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="w-[20%] px-4 footer-inner-div">
                <div className="mb-10 w-full">
                  <h4 className="mb-9 text-lg font-semibold text-white">Tools</h4>
                  <ul>
                    <li>
                      <NavLink
                        to="/market-insight"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >

                        Market Insight
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/economic-calendar"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Economic Calender
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/news-and-analysis"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        News & Analysis

                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/trading-calculator"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Trading Calculator
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/market-heatmap"
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Market Heatmap

                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-[20%] px-4 footer-inner-div">
                <div className="mb-10 w-full">
                  <h4 className="mb-9 text-lg font-semibold text-white">Investment</h4>
                  <ul>
                    <li>
                      <NavLink
                        target='_blank'
                        to="https://investment.investorszon.com/ "
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Fund Raising

                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        target='_blank'
                        to="https://investment.investorszon.com/ "
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Equity Investment

                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        target='_blank'
                        to="https://investment.investorszon.com/ "
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Donate               </NavLink>
                    </li>
                    <li>
                      <NavLink
                        target='_blank'
                        to="https://blokistaworld.com/ "
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                      >
                        Discover                </NavLink>
                    </li>
                    <li>
                      <NavLink
                        target='_blank'
                        to="https://investment.investorszon.com/ "
                        className="mb-3 inline-block text-base text-gray-7 hover:text-primary "
                      >
                        Start A Campaign
                        </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className=" container flex space-x-4">
          <div className="twitter">
            <NavLink
              className=""
              to="https://x.com/i/flow/login"  target='_blank'
            >
    <FaXTwitter className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />

            </NavLink>
          </div>
          <div className="telegram">
            <NavLink className="" to="https://web.telegram.org/k/"  target='_blank'>
            <FaTelegramPlane className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />
            </NavLink>
          </div>
          <div className="Linkdin">
            <NavLink className="" to="https://api.linkedin.com/login" target='_blank'>
            <FaLinkedinIn className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />
            </NavLink>
          </div>
          <div className="youtube">
            <NavLink className="" to="https://www.youtube.com"  target='_blank'>
            <FaYoutubeSquare className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />
            </NavLink>
          </div>
          <div className="Facebook">
            <NavLink className="" to="https://www.facebook.com/"  target='_blank'>
            <FaFacebookF className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />
            </NavLink>
          </div>
          <div className="instagram">
            <NavLink className="" to="https://www.instagram.com/"  target='_blank'>
            <FaInstagram className="twitter-icon transition-colors duration-300 ease-in-out text-white hover:text-blue-500" size={20} />
            </NavLink>
          </div>
         
        </div>
        <div className="mt-12 border-t border-[#8890A4] border-opacity-40 py-8 lg:mt-[60px]"
        >
          <div className="container pb-8">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 md:w-2/3 lg:w-1/2">
                <div className="my-1">
                  <div
                    className="-mx-3 flex items-center justify-center md:justify-start"
                  >
                    <NavLink
                      to="/cookie-privacy-policy"
                      className="px-3 text-base text-gray-7 transition-colors duration-500 ease-in-out  hover:underline hover:text-blue-500"
                    >
                     Cookies & Privacy policy 
                    </NavLink>
                    <NavLink
                      to="javascript:void(0)"
                      className="px-3 text-base text-gray-7 transition-colors duration-500  hover:underline hover:text-blue-500"
                    >
                       Legal notice
                    </NavLink>
                    
                    <NavLink
                      to="/terms-of-service"
                      className="px-3 text-base text-gray-7 transition-colors duration-500  hover:underline hover:text-blue-500"
                    >
                      Terms of service
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="w-full px-4 md:w-1/3 lg:w-1/2">
                <div className="my-1 flex justify-center md:justify-end">
                  <p className="text-base text-gray-7">
                    ©2024 Investors Zon. All Rights Reserved.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <span className="absolute right-0 bottom-0 z-[-1]"><img alt="" src={Shape1} /></span>

        </div>

        <div>
          <span className="absolute left-0 top-0 z-[-1]">
            <img src="assets/images/footer/shape-1.svg" alt="" />
          </span>

          <span className="absolute bottom-0 right-0 z-[-1]">
            <img src="assets/images/footer/shape-3.svg" alt="" />
          </span>


        </div>
        <div className="" style={{ bottom: "0", left: "0", position: "fixed", width: "100vw" }}>
          {/* <FooterWedgit/> */}
          <div className="tradingview-widget-container" >
            <div className="tradingview-widget-container__widget"></div>

          </div>
        </div>
      </footer>


    </>
  )
}

export default Footer
