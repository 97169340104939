import React from 'react';
import Faq from "../Faq/Faq";
import { BsFire } from "react-icons/bs";
import { RiFileListFill } from "react-icons/ri";
import { FaMobileRetro } from "react-icons/fa6";

const Whyrisefx = () => {
  window.scroll(0,0)
  return (
    <>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo rerum, nesciunt veritatis blanditiis autem corrupti veniam ratione dolorum animi odio quas similique velit quisquam maxime deserunt modi harum est consectetur earum dignissimos saepe? Magnam quis, amet illum blanditiis cumque repellendus unde? Dolores reprehenderit veniam, facere molestias est eligendi commodi eveniet veritatis libero praesentium velit modi enim inventore ullam vero repellat sunt consequuntur cumque facilis nihil soluta doloribus! Tenetur nihil, ad deserunt facilis soluta cumque voluptate enim, fuga tempora earum est quidem blanditiis nostrum? Dolore cum odio ratione, dolor praesentium recusandae sed deserunt fugiat eius, minus, eligendi quas officiis voluptate quo.</p>

      <div className="flex flex-row justify-between items-start space-x-4">
        <div className="flex flex-col items-center">
          <BsFire style={{ color: 'orange', fontSize: '2rem' }} />
          <p className="font-bold text-lg mt-5">Reputation</p>
          <p className="text-center">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi minus possimus rerum ad. Aspernatur, quos voluptates, commodi illo unde dolorum qui odit quibusdam iste delectus, impedit laudantium provident quisquam quas iste. Libero facilis sapiente dicta unde aliquid quam autem eligendi dolore reiciendis.</p>
        </div>
        
        <div className="flex flex-col items-center">
          <RiFileListFill style={{ color: 'orange', fontSize: '2rem' }} />
          <p className="font-bold text-lg mt-5">Advanced Technology</p>
          <p className="text-center">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi minus possimus rerum ad. Aspernatur, quos voluptates, commodi illo unde dolorum qui odit quibusdam iste delectus, impedit laudantium provident qfacilis  unde aliquid quam autem eligendi dolore reiciendis.</p>
        </div>

        <div className="flex flex-col items-center">
          <FaMobileRetro style={{ color: 'orange', fontSize: '2rem' }} />
          <p className="font-bold text-lg mt-5">Competitive Conditions</p>
          <p className="text-center">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi minus possimus rerum ad. Aspernatur, quos voluptates, commodi illo unde i cupiditate voluptatum ipsa! Ex impedit numquam illo tenetur accusantiumfacilis sapiente dicta unde aliquid quam autem eligendi dolore reiciendis.</p>
        </div>
      </div>
    </>
  )
}

export default Whyrisefx;
