import React, { useState, useContext } from "react";
import "./style.css";
import Header from "../Header/Header";
import Feature from "../Feature/Feature";
import About from "../About/About";
import Cta from "../CTA/Cta";
import Pricing from "../Pricing/Pricing";
import Contact from "../Contact/Contact";
import Testominal from "../Testominal/Testominal";
import HomePageCard from "../HomePageSimpleCard/HomePageCard";
import Buttoncard from "../HomePageSimpleCard/Buttoncard";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { CiBitcoin } from "react-icons/ci";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineHighQuality } from "react-icons/md";
import CookiesComponent from "./Cookies";
const Homepage = () => {
  window.scroll(0,0)
  const TradingSignalone = [
    { title: "What Are You Looking For?    ", header: "Get Started Now", button: "Start Using Investors zon", description: "At Investors zon, we offer a wide range of financial services tailored to meet your needs. Whether you are a beginner or an experienced investor, our resources are designed to help you succeed.Start leveraging our tools and insights to make informed financial decisions. " }
  ]

  const TradingSignaltwo = [
    { header: "Signals", subheader: " Investor  has helped over 150000 Happy Clients.", testimonial: "We have been providing our services to Investors & Businesses for more than 5 years and we have helped over 80 thousand Happy Customers so far. We have more than 10 years of experience in Crypto and Forex Markets. Our Goal is to offer You Quality Professional Services and with all our knowledge, efforts and experience guide you to become a formed investor.    " }
  ]
  const Homecard = [
    { icon: <BsFillPeopleFill size={30} color='#ffff' className='text-center' />, heading: "Reliable Features and Exceptional Customer Service", description: "Experience our outstanding features and top-notch customer service. We ensure a seamless and rewarding experience, building trust and satisfaction in no time." },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, heading: "High-Accuracy Trading Signals", description: "Benefit from our trading signals with a success rate exceeding 90%. Our signals are delivered directly to your Telegram, Email, and account on our website, with SMS and mobile app notifications coming soon." },
    { icon: <CiBitcoin size={30} color='#ffff' className='text-center' />, heading: "Automated MQL Copy Trading", description: "Join our MQL platform as a follower, sit back, and watch the profits roll in. Our trades are automatically copied to your real account, allowing you to withdraw your trading profits anytime you desire." },
    { icon: <HiOutlineUserGroup size={30} color='#ffff' className='text-center' />, heading: "PAMM Accounts for Profit Sharing", description: "Easily join our PAMM pool accounts with selected brokers. Find a list of our trusted brokers on the Broker page (main menu) and enjoy profit-sharing benefits with minimal effort." },
    { icon: <FaPeopleGroup size={30} color='#ffff' className='text-center' />, heading: "Expert Trading and Analysis Team", description: "Our team comprises highly experienced Forex trading and analysis experts, providing you with insights and strategies that drive success." },
    { icon: <MdOutlineHighQuality size={30} color='#ffff' className='text-center' />, heading: "Affordable, High-Quality Services", description: "We are proud to offer premium services that are accessible to everyone. Our commitment to high-quality service ensures your success in Forex trading without breaking the bank." },
    // {icon:<RiSettings4Line size={40}/>,heading:"Friendly and Premium Customer Support",description:"Receive premium, fast client support services. We continuously monitor all support processes to ensure they remain exceptional and responsive to your needs."},

    // {icon:<BsGraphUp size={40}/>,heading:"Friendly and Premium Customer Support",description:"Receive premium, fast client support services. We continuously monitor all support processes to ensure they remain exceptional and responsive to your needs."},


  ]
  const  buttondata=[
    {title:" 100% Trusted    Forex    Trading Signal Provider",descp:" At Investors zon, we pride ourselves on being your go-to source for reliable Forex trading signals. With a team of highly experienced Forex experts, we constantly analyze charts and market trends to generate trading signals that help you achieve substantial profits. Our commitment to accuracy and expertise ensures you can trade with confidence and success.",icon: <FaTelegramPlane  size={20} />,button:"     SIGN UP"}
  ]
  const [showCookies, setShowCookies] = useState(true);

  
  const handleAccept = () => {
    // Implement logic for cookie acceptance here
    setShowCookies(false);
  };

  const handleClose = () => {
    // Implement logic for closing the cookies banner here
    setShowCookies(false);
  };
  return (
    <div>
      <div className="realative min-h-screen">
      <Header />
      <div className="container mt-[6%]">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[485px] text-center lg:mb-[70px]">

              <h2
                className="mb-3 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
              >
                Why We Are Special ?

              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                Discover why Investors zon stands out as your premier choice for investment solutions:              </p>
            </div>
          </div>
        </div>

        <HomePageCard data={Homecard} />
      </div>
      <Buttoncard data={buttondata} />
      <Feature />
      <About />
      <Cta data={TradingSignalone} />
      <Pricing />
      <Testominal data={TradingSignaltwo} />
      <Contact />
     
      </div>
      {showCookies && (
        <CookiesComponent/>
      )}
     
    </div>
  );
};

export default Homepage;
