import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { deleteUserById, getUserDetails, updateUserProfile } from "../../api/CourseApi";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import PasswordUpdateForm from "./PasswordUpdate";
import { TiWarning } from "react-icons/ti";
import { IoCloseSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import Countrycode from "../Signup/registerationformComp/Countrycode.json"
import Country from "../Signup/registerationformComp/Country.json"
import "./DashboardSetting.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DashboardSetting = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    address: "",
    country: "",
    whatsAppNumber: "",
    phoneCC: "",
    whatsappCC: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChangesMade(true);
    if (name === 'whatsappcountryCode' || name === 'countryCode') {
      setUserDetails((prevData) => ({
        ...prevData,
        phoneCC: name === 'countryCode' ? value : prevData.phoneCC,
        whatsappCC: name === 'whatsappcountryCode' ? value : prevData.whatsappCC,
      }));
    } else {
      setUserDetails((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const UpdateHandler = async () => {
    if ((userDetails?.whatsAppNumber && !userDetails?.whatsappCC) || (!userDetails?.whatsAppNumber && userDetails?.whatsappCC)) {
      toast.error("Both WhatsApp number and country code must be provided.", {
        position: "bottom-right",
        autoClose: 5000,
      });
      return; // Exit the function without updating
    }
    const data = {
      name: userDetails?.name,
      phoneNumber: userDetails?.phoneNumber,
      country: userDetails?.country,
      address: userDetails?.address,
      whatsAppNumber: userDetails?.whatsAppNumber,
      phoneCC: userDetails?.phoneCC,
      whatsappCC: userDetails?.whatsappCC
    };
    const resp = await updateUserProfile(authUser?.token, data);
    if (resp.status === 200) {
      setShowToast(true); // Show toast when adding to cart
      toast.success("User updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
      });
      // setIsUpdate(true);
      setError(true);
      setChangesMade(false); // Reset changes made
    } else {
      setError(false);
    }
  };
  const fetchUserDeatils = async () => {
    const response = await getUserDetails(authUser?.token);
    setUserDetails(response?.data);
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const deleteHandler = async () => {
    const resp = await deleteUserById(authUser?.token);
    if (resp.status === 204) {
      navigate('/signin');
    } else {
      setShowModal(!showModal);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Profile":
        return (
          <>
            {isUpdate && (
              <div className="absolute right-0 top-[34%] w-full max-w-xs p-4 mx-auto bg-white rounded-lg shadow text-gray-500 dark:text-green-600 dark:bg-green-800">
                <div className="flex items-center space-x-2 mb-2">
                  <FaCheck color="green" size={30} />
                  <span className="text-sm font-medium">User updated successfully</span>
                </div>
                <button
                  onClick={() => setIsUpdate(!isUpdate)}
                  className="absolute top-3 right-2 mt-2 mr-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  <IoCloseSharp size={20} />
                </button>
              </div>
            )}
            {isDeleted && (
              <div className="absolute right-0 top-[34%] w-full max-w-xs p-4 mx-auto bg-white rounded-lg shadow text-gray-500 dark:text-red-600 dark:bg-red-800">
                <div className="flex items-center space-x-2 mb-2">
                  <FaCheck color="red" size={30} />
                  <span className="text-sm font-medium">Delete Successfully</span>
                </div>
                <button
                  onClick={() => setIsDeleted(!isDeleted)}
                  className="absolute top-3 right-2 mt-2 mr-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  <IoCloseSharp size={20} />
                </button>
              </div>
            )}
            <div className="space-y-12 max-w-4xl mt-2 mx-auto mb-9 p-6 bg-white shadow-lg rounded-lg">
              <div className="pb-12">
                <div className="main-dashboard-setting mb-8 flex items-center justify-between">
                  <div className="relative inner-main-dashbaord ">
                    <input
                      type="text"
                      id="floating_email"
                      className="update-width block px-2.5 pb-2.5 pt-4 w-[23vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Name"
                      value={userDetails?.name}
                      name="name"
                      onChange={handleInputChange}
                    />
                    <label
                      htmlFor="floating_email"
                      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Name
                    </label>
                  </div>
                  <div className="relative inner-main-dashbaord">
                    <input
                      type="text"
                      id="floating_user"
                      className="update-width block px-2.5 pb-2.5 pt-4 w-[23vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Email"
                      value={userDetails?.email}
                      onChange={handleInputChange}
                      disabled
                    />
                    <label
                      htmlFor="floating_user"
                      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Email
                    </label>
                  </div>
                </div>
                <div className="main-dashboard-setting mb-8 flex items-center justify-between">
                  <div className="relative flex items-center mb-[19px] w-[15vw] inner-input-field-dashboard">
                    <select
                      name="countryCode"
                      value={userDetails.phoneCC}
                      onChange={handleInputChange}
                      className="select-code w-[6vw] px-2.5 py-3.5 text-sm text-gray bg-transparent rounded-l-lg border border-gray-400 dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                    >
                      <option value="" disabled>Select Code</option>
                      {Countrycode.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    <div className="relative phone-input-div">
                      <input
                        type="text"
                        id="floating_phone"
                        className="update-width-phone block px-2.5 pb-2.5 pt-4 w-[15vw] text-sm text-gray bg-transparent rounded-r-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder="Phone Number"
                        value={userDetails.phoneNumber}
                        onChange={handleInputChange}
                        name="phoneNumber"
                      />
                      <label
                        htmlFor="floating_phone"
                        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                  <div className="relative inner-main-dashbaord">
                    <input
                      type="text"
                      id="floating_phone"
                      className="update-width block px-2.5 pb-2.5 pt-4 w-[23vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Gender"
                      value={userDetails?.gender}
                      onChange={handleInputChange}
                      disabled
                    />
                    <label
                      htmlFor="floating_phone"
                      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Gender
                    </label>
                  </div>
                </div>
                <div className="main-dashboard-setting mb-8 flex items-center justify-between">
                  <div className="relative inner-main-dashbaord">
                    <select
                      id="floating_skill"
                      className={`inner-inputdashboard-field w-[21vw] px-5 py-3 text-base transition bg-transparent border border-gray-400 rounded-md outline-none 'border-red-500' : 'border-stroke dark:border-dark-3'} text-body-color dark:text-dark-6 focus:border-primary dark:focus:border-primary focus-visible:shadow-none`}
                      value={userDetails?.country}
                      onChange={handleInputChange}
                      name="country"
                    >
                      <option value="" disabled>Select Country</option>
                      {Country.map(country => (
                        <option key={country.code} value={country.name}>{country.name}</option>
                      ))}
                    </select>
                    <label
                      htmlFor="floating_skill"
                      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Country
                    </label>
                  </div>
                  <div className="relative inner-main-dashbaord">
                    <input
                      type="text"
                      id="floating_address"
                      className="update-width block px-2.5 pb-2.5 pt-4 w-[23vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Address"
                      value={userDetails?.address}
                      onChange={handleInputChange}
                      name="address"
                    />
                    <label
                      htmlFor="floating_address"
                      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Address
                    </label>
                  </div>
                </div>
                <div className="main-dashboard-setting mb-8 flex items-center justify-between">
                  
                  <div className="relative flex items-center mb-[19px] w-[21vw] inner-input-field-dashboard">
                    <select
                      name="whatsappcountryCode"
                      value={userDetails.whatsappCC}
                      onChange={handleInputChange}
                      className="select-code w-[6vw] px-2.5 py-3.5 text-sm text-gray bg-transparent rounded-l-lg border border-gray-400 dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                    >
                      <option value="" disabled>Select Code</option>
                      {Countrycode.map((country, index) => (
                        <option key={index} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    <div className="relative">
                      <input
                        id="floating_whatsapp"
                        className="update-width block px-2.5 pb-2.5 pt-4 w-[15vw] text-sm text-gray bg-transparent rounded-r-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder={userDetails?.whatsAppNumber === null ? "Whatsapp" : "Whatsapp"}
                        value={userDetails?.whatsAppNumber}
                        onChange={handleInputChange}
                        name="whatsAppNumber"
                      />
                      <label
                        htmlFor="floating_whatsapp"
                        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Whatsapp
                      </label>
                    </div>
                  </div>
                </div>
                <div className="main-dashboard-setting-button flex items-center justify-start gap-4">
                  <button
                    onClick={UpdateHandler}
                    className={`bg-blue-500 text-white px-4 py-2 rounded-lg transform transition-transform duration-300 hover:translate-x-2 hover:bg-blue-600 ${!changesMade ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!changesMade}
                  >
                    Update Info
                  </button>
                  <button
                    onClick={toggleModal}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg transform transition-transform duration-300 hover:translate-x-2 hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      case "Password":
        return <PasswordUpdateForm />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchUserDeatils();
  }, [authUser]);

  return (
    <div>
      <div>
        <ul className="flex flex-wrap justify-start text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400">
          <li className="me-2">
            <NavLink
              to="#"
              onClick={() => setActiveTab("Profile")}
              aria-current={activeTab === "Profile " ? "page" : undefined}
              className={`inline-block p-4 ${activeTab === "Profile"
                ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
                : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
                }`}
            >
              Update Profile
            </NavLink>
          </li>
          <li className="me-2">
            <NavLink
              to="#"
              onClick={() => setActiveTab("Password")}
              className={`inline-block p-4 ${activeTab === "Password"
                ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
                : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
                }`}
            >
              Change Password
            </NavLink>
          </li>
        </ul>
        <div className="p-4">{renderContent()}</div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div className="bg-white p-8 max-w-md rounded-lg z-50">
            <div className="flex items-center justify-center mb-6">
              <TiWarning size={50} color="yellow" className="items-center" />
            </div>
            <h2 className="text-xl font-bold">Warning: Account Deletion</h2>
            <p className="text-gray-700">You are about to permanently delete your account. This action is irreversible.</p>
            <div className="text-gray-700">
              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li>All your data and settings will be lost.</li>
                <li>You will no longer have access to your account.</li>
                <li>This action cannot be undone.</li>
              </ul>
            </div>
            <p className="text-gray-700">If you are sure you want to proceed, please confirm your decision.</p>
            <h2 className="text-xl font-bold mb-4">Do you want to delete your account?</h2>
            <div className="flex justify-end gap-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={deleteHandler}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg"
                onClick={toggleModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />

    </div>
  );
}

export default DashboardSetting;
