import React from "react";
import Faq from "../Faq/Faq";


const BenifitsOurJoinPathner = () => {
  return (
    <>
      <Faq/>
    </>
  );
};

export default BenifitsOurJoinPathner;
