import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { NavLink, useNavigate } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { fetchUserCourseSubscription } from '../../api/CourseApi';
import CourseSubscrptionAnimation from '../Lottie Animation Comp/CourseSubscrptionAnimation';
import "./CourseSubscriptioncard.css"
import { IoInformationCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import ConfirmModal from '../Modals/confirmed';
import { IoMdStarOutline } from "react-icons/io";
import { CiLock } from "react-icons/ci";
import { BsDash } from "react-icons/bs";

const CourseSubscriptionCard = ({ data, userSubscriptions }) => {
  console.log('datat----------', data)
  const [showModal, setShowModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState({});
  const [updatedData, setUpdatedData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popoverData, setPopoverData] = useState([]);
  const [courseSubscriptionToBuy, setCourseSubscriptionToBuy] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
const [planType, setPlanType] = useState();
  const authUser = useAuthUser();
  const navigate = useNavigate();


  const toggleModal = async (id) => {
    if (!authUser) {
      navigate("/Signin");
      return;
    }
    setLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
    try {
      const resp = await fetchUserCourseSubscription(id, authUser?.token);
      if (resp.status === 201) {
        setUpdatedData(prevData => {
          const newData = prevData.map(plan =>
            plan.id === id ? { ...plan, isPurchased: "yes" } : plan
          );
          return newData;
        });
        localStorage.setItem('purchasedCourses', JSON.stringify({ ...userSubscriptions, [id]: { isPurchased: "yes" } }));
        setSuccessMessage(resp.data.message);
        setShowModal(true);
      } else {
        setErrorModal(true);
        setSuccessMessage(resp.response.data.error);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
    }
  };

  const handleInfoClick = (id, value) => {
    setPlanType( value);
    const resp = data.filter((val) => val.id === id);
    setPopoverData(resp);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handlePurchaseCourse = async (val) => {
    if (!authUser) {
      navigate("/signin");
    } else {
      setCourseSubscriptionToBuy(val)
      handlePurchase()
    }
  };

  const handlePurchase = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    // navigate("/dashboard");
  };



  useEffect(() => {
    const updatedData = data.map((item) => {
      const isPurchased = item.id === userSubscriptions?.id && item.name === userSubscriptions?.name;
      return { ...item, isPurchased: isPurchased };
    });
    updatedData.sort((a, b) => {
      const priceA = a.planPrice === "0" ? 0 : parseFloat(a.planPrice);
      const priceB = b.planPrice === "0" ? 0 : parseFloat(b.planPrice);
      return priceA - priceB;
    });
    setUpdatedData(updatedData);
  }, [data, userSubscriptions]);


  return (
    <div className='flex gap-6  flex-wrap main-CourseSubcard'>
      {updatedData?.map((val, index) => {
        return (
          <div key={index} className=' inner-CoursesSubcard-div w-[23.5%] relative   rounded-xl border border-gray-300  flex flex-col items-center justify-center'>
            <div >
              <div className='text-center h-[300px] bg-blue-300 rounded-t-lg mb-8' style={{ background: "#8DDDB4", width: "100%" }}>
                <h2 className=' pt-4 mb-6 font-semibold text-xl' style={{ color: `${val?.color}`, textTransform: 'capitalize' }}> {val.planType}</h2>
                <div className='  relative'>
                  {val.planDurationType === "onetime" ?
                    <p className='inline-block font-semibold text-4xl mb-6' style={{ color: `${val?.color}` }}>
                      {val.planPrice}</p>
                    :
                    <p className='inline-block font-semibold text-4xl mb-6' style={{ color: `${val?.color}` }}>
                      {val.planPrice}</p>}
                  <span className='inline-block absolute top-0 left-45' style={{ color: "#112A46" }}>$</span>
                </div>
                <p className='mb-6' style={{ color: "#112A46" }}> {val.planDurationType === "onetime" ? "One Time" : val.planDurationType === "monthly" ? "Monthly" : "Yearly"}</p>
                <p className='mb-6' style={{ color: "#112A46" }}> {authUser && val?.isPurchased ? "Your Current Plan" : "Try Now"}</p>
                {/* <p className='mb-6 px-4' style={{ color: "#112A46" }}>{val.planDescription}</p> */}
                <button className='w-[70%] border bg-white  font-bold py-2 px-4 rounded-3xl mt-2 text-black hover:text-black'
                  style={{ background: val?.isPurchased ? "green" : val?.courses?.length === 0 && val?.modules?.length === 0 ? 'gray' : `${val?.color}` 
                  ,
                   color: val?.isPurchased ? "#ffff" : val?.courses?.length === 0 && val?.modules?.length === 0 ? '#FFFFFF': `${val?.color}` }}
                  onClick={() => handlePurchaseCourse(val)}
                  disabled={val?.courses?.length === 0 && val?.modules?.length === 0}

                >
                  {loading[val.id] && !val?.isPurchased ?
                    "Processing..."
                    :
                    authUser
                      ? (
                        val?.isPurchased
                          ? (val.planDurationType === 'onetime' ? "Already Purchased" : "Already Subscribed")
                          : (val.planDurationType === 'onetime' ? "Buy Course" : "Subscribe Course")
                      )
                      :

                      val.planDurationType === "onetime" ? "Buy Plan" : "Subscribe Plan"}
                </button>
              </div>
              <div className='text-center '>
                <div className=' flex w-[90%] mb-8 mx-6'>
                  <div className='w-[10%] h-[]'>
                    <IoInformationCircle size={20} style={{ width: "100%", height: "30px" }} className='mr-2 ' />
                  </div>
                  <div className='w-[90%] text-gray-500'>
                    {val.planDescription}
                  </div>
                </div>
                <div className='flex items-center justify-center mb-4'>

                  <p>Certificate After Completion</p>
                </div>
                <p className='mb-4'>Study at your Pace</p>
                <p className='mb-6'>Lifetime Access</p>
              </div>
            </div>
            <div className='absolute top-1 right-1'>
              <IoInformationCircle
                size={25}

                onClick={() => handleInfoClick(val.id, val.planType)}
                style={{ cursor: 'pointer', background: "#8DDDB4" }}
              />
            </div>

          </div>
        );
      })}
      {showModal && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="w-[30vw] h-[38vh] mx-auto bg-white text-black p-6 rounded-lg shadow-lg">
            <RxCross2 size={30} style={{ float: "right", cursor: "pointer" }} onClick={() => setShowModal(false)} />
            <div className="flex items-center justify-center mb-4 mt-4 text-white mt-8">
              <h2 className="text-2xl text-black font-semibold">Thank you</h2>
            </div>
            <div className='w-fit m-auto mb-5 border border-green-800 rounded-[50%] p-2'>
              <FaCheck className='text-green-800' size={20} />
            </div>
            <p className="text-gray-600 text-lg mb-4 text-center">
              {successMessage}
            </p>
            <div className='flex justify-center'>
              <button
                className="bg-blue-500 text-white hover:bg-blue-900 hover:text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Dismiss
              </button>
              <button className='border border-gray-500 rounded py-2 px-3 ml-3'><NavLink to="/school-of-investors-zon">My Course</NavLink> </button>
            </div>
          </div>
        </div>
      )}
      {errorModal && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="Error-popup w-[30vw] h-[38vh] mx-auto bg-white text-black p-6 rounded-lg shadow-lg">
            <RxCross2 size={30} style={{ float: "right", cursor: "pointer" }} onClick={() => setErrorModal(false)} />
            <div className="flex items-center justify-center mb-4 mt-4 text-white mt-8">
              <h2 className="text-2xl text-black font-semibold">Error</h2>
            </div>
            <div className='w-fit m-auto mb-2'>
              <IoIosCloseCircle className='text-red-800' size={20} />
            </div>
            <p className="text-gray-600 text-lg mb-4 text-center">
              {successMessage}
            </p>
            <div className='flex justify-center'>
              <button
                className="bg-blue-500 text-white hover:bg-blue-900 hover:text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  setErrorModal(false);
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      )}






      {isPopupOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75'>

          <div className='SubscriptionPopup relative  p-8 border border-gray-300 rounded-xl shadow-lg w-[50%] h-[50%] overflow-auto' style={{ background: "#FCF0F2" }}>
            <RxCross2
              size={30}
              style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }}
              onClick={handleClosePopup}
            />
            
                <div className='flex '>
                  <h2 className=' text-2xl mr-2 capitalize'> {planType} </h2>
                  <IoMdStarOutline size={25} className='mr-2' />


                </div>
             
            <div className='flex items-center w-full justify-start gap-[2%] mb-4 mt-8  text-white'>
              <div className='w-[49%]  border rounded-lg ' style={{ background: "#FCF6F7" }}>
                <h2 className="text-lg text-gray-600  ml-4 mt-2 mb-1">Courses</h2>
                {popoverData[0]?.courses?.length > 0 ? (
                  popoverData[0]?.courses?.map((val, index) => (
                    <div className=' w-[80%] mt-1 ml-4' key={index}>
                    {/* <div className='mr-4'>
                        <FaCheck className='text-green-800' size={20} />
                      </div> */}
                    <div className=' relative bg-white pt-2  mb-2  border rounded-lg'>
                      <p className='text-sm text-gray-500  pl-2  mb-1'> {val.title}</p>
                      <span className='absolute top-[-7px] left-[7px] flex gap-2'>
                        <p className='text-red-500'>-</p>
                        <p className='text-blue-500'>-</p>
                      </span>
                    </div>
                  </div>
                  ))
                ) : (<>
                  
                  <div className='relative  bg-white w-[80%] ml-4 border  mb-4 rounded-lg'>
                    <p className='text-sm text-gray-500 pt-1 pl-2 mt-1 mb-1  '>No Course Included</p>
                    <span className='absolute top-[-7px] left-[7px] flex gap-2'>
                      <p className='text-red-500'>-</p>
                      <p className='text-blue-500'>-</p>
                    </span>
                  </div>
                </>)}
              </div>
              <div className='w-[49%] bg-blue-50 border rounded-lg  ml-4' style={{ background: "#FCF6F7" }}>
                <h2 className="text-lg text-gray-600  ml-4 mt-2 mb-1">Modules</h2>
                {popoverData[0]?.modules?.length > 0 ? (
                  popoverData[0]?.modules?.map((val, index) => (
                    <div className=' w-[80%] mt-1 ml-4' key={index}>
                    {/* <div className='mr-4'>
                        <FaCheck className='text-green-800' size={20} />
                      </div> */}
                    <div className=' relative bg-white pt-2  mb-2  border rounded-lg'>
                      <p className='text-sm text-gray-500  pl-2  mb-1'> {val.title}</p>
                      <span className='absolute top-[-7px] left-[7px] flex gap-2'>
                        <p className='text-red-500'>-</p>
                        <p className='text-blue-500'>-</p>
                      </span>
                    </div>
                  </div>
                  ))
                ) : (
                  <div className='relative  bg-white w-[80%]  border mb-4 ml-4 rounded-lg'>
                    <p className='text-sm text-gray-500  pt-1 pl-2 mt-1 mb-1'>No Module Included</p>
                    <span className='absolute top-[-7px] left-[7px] flex gap-2'>
                      <p className='text-red-500'>-</p>
                      <p className='text-blue-500'>-</p>
                    </span>
                  </div>)}
              </div>
            </div>
          </div>
        </div>
      )}

      <ConfirmModal
        setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        data={courseSubscriptionToBuy}
        type={'subscriptionCourse'}
      />
    </div>
  );
};

export default CourseSubscriptionCard;
