import React, { useEffect, useState } from 'react';
import { AiFillGold } from 'react-icons/ai';
import { BsCurrencyExchange, BsPatchCheckFill } from 'react-icons/bs';
import { FaBlogger, FaChartLine, FaDonate, FaLongArrowAltRight } from 'react-icons/fa';
import { FcCurrencyExchange, FcDonate } from 'react-icons/fc';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GoGraph } from 'react-icons/go';
import { LiaCertificateSolid } from 'react-icons/lia';
import { LuSchool } from 'react-icons/lu';
import { TbPigMoney, TbWorldWww } from 'react-icons/tb';
import { Link, NavLink } from 'react-router-dom';
import { fetchPublishedCourses } from '../../api/CourseApi';
import { VscGraph, VscGraphLine } from 'react-icons/vsc';
import "./Nav.css";
import { RxCross2 } from "react-icons/rx";
import { RxHamburgerMenu } from "react-icons/rx";
import { SiGooglemarketingplatform, SiStockx } from "react-icons/si";
import { SlCalender } from "react-icons/sl";
import { SiGooglenews } from "react-icons/si";
import { IoIosCalculator } from "react-icons/io";
import { AiOutlineHeatMap } from "react-icons/ai";
import { SiProtools } from "react-icons/si";
import { TfiAnnouncement } from "react-icons/tfi";
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';


const Nav = () => {
  window.scroll(0, 0)
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);
  const [courseList, setCourseList] = useState([]);

  let hoverTimeout;

  const links = [
    { name: 'Market', id: 'market' },
    { name: 'Courses', id: 'courses' },
    { name: 'Signals', id: 'signals' },
    { name: 'Tools', id: 'tools' },
    { name: 'Investment', id: 'donation' },
    { name: 'About', id: 'about' },
  ];

  const handleImageClick = () => {
    alert('Image clicked!');
  };

  const handleMouseEnter = (linkId) => {
    clearTimeout(hoverTimeout);
    setHoveredLink(linkId);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setIsDropdownHovered(false);
      setHoveredLink(null);
    }, 300); // Adjust delay time (in milliseconds) as needed
  };

  const handleDropdownMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsDropdownHovered(true);
  };

  const handleDropdownMouseLeave = () => {
    setIsDropdownHovered(false);
    setHoveredLink(null);
  };

  const fetchCoursesData = async () => {
    const resp = await fetchPublishedCourses();
    setCourseList(resp)
  };

  const handledropdownclose = () => {
    setIsDropdownHovered(false);
    setHoveredLink(null);
  };


  useEffect(() => {
    fetchCoursesData()
  }, [])

  const [isOpen, setIsOpen] = useState(false);

  const [activeLink, setActiveLink] = useState(null);
  const hamburgerHander = () => {
    setActiveLink(null);
    setIsOpen(!isOpen);
  };




  return (
    <>
      <div className="mobile-ham fixed top-[12%] left-0">
        {/* <div className="ham-wrapper" onClick={hamburgerHander}>
          {isOpen ? <RxHamburgerMenu size={40} color='blue' /> : <RxCross2 size={40} color='red' />}
        </div> */}
      </div>
      <div className='z-30 main-nav fixed top-[6.5%] left-0 w-[100vw]  '
      // style={{ position: "fixed", top: "6.5%", left: 0, width: '100vw' }}
      >
        <nav className="bg-gray-800 text-white h-[80px] p-3 relative mt-[1.5%]">
          <ul className="flex space-x-12 w-[70%]   m-auto main-navbar ">
            {links.map((link) => (
              <li
                key={link.id}
                className="relative group pt-4 inner-navbar"
                onMouseEnter={() => handleMouseEnter(link.id)}
                onMouseLeave={handleMouseLeave}
              >
                <a href="#" className="flex items-center">
                  {link.name}
                  {hoveredLink === link.id ? (
                    <FiChevronUp className="ml-2" />
                  ) : (
                    <FiChevronDown className="ml-2" />
                  )}
                </a>
              </li>
            ))}
            <li className="relative group pt-[0.5%] nav-button">
              <button className="  w-[180px] h-[45px] rounded-lg bg-blue-700 flex items-center justify-center text-white hover:bg-blue-800">
                <NavLink target='_blank' to='https://investment.investorszon.com/'>INVEST WITH US  </NavLink>
              </button>
            </li>
          </ul>
          {(hoveredLink || isDropdownHovered) && (
            <div
              style={{ marginTop: "1%" }}
              className="absolute left-0 w-screen  bg-white text-black p-4 shadow-lg z-10 dropdown-content-div"
              onMouseEnter={handleDropdownMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
            >
              <div className="max-w-screen-xl mx-auto">
                {hoveredLink === 'market' ? (
                  <div>
                    <div className="bg-white">
                      <Link
                        to="/market"
                        onClick={handledropdownclose}
                        className="block font-bold rounded text-black flex justify-center py-[10px] text-sm hover:text-primary dark:text-dark-6 dark:hover:text-primary flex items-center"
                      >
                        <FaChartLine size={30} className="mr-4" />
                        Market
                      </Link>
                      <p className="text-xs pl-4 py-[10px] text-gray-500 text-center">
                        Markets are platforms where buyers and sellers exchange assets like
                        stocks, commodities, and cryptocurrencies. They play a crucial role in
                        the economy by determining prices, allocating resources, and
                        facilitating trade.
                      </p>
                      <div className="w-[100%] flex justify-between">
                        <Link to="/forex-market">
                          <div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                            <p className="block rounded text-black font-bold px-4 py-[10px] text-sm hover:text-primary dark:text-dark-6 dark:hover:text-primary flex items-center">
                              <BsCurrencyExchange size={35} className="mr-2" />
                              Forex Market
                            </p>
                            <p className="mx-5 text-xs text-gray-500">
                              Forex markets are global platforms for trading currencies. They
                              operate 24/7, allowing participants to buy, sell, and exchange
                              currencies. Forex markets are crucial for international trade
                              and investment, influencing global economic stability.
                            </p>
                          </div>
                        </Link>
                        <Link to="/stock-market">
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p className="block rounded text-black font-bold px-4 py-[10px] text-sm hover:text-primary dark:text-dark-6 dark:hover:text-primary flex items-center">
                              <GoGraph size={35} className="mr-2" />
                              Stock Market
                            </p>
                            <p className="mx-5 text-xs text-gray-500">
                              Stock markets are venues where investors buy and sell shares of
                              publicly traded companies. They facilitate capital raising for
                              businesses and offer opportunities for individuals to invest,
                              driving economic growth and wealth creation.
                            </p>
                          </div>
                        </Link>
                        <Link to="/mutual-fund" onClick={handledropdownclose} >
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p className="block rounded text-black font-bold px-4 py-[10px] text-sm hover:text-primary dark:text-dark-6 dark:hover:text-primary flex items-center">
                              <TbPigMoney size={35} className="mr-2" />
                              Mutual Funds
                            </p>
                            <p className="mx-5 text-xs text-gray-500">
                              Mutual funds markets involve pooled investments where funds are
                              collected from many investors to buy securities. Managed by
                              professionals, these funds offer diversification, reducing risk
                              while aiming for steady returns.
                            </p>
                          </div>
                        </Link>
                        <Link to="/commodity-market" onClick={handledropdownclose}>
                          <div className="w-[98%] hover:bg-blue-100 my-2 rounded py-3">
                            <p className="block rounded text-black font-bold px-4 py-[10px] text-sm hover:text-primary dark:text-dark-6 dark:hover:text-primary flex items-center">
                              <AiFillGold size={35} className="mr-2" />
                              Commodity Market
                            </p>
                            <p className="mx-5 text-xs text-gray-500">
                              Commodity markets are platforms where raw materials like gold,
                              oil, and agricultural products are traded. These markets enable
                              price discovery, risk management, and investment opportunities,
                              impacting global economic stability.
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : hoveredLink === 'courses' ? (
                  <div>
                    <Link
                      to="/school-of-investors-zon" onClick={handledropdownclose}>
                      <div className="bg-white ">
                        <p className=" block font-bold  flex justify-center  rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center">
                          <LuSchool
                            size={35}
                            className="mr-2"
                          /> School of Investors zon
                        </p>
                        <p className="text-xs   py-[10px]  text-gray-500 text-center">
                          Welcome! Are you new to Fintech Forex or Crypto
                          Trading . The School of Investors zon provides
                          detailed course that helps beginners learn how to
                          trade forex and crypto. Explore it
                        </p>
                        <div className="w-[100%] flex   justify-between">
                          {courseList.length > 0 ?
                            courseList.slice(0, 2).map((val, index) => {
                              const someState = { id: val.id };

                              return <div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                                <Link
                                  state={someState}
                                  to={`/single-course-details/${val.slug}`}
                                  onClick={handledropdownclose}
                                  className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                                >
                                  <FcCurrencyExchange
                                    size={35}
                                    className="mr-2"
                                  />
                                  {val.title}
                                </Link>
                                <p className="mx-5  text-xs  text-gray-500">
                                  {val.description}
                                </p>
                              </div>
                            })
                            :
                            < div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                              <Link
                                to="#"
                                className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                              >
                                <FcCurrencyExchange
                                  size={35}
                                  className="mr-2"
                                />
                                Comming Soon
                              </Link>
                              <p className="mx-5  text-xs  text-gray-500">
                                Comming Soon
                              </p>
                            </div>
                          }



                          <Link
                            to="/course-subscription-plan" onClick={handledropdownclose}>
                            <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                              <p
                                className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                              >
                                <BsPatchCheckFill
                                  size={25}
                                  color="blue"
                                  className="mr-2 "
                                />
                                Course Subscription Plan
                              </p>
                              <div>
                                <p className="mx-5 text-xs  text-gray-500">
                                  Unlock access to our comprehensive Trading
                                  Course subscription and gain valuable insights
                                  into profitable cryptocurrency trading
                                  strategies and techniques.
                                </p>
                              </div>
                            </div>
                          </Link>
                          <Link
                            to="/my-certificates" onClick={handledropdownclose}>
                            <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                              <p
                                className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                              >
                                <LiaCertificateSolid
                                  size={35}
                                  color="blue"
                                  className="mr-2"
                                />
                                Verify Certificates 
                              </p>
                              <p className="mx-5 text-xs text-gray-500">
                                Receive our Course Completion Certificate -
                                Prove your proficiency in trading with our
                                certification and show case your skills .
                              </p>
                            </div>
                          </Link>

                        </div>
                      </div>
                    </Link>
                  </div>
                ) : hoveredLink === 'signals' ? (
                  <div>

                    <div className="bg-white ">
                      <NavLink
                        to="/signals"
                        onClick={handledropdownclose}
                        className="  block   flex justify-center rounded px-4 py-[10px] font-bold text-sm text-body-color hover:text-primary dark:text-dark-6 dark:hover:text-primary"
                      >
                        <p className=" block font-bold  flex justify-center rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center">
                          <VscGraphLine size={35} className="mr-2 font-bold" /> Trading Signals
                        </p>
                      </NavLink>
                      <p className="text-xs py-[10px] text-gray-500   text-center">
                        "Accurate trading signals provider offering real-time alerts for stocks, forex, and cryptocurrencies. Enhance your trading strategy today!"
                      </p>
                      <div className="w-[100%] flex   justify-between">
                        <Link
                          to="/free-paid-crypto-signals"
                          onClick={handledropdownclose}>
                          <div className=" w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                            <p
                              className="block  font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <SiStockx size={25} className="mr-2" />
                              Free Crypto Forex & Stocks Signals
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Get free crypto, forex, and stock signals! Enhance your trading strategies with our accurate, real-time market insights and expert recommendations.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/signals-subscription" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <VscGraphLine size={25} className="mr-2" />
                              Subscription Plans
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">

                              Discover our subscription plans for exclusive trading signals, in-depth market analysis, educational content, and dedicated support. Optimize your trading journey today!
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/signal-blog-video" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <FaBlogger size={25} className="mr-2" />
                              Signal Blogs and Videos
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Explore our signal blogs and videos for the latest trading insights, strategies, and tutorials. Stay informed and enhance your trading skills with expert content.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/signal-annoucements" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block  font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <TfiAnnouncement size={25} className="mr-2" />
                              Signal Announcements
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Stay updated with our signal announcements! Receive timely alerts and updates on trading signals, market trends, and important financial news. Stay ahead in your trading journey.                          </p>
                          </div>
                        </Link>
                        <div className=" hover:bg-blue-100 my-2 rounded py-3"></div>
                      </div>
                    </div>

                  </div>
                ) : hoveredLink === 'donation' ? (
                  <div>
                    <div className="bg-white ">
                      <Link
                        target='_blank'
                        to="https://investment.investorszon.com/"
                        onClick={handledropdownclose}
                        className="block  flex justify-center rounded px-4 py-[10px] font-bold text-sm text-body-color hover:text-primary dark:text-dark-6 dark:hover:text-primary"
                      >
                        <p className=" block font-bold  flex justify-center rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center">
                          <GiTakeMyMoney size={25} color='black' className="mr-2" />  Fund Raising
                        </p>

                      </Link>
                      <p className="text-xs py-[10px]  text-gray-500  text-center">
                        Fund raising is the process of gathering voluntary contributions or funds from individuals, businesses, or organizations to support specific causes or initiatives.
                      </p>
                      <div className="w-[100%] flex   justify-between">
                        <Link
                          target='_blank'
                          to="https://investment.investorszon.com/"
                          onClick={handledropdownclose}>

                          <div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <VscGraph size={25} className="mr-2" />
                              Equity Investment
                            </p>
                            <p className="mx-5  text-xs  text-gray-500">
                              Equity investment involves buying ownership in a company through purchasing shares, aiming for returns through dividends or capital appreciation.
                            </p>
                          </div>
                        </Link>
                        <Link
                          target='_blank'
                          to="https://investment.investorszon.com/ "
                          onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <FaDonate size={25} className="mr-2" />
                              Donate
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              To donate is to voluntarily give money or resources to a cause, charity, or organization without expecting anything in return.
                            </p>
                          </div>
                        </Link>

                        {/* <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                          <Link
                            to="/portfolio-management                        "
                            className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                          >
                            <TbWorldWww size={25} className="mr-2" />
                            Portfolio Management
                          </Link>
                          <p className="mx-5 text-xs  text-gray-500">
                            Lorem ipsum dolor sit amet consectetur.
                          </p>
                        </div>

                        <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                          <Link
                            to="/withdrawal-options"
                            className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                          >
                            <TbWorldWww size={25} className="mr-2 " />
                            Withdrawal Options
                          </Link>
                          <p className="mx-5 text-xs  text-gray-500">
                            Lorem ipsum dolor sit amet consectetur.
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ) : hoveredLink === 'about' ? (
                  <div>
                    <div className="bg-white ">
                      <Link
                        to="/about-us"
                        onClick={handledropdownclose}
                        className="  block  flex justify-center rounded px-4 py-[10px] font-bold text-sm text-body-color hover:text-primary dark:text-dark-6 dark:hover:text-primary"
                      >
                        <p className=" block font-bold  flex justify-center rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center">
                          <TbWorldWww size={30} className="mr-4" /> Investors Zon
                        </p>

                      </Link>
                      <p className="text-xs py-[10px]  text-gray-500   text-center">
                        We offering diverse financial services, innovative solutions, and seamless transactions, revolutionizing the global financial landscape and customer experience.
                      </p>
                      <div className="w-[100%] flex   justify-between">
                        <Link
                          to="/about-investorszon"
                          onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <TbWorldWww size={25} className="mr-2" />
                              About Investors Zon
                            </p>
                            <p className="mx-5  text-xs  text-gray-500">
                              Investors Zone: A platform offering insights, resources, and networking for investors to explore opportunities and enhance financial decision-making.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/founders-investorszon" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <TbWorldWww size={25} className="mr-2" />
                              Founder's Investors Zon
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Founders of Investors Zon aim to democratize investing, offering accessible financial insights and opportunities through user-friendly platforms and expert guidance.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/contact-us " onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <TbWorldWww size={25} className="mr-2" />
                              Contact Us
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Have questions or feedback? Get in touch with us using the details below:


                            </p>
                          </div>
                        </Link>


                        {/* <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                              <Link
                                to="/privacypolicy"
                                className="block rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                              >
                                <TbWorldWww size={25} className="mr-2" />
                                Privacy Policy
                              </Link>
                              <p className="mx-5 text-xs">
                                Lorem ipsum dolor sit amet consectetur.
                              </p>
                            </div> */}

                        <div className=" hover:bg-blue-100 my-2 rounded py-3"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="bg-white ">
                      <Link
                        to="/tools"
                        onClick={handledropdownclose}
                        className="  block  flex justify-center rounded px-4 py-[10px] text-black font-bold pl-4 text-sm text-body-color hover:text-primary dark:text-dark-6 dark:hover:text-primary"
                      >
                        <p className=" block font-bold  flex justify-center rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center">
                          <SiProtools size={25} className="mr-2" />  Tools
                        </p>

                      </Link>
                      <p className="text-xs py-[10px]  text-gray-500   text-center">
                        Trading tools include essential resources like charting software, risk calculators, economic calendars, and news aggregators. These tools are pivotal for refining trading strategies and staying informed about market trends.                      </p>
                      <div className="w-[100%] flex   justify-between">
                        <Link
                          to="/market-insight" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 text-black my-2 rounded py-3">
                            <p
                              className="block rounded font-bold text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <SiGooglemarketingplatform size={25} className="mr-2" />
                              Market Insight
                            </p>
                            <p className="mx-5  text-xs  text-gray-500">
                              Market Insight provides a comprehensive analysis of current trends, economic indicators, and investment opportunities, empowering informed decision-making for investors.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/economic-calendar" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block  font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <SlCalender size={25} className="mr-2" />
                              Economic Calendar
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              An Economic Calendar highlights key financial events, announcements, and indicators, guiding investors and analysts in understanding market movements and economic trends.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/news-and-analysis " onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <SiGooglenews size={25} className="mr-2" />
                              News and Analysis
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              News and Analysis provide insights into market trends, economic developments, and financial events, helping investors make informed decisions and stay updated on current affairs."
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/trading-calculator" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p
                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <IoIosCalculator size={25} className="mr-2 " />
                              Trading Calculator
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Trading Calculator assists traders in calculating potential profits, losses, and risks associated with various trades, helping to optimize trading strategies and manage investment portfolios effectively.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/market-heatmap" onClick={handledropdownclose}>
                          <div className="w-[100%] hover:bg-blue-100 my-2 rounded py-3">
                            <p

                              className="block font-bold rounded text-black  px-4 py-[10px] text-sm  hover:text-primary dark:text-dark-6 dark:hover:text-primary flex  items-center   "
                            >
                              <AiOutlineHeatMap size={25} className="mr-2 " />
                              Market Heatmap
                            </p>
                            <p className="mx-5 text-xs  text-gray-500">
                              Market Heatmap offers a comprehensive, visual overview of market performance, highlighting trends and movements across various sectors
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          )}
        </nav>
      </div>







    </>
  );
};

export default Nav;

