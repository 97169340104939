import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit'
import BasicTabs, {TabPanel} from "./Components/DemoScreen";
import CartProvider from "./context/CartContext";
// import {CartProvider} from "./context/CartContext";
// import TabPanel from "../public/DemoScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));
const authStore = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'http:',
})
root.render(
    <BrowserRouter>
<CartProvider>
       <AuthProvider store={authStore}> 
      <App  />
      </AuthProvider>
      </CartProvider> 
    </BrowserRouter>
  
);


