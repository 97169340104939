import React from 'react'
import Title from '../TitleComp/Title'
import CookiePrivacy from './PrivacypolicyComp/CookiePrivacy';

const CookiesandPrivacy = () => {
    const titledata = [
        {  header: "Privacy Policy", descp:"Welcome to INVESTORS ZON. We prioritize your privacy and are dedicated to safeguarding your personal information. This Privacy Policy details how we gather, utilize, disclose, and protect your data when you interact with our websites, products, and services." },
    ];
    const Cookiesdata=[
        {header:" 1. Acceptance of Terms", descp:`By accessing or using Investors Zon  Services, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree with these Terms, please discontinue using our Services immediately.`},

        {header:"2. How We Use Your Information", descp:`To Provide and Improve Our Services: We utilize your information to operate, maintain, and enhance our products and services. This includes processing transactions, offering customer support, and personalizing your experience.

To Communicate with You: We use your information to keep you informed about your account, our services, and promotions. This includes sending updates, newsletters, and marketing materials.

To Analyze and Improve: We analyze the information we collect to identify trends, track user engagement, and enhance our websites, products, and services. This helps us better understand our users and improve their experience.

To Ensure Security: We use your information to detect, prevent, and address fraud, abuse, and security issues that could impact Investors Zon or our users.`},
{header:"3. Sharing Your Information", descp:`With Service Providers: We may share your information with third-party service providers who perform various services on our behalf, such as payment processing, data analysis, email delivery, and customer support.

For Legal Reasons: We may disclose your information if required by law or in response to a valid request from a governmental authority. This includes complying with legal processes and protecting the rights, property, and safety of Investors Zon, our users, and others.

With Your Consent: We may share your information with third parties if you have provided your consent for us to do so.`},
{header:"4. Your Choices and Rights", descp:`Access and Update Your Information: You have the right to access, update, and correct your personal information. You can do this by logging into your account or by contacting us directly.

Opt-Out of Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails or by contacting us directly.

Manage Cookies: You can manage your cookie preferences through your browser settings. However, disabling cookies may impact your ability to use certain features of our websites.

Delete Your Information: You have the right to request the deletion of your personal information. Please note that some information may be retained for legal, security, or business purposes`},
{header:"5. Security of Your Information", descp:`We implement reasonable measures to protect your information from unauthorized access, use, and disclosure. This includes physical, technical, and administrative security safeguards. However, no security system is entirely impenetrable, and we cannot guarantee the absolute security of your information.`},
{header:"6. Children’s Privacy", descp:`Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we discover that we have collected such information, we will take steps to delete it from our records.`},
{header:"7. Changes to This Privacy Policy", descp:`We may update this Privacy Policy periodically to reflect changes in our practices or for operational, legal, or regulatory reasons. We will notify you of any significant updates by posting the revised policy on our website and updating the “Last Updated” date at the top of this page.`},
{header:"8. Contact Us", descp:`If you have any questions or concerns about this Privacy Policy or our data practices, please reach out to us at:

Investors Zon Privacy Team
[Your Contact Information]
[Your Email Address]

We are dedicated to addressing your concerns and resolving any issues related to your privacy and data security. Thank you for trusting Investors Zon with your information.`},
    ]
  return (
    <div>
      <div className=' container mt-[10%]'>
        <Title data ={titledata}/>
<CookiePrivacy data={Cookiesdata}/>
{/* <h2  <h2 className='font-bold text-2xl mb-4'></h2>
     <p className='text-gray-500 mb-6'>   className='font-bold text-5xl mb-4'>Privacy Policy</h2>
<p className='text-gray-500 mb-6'>Welcome to INVESTORS ZON. We prioritize your privacy and are dedicated to safeguarding your personal information. This Privacy Policy details how we gather, utilize, disclose, and protect your data when you interact with our websites, products, and services. </p> */}
     
     
     
      </div>
    </div>
  )
}

export default CookiesandPrivacy
