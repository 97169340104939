import React, { useEffect, useState } from 'react';
import About from './About/About'
import { VscGraph } from "react-icons/vsc";
import { FaStar } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Featurecard from './FeatureCardComp/Featurecard';
import HomePageCard from './HomePageSimpleCard/HomePageCard';
import Title from './TitleComp/Title';
import Accordian from './AccordianComp/Accordian';
import "../Components/Signal.css"
const Signals = () => {
  window.scroll(0, 0)

  const [activeButton, setActiveButton] = useState(0); // State to track active button index

  const handleButtonClick = (index) => {
    setActiveButton(index); // Update active button index on click
  };


  const dataones = [
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Reliability and Trustworthiness", descp1: "User reviews reveal if a broker  is reliable and trustworthy.." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Service Quality ", descp1: "Reviews provide insights into the brokers platform performance,order execution speed. " },
    { icon: <FaSearch size={30} color='white' className='text-white' />, title: "Transparency and Practices ", descp1: "enuine reviews highlight any hidden fees,  account restrictions, or withdrawal issues." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Regulatory Compliance ", descp1: "Reviews help verify if a broker  is properly regulated." },
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Issues and Concerns", descp1: " Consistent negative feedback indicates potential problems with the broker." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Comparisons", descp1: " Reviews allow you to compare brokers based on various factors." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Realistic Expectations ", descp1: "Reviews provide a realistic view of what to  expect from  the broker’s services." },
  ]
  const dataonetwo = [
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Authenticity", descp1: "Ensure the reviews are genuine and not fabricated." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Volume of Reviews ", descp1: "A large number of positive reviews across multiple platforms is more reliable." },
    { icon: <FaSearch size={30} color='white' className='text-white' />, title: "Review Sources ", descp1: "Trustworthy review sites are less likely to have biased reviews." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Recency ", descp1: "Recent reviews provide the most current information." },
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Negative Reviews", descp1: " Consistent negative feedback should be taken seriously." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Personal Needs", descp1: " Consider your specific trading requirements." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Regulatory Compliance ", descp1: "Verify the broker’s regulatory status." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Multiple Sources", descp1: " Check reviews from various sources for a well-rounded view." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Demo Accounts ", descp1: "Test the broker’s platform and services using a demo account before committing real funds." },
  ]
  const dataonethree = [
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Major Market Changes", descp1: "Check reviews following significant economic events." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Regulatory Updates ", descp1: "Stay informed about regulatory changes." },
    { icon: <FaSearch size={30} color='white' className='text-white' />, title: "Broker Offerings ", descp1: " Monitor changes in broker services and fees." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "User Feedback ", descp1: "Regularly check user reviews for consistent patterns." },
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Periodic Reviews", descp1: " Follow monthly or quarterly broker reviews." },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Considering New Brokers", descp1: "Review the latest feedback when evaluating new brokers." },
    { icon: <HiSupport size={30} color='white' className='text-white' />, title: "Technology Updates ", descp1: "Keep an eye on updates to trading platforms." },
  ]
  const dataonefour = [
    { icon: <VscGraph size={30} color='white' className='text-white' />, title: "Evaluate Platform Performance", descp1: "Test the broker’s trading platform", descp2: ' without risking real money.' },
    { icon: <FaStar size={30} color='white' className='text-white' />, title: "Practice Strategies ", descp1: "Try different trading strategies ", descp2: "in a risk-free environment." },
    { icon: <FaSearch size={30} color='white' className='text-white' />, title: "Assess Broker Reliability ", descp1: " Ensure the broker’s services ", descp2: "meet your expectations." },

  ]


  const data = [
    { icon: <VscGraph size={40} color='white' className='text-white' />, title: "Proven Performance", descp1: "High Performance forex live  ", descp2: "Signals verify by MyFXBook." },
    { icon: <FaStar size={40} color='white' className='text-white' />, title: "Five Star Rated Signals ", descp1: "Recomended by Happy ", descp2: "Members." },
    { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Expert Analysis ", descp1: "Reliable Forex signals  ", descp2: "experienced team." },
    { icon: <HiSupport size={40} color='white' className='text-white' />, title: "Full support ", descp1: "we are here to support ", descp2: "you all the way" },
  ]
  const accordiandataone = [
    {
      header: "Join Our Telegram Exclusive Group for Extra Benefits",
      text: "Unlock additional benefits by joining our exclusive Telegram group. Connect with other traders, share your ideas and knowledge, and access extra resources that can help you improve your trading skills. Be part of a vibrant community that supports and uplifts each other on the path to financial success. Together, we can achieve more through the best forex live signals provided on Telegram.",
      text1: "By joining Investors zon, you gain more than just access to live forex signals; you become part of a community dedicated to your success. Let us guide you through the intricacies of forex trading and help you realize your full potential in the market. With Investors zon, your journey to financial success is just a signal away.",
    },
    {
      header: "Live Forex Signals",
      text: "Forex signals provide you with a way to make trades faster and more efficiently without having to go through all the work it takes. At Investors zon, we have a simple ethos that compels us to increase efficiency while reducing the cost of premium live forex signals.",
      text1: "In your journey to becoming a trader, you have likely encountered numerous educational materials emphasizing the importance of analysis. Does it seem challenging? If so, that’s because it is.",
      text2: "Live forex signals from one of our premium plans will ensure that you dont have to go through all that hassle and still risk losing money. Give yourself the best chance to succeed and get forex signals to significantly increase your chances of winning in the FX markets."
    },
    {
      header: "Forex Broker Reviews",
      text: "Imagine stepping into the exhilarating world of forex trading, where currencies from different nations are exchanged, and fortunes can be made or lost in moments. Picture yourself as a captain navigating through this vast ocean of opportunities, seeking the best routes to profitability. In such a dynamic environment, you need a reliable guide, and thats where forex broker reviews or forex trading reviews come in.",
      text1: "With hundreds of online forex brokers available, choosing the right one can be confusing. How can you determine the most suitable option for yourself? That’s where forex trading reviews become invaluable. They provide insightful information and opinions from other traders who have already tried these brokers.",
      text2: "this comprehensive guide, we’ll explore what makes a great forex broker stand out. We’ll evaluate their trading platforms, ease of use, available trading instruments, and customer support quality. By the end of this journey, you’ll be equipped with the knowledge to make informed decisions, ensuring your trading experience is not only exciting but also rewarding. Let the winds of knowledge guide you as you chart a course toward trading success!"
    },
    {
      header: "Forex Trading and the Role of Forex Brokers",
      text: "People who enjoy trading and investing have always been drawn to global financial markets. One of the most dynamic markets is forex trading, offering numerous opportunities for quick profits. When traders want to engage in forex trading, they need the assistance of forex brokers. This section will delve into the fundamentals of forex trading, the crucial role of forex brokers, and the importance of reliable forex broker reviews..",
      text1: 'Forex trading involves the exchange of various currencies from around the world. It’s like a vast marketplace where banks, corporations, governments, and individual traders exchange currencies. The exchange rates fluctuate constantly, driven by various economic and geopolitical factors.'
    },
    {
      header: "The Crucial Role of Forex Brokers",
      text: "Forex brokers act as intermediaries, connecting individual traders to the interbank market. Without brokers, accessing this large market would be challenging for individual traders. Brokers provide trading platforms, tools, and services to facilitate trading and profit from currency value fluctuations.",
      text1: 'Brokers offer access to a wide range of currency pairs and other financial instruments, enabling traders to employ diverse trading strategies. They also offer leverage, allowing traders to control larger positions with a smaller amount of capital. While leverage can amplify profits, it also increases potential losses, making it crucial to use it wisely..'
    },
    {
      header: "Why Forex Broker Reviews Matter",
      text: "Choosing the right forex broker is critical to a trader’s success. However, with so many brokers claiming to be the best, deciding whom to trust can be daunting. This is where forex broker reviews become invaluable.",
      text1: 'Forex trading reviews provide essential insights into a broker’s offerings, performance, customer service, regulatory compliance, and reputation. These reviews are typically written by experienced traders or industry experts, making them a trustworthy source of information.'
    },

  ]
  const featuredata = [
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, heading: "Follow Every Signal and Maximize Your Earnings", description: 'Imagine never missing out on a profitable trading opportunity again. With our live forex signals, you’ll receive instant notifications through our Telegram Channel for every signal generated by our expert traders.  ' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, heading: "Stay Ahead with Email Alerts on All Signals", description: 'Don’t let profitable trading opportunities slip through your fingers. Our live trading signals service ensures you stay informed with email alerts on all signals. Whether you’re at your computer or on the go, you’ll never miss a chance to make a winning trade. ' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, heading: "Full Access to Signals Performance Reports", description: 'Transparency is crucial in forex trading. Gain full access to comprehensive performance reports for all our signals. Track the success rate, analyze past trends, and make informed decisions based on real-time forex signals. ' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, heading: "Get Informed with Alerts on Economic Events", description: 'Stay updated on critical economic events that can impact your trades. Our daily live signals service goes beyond just signals. Receive timely alerts on important economic events that could influence the market.' },




  ]
  const Signalsdata = [
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Forex Regulations and Licensing", description: "Verify if the broker is regulated by reputable financial authorities such as the FCA, CFTC, or ASIC. Regulatory compliance ensures the broker adheres to strict rules, protecting your interests and funds." },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Trading Platform and Technology", description: "The trading platform is the interface through which you execute trades. Look for brokers offering popular platforms like MobiusTrader 7 (MT7) or MetaTrader 5 (MT5), known for their user-friendly interfaces, advanced charting tools, and automated trading capabilities." },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Forex Account Types and Minimum Deposit", description: "Different trading strategies and budgets require different account types. Choose a broker offering various account options, such as standard, mini, or micro accounts, with suitable minimum deposit requirements." },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Spreads and Commissions", description: "Low spreads and commissions are essential for profitable trading. Compare the costs across different brokers to find the most competitive rates." },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Leverage Options", description: "Leverage allows you to control larger positions with a smaller capital outlay. However, it also increases risk. Choose a broker offering sensible leverage options, complying with regulatory limits." },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Customer Support", description: "Efficient customer support is crucial for resolving issues promptly. Look for brokers offering 24/5 support via multiple channels, including live chat, email, and phone." },

    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Educational Resources", description: 'Continuous learning is vital for trading success. Opt for brokers providing comprehensive educational resources, including webinars, tutorials, articles, and market analysis.' },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Deposit and Withdrawal Options", description: 'Easy and fast deposit and withdrawal processes are essential. Choose brokers supporting multiple payment methods like bank transfers, credit/debit cards, and e-wallets.' },
    { icon: <VscGraph size={40} color='white' className='text-center' />, heading: "Tradable Assets", description: 'Diversification is a key strategy in trading. Look for brokers offering a wide range of tradable assets, including major, minor, and exotic currency pairs, commodities, indices, and cryptocurrencies.' },
  ]
  const Featuretitle = [
    { header: "Types of Forex Brokers", descp: 'Understanding the different types of forex brokers helps in making an informed choice:' },

  ]
  const Featuredatatwo = [
    { header: "Real User Reviews and Testimonials", descp: "Real user reviews provide firsthand insights into a broker’s reliability, service quality, transparency, and regulatory compliance. While reading reviews, consider the following:" }
  ]
  const Featuretitleone = [
    { header: "Factors to Consider in the Forex Review", descp: 'Choosing the right forex broker can significantly impact your trading success. Here’s a comprehensive guide to the crucial factors to consider:' },

  ]
  const Featuretitlesone = [
    { header: "Benefits of Reliable Forex Broker Reviews" },

  ]
  const featuredataone = [
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Dealing Desk (DD) Forex Brokers", descp: 'Also known as Market Makers, DD brokers create a market for their clients. They act as the counterparty to your trades, offering fixed spreads and stability, making them suitable for beginners.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "No Dealing Desk (NDD) Forex Brokers", descp: 'NDD brokers connect you directly to the interbank market without intervening in your trades. They usually offer variable spreads and faster execution, suitable for traders seeking transparency.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Electronic Communication Network (ECN) Forex Brokers", descp: 'ECN brokers provide direct access to the interbank market, displaying the best buy and sell prices. They offer tighter spreads and are ideal for experienced traders seeking direct market access.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Straight Through Processing (STP) Forex Brokers", descp: 'STP brokers route your orders directly to liquidity providers, offering competitive spreads and fast execution. They are suitable for professional traders seeking fair and efficient trading.' },
  ]
  const featuredataatwo = [
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Evaluate Broker Reliability", descp: 'Reviews help determine if a broker is trustworthy, ensuring your funds are in safe hands.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Assess Trading Platform", descp: 'Reviews provide insights into the usability and efficiency of a broker’s trading platform, crucial for a smooth trading experience.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Examine Customer Support", descp: 'Good customer support is vital, especially in the fast-paced forex market. Reviews highlight the quality of a broker’s support services.' },
    { icon: <VscGraph size={30} color='#ffff' className='text-center' />, title: "Uncover Regulation and Security", descp: 'Reviews reveal whether a broker complies with regulatory standards, ensuring your funds and personal information are secure.' },
  ]
  const datafive = [
    { title: "Final Words", descp: 'Forex broker reviews play a crucial role in guiding traders to make informed decisions in the dynamic world of forex trading. By understanding the key factors to consider and leveraging genuine user feedback, you can choose a reliable broker that meets your trading needs. Remember, continuous research and staying informed about market changes and broker updates are essential for long-term trading success.' },

  ]
  const Titlefeatures = [
    { header: "Signals " }
  ]
  const FourTrading = [
    { header: "Four Reason to join our Services" }
  ]
  const Featuredatathree = [
    { header: "Can I Trust the Top-Rated Brokers in Reviews?", descp: "While top-rated brokers often have positive reviews, it’s essential to exercise caution and conduct additional research. Consider the following:" }
  ]
  const Featuredatafour = [
    { header: "How Often Should I Check for Updated Forex Broker Reviews?", descp: "The frequency of checking for updated reviews depends on market changes and personal trading needs. Generally, reviewing at least once a month is advisable. Pay attention to:" }
  ]
  const Featuredatafive = [
    { header: "Are Demo Accounts Useful for Testing Brokers?", descp: "Demo accounts are extremely useful for testing brokers. They allow you to:" }
  ]
  return (
    <div>
      <div className='mt-[96px]'>
        <About />
      </div>
      <div className="-mx-4 flex flex-wrap">
      </div>
      <div className='container '>
        <h2 className='w-[80%] m-auto mb-5  mt-9 text-center font-bold leading-tight text-dark dark:text-white sm:text-[40px] sm:leading-[1.2] '>Unleash Your Trading Potential with Investors zon's Live Forex Signals</h2>
        <p className='w-[80%] m-auto text-center'>Are you overwhelmed by complex charts and struggling to understand market movements on your own? Have you ever wished for a reliable solution to navigate the world of forex trading effortlessly? Your search ends here!</p>
        <p className='w-[80%] m-auto text-center'>At Investors zon, we guarantee that our live forex signals will elevate your trading game to unprecedented heights. We are not just another signals service; we are a dynamic force poised to revolutionize your trading journey. Prepare to be amazed as our expert team integrates cutting-edge technology with in-depth market analysis to deliver precise and timely trade opportunities directly to your fingertips.</p>
      </div>
      <div className='container   mt-24  py-6 '>
        <Title data={FourTrading} />
        <div className='flex flex-wrap gap-8 w-[100%] justify-center mb-4  '>
          {data.map((val, index) => {
            return (
              <div className='flex inner-signal '>
                <div className='left-inner-signal flex items-center justify-center w-[70px] h-[70px] bg-blue-600 rounded-full'>
                  {val.icon}
                </div>
                <div className='right-inner-signal ml-4 '>
                  <h2 className=' font-semibold '>{val.title}</h2>
                  <p className='w-[100%] text-gray-500'>{val.descp1}<br></br>{val.descp2}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='container '>
        <Title data={Titlefeatures} />
        <HomePageCard data={featuredata} />
      </div>
      <Accordian data={accordiandataone} />
      <div className=' container'>
        <Title data={Featuretitlesone} />
        <Featurecard data={featuredataatwo} />
      </div>
      <div className='container mt-6'>
        <Title data={Featuretitleone} />
        <HomePageCard data={Signalsdata} />
      </div>
      <div className='container '>
        <Title data={Featuretitle} />
        <Featurecard data={featuredataone} />
      </div>
      <div className='container mt-4 mb-12'>
        <Title data={Featuredatatwo} />
        <div className='flex flex-wrap gap-8 w-[100%] lg:w-[100%] justify-center  '>
          {dataones.map((val, index) => {
            return (
              <div className='flex w-[30%] inner-signal '>
                <div className='flex items-center justify-center w-[55px] h-[55px] bg-blue-600 rounded-full'>
                  {val.icon}
                </div>
                <div className='ml-4 w-[280px] h-[100px]'>
                  <h2 className=' font-semibold '>{val.title}</h2>
                  <p className='w-[100%] text-gray-500'>{val.descp1}<br></br>{val.descp2}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='container mb-12'>
        <Title data={Featuredatathree} />
        <div className='flex flex-wrap gap-8 w-[100%] lg:w-[100%] justify-center  '>
          {dataonetwo.map((val, index) => {
            return (
              <div className='flex w-[30%] inner-signal '>
                <div className='flex items-center justify-center w-[55px] h-[55px] bg-blue-600 rounded-full'>
                  {val.icon}
                </div>
                <div className='ml-4 w-[280px] h-[100px]'>
                  <h2 className=' font-semibold '>{val.title}</h2>
                  <p className='w-[100%] text-gray-500'>{val.descp1}<br></br>{val.descp2}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='container mb-12'>
        <Title data={Featuredatafour} />
        <div className='flex flex-wrap gap-8 w-[100%] lg:w-[100%] justify-center  '>
          {dataonethree.map((val, index) => {
            return (
              <div className='flex w-[30%] inner-signal '>
                <div className='flex items-center justify-center  w-[55px] h-[55px] bg-blue-600 rounded-full'>
                  {val.icon}
                </div>
                <div className='ml-4 w-[280px] h-[100px]'>
                  <h2 className=' font-semibold '>{val.title}</h2>
                  <p className='w-[100%] text-gray-500'>{val.descp1}<br></br>{val.descp2}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='container mb-12'>
        <Title data={Featuredatafive} />
        <div className='flex flex-wrap gap-8 w-[100%] lg:w-[100%] justify-center  '>
          {dataonefour.map((val, index) => {
            return (
              <div className='flex w-[30%] inner-signal '>
                <div className='flex items-center justify-center w-[50px] h-[50px] bg-blue-600 rounded-full'>
                  {val.icon}
                </div>
                <div className='ml-4 '>
                  <h2 className=' font-semibold '>{val.title}</h2>
                  <p className='w-[100%] text-gray-500'>{val.descp1}<br></br>{val.descp2}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {datafive.map((val, index) => {
        return (
          <div className='container mb-12'>
            <h2 className='m-auto mb-5  mt-5 text-center font-semibold leading-tight text-dark dark:text-white sm:text-[40px] '>{val.title} </h2>
            <p className=''>{val.descp}</p>
          </div>
        )
      })}
    </div>
  )
}

export default Signals
