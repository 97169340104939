import React from "react";
import ocurse from "../../assets/homepage/bg.png";
import HeaderComp from "../SubComponents/HeaderComp";
import Signin from "../Signin/Signin";
import Cta from "../CTA/Cta";
import Card from "../CardComponent/Card";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import CousreCard from "../SubComponents/CousreCard";

const TradingStrategies = () => {
  window.scroll(0,0)
  const currency = { title: "Currency Paris" };
  const TradingSignalone = [
    {
      title: `"`,
      header: "",
      button: "Start using Play",
      description:
        "There are many variations of passages of Lorem Ipsum but the majority have suffered in some form. ",
    },
  ];
  const Carddata = [
    {
      img: ocurse,
      description:
        "There are many variations of passages of  suffered in some form. ",
      name: "Glomiya Lucy",
    },
    {
      img: ocurse,
      description:
        "There are many variations of passages of suffered in some form. ",
      name: "Glomiya Lucy",
    },
    {
      img: ocurse,
      description:
        "There are many variations of passages of  suffered in some form. ",
      name: "Glomiya Lucy",
    },
    {
      img: ocurse,
      description:
        "There are many variations of passages of suffered in some form. ",
      name: "Glomiya Lucy",
    },
  ];
  const courseone =[
    {subscription:"Read More", href:"currencyparis", img:ocurse,title:"Currency Pairs",description:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia, sunt.        "},
    {subscription:"Read More", href:"tradingstrategies",img:ocurse,title:"Trading Strategies",description:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia, sunt.        "},
    {subscription:"Read More", href:"marketanalysis",img:ocurse,title:" Market Analysis",description:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia, sunt.        "},
  ]
  return (
    <div>
      <div className=" container mx-auto mt-24  relative">
        <div className="relative">
          <img
            src={ocurse}
            alt="Paris Financial District  "
            className="w-full h-auto"
            style={{ height: "75vh" }}
          />
          <div className="absolute inset-0 flex items-end justify-center">
            <h1 className="text-3xl font-bold text-white bg-black bg-opacity-50 p-4">
              Currency Paris: Exploring the Heart of Finance
            </h1>
          </div>
        </div>
      </div>
      {/* <HeaderComp data={currency}/> */}

      <div className="container mx-auto flex justify-between">
        <div className="w-3/5		">
          <p className="text-lg mb-4 mt-8">
            Paris, the iconic city of lights, is not only celebrated for its
            rich history, art, and culture but also for its significant role in
            the world of finance. The bustling streets of Paris are home to some
            of the most influential financial institutions, making it a global
            hub for currency exchange and trading.
          </p>
          <p className="text-lg mb-4">
            At the heart of Paris's financial district lies La Défense, a modern
            marvel of skyscrapers and office buildings that serves as the
            epicenter of France's financial industry. Here, towering structures
            like the Grande Arche and the Tour First stand as symbols of
            economic power, housing major banks, investment firms, and
            multinational corporations.
          </p>
          <p className="text-lg mb-4">
            As one of the leading financial centers in the world, Paris plays a
            crucial role in global currency markets. The city is home to the
            Banque de France, the central bank responsible for issuing the euro
            and regulating monetary policy within the Eurozone. With its
            meticulous oversight and stability, the Banque de France ensures the
            integrity of the euro as a reliable currency for trade and
            investment.
          </p>
          <p className="text-lg mb-4">
            In addition to its central role in the Eurozone, Paris boasts a
            vibrant foreign exchange market where traders from around the world
            converge to buy and sell currencies. The city's strategic location
            between European, Asian, and American trading sessions makes it an
            ideal platform for conducting international currency transactions.
          </p>
          <p className="text-lg mb-4">
            Beyond its institutional infrastructure, Paris offers a dynamic
            ecosystem for financial innovation and entrepreneurship. Startups
            and fintech companies flock to the city, drawn by its supportive
            regulatory environment and access to top talent. From mobile payment
            solutions to blockchain technology, Paris is at the forefront of
            shaping the future of finance.
          </p>
          <p className="text-lg mb-4">
            But Paris's significance extends beyond the confines of its
            financial institutions. The city's cultural allure and cosmopolitan
            atmosphere attract a diverse community of professionals, fostering
            cross-border collaboration and exchange. Whether it's a business
            meeting over café au lait or a networking event at a chic brasserie,
            Paris provides the perfect backdrop for forging connections and
            fostering innovation.
          </p>
          <p className="text-lg mb-4">
            In the ever-evolving landscape of global finance, Paris remains a
            steadfast pillar of stability and prosperity. Its legacy as a center
            of commerce and trade continues to thrive, drawing investors,
            entrepreneurs, and visionaries from every corner of the globe. As
            the world navigates the complexities of a rapidly changing economy,
            one thing remains certain: Currency Paris will always be synonymous
            with excellence, integrity, and opportunity.
          </p>
        </div>
        <div className="w-2/5	">
          <Signin />
        </div>
      </div>

      <div className="container mx-auto flex justify-between">
        <div className="w-3/5		">
          <Cta data={TradingSignalone} />
        </div>
        <div className="w-2/5	">
          <Card data={Carddata} />
        </div>
      </div>
      {/* Additional content can be added here to reach around 1000 words */}
      <div className="container mx-auto flex justify-between">
        <div className="w-3/5		">
          <div className="flex justify-between items-center px-4 py-2">
            <div className="flex">
              <button className="mr-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out">
                Design
              </button>
              <button className="mr-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out">
                Developement
              </button>
              <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out">
                Info
              </button>
            </div>
            <div className="flex">
              <a
                href="https://www.facebook.com"
                className="mr-2 hover:text-blue-600"
              >
                <FaFacebook
                  size={30}
                  className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                />
              </a>
              <a
                href="https://www.instagram.com"
                className="mr-2 hover:text-pink-600"
              >
                <FaInstagram
                  size={30}
                  className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                />
              </a>
              <a href="https://www.twitter.com">
                <FaTwitter
                  size={30}
                  className="text-blue-400 hover:text-blue-600 transition duration-300 ease-in-out"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="w-2/5	">
          <div className="relative rounded overflow-hidden">
            <img src={ocurse} className="rounded" />
            <div
              className="absolute inset-0 bg-white opacity-50 rounded"></div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold p-4">Related card</h1>
      <CousreCard data={courseone}/>
      
      </div>
    </div>
  );
};
export default TradingStrategies
