import React from "react";
import CurrencyParis from "./CurrencyParis";


const EconomicCalender = () => {
  window.scroll(0,0)
  return (
    <div>
      <CurrencyParis/>
    </div>
  )
}

export default EconomicCalender
