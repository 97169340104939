import React from 'react'
import Title from '../TitleComp/Title'
import blog from '../../assets/blog/blog-details-01.jpg'
import AnnoucemnetCard from '../SignalBlog&videoComp/AnnouncementCard'
const SignalAnnoucement = () => {
  const signaldata = [
    {title:"", header:"Announcement", descp:"Stay alert for new signals! Receive timely updates and announcements directly on our website, including major happenings, system updates, offers, and more. Never miss an opportunity! "}
]
const signaldataone = [
    {image:blog , date:"Dec 22, 2023",CardTitle:"Meet AutoManage, the best AI management tools",CardDescription:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
    {image:blog , date:"Dec 22, 2023",CardTitle:"Meet AutoManage, the best AI management tools",CardDescription:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
    {image:blog , date:"Dec 22, 2023",CardTitle:"Meet AutoManage, the best AI management tools",CardDescription:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
]
  return (
    <>
    <div className='container mt-[8%]'>
      <Title data={signaldata}/>
      <AnnoucemnetCard data={signaldataone}/>
    </div>
    </>
  )
}
export default SignalAnnoucement
