import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createReview } from '../../api/reviewApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'


const ShowReviewModuleModal = ({ id,reviews,isOpen, onClose }) => {
console.log("reviews",reviews)
console.log("id",id);

const reviewData = reviews?.filter((val)=> val?.moduleId === id);
console.log(")000000",reviewData)

  if (!isOpen) return null;

  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl mb-2 font-semibold">Rating</h2>
        <div className="flex items-center mb-4">
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              className={`w-8 h-8 ms-1 ${
                index < reviewData[0]?.rating ? "text-yellow-300" : "text-gray-300 dark:text-gray-500"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
              style={{ cursor: "pointer" }}
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          ))}
        </div>
        <label className='font-semibold text-2xl'> Message</label>
        <textarea
          className="mt-2 w-full p-2 border border-gray-300 rounded-md mb-4"
          rows="4"
          placeholder="Write your review here..."
          value={reviewData[0]?.message}
        ></textarea>
        <button
          className="bg-blue-600 text-white py-2 px-4 rounded-md"
          onClick={()=>onClose()}
        >
          close
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ShowReviewModuleModal;
