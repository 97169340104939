import React from 'react'
import image1 from '../../assets/about/about-image-01.jpg'
import image2 from '../../assets/about/about-image-02.jpg'
import { NavLink } from 'react-router-dom'
import webinar from "../../assets/webinar.jpg"
import pannel from "../../assets/Pannel.jpg"
import "../About/About.css"
import Title from '../TitleComp/Title'

const About = () => {
  window.scroll(0, 0)
  const titiledata = [
    { title: "About Us", header: "Welcome to InvestorsZon" , descp:"At InvestorsZon, we are dedicated to empowering traders and investors worldwide with the tools, knowledge, and resources needed to navigate the complex and dynamic financial markets. Our mission is to provide a comprehensive and user-friendly platform that enhances your trading experience and helps you achieve your financial goals."}
  ]
  
  return (

    < >



      <section
        id="about"
        className="bg-gray-1 pb-8 pt-20 dark:bg-dark-2 lg:pb-0 lg:pt-[120px]"
      >
        <div className="container mx-auto px-4">
          <div className="wow fadeInUp" data-wow-delay=".2s">
            <div className="-mx-4 flex flex-wrap items-center">
              <div className="top-main w-full px-4 lg:w-1/2">
                <div className="main-heading mb-12  lg:mb-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
            Signals
                </span>
                  <h2
                    className="mb-5 text-3xl font-bold leading-tight text-dark dark:text-white sm:text-[40px] sm:leading-[1.2]"
                  >
                  Welcome to InvestorsZon
                  </h2>
                  <p
                    className="mb-10 text-base leading-relaxed text-body-color dark:text-dark-6"
                  >

At InvestorsZon, we are dedicated to empowering traders and investors worldwide with the tools, knowledge, and resources needed to navigate the complex and dynamic financial markets. Our mission is to provide a comprehensive and user-friendly platform that enhances your trading experience and helps you achieve your financial goals.
                    
                    {/* <NavLink to="#" className="text-blue-500"> Know More</NavLink> */}
                  </p>
                </div>
              </div>

              <div className="w-full px-4 lg:w-1/2">
                <div className="-mx-2 flex flex-wrap sm:-mx-4 lg:-mx-2 xl:-mx-4">
                  <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                    <div
                      className="mb-4 sm:mb-8 sm:h-[400px] md:h-[540px] lg:h-[400px] xl:h-[500px]"
                    >
                      <img src={image1} alt="" className="h-full w-full object-cover object-center" />
                    </div>
                  </div>

                  <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                    <div
                      className="mb-4 sm:mb-8 sm:h-[220px] md:h-[346px] lg:mb-4 lg:h-[225px] xl:mb-8 xl:h-[310px] about-image"
                    >
                      <img src={webinar} alt="" className="image-div h-[16vh] w-[21vw] lg:mb-2 lg:h-[16vh] " />
                      <img src={pannel} alt="" className="image-div imagess h-[16vh] w-[21vw] lg:mb-2 lg:h-[16vh]" />
                    </div>

                    <div
                      className="relative z-10 mb-4 flex items-center justify-center overflow-hidden bg-primary px-6 py-12 sm:mb-8 sm:h-[160px] sm:p-5 lg:mb-4 xl:mb-8"
                    >
                      <div>
                        <span className="block text-5xl font-extrabold text-white">
                          09
                        </span>
                        <span className="block text-base font-semibold text-white">
                          We have
                        </span>
                        <span
                          className="block text-base font-medium text-white text-opacity-70"
                        >
                          Years of experience
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      
    </>
  )
}

export default About
