import React, { useEffect, useState } from 'react';
import { fetchUserTransactionsDetails } from '../../api/CourseApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { FaSpinner } from "react-icons/fa";
import NoData from '../Lottie Animation Comp/NoData';
const DashbooardOrderHistory = () => {
  const authUser = useAuthUser();
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 8;
  const fetchTranscations = async () => {
    setLoader(true);
    const resp = await fetchUserTransactionsDetails(authUser?.token);
    setLoader(false);
    setTransactions(resp.data);
  };
  useEffect(() => {
    fetchTranscations();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };
  const getStatusColor = (status) => {
    if (status === 'failed') return 'red';
    if (status === 'pending') return 'orange';
    if (status === 'success') return 'green';
    return 'black';
  };
  const getTransactionTypeColor = (type) => {
    if (type === 'course') return 'blue';
    if (type === 'module') return 'purple';
    if (type === 'subscriptionSignals') return 'orange';
    return 'black';
  };
  const formatTransactionType = (type) => {
    if (type === 'subscriptionSignals') return 'Signal Subscriptions';
    if (type === 'subscriptionCourse') return 'Course Subscriptions';
    if (type === 'course') return 'Course Purchased';
    if (type === 'module') return 'Module Purchased';
    return type.charAt(0).toUpperCase() + type.slice(1);
  };
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions?.slice(indexOfFirstTransaction, indexOfLastTransaction);
  const handleNextPage = () => {
    if (currentPage < Math.ceil(transactions?.length / transactionsPerPage)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };
  return (
    <div>
      <div className="bg-white shadow-lg p-6 rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="px-2 py-2 border-b border-gray-200 text-start bg-blue-100">TXN Type</th>
                <th className="px-2 py-2 border-b border-gray-200 text-start bg-blue-100">TXN ID</th>
                <th className="px-2 py-2 border-b border-gray-200 text-start bg-blue-100">Order ID</th>
                <th className="px-2 py-2 border-b border-gray-200 text-start bg-blue-100">Date</th>
                <th className="px-2 py-2 border-b border-gray-200 text-start bg-blue-100">Amount</th>
                <th className="px-4 py-2 border-b border-gray-200 text-start bg-blue-100">Status</th>
              </tr>
            </thead>
            {loader ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (
              transactions?.length > 0 ? (
                <tbody>
                  {currentTransactions.map((order, index) => {
                    const formattedDateTime = `${formatDate(order.createdAt)} @ ${new Date(order.createdAt).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}`;
                    return (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                        <td className="border-b p-2" style={{ color: getTransactionTypeColor(order.transactionType) }}>{formatTransactionType(order.transactionType)}</td>
                        <td className="border-b p-2">{order.txnId}</td>
                        <th className="border-b p-2 text-left">{order.orderId}</th>
                        <td className="border-b p-2">{formattedDateTime}</td>
                        <td className="border-b p-2">{order.totalPrice}</td>
                        <td className="border-b p-2">
                          <span className={`px-2 py-1 rounded`} style={{ color: getStatusColor(order.transactionStatus) }}>{order.transactionStatus}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="6" className="text-center p-6">
                      <NoData />
                    </td>
                  </tr>
                </tbody>
              )
            )}
          </table>
        </div>
        {!loader && transactions?.length > 0 && (
          <div className="flex justify-start gap-4 mt-4">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='pt-2'>Page {currentPage} of {Math.ceil(transactions?.length / transactionsPerPage)}</span>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(transactions?.length / transactionsPerPage)}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashbooardOrderHistory;
