// import React from 'react'
// import About from '../About/About'

// const Ourservices = () => {
//   return (
//     <>
//       <div>
//         <p
//           style={{
//             color: 'black',
//             marginTop: '10%',
//             fontSize: '30px',
//             borderBottom: '2px solid black',
            
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//           className='my-5'
//         >
//           OurMission
//         </p>
//         <p style={{ textAlign: 'center' }}>
//           Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil repudiandae ea nesciunt optio officia omnis sint est, reprehenderit voluptatem. Debitis sequi consectetur repellendus aliquid tempore error voluptas quod unde temporibus.50
//         </p>
//       </div>
//       <About />
//     </>
//   )
// }

// export default Ourservices


import React from 'react'
import About from '../About/About'

const Ourservices = () => {
  window.scroll(0,0)
  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <p
          style={{
            color: 'black',
            fontSize: '30px',
            borderBottom: '2px solid black',
            display: 'inline-block',
          }}
        >
          OurMission
        </p>
      </div>
      <p style={{ textAlign: 'center' }}>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil repudiandae ea nesciunt optio officia omnis sint est, reprehenderit voluptatem. Debitis sequi consectetur repellendus aliquid tempore error voluptas quod unde temporibus.50
      </p>
      <About />
    </>
  )
}

export default Ourservices
  