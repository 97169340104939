import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaLock } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Assest from '../../assets/blog/bannder-ad.png';
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { fetchModulePercentage, updateModuleProgress } from '../../api/CourseApi';
import "./CourseModule.css"
const CourseModule = ({  data, isPurchased, setIsStarted }) => {
  console.log("++++++data+++++",data,isPurchased)
  
  const [userProgressDetails, setUserPregressDetails] = useState([]);
  const authUser = useAuthUser();
  const completed = data?.Contents?.flatMap(course =>
    course?.Items.findIndex(item => item.id === userProgressDetails)
  )[0] ?? -1;
  const totalCompleted = completed + 1;

  const [myPercentage,setMyPercentage]= useState();

  const percentage = (totalCompleted / 200) * 100;
  const radius = 90;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const getModuleProgressPercentage = () => {
    if (!data.progress || data.progress === 0) {
      return "0%";
    }
    const progress = data?.progress;
    return progress >= 0 ? `${progress}%` : "0%";
  };

  const startHandler =async(data)=>{
    console.log(data.id,data.courseId)
    const resp =await updateModuleProgress(authUser?.token,data.courseId,data.id);
    if(resp.status ==200){
      setIsStarted(true)
    }
    console.log("resp",resp)
  }

  const someState = { id: data.id };
  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  return (
    <div>
      <h2 className="font-bold text-3xl text-start mt-8 mb-4">Module {data.rank}</h2>
      <NavLink
        to="#"
        className="main-module mt-5 flex items-center justify-between h-[350px] w-[50vw] bg-white shadow-xl rounded-xl dark:border-gray-700 dark:bg-gray-800"
      >
        <img
          className="img object-cover w-[30%] h-[100%] rounded-tl-xl rounded-bl-xl"
          src={data.image ? `https://investorszon.com${data.image}`:Assest}
          alt="Noteworthy technology acquisitions 2021"
        />
        <div className="  flex flex-wrap w-[100%] px-8 py-8 justify-between leading-normal">
          <div className="flex flex-col w-[70%] dark:border-r">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
              {data.title}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {truncateDescription(data.description, 12)}
              {/* {data.description} */}
            </p>
          </div>
          <div className="w-[25%]">
            <div className="relative">
              <svg className="   transform -rotate-90 w-30 h-30" viewBox="0 0 200 200">
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="gray"
                  strokeWidth="15"
                  fill="none"
                />
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="gray"
                  strokeWidth="15"
                  fill="none"
                  strokeDasharray={circumference}
                  strokeDashoffset={offset}
                  strokeLinecap="round"
                />
              </svg>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
              {authUser &&  isPurchased && <div className="module-percentage text-xl font-bold text-gray-700">
                 {/* {getModuleProgressPercentage()}  */}
                 {data?.progress % 1 === 0 
    ? `${data?.progress}%` 
    : `${data?.progress?.toFixed(2).replace(/\.00$/, '')}%`}
                </div>}

                <div className="text-sm text-gray-500">
                  {!authUser && (
                    <div className="module-lock group p-4 cursor-pointer transform transition-transform duration-300 hover:scale-125" style={{ borderRadius: "50%", width: "100%" }}>
                      <NavLink to="/signin">
                        <FaLock size={30} color="gray " className=" size " />
                      </NavLink>
                      <div className="cursor-pointer gap-4 absolute inset-0 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
                        <NavLink to={`/signin`} className="text-black font-bold text-center py-2 w-[90%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">Unlock
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  {authUser && !isPurchased && (
                    <div className="module-lock group p-4 cursor-pointer transform transition-transform duration-300 hover:scale-125" style={{ borderRadius: "50%", width: "100%" }}>
                      <NavLink to="/signin">
                        <FaLock size={30} color="gray " className=" size " />
                      </NavLink>
                      <div className="cursor-pointer gap-4 absolute inset-0 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
                        <NavLink to={`/school-of-investors-zon`} className="text-black font-bold text-center py-2 w-[90%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">Unlock
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="justify-evenly w-[100%]">
            <p className="flex items-center w-[45%] h-[40px] justify-center mt-4 px-4 py-2 bg-yellow-200 text-yellow-800 font-semibold rounded ">
              $ {data.price}
            </p>
            <NavLink state={someState} to={`/single-module-details/${data.slug}`} className="flex items-center w-[45%] h-[50px] justify-center mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600">
              Check Contents <FaArrowRight className="ml-2" />
            </NavLink>
            { !data.isUserProgress && authUser && (
            <button  onClick={()=>startHandler(data)} className="flex items-center w-[45%] h-[50px] justify-center mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600">Start
            </button>)}
          </div>
        </div>
        <div>
        </div>
      </NavLink>
    </div>
  );
};

export default CourseModule;
