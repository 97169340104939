import React, { useEffect, useState } from 'react'
import Signalsubscriptioncard from '../SignalSubscriptioncardComp/Signalsubscriptioncard'
import { FaCheckCircle,FaSpinner } from "react-icons/fa";
import Title from '../TitleComp/Title';
import { fetchFreeSignalsSubscription } from '../../api/signalsAPI';
import NoData from '../Lottie Animation Comp/NoData';
const SignalSubscription = () => {
  const [signalSubscription, setSignalSubscription] = useState();
  const [loading, setLoading] = useState(true);

  window.scroll(0, 0)

  const SignalData = [
    {
      icon: <FaCheckCircle size={25} color="White" className='mt-2' />, heading: "Basic", descp: "$8.00",
      list: [
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Dashboard Signal" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Whatsapp Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Telegram Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Email Alert" },
      ]
    },
    {
      icon: <FaCheckCircle size={25} color="White" className='mt-2' />, heading: "CHARLES1", descp: "$20.00",
      list: [
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Dashboard Signal" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Whatsapp Alert" },
        // { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Telegram Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Email Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "All Major Currency Pairs" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Instant Notification Alerts" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "500+ Profit Pips" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "High Success Rate" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "2-3 premium signals per day" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "30-day moneyback guarantee" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Support - Premium" },
      ]
    },
    {
      icon: <FaCheckCircle size={25} color="White" className='mt-2' />, heading: "BRONZE", descp: "$40.00",
      list: [
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Dashboard Signal" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Whatsapp Alert" },
        // { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Telegram Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Email Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "All Major Currency Pairs" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Instant Notification Alerts" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "1000+ Profit Pips" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "High Success Rate" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "3-4 premium signals per day" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "30-day moneyback guarantee" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Support - Premium" },
      ]
    },
    {
      icon: <FaCheckCircle size={25} color="White" className='mt-2' />, heading: "ECONOMY", descp: "$80.00",
      list: [
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Dashboard Signal" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Whatsapp Alert" },
        // { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Telegram Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Email Alert" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "All Major Currency Pairs" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Instant Notification Alerts" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "1000+ Profit Pips" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "High Success Rate" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "3-4 premium signals per day" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "30-day moneyback guarantee" },
        { icon1: <FaCheckCircle size={20} color="blue" className='mr-2' />, descp: "Support - Premium" },
      ]
    },
  ]
  const Signaltitle = [
    { title: 'Signal', header: " Subscription Plans ", descp: "" }
  ]

  const freeSubscriptions = async () => {

    try {
      const resp = await fetchFreeSignalsSubscription();
      console.log("realResponse", resp)
      setSignalSubscription(resp.data)
    } catch (error) {
      console.error("Error fetching subscription:", error);
    } finally {
      setLoading(false);
    }

  };


  useEffect(() => {
    freeSubscriptions();
  }, [])

  const updatedPlans = signalSubscription?.map(plan => {
    if (plan.planPrice === null) {
        return { ...plan, planPrice: "0" };
    }
    return plan;
});

  return (
    <div className='mt-[180px] Main container'>
      <div className=" container    mt-4 mb-12 ">
        <Title data={Signaltitle} />
        <div className="mt-[3%] mb-[3%] w-[100%]">
          {loading ? (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          ) : (signalSubscription && signalSubscription.length > 0 ? (
            <Signalsubscriptioncard signalSubscription={updatedPlans} data={SignalData} />
          ) : (
            <NoData />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SignalSubscription
