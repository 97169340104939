import React, { useState } from 'react';
import { updateUserPassword } from '../../api/CourseApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import "./Passwordupdate.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

const PasswordUpdateForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const useAuth = useAuthUser();

  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Previous password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[!@#$%^&*]/, "Password must contain at least one special character"),
    confirmPassword: Yup.string()
      .required("Please confirm your new password")
      .oneOf([Yup.ref('newPassword'), null], "")
  });

  const validateField = async (name, value) => {
    try {
      await passwordSchema.validateAt(name, { [name]: value });
      setErrors(prev => ({ ...prev, [name]: '' }));
    } catch (validationError) {
      setErrors(prev => ({ ...prev, [name]: validationError.message }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'oldPassword') setOldPassword(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      if (value === newPassword) {
        setPasswordsMatch(true);
        setErrors(prev => ({ ...prev, confirmPassword: '' }));
      } else {
        setPasswordsMatch(false);
        setErrors(prev => ({ ...prev, confirmPassword: 'Passwords must match' }));
      }
    }
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await passwordSchema.validate({ oldPassword, newPassword, confirmPassword }, { abortEarly: false });
      const formData = { oldPassword, newPassword, confirmPassword };
      const resp = await updateUserPassword(useAuth?.token, formData);
      if (resp.status === 200) {
        toast.success(resp.data.message, { position: "top-right", autoClose: 5000 });
      } else {
        toast.error("Error updating password", { position: "top-right", autoClose: 5000 });
      }
    } catch (validationErrors) {
      const formErrors = {};
      validationErrors.inner.forEach(error => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
      toast.error("Please correct the errors before submitting", { position: "top-right", autoClose: 5000 });
    }
  };

  return (
    <div className="space-y-12 max-w-4xl mt-2 mx-auto mb-9 p-6 bg-white shadow-lg rounded-lg">
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="relative mb-8">
          <input
            type="password"
            id="previous_password"
            name="oldPassword"
            className="password-width block px-2.5 pb-2.5 pt-4 w-[49vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder="Previous Password"
            value={oldPassword}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="previous_password"
            className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Previous Password
          </label>
          {errors.oldPassword && <div className="text-red-500 text-sm">{errors.oldPassword}</div>}
        </div>

        <div className="relative mb-8">
          <input
            type="password"
            id="new_password"
            name="newPassword"
            className="password-width block px-2.5 pb-2.5 pt-4 w-[49vw] text-sm text-gray bg-transparent rounded-lg border border-gray-400 appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder="New Password"
            value={newPassword}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="new_password"
            className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            New Password
          </label>
          {errors.newPassword && <div className="text-red-500 text-sm">{errors.newPassword}</div>}
        </div>

        <div className="relative mb-8">
          <input
            type="password"
            id="confirm_password"
            name="confirmPassword"
            className={`password-width block px-2.5 pb-2.5 pt-4 w-[49vw] text-sm text-gray bg-transparent rounded-lg border ${
              passwordsMatch ? 'border-green-500' : 'border-gray-400'
            } appearance-none dark:text-black dark:border-gray dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="confirm_password"
            className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Confirm Password
          </label>
          {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
        </div>

        <div className="text-start">
          <button
            type="submit"
            className="bg-blue-500 border text-black px-4 py-2 rounded-lg transform transition-transform duration-300 hover:translate-x-2 hover:bg-blue-600 hover:text-white"
          >
            Update Password
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PasswordUpdateForm;
