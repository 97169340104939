import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';
import { NavLink } from 'react-router-dom';
import { FaTrashAlt } from 'react-icons/fa';
import lottie from "lottie-web";
import "./Cart.css";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { IoCloseCircleOutline } from "react-icons/io5";
import LottieGreenCheck from "../Lottie Animation Comp/LottieGreenCheck";
import ErrorLottie from "../Lottie Animation Comp/ErrorLottie";
import ConfirmModal from "../Modals/confirmed";
import { MdKeyboardArrowLeft } from "react-icons/md";

const Cart = () => {
  const loadingContainer = useRef(null);
  const navigate = useNavigate();
  const authUser = useAuthUser(); // Replace this with the actual check for authenticated user
  const [isToaster, setIsToaster] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
  const [courseIds, setCourseIds] = useState([]);
  const { cart, removeFromCart, clearCart } = useContext(CartContext);

  useEffect(() => {
    lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/animation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  // useEffect(() => {
  //   // Clear cart if the user is not authenticated
  //   if (!authUser) {
  //     clearCart();
  //   }
  // }, [authUser, clearCart]);

  const onClose = () => {
    navigate('/dashboard');
  };

  const handlePurchaseCourse = async () => {
    if (authUser) {
      const courseIds = cart.map(item => item.id);
      setCourseIds(courseIds);
      handlePurchase();
    } else {
      navigate('/signin');
    }
  };

  const handlePurchase = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    navigate("/dashboard");
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + (item.quantity * item.price), 0);
  };

  return (
    <div className='mt-[9%] pt-[20px] pb-5 mb-[5%] '>
      {cart.length === 0 ? (
        <div className="main-lottie w-[30%] h-[70vh] m-auto bg-white overflow-hidden flex justify-center mt-[50px] mb-[30px]">
          <div className="text-center main-lottie-cart">
            <div className="w-[70%] m-auto">
              <div className="loader-container" ref={loadingContainer}></div>
            </div>
            <h2 className="mt-12 text-xl font-semibold text-gray-800">Your cart is currently empty</h2>
            <button className="mt-8 bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <NavLink to='/school-of-investors-zon'>
              Browser School of Investors Zon
              </NavLink>
            </button>
          </div>
        </div>
      ) : (
        <div className='container flex  px-2 py-4 rounded' style={{ border: '1px solid #f5f5f5' }}>
          <ul className='main-cart flex flex-wrap w-[70%] gap-12 m-auto'>
            {cart.map((item) => (
              <li key={item.id} className='w-full mt-2 ' style={{ borderBottom: '1px solid #f5f5f5' }}>
                <div className='flex justify-between items-start h-[20vh] bg-white'>
                  <div className='w-[25%]'>
                    <NavLink to={item.href} className="block">
                      <div className='w-[80%] m-auto'>
                        <img
                          src={item.image ? `https://investorszon.com${item.image}`: "https://placehold.co/600x400"}
                          alt="Course"
                          className="main-cartImage w-[150px] h-[150px] transition group-hover:rotate-6 group-hover:scale-125"
                        />
                      </div>
                    </NavLink>
                  </div>
                  <div className="w-[50%] h-[150px] flex flex-col justify-center px-4">
                    <h3>
                      <NavLink
                        to={item.href}
                        className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white dark:hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl"
                      >
                        {item.title}
                      </NavLink>
                    </h3>
                    <p className="text-body-color dark:text-dark-6 line-clamp-1">
                      <NavLink to={item.href} className="block">
                        {item.description}
                      </NavLink>
                    </p>
                    <div className='flex items-center justify-start mt-3'>
                      <div className='flex items-center'>
                        <p className="mx-3 text-black dark:text-dark-6 font-bold">
                         Qty: {item.quantity}
                        </p>
                      </div>
                      <FaTrashAlt
                        size={20}
                        color='#1B44C8'
                        className='cursor-pointer ml-4'
                        onClick={() => removeFromCart(item.id)}
                      />
                    </div>
                  </div>
                  <div className="w-[25%] h-full flex items-center justify-center px-2">
                    <p className="dark:text-dark-6 font-bold text-black ">
                      Price: ${item.price * item.quantity}
                    </p>
                  </div>
                </div>
              </li>
            ))}
            <div className="flex hover:text-blue-600">
            <MdKeyboardArrowLeft  size={30} className="pb-1"/>   
            <p className="text-lg font-semibold  ">  <NavLink to='/school-of-investors-zon'>Continue Shopping</NavLink>        </p>
            </div>
          </ul>





          <div className="w-[25%] min-h-[280px] m-auto mt-3 flex flex-col justify-start gap-3 border-sm rounded  bg-gray-100">
            <h2 className="text-2xl font-semibold  mt-4 pl-4"> Payment Info.</h2>
            <div className="flex justify-between items-center">
            <p className="text-lg  flex items-center justify-center pl-4"> Subtotal </p>
            <p className="mr-2"> ${calculateTotalPrice().toFixed(2)}</p>
            </div>
            <div className="flex justify-between items-center">
            <p className="text-lg  flex items-center justify-center pl-4"> Taxes </p>
            <p className="mr-2 w-[60px] text-center"> -</p>
            </div>
            <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold flex items-center justify-center pl-4 ">
            Total Price</h3>
            <p className="mr-2  font-semibold text-center"> ${calculateTotalPrice().toFixed(2)}</p>
            </div>
            {/* <h3 className="text-xl font-semibold flex items-center justify-center mt-6">
              Total Price: ${calculateTotalPrice().toFixed(2)}
            </h3> */}
            <div className="flex justify-center mt-4 ">
              <button className="text-xl font-semibold flex text-center items-center justify-center bg-blue-700 text-white rounded w-[160px] h-[40px]" onClick={handlePurchaseCourse}>
                Checkout
              </button>
            </div>
          </div>
        </div>





      )}

      {isToaster && (
         <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
         <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
         <IoCloseCircleOutline  size={30} style={{cursor:"pointer",position:"absolute",top:"5%",right:"2%"}} onClick={()=>setIsToaster(!isToaster)} />
           <div className="text-center">
             <p className="mb-6">{toastMessage}</p>
             <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
             {toastMessage === "Courses purchased successfully" ? <LottieGreenCheck/> : <ErrorLottie/>}
             </div>
              {toastMessage === "Courses purchased successfully" &&
             <button
               onClick={onClose}
               className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
             >
               View Course
             </button>}
           </div>
         </div>
       </div>
      )}

      <ConfirmModal
        setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        data={cart}
        totalAmount={calculateTotalPrice().toFixed(2)}
        type={'course'}
        clearCart={clearCart}
      />
    </div>
  );
};

export default Cart;
