import React from 'react'
import HomePageCard from '../HomePageSimpleCard/HomePageCard'
import { BiCreditCardFront } from "react-icons/bi";
import Title from '../TitleComp/Title';
import ModuleCertificate from './ModuleCertificate';
import { GrCertificate } from "react-icons/gr";
import { LuUserCheck2 } from "react-icons/lu";
import { GrStorage } from "react-icons/gr";
import { FaSlideshare } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const MyCertificate = () => {
    const Certificatedata =[
        { icon: <GrCertificate size={30} color='#ffff' className='text-center' />, heading: " Downloadable Certificates", description: " Instantly download your completion certificate upon finishing a module or course. Display your accomplishments and share your achievements with employers and colleagues, showcasing your expertise and commitment to continuous learning." },
        { icon: <LuUserCheck2 size={30} color='#ffff' className='text-center' />, heading: " Verifiable Credentials", description: "Verify your certificates on our platform easily. Employers and peers can confirm the authenticity of your achievements, ensuring your credentials are recognized and trusted in the professional community." },
        { icon: <BiCreditCardFront size={30} color='#ffff' className='text-center' />, heading: "Showcase Your Skills", description: "Highlight your skills and knowledge with InvestorZon's certified recognition. Our certificates serve as a testament to your hard work and dedication, enhancing your professional profile and boosting your career prospects" },

    { icon: <GrStorage size={30} color='#ffff' className='text-center' />, heading: "Secure Digital Storage", description: " Access and store your certificates securely on our platform. Never worry about losing important documents; your achievements are safely archived and readily available whenever you need them." },
    { icon: <GrCertificate size={30} color='#ffff' className='text-center' />, heading: " Customizable Certificates", description: "Personalize your certificates with your name, course details, and completion date. Our platform generates professional, customized certificates that reflect your unique accomplishments and learning journey." },
        { icon: <FaSlideshare size={30} color='#ffff' className='text-center' />, heading: "Easy Sharing Options", description: "Share your certificates effortlessly on social media, professional networks, or via email. Our platform provides easy sharing options, allowing you to proudly display your achievements to a wider audience." },
    ]
    const Titleone=[
      {title:"Certificates",  header:"My Certificates",descp:"Upon completing a module or course on our platform , download your completion certificate, verifiable on our platform. Celebrate your achievements and showcase your skills with Investors. zon certified recognition."},
  
    ]
    const Titletwo=[
      {title:"Certificate",  header:"Sample Certificate", descp:"Check out our sample certificate. Upon completing a course on InvestorZon, download a professional, verifiable certificate showcasing your achievement and enhancing your professional credibility."},
  
    ]
    const Titlethree=[
      {  header:"Verify Certificate", descp:`Verify the certificate by examining its Certificate number, confirming the issuer’s authenticity, ensuring it matches the server’s information, and checking for any revocations or security warnings from trusted authorities.`},

    ]
  return (
    <div className='mt-[6%]'>
      <div className="container">
        <Title data ={Titleone}/>
        <HomePageCard data={Certificatedata}/>

        <Title data ={Titletwo}/>
        <ModuleCertificate/>
        <Title data ={Titlethree}/>
        <p className=' text-center mb-4'>  Click here to verify your Certificate</p>
        <div className='flex justify-center mb-12'>   
               <button className="  w-[150px] h-[40px] rounded-lg bg-blue-700 flex items-center justify-center text-white hover:bg-blue-800">
              <NavLink target='_blank' to='/my-certificate-verify-certificate'>Verify Certificate </NavLink>  
              </button>
              </div>

        </div>
     </div>
  )
}

export default MyCertificate
