import React, { useEffect, useState } from 'react'
import { NavLink, useParams ,useNavigate, useLocation} from 'react-router-dom'
import Logo from '../../assets/0-3.jpeg';
import { Button } from "@mui/material";
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchAllDetailsUsingContentItemId, fetchModuleContentInfoByItemId, fetchModuleItemIdStatus, fetchUserProgress, fetchUserProgressContentItemId } from '../../api/CourseApi';

const PreviewCourse = () => {
    const { slug } = useParams();
    window.scroll(0, 0)
    const location = useLocation();
    const { id } = location.state || {};
    const navigate = useNavigate();
    const [userCompleteDetails, setUserCompleteDetails] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [details, setDetails] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [content, setContent] = useState();

    const progressHandler = async () => {
        const resp = await fetchUserProgress(authUser?.token, id, userCompleteDetails.moduleContentId, userCompleteDetails.moduleId, userCompleteDetails.courseId);
        if (resp.status === 200) {
            const someState = { id: userCompleteDetails.moduleId };
            {userCompleteDetails?.IsModulePurchased ?  navigate(`/purchased-module-details/${userCompleteDetails?.moduleId}`, { state: someState }):
            navigate(`/single-module-details/${userCompleteDetails.moduleSlug}`, { state: someState })}
        }
    }

    const authUser = useAuthUser();

    const getDetails = async () => {
        const resp = await fetchAllDetailsUsingContentItemId(authUser?.token, slug);
        setUserCompleteDetails(resp.data);
    }

    const getStatus = async () => {
        const resp = await fetchModuleItemIdStatus(authUser?.token, id);
        setIsComplete(resp.data.complete);
    };

    const getFullDetails = async () => {
        const resp = await fetchModuleContentInfoByItemId(authUser?.token, slug);
        setDetails(resp);
    };

    useEffect(() => {
        getDetails();
        getStatus();
        getFullDetails();
    }, []);

    useEffect(() => {
        if (details.length > 0) {
            const Completed = details.filter((val) => val.id === id);
            setContent(Completed[0]?.content);
            setIsCompleted(Completed[0]?.completed);
        }
    }, [details, id]);

    function getCompletedStatus(detailsArray, givenId) {
        // Sort the array by id in ascending order
        detailsArray.sort((a, b) => a.id - b.id);

        // Find the first object with completed status as true
        for (let detail of detailsArray) {
            if (detail.completed === true && detail.id > givenId) {
                setIsCompleted(true);
                return true;
            }
        }

        // If no such object is found, return false or a suitable default value
        return false;
    }

    useEffect(() => {
        getCompletedStatus(details, id);
    }, [details, id]);

    console.log("user---",details)


    return (
        <div className='w-full relative mt-[140px]'>
            <div className='mt-[70px] p-5 w-[60%]'>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {!isCompleted && (
                    <Button style={{ marginRight: "10px" }} className="h-[6vh] w-[10vw] mt-12" variant="contained" onClick={progressHandler}>Complete</Button>
                )}
            </div>
            <div className='fixed right-[3%] top-[150px]  h-[300px] w-[400px] shadow-xl '>
                <div className='h-full'>
                    <h1 className='font-bold flex items-center justify-center text-2xl h-[20%] bg-gray-300'>{userCompleteDetails?.moduleTitle}</h1>
                    <div className='h-[80%] overflow-y-scroll'>
                        {details?.map((val, index) => {
                              let textColor = 'text-gray-500';
                              if (val?.id === id) {
                                textColor = 'text-black-600'; // Set text color to red for indices smaller than the first completed index
                              }
                            return (
                                <React.Fragment key={index}>
                                    <p className={`${textColor} ml-[3%] mt-3 block  m-auto`} >{val?.title}</p>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviewCourse;
