import React, { useState } from 'react';

const Toast = ({ message, type, showToast ,setShowToast }) => {

  // Determine background color based on type (success, error, info, etc.)
  let bgColor = '';
  switch (type) {
    case 'success':
      bgColor = 'bg-green-500';
      break;
    case 'error':
      bgColor = 'bg-red-500';
      break;
    case 'info':
      bgColor = 'bg-blue-500';
      break;
    default:
      bgColor = 'bg-gray-500';
  }

  const handleClose = () => {
    setShowToast(false);
  };

  return (
    <>
      {showToast && (
        <div className={`fixed top-40 z-50 right-10 p-4 rounded-md text-white ${bgColor}`}>
          <div className="flex justify-between items-center">
            <span>{message}</span>
            <button style={{marginLeft:'10px'}} onClick={handleClose} className="text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
