import React, { useEffect, useState } from 'react'
import Testimonials from '../Testominal/Testominal';



import { useLocation, useParams } from 'react-router-dom';
import { fetchModuleByModuleId, fetchSubscribedModuleByModuleId } from '../../api/CourseApi';
import AccordionComp from '../SubComponents/AccordionComp';
import HeroCourseModule from '../SubComponents/HeroCourseModule';
import SubscribedHeroCourseModule from './SubscribedHeroCourseModule';
import SubscribedAccordianComp from './SubscribedAccordianComp';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';


const SubscribedModulesItems = () => {
    window.scroll(0,0)
    const {slug} = useParams();
    const location =  useLocation();

    console.log("location--------------------",location)
    const id = location?.state?.id;

    if (location?.state?.id !== undefined && location?.state?.id !== null) {
      const id = localStorage.getItem('subscribedModuleState');
      if (id === null || id !== location?.state?.id) {
        localStorage.setItem('subscribedModuleState', location?.state?.id);
      }
    }

    const authUser = useAuthUser();
    const [moduleHeaderInfo,setModuleHeaderList]= useState({});
   
    const fetchModule = async()=>{
      const resp = await fetchSubscribedModuleByModuleId(authUser?.token,id);
      setModuleHeaderList(resp)
    };

    const [totalModuleItemsCount,setTotalModuleItemsCount] = useState();
    window.scroll(0,0);
    useEffect(()=>{
      fetchModule()
    },[]);

    console.log("subscription-module-info",moduleHeaderInfo)

  return (
    <div>
       <div className="bg-white mt-[70px]">
        <div className='bg-[#090E34]  p-5'>
          
      <SubscribedHeroCourseModule data={moduleHeaderInfo} total={totalModuleItemsCount}/>
      
      </div>
     
      <div className='w-[100%]'>
        
          
      <SubscribedAccordianComp id={id} data={moduleHeaderInfo} setTotalModuleItemsCount={setTotalModuleItemsCount} />
      
      
          
      </div>
     
      <div className='w-[100%]'>
      <Testimonials/>
      </div>
      
    </div>
    </div>
  )
}


export default SubscribedModulesItems
