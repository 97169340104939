import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import SignalCardDashboardComp from "./SignalCardDashboardComp";
import NoData from "../Lottie Animation Comp/NoData";
import { fetchUserCoursePurchaseStatus, getUserSubscribedCourses, getUserSubscribedSignalDetails } from "../../api/CourseApi";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { FaSpinner } from "react-icons/fa";
import SubscriptionCardCourse from "../CourseSubscriptionCardComp/SubscriptionCardCourse";
const DashboardMySubscription = () => {
  const [activeTab, setActiveTab] = useState("Signal Subscribed");
  const [subscribedCourses, setSubscribedCourses] = useState();
  const [signals, setSignals] = useState();
  const [loading, setLoading] = useState();
  const [loader, setLoader] = useState(true);
  const [subscribedDetails, setSubscribedDetails] = useState();
  const [signalLoader, setSignalLoader] = useState(false)
  const authUser = useAuthUser();
  const loadingContainer = useRef(null);
  const fetchUserSubscribedCourses = async () => {
    setLoading(true)
    const resp = await getUserSubscribedCourses(authUser?.token);
    setSubscribedCourses(resp.data)
    setLoading(false)
  };
  const Signalcarddata = [
    {
      icon1: <FaRegEdit size={30} color="white" className='' />, heading: "BASIC",
      list: [
        { icon2: <IoCheckmarkCircleOutline size={25} color="green" className='' />, description: "Dashboard Signal" },
        { icon2: <IoCheckmarkCircleOutline size={25} color="green" className='' />, description: "Whatsapp Alert" },
        { icon2: <IoCheckmarkCircleOutline size={25} color="green" className='' />, description: "Telegram Alert" },
        { icon2: <IoCheckmarkCircleOutline size={25} color="green" className='' />, description: "SMS Alert" },
      ]
    },
  ]
  console.log("++++++++++++++++++++++++++++++++susbcriptiondeatils", subscribedDetails)
  const renderContent = () => {
    switch (activeTab) {
      case "Module Subscribed":
        return (
          <div className="  ">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) :
              subscribedDetails ? (
                <SubscriptionCardCourse data={subscribedDetails} />
              ) : (
                <div className="flex justify-center ">
                  <NoData />
                </div>
              )}
          </div>
        );
      case "Signal Subscribed":
        return (
          <div className="  ">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) :
              signals && signals.length > 0 ? (
                <SignalCardDashboardComp setLoading={setSignalLoader} signals={signals} data={Signalcarddata} />
              ) : (
                <div className="flex justify-center ">
                  <NoData />
                </div>
              )}
          </div>
        )
      default:
        return null;
    }
  };
  const fetchSignals = async () => {
    setLoading(true);
    const resp = await getUserSubscribedSignalDetails(authUser?.token);
    setSignals(resp.data);
    setLoading(false);
  };
  const fetchUserSubscribeInfo = async () => {
    setLoader(true);
    const resp = await fetchUserCoursePurchaseStatus(authUser?.token);
    setSubscribedDetails(resp?.data?.data);
    setLoader(false);
  };
  useEffect(() => {
    switch (activeTab) {
      case "Signal Subscribed":
        fetchSignals();
        break;
      case "Module Subscribed":
        fetchUserSubscribeInfo();
        break;
      default:
        break;
    }
  }, [activeTab, signalLoader]);

  useEffect(() => {
    fetchUserSubscribedCourses()
  }, [])

  return (
    <div>
      <ul className="flex flex-wrap justify-start text-sm font-medium text-center text-gray-500 border-b border-gray-200  dark:text-gray-400">
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Signal Subscribed")}
            className={`inline-block p-4 ${activeTab === "Signal Subscribed"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Signal Subscribed Plan
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Module Subscribed")}
            className={`inline-block p-4 ${activeTab === "Module Subscribed"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Course Subscribed Plan
          </NavLink>
        </li>
      </ul>
      <div className="p-4">{renderContent()}</div>
    </div>
  );
};
export default DashboardMySubscription
