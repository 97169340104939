import React from 'react'
import HeaderComp from './SubComponents/HeaderComp';
// import Image from './assets/stock.webp'; 

import Image from '../assets/stock.webp';
import Imageq from '../assets/header.png';
import Image3 from '../assets/Mutual.webp';
import Image4 from '../assets/commodity.webp';
import Image5 from '../assets/trading-img.jpg';
import Image6 from '../assets/Glossary.jpg';
import MarketCourseCard from './SubComponents/MarketCourseCard';




const ForexMarket = () => {
  window.scroll(0, 0)
  const courseone = [
    { subscription: "Read More", href: "/forex-market", img: Imageq, title: " Forex Market ", description: "  Markets are platforms where buyers and sellers exchange assets like stocks, commodities, and cryptocurrencies. They play a crucial role in the economy by determining prices, allocating resources, and facilitating trade..        " },
    { subscription: "Read More", href: "/stock-market", img: Image, title: "Stock Market", description: "The stock market is a platform where buyers and sellers exchange shares of companies. It plays a crucial role in the economy by determining prices, allocating resources, and facilitating trade.        " },
    { subscription: "Read More", href: "/mutual-fund", img: Image3, title: " Mutual Funds", description: "A leading mutual fund platform offers advanced tools, real-time data, and seamless execution, empowering investors to make informed decisions and maximize returns in dynamic financial markets.      " },
    { subscription: "Read More", href: "/commodity-market", img: Image4, title: " Commodity Market", description: "The commodity market is a platform where buyers and sellers exchange goods like metals, energy, and agricultural products. It plays a crucial role in the economy by determining prices, allocating resources, and facilitating trade.       ," },

    { subscription: "Read More", href: "/crypto-market", img: Image5, title: "Crypto Market", description: "The cryptocurrency market represents a revolutionary shift in the financial landscape, offering decentralized digital assets powered by blockchain technology.  " },
    { subscription: "Read More", href: "/forex-glossary", img: Image6, title: "Forex Glossary", description: "The forex glossary provides definitions of terms used in currency trading. It plays a crucial role in helping traders understand key concepts, navigate the market, and facilitate informed decisions.        " },

  ]
  const headerdata = { header: " Markets", title: "Explore Different Market", description: "Markets are platforms where buyers and sellers exchange assets like stocks, commodities, and cryptocurrencies. They play a crucial role in the economy by determining prices, allocating resources, and facilitating trade.." }
  return (
    <div>
      <div className='w-4/5 mx-auto mt-24 mt-[140px] ' >
        <div className=' mt-[13%] mb-8'>
          <HeaderComp data={headerdata} />
        </div>

        <MarketCourseCard data={courseone} />


      </div>

    </div>
  )
}

export default ForexMarket;
