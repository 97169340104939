import React from 'react'
import { NavLink } from 'react-router-dom'
import { SiExpertsexchange } from "react-icons/si";
import { GiFlatPlatform } from "react-icons/gi";
import { FaUserFriends } from "react-icons/fa";
import { LuBadgeDollarSign } from "react-icons/lu";
import { MdOutlineHighQuality } from "react-icons/md";
import { AiOutlineDollar } from "react-icons/ai";

import "../About/About.css"

const Feature = () => {
  window.scroll(0,0)
  return (
    <>
    <section className="pb-8 pt-20 dark:bg-dark lg:pb-[0px] lg:pt-[120px]">
      
      <div className="container">
        <div className="mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[485px] text-center lg:mb-[70px]">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Features
              </span>
              <h2
                className="mb-3 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
              >
             Main Features of Investors zon
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
              Investors zon offers a diverse range of features designed to meet all your financial needs, from trading and investing to education and analysis. Here are some of our standout features:
              </p>
            </div>
          </div>
        </div>

        
        <div className=" main-feature-div -mx-4 flex align-center flex-wrap Feature-gap">
          <div className=" feature-div w-full px-4 md:w-1/2 lg:w-1/4">
            <div className=" wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".1s">
              <div
                className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
              >
                <span
                  className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <SiExpertsexchange  size={30} color='#ffff' className='text-center'/>
 
                </svg>
              </div>
              <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              Expert Analysis
              </h4>
              <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[120px]">
              Gain access to insights and strategies from seasoned financial experts, helping you make informed investment decisions.
            
              </p>
             
            </div>
          </div>
          <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".15s">
              <div
                className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
              >
                <span
                  className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <GiFlatPlatform size={40} color='#ffff' className='text-center'/>
                </svg>
              </div>
              <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px] ">
              Comprehensive Financial Platform
              </h4>
              <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[120px]">
              Our platform caters to a wide array of financial activities, including trading, investing, market analysis, and educational resources.
             
              </p>
              
            </div>
          </div>
          <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".2s">
              <div
                className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
              >
                <span
                  className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  
                                    <MdOutlineHighQuality  size={30} color='#ffff' className='text-center'/>

                </svg>
              </div>
              <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              High-Quality User Experience
              </h4>
              <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[120px]">
              Experience our intuitive, user-friendly interface designed to enhance your efficiency and ease of use. 
              </p>
              
            </div>
          </div>
          <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".25s">
              <div
                className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
              >
                <span
                  className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 
                                    <AiOutlineDollar  size={40} color='#ffff' className='text-center'/>

                </svg>
              </div>
              <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              All-Inclusive Financial Tools
              </h4>
              <p className="mb-8 text-body-color dark:text-dark-6 lg:mb-9 h-[120px]">
              Everything you need to make informed investment decisions is available, from real-time market data to expert analysis and insights.
             
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </section>
      
    </>
  )
}

export default Feature
