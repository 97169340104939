import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaRegEdit } from "react-icons/fa"; // If you're using React Icons
import "./SignalSubscriptionCard.css";
import {
  createSignalSubscription,
  subscribeSignalSubscription,
} from "../../api/signalsAPI";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { RxCrossCircled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../Modals/confirmed";
import { IoInformationCircle } from "react-icons/io5";
import { getUserSubscribedSignalDetails } from "../../api/CourseApi";
import SignalConfirmModal from "../Modals/SignalConfirmed";

const Signalsubscriptioncard = ({ signalSubscription: initialSignalSubscription, data }) => {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(false);
  const [signalToBuy, setSignalToBuy] = useState();
  const [signalSubscription, setSignalSubscription] = useState([]);
  const [purchasedSubs, setPurchasedSubs] = useState();

  useEffect(() => {
    // Sort the signalSubscription array by planPrice in ascending order
    const sortedSubscriptions = [...initialSignalSubscription].sort((a, b) => {
      const priceA = a.planPrice === "0" ? 0 : parseFloat(a.planPrice);
      const priceB = b.planPrice === "0" ? 0 : parseFloat(b.planPrice);
      return priceA - priceB;
    });
    setSignalSubscription(sortedSubscriptions);
  }, [initialSignalSubscription]);

  useEffect(() => {
    const fetchMySubscription = async () => {
      const resp = await getUserSubscribedSignalDetails(authUser?.token);
      setPurchasedSubs(resp?.data[0]);
    };
    fetchMySubscription();
  }, [authUser]);

  useEffect(() => {
    if (purchasedSubs) {
      const updatedSubscriptions = signalSubscription.map(subscription => ({
        ...subscription,
        isPurchased: subscription.id === purchasedSubs.subscriptionId
      }));
      setSignalSubscription(updatedSubscriptions);
    }
  }, [purchasedSubs, initialSignalSubscription]);

  const handleSubscribe = async (subscription) => {
    if (!authUser) {
      navigate("/signin");
      return;
    }
    const resp = await subscribeSignalSubscription(
      authUser?.token,
      subscription.id
    );
    if (resp.status === 201) {
      alert("Signal subscribed successfully!");
      console.log("resp", resp);
    } else {
      alert("Error Alert");
    }
  };

  const handlePurchaseCourse = async (val) => {
    if (!authUser) {
      navigate("/signin");
    } else {
      if(val?.planPrice === '0'){
      }
      setSignalToBuy(val);
      handlePurchase();
    }
  };

  const handlePurchase = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  console.log("purchased--subs", purchasedSubs);
  console.log("signal-subscriptions----", signalSubscription);

  return (
    <div className='main-subscription flex flex-wrap gap-4'>
      {signalSubscription?.map((val, index) => (
        <div key={index} className='main-subscription-card w-[23%] relative pb-6 rounded-xl border border-gray-300 flex flex-col items-center justify-center'>
          <div className='w-full'>
            <div className='text-center h-[420px] w-full rounded-t-lg mb-8' style={{ background: "#8AE6E2" }}>
              <h2 className='pt-4 mb-6 font-semibold text-xl' style={{ color: `${val?.color}`, textTransform: 'capitalize' }}>
                {val.planName}
              </h2>
              <div className='relative'>
                <p className='inline-block font-semibold text-4xl mb-6'>{val?.planPrice ===  "0" ? "Free" : val?.planPrice}</p>
                <span className='inline-block absolute top-0 left-45'>{val?.planPrice !==  "0" && '$'}</span>
              </div>
              <p className='mb-6'>{authUser && val?.isPurchased ? "Your Current Plan" : "Try Now"}</p>
              <p className='mb-6'>{val.planDescription}</p>
              {val?.planDurationType === "unlimited" && (
                <p className='text-center w-fit mb-6 m-auto p-2'>
                  {val.planDurationType.charAt(0).toUpperCase() + val.planDurationType.slice(1)}
                </p>
              )}
              {val?.planDurationType === "limited" && (
                <p className='text-center w-fit m-auto mb-6 p-2'>
                  {val?.planDurationType} for {val?.duration} Days
                </p>
              )}

              <button className='w-[70%] border bg-white font-bold py-2 px-4 rounded-3xl mt-2 text-black'
                onClick={() => handlePurchaseCourse(val)}
                style={{background: val?.isPurchased ? 'green' : '',color: val?.isPurchased ? "#FFFFFF" : ""}}
                disabled={val?.isPurchased}
              >
               {val?.isPurchased ? "Already Subscribed" : "Subscribe Now"} 
              </button>
            </div>
            <div className='text-center ml-6'>
              {val?.planFeatures && (
                typeof val.planFeatures === 'string'
                  ? Object.entries(JSON.parse(val.planFeatures))
                  : Object.entries(val.planFeatures)
              ).map(([key, value], index) => (
                <div className='py-2' key={index}>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <FaCheckCircle size={20} color='black' />
                      </div>
                      <div className='text-gray-500'>
                        <p>{value ? value : '1 major currency pair'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {val.whatsappAlert === "Yes" ? (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <FaCheckCircle size={20} color='black' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Whatsapp Alert</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <RxCrossCircled size={20} color='red' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Whatsapp Alert</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {val.emailAlert === "Yes" ? (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <FaCheckCircle size={20} color='black' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Email Alert</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <RxCrossCircled size={20} color='red' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Email Alert</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {val.support === "Yes" ? (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <FaCheckCircle size={20} color='black' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Premium Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <RxCrossCircled size={20} color='red' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Premium Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {val.dashboardSignal === "Yes" ? (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <FaCheckCircle size={20} color='black' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Dashboard Signal</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='py-2'>
                  <div className='mt-4'>
                    <div className='flex'>
                      <div className='mt-[4px] mr-2'>
                        <RxCrossCircled size={20} color='red' />
                      </div>
                      <div className='text-gray-500'>
                        <p>Dashboard Signal</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      <SignalConfirmModal
        setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        data={signalToBuy}
        type={'subscriptionSignals'}
      />
    </div>
  );
};

export default Signalsubscriptioncard;
