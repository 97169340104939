import axios from 'axios';
import { URL } from '../config';

export const fetchCryptoSignals = async () => {
  try {
    const response = await axios(`${URL}/crypto-signals-public`);
    return response;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

export const fetchFreeCryptoSignals = async () => {
  try {
    const response = await axios(`${URL}/crypto-signals/free`);
    return response;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

export const fetchFreeForexSignals = async () => {
  try {
    const response = await axios(`${URL}/forex-signals/free`);
    return response;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};
export const fetchFreeStocksSignals = async () => {
  try {
    const response = await axios(`${URL}/stocks-signals/free`,
      
    );
    return response;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

export const fetchFreeSignalsSubscription = async () => {
  try {
    const response = await axios(`${URL}/plans-signal-public`);
    return response;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

export const fetchPaidForexSignals = async (token) => {
  try {
    const response = await axios(`${URL}/forex-signals/paid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching paid forex signals:', error);
    throw error;
  }
};

export const fetchPaidCryptoSignals = async (token) => {
  try {
    const response = await axios(`${URL}/crypto-signals/paid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching paid crypto signals:', error);
    throw error;
  }
};
export const fetchPaidStocksSignals = async (token) => {
  try {
    const response = await axios(`${URL}/stocks-signals/paid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching paid stocks signals:', error);
    throw error;
  }
};


export const renewSubscription =async(token,id)=>{  
  try {
    const response = await axios.put(`${URL}/renew-subscription/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during renew susbcription",error)
    return error;
  }
};

export const cancelSubscription =async(token,id)=>{  
  try {
    const response = await axios.put(`${URL}/cancel-subscription/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during cancel subscription",error)
    return error;
  }
};

export const subscribeSignalSubscription =async(token,data)=>{  
  console.log("token-----------",token)
  try {
    const response = await axios.post(`${URL}/signal-subscribe`,{subscriptionId:data},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during signal subscription",error)
    return error;
  }
};


export const getQRCode = async (val) => {
  try {
    const response = await axios.post(`${URL}/generate-qr-code`, { address:val });
    
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      console.error(data.error);
      return data;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return { error: 'An error occurred while generating the QR code' };
  }
};

export const makePayment =async(token,email,data,type)=>{  
  try {
    const response = await axios.post(`${URL}/pay`,{email:email,data:data,transactionType:type}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    } );
    return response;
  } catch (error) {
    console.log("error during make payment",error)
    return error;
  }
};





