import React from 'react'
import CurrencyParis from './CurrencyParis'

const MarketAnalysis = () => {
  window.scroll(0,0)
  return (
    <div>
      <CurrencyParis/>
    </div>
  )
}

export default MarketAnalysis
