import axios from 'axios';
import { URL } from '../config';

export const createReview =async(token,data)=>{  
    try {
      const response = await axios.post(`${URL}/review`,data,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } );
      return response;
    } catch (error) {
      return error;
    }
  };

  export const fetchUserReview=async(token)=>{
    try {
        const response = await axios.get(`${URL}/fetch-review`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          } );
          return response;
    } catch (error) {
      return error;
        
    }
  };

  export const fetchPublishedReview=async(token)=>{
    try {
        const response = await axios.get(`${URL}/fetch-approved-review`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          } );
          return response;
    } catch (error) {
      return error;
        
    }
  };