import React from 'react'
import CurrencyParis from './CurrencyParis'

const TradingPlatform = () => {
  window.scroll(0,0)
  return (
    <div>
         <CurrencyParis/>
    </div>
  )
}

export default TradingPlatform
