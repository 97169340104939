import lottie from "lottie-web";
import React, { useEffect, useState, useRef } from "react";

const Notfound = () => {
  window.scroll(0,0)
  const [loading, setloading] =useState()
  const loadingContainer = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Components/Lottie Animation Comp/404Animation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);

  return (
    <div>
        <div className='mt-[80px] '> 
<div className="w-[50%] m-auto">
<div className="loader-container" ref={loadingContainer}></div>
</div>
       


        
        </div>
    </div>
  )
}

export default Notfound
