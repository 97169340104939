import React, { useEffect, useState } from 'react';
import Testimonials from '../Testominal/Testominal';
import Subscriptioncard from './Subscriptioncard';
import CourseModule from './CourseModule';
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useLocation, useParams } from 'react-router-dom';
import { URL } from '../../config';
import HeroCourseModule from './HeroCourseModule';
import { fetchCourseProgress, fetchUserBuyCourseInfo, myCourses } from '../../api/CourseApi';
import "./Subscriptioncard.css"
const CourseComponent = () => {
  
  const [Courseprice, setCourseprice] = useState([]);
  const [courseListtitle, setCourseListtitle] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isStarted,setIsStarted]= useState(false);
  const [courseData,setCourseData] = useState();
  const authUser = useAuthUser();
  const { id } = useParams();
  const location = useLocation();
  const {state} = location;
  if (state?.id !== undefined && state?.id !== null) {
    const courseState = localStorage.getItem('courseState');
    if (courseState === null || courseState !== state?.id) {
      localStorage.setItem('courseState', state?.id);
    }
  }

  const fetchCourseTitle = async () => {
    const response = await axios.get(`${URL}/courses/${id}`, {
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
      },
    });
  
    setCourseprice(response.data);
  };

  const fetchModules = async () => {
    const response = await axios.get(`${URL}/modules-by-cid/${state?.id ? state?.id :localStorage.getItem('courseState')}`,
      {
        headers: {
          Authorization: `Bearer ${authUser?.token}`,
        },
      }
    );
    setModuleList(response.data);
    console.log("modules @@@@@@@@@@@@@@",response.data)
  };

  const fetchPublicModules = async () => {
    const response = await axios.get(`${URL}/public-modules-by-cid/${state?.id}`);
    setModuleList(response.data);
  };

  const fetchData= async()=>{
    const resp = await myCourses(authUser?.token);
    setCourseData(resp.data)
    setCourseListtitle(resp.data[0]);

  };

  let myCourse =[];
  if(courseData){
   myCourse = courseData.filter((val)=> val.id == state?.id);
  }

 

  const fetchUserPurchaseInfo = async () => {
    try {
      const resp = await fetchUserBuyCourseInfo(authUser?.token, state?.id ? state?.id :localStorage.getItem('courseState'));
      setIsPurchased(resp.isPurchased);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading to false once fetching is complete
    }
  };
  useEffect(() => {
    if(authUser){
    fetchUserPurchaseInfo();
    fetchData();
    }

  }, [id,isStarted]);


  useEffect(()=>{
    if(authUser){
    fetchModules()}
    if(!authUser){
      fetchPublicModules()
    }
    fetchCourseTitle();
  },[id])


  

  const updatedModuleData = moduleList.map((item, index) => ({
    ...item,
    rank: index + 1,
  }));

  const titleone = [
    { subheader: "Testimonials" }
  ];

  const calculateCourseCompletion = (modules) => {
    const totalModules = modules.length;
    const totalCompletion = modules.reduce((sum, module) => {
      const moduleCompletion = module.progress || 0; // Ensure progress is handled correctly
      return sum + moduleCompletion;
    }, 0);
    return totalCompletion / totalModules;
  };

  const courseCompletionPercentage = calculateCourseCompletion(moduleList);
  console.log("updated--moduleDtaaaaa",updatedModuleData)

  return (
    <div className="bg-white">
      
      <div className='bg-[#090E34] p-5'>
        <HeroCourseModule data={ Courseprice} isPurchased={isPurchased} percentage={courseCompletionPercentage} />
      </div>
      <div className='w-[100%]'>
        <h2 className='Various text-start text-3xl font-bold ml-32 pt-12'>Various Modules in this Course</h2>
        <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
          <div className="container mx-auto">
            {updatedModuleData.map((val, index) => {
              return <CourseModule key={index} data={val} isPurchased={isPurchased} setIsStarted={setIsStarted} />;
            })}
          </div>
          <div className="absolute top-0 right-0 -z-10">
            <svg
              width="1440"
              height="886"
              viewBox="0 0 1440 886"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="1308.65"
                  y1="1142.58"
                  x2="602.827"
                  y2="-418.681"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-Color="#3056D3" stopOpacity="0.36" />
                  <stop offset="1" stop-Color="#F5F2FD" stopOpacity="0" />
                  <stop offset="1" stop-Color="#F5F2FD" stopOpacity="0.096144" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
      </div>
      <div className='w-[100%]'>
        <Testimonials data={titleone} />
      </div>
      <div className='Subscription absolute top-[190px] right-[3%] z-20'>
        {!loading && !isPurchased  &&  <Subscriptioncard data={Courseprice} />}
        {authUser === null && <Subscriptioncard data={Courseprice} /> }
      </div>
    </div>
  );
};

export default CourseComponent;
