import React from 'react'
import "./Spinner.css"
const Spinner = () => {
    return (
        <div className='loading'>
      <div className="loading-container">
        <div className="loading-text">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
      <div className="socials">
        
      </div>
    </div>
    )
}

export default Spinner
