import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {  IoCloseCircleOutline } from "react-icons/io5";
import { FaSpinner } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import "./DashboardSignal.css";
import { fetchPaidCryptoSignals,fetchPaidForexSignals,fetchPaidStocksSignals,} from "../../api/signalsAPI";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import NoData from "../Lottie Animation Comp/NoData";

const DashboardSignal = () => {
  const [activeTab, setActiveTab] = useState("Forex Signal");
  const [forexPaidSignals, setForexPaidSignals] = useState();
  const [cryptoPaidSignals, setCryptoPaidSignals] = useState();
  const [stocksPaidSignals, setStocksPaidSignals] = useState();
  const [loading, setLoading] = useState();
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [signalImage, setImage] = useState();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  };
  const authUser = useAuthUser();
  const imageHandler = async (data) => {
    setImage(data)
    setConfirmDeleteVisible(true)
  };
  const SignalCard = (val) => {
    console.log("val", val)
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);
    const [isOpenAnalysis, setIsOpenAnalysis] = useState(false);
    const [selectedContentAnalysis, setSelectedContentAnalysis] = useState(null);
    const imageHandler = (image, content) => {
      setSelectedImage(image);
      setSelectedContent(content);
      setIsOpen(true);
    };
    const closeModal = () => {
      setIsOpen(false);
      setSelectedImage(null);
      setSelectedContent(null);
    };
    const openModalAnalysis = (content) => {
      setSelectedContentAnalysis(content);
      setIsOpenAnalysis(true);
    };
    const closeModalAnalysis = () => {
      setIsOpenAnalysis(false);
      setSelectedContentAnalysis(null);
    };
    return (
      <>
        <div className="m-auto flex flex-wrap gap-8 items-center justify-between mb-[40px] w-[90%] h-full">
          {val?.val?.map((val, index) => {
            const imageUrl = val.image ? `https://investorszon.com${val.image}` : "https://placehold.co/800x400";
            return (
              <div
                key={index}
                className="main-dashboard-signal group relative w-[45%] bg-white shadow-lg rounded-lg"
              >
                <div className=" relative flex justify-between w-full" onClick={() => imageHandler(imageUrl, val)}>
                  <img
                    src={imageUrl}
                    className="w-full h-[200px]  hover:blur-sm "
                    style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", height: "200px" }}
                    alt="crypto signal"
                  />
                  <div className="absolute  inset-0 bg-blue-500 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-t-[5px]">
                    <p className="bg-white text-blue-600 font-bold text-center py-2  w-[40%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">View Signal
                    </p>
                  </div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2 bg-blue-700">
                  <div className="header-div text-white text-center py-1 ml-4">
                    {val.symbol}
                  </div>
                  <div className="data-div text-white text-center py-1 mr-4">Buy</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Entry</div>
                  <div className="data-div text-center py-1 mr-4">{val.entry}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Type</div>
                  <div className="data-div text-center py-1 mr-4">{val.longShort}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Entry Date & Time</div>
                  <div className="data-div text-center py-1 mr-4">
                    {new Date(val.entryDateTime).toLocaleDateString("en-US")}
                  </div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Stop Loss</div>
                  <div className="data-div text-center py-1 mr-4">{val.stopLoss}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Stop Loss Hit</div>
                  <div className="data-div text-center py-1 mr-4">{val.stopLossHit}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 1</div>
                  <div className="data-div text-center py-1 mr-4">{val.takeProfit1}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 2</div>
                  <div className="data-div text-center py-1 mr-4">{val.takeProfit2}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2">
                  <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 3</div>
                  <div className="data-div text-center py-1 mr-4">{val.takeProfit3}</div>
                </div>
                <div className="inner-dashboard-signal flex justify-between w-full border-b-2 mb-4">
                  <div className="header-div text-center py-1 ml-4 font-bold">Analysis</div>
                  <div className="text-center py-1 mr-4">
                    <button
                      onClick={() => openModalAnalysis(val.comment)}
                      className="text-white bg-blue-500 w-[60px] h-[30px] rounded-lg"
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          {isOpenAnalysis && (
            <div className="  fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
              <div className="relative bg-white  p-4 rounded-lg overflow-y-auto w-[400px] h-[300px]" style={{ minHeight: "20%", minWidth: "20%" }}>
                <h2 className="text-2xl font-bold mb-4">Analysis</h2>
                <p>{selectedContentAnalysis}</p>
                <button onClick={closeModalAnalysis} className="absolute top-2 right-2  text-white  rounded-lg"><IoMdCloseCircleOutline size={30} color="black" />
                </button>
              </div>
            </div>
          )
          }
          {isOpen && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
              <div className="bg-white w-3/4 h-3/4 p-4 rounded-lg flex relative">
                <img src={selectedImage} alt="crypto signal" className="w-1/2 h-full object-contain rounded-l-lg" />
                <div className="w-1/2 p-4 overflow-y-auto ">
                  <div className="flex justify-between w-full border-b-2 bg-blue-700 mb-4">
                    <div className="header-div text-white text-center py-1 ml-4">{selectedContent.symbol}</div>
                    <div className="data-div text-white text-center py-1 mr-4">Buy</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Entry</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.entry}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Type</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.longShort}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Entry Date & Time</div>
                    <div className="data-div text-center py-1 mr-4">{new Date(selectedContent.entryDateTime).toLocaleDateString("en-US")}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Stop Loss</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.stopLoss}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Stop Loss Hit</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.stopLossHit}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 1</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.takeProfit1}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 2</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.takeProfit2}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Take Profit 3</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.takeProfit3}</div>
                  </div>
                  <div className="flex justify-between w-full border-b-2 mb-4">
                    <div className="header-div text-center py-1 ml-4 font-bold">Analysis</div>
                    <div className="data-div text-center py-1 mr-4">{selectedContent.comment}</div>
                  </div>
                </div>
                <button onClick={closeModal} className="absolute top-0 right-0  text-white  rounded-lg"><IoMdCloseCircleOutline size={30} color="black" />
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Forex Signal":
        return (
          <div className="">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : forexPaidSignals && forexPaidSignals.length > 0 ? (
              <SignalCard val={forexPaidSignals} />
            ) : (
              <div className="flex justify-center ">
                <NoData />
              </div>
            )}
          </div>
        );
      case "Crypto Signal":
        return (
          <div className="">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : cryptoPaidSignals && cryptoPaidSignals.length > 0 ? (
              <SignalCard val={cryptoPaidSignals} />
            ) : (
              <div className="flex justify-center ">
                <NoData />
              </div>
            )}
          </div>
        );
      case "Stocks Signal":
        return (
          <div className="">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (stocksPaidSignals && stocksPaidSignals.length > 0 ? (
              <SignalCard val={stocksPaidSignals} />) : (
              <div className="flex justify-center ">
                <NoData />
              </div>)
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const fetchForexSignalsPaid = async () => {
    setLoading(true);
    try {
      const resp = await fetchPaidForexSignals(authUser?.token);
      setForexPaidSignals(resp?.data?.signals);
    } catch (error) {
      console.error("Error fetching forex signals:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCryptoSignalsPaid = async () => {
    setLoading(true);
    try {
      const resp = await fetchPaidCryptoSignals(authUser?.token);
      setCryptoPaidSignals(resp?.data?.signals);
    } catch (error) {
      console.error("Error fetching crypto signals:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchStocksSignalsPaid = async () => {
    setLoading(true);
    try {
      const resp = await fetchPaidStocksSignals(authUser?.token);
      setStocksPaidSignals(resp?.data?.signals);
    } catch (error) {
      console.error("Error fetching stocks signals:", error);
      // You might want to handle the error by setting an error state
      // setError(true);
    } finally {
      setLoading(false);
    }
  };
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-background')) {
      setConfirmDeleteVisible(false);
    }
  };
  useEffect(() => {
    switch (activeTab) {
      case "Forex Signal":
        fetchForexSignalsPaid();
        break;
      case "Crypto Signal":
        fetchCryptoSignalsPaid();
        break;
      case "Stocks Signal":
        fetchStocksSignalsPaid();
        break;
      default:
        break;
    }
  }, [activeTab]);
  useEffect(() => {
    if (confirmDeleteVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto'; // Reset overflow on unmount
    };
  }, [confirmDeleteVisible]);
  return (
    <div>
      <ul className="flex flex-wrap justify-start text-sm font-medium text-center text-gray-500 border-b border-gray-200  dark:text-gray-400">
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Forex Signal")}
            aria-current={activeTab === "Forex Signal" ? "page" : undefined}
            className={`inline-block p-4  ${activeTab === "Forex Signal"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Forex Signal
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Crypto Signal")}
            className={`inline-block p-4 ${activeTab === "Crypto Signal"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Crypto Signal
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Stocks Signal")}
            className={`inline-block p-4 ${activeTab === "Stocks Signal"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Stocks Signal
          </NavLink>
        </li>
      </ul>
      <div className="p-4">{renderContent()}</div>
      {confirmDeleteVisible &&
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 modal-background"
          onClick={handleOutsideClick}
        >
          <div className="mx-auto bg-white p-6 rounded-lg shadow-lg relative">
            <IoCloseCircleOutline
              size={30}
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => setConfirmDeleteVisible(false)}
            />
            <div className="flex flex-col items-center justify-center text-white">
              <img src={signalImage} className="w-full h-auto" style={{ maxHeight: 'calc(100vh - 6rem)' }} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};
export default DashboardSignal;
