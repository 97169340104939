import React, { useEffect, useState } from "react";
import { fetchVideo } from "../../api/BlogApi";
import "./BlogCard.css";
import NoData from "../Lottie Animation Comp/NoData";
import { FaSpinner } from "react-icons/fa";
import { NavLink } from 'react-router-dom'

const Video = () => {
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        const data = await fetchVideo();
        console.log('Fetched data:', data);
        if (data) {
          setVideo(data);
        } else {
          setError('Unexpected response format');
        }
      } catch (error) {
        setError('Failed to fetch video');
        console.error('Error fetching video:', error);
      } finally {
        setLoading(false);
      }
    };

    loadVideo();
  }, []);

  const handlePlayVideo = (videoUrl) => {
    setPlayingVideo(videoUrl);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }




  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
          <FaSpinner className="animate-spin text-white" size={40} />
        </div>
      ) : (video && video.length > 0 ? (
        <div className="container flex flex-wrap gap-4  justify-center">
          {video?.map((val, index) => {
            const videoUrl = `https://investorszon.com${val.video}`;
            const blogContent = { content: val }

            return (
              <div
                key={index}
                className="main-blog relative flex flex-col  w-[30%] h-[50vh] max-h-92 mb-6 bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 cursor-pointer  box-border hover:transform hover:-translate-y-2 hover:shadow-lg"
              >
                <div className="relative w-full h-full rounded-t-lg mb-3 overflow-hidden bg-gray-400">
                  <img
                    src={`https://investorszon.com${val.image}`}
                    alt="Video Background"
                    className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 opacity-100 hover:opacity-75"
                  />
                  
                  {playingVideo === videoUrl && (
                    <iframe
                      className="absolute inset-0 w-full h-full"
                      src={`${videoUrl}?autoplay=1`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={val.title}
                    />
                  )}
                </div>
                <div className="p-4" >
                <h3 className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {val.title}
                </h3>
                <p className=" text-base text-body-color dark:text-dark-6" style={{ minHeight: "100px" }}>
                  {val.description}
                </p>
                </div>
                <div
                    className="absolute inset-0 flex justify-center bg-blue-500 bg-opacity-50 items-center opacity-0 transition-opacity duration-300 hover:opacity-100 cursor-pointer"
                  >
                    <NavLink state={blogContent} to={`/video-blog/${val.slug}`} className="bg-white text-blue-600 font-bold text-center py-2  w-[42%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">
                    View Video Blogs
                    </NavLink>
                  </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoData />
      ))}
    </>
  );
};

export default Video;
