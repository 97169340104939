import React, { useState } from 'react';
import Title from '../TitleComp/Title';
import { Verifycertificate } from '../../api/BlogApi';
import { FaCheckCircle } from "react-icons/fa";
import LottieGreenCheck from '../Lottie Animation Comp/LottieGreenCheck';
import { IoMdCloseCircleOutline } from "react-icons/io";

const VerifyCertificate = () => {
    const [formData, setFormData] = useState({ certificatenumber: '' });
    const [errors, setErrors] = useState({});
    const [certificate, setCertificate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors({});
        setCertificate(null);

        if (!formData.certificatenumber) {
            setErrors({ certificatenumber: 'Certificate number is required' });
            return;
        }

        try {
            const result = await Verifycertificate(formData.certificatenumber);
            setCertificate(result.certificate);
            setIsModalOpen(true);
        } catch (error) {
            setErrors({ certificatenumber: 'Failed to verify certificate' });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const titledata = [
        {
            header: "Verify Certificate",
            descp: "To verify a certificate, confirm its validity period, validate the issuer’s credentials, ensure it matches the intended server, and check for any revocation or security alerts from trusted certificate authorities."
        }
    ];

    return (
        <div className='container mt-[5%]'>
            <Title data={titledata} />
            <section>
                <div className="w-full m-auto mb-6 px-4 lg:w-5/12 xl:w-4/12">
                    <div className="wow fadeInUp rounded-lg border bg-white px-8 py-10 shadow-testimonial dark:bg-dark-2 dark:shadow-none sm:px-10 sm:py-12 md:p-[60px] lg:p-10 lg:px-10 lg:py-12 2xl:p-[60px]" data-wow-delay=".2s">
                        <h3 className="mb-8 text-2xl font-semibold text-dark dark:text-white md:text-[28px] md:leading-[1.42]">
                            Verify Certificate
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-[22px]">
                                <label
                                    htmlFor="certificatenumber"
                                    className="mb-4 block text-sm text-body-color dark:text-dark-6"
                                >
                                    Certificate Number
                                </label>
                                <input
                                    type="text"
                                    name="certificatenumber"
                                    placeholder="Enter your Certificate Number"
                                    className={`w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6 ${errors.certificatenumber ? 'border-red-500' : ''}`}
                                    value={formData.certificatenumber}
                                    onChange={handleChange}
                                />
                                {errors.certificatenumber && <p className="text-red-500 text-xs mt-1">{errors.certificatenumber}</p>}
                            </div>
                            <div className="mb-0">
                                <button
                                    type="submit"
                                    className="inline-flex items-center justify-center rounded-md bg-primary px-10 py-3 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-blue-dark"
                                >
                                    Verify
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white item  rounded-lg overflow-hidden shadow-xl p-6 w-full max-w-md">
                        <h2 className="text-xl  font-semibold mb-4"> Certificate Verify Successfully</h2>
                        {certificate ? (
                            <>
                                {/* <div className="mb-4 justify-center flex">
                <FaCheckCircle  size={20} color='green' className='mr-2'/>
                <strong>Your Certificate is Vertify</strong> 
                </div> */}
                                <div className='w-[40%] m-auto'>
                                    <LottieGreenCheck />
                                </div>


                                <div className="mb-4 text-center">
                                    <strong> Certificate Number:</strong> {certificate.number}
                                </div>
                                <div className="mb-4 text-center">
                                    <strong>Activation Date:</strong> {new Date(certificate.createdAt).toLocaleString()}
                                </div>
                            </>
                        ) : (
                            <p>No certificate details available.</p>
                        )}
                        <div className='absolute top-6 right-2'>
                            <div onClick={() => setIsModalOpen(false)} className="">
                                <IoMdCloseCircleOutline size={30} />

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyCertificate;
