import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa'
import Assest from "../../assets/blog/bannder-ad.png";
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { NavLink,useLocation, useParams  } from 'react-router-dom'
import { FaLock } from "react-icons/fa";
import { URL } from '../../config'
const MyCourseModule = ({data,total }) => {
  window.scroll(0,0)

  
//   const {title} = useParams();
const title =title;
  const location = useLocation();
  const [userProgressDetails, setUserPregressDetails]=useState([])
  const authUser = useAuthUser()
 
  console.log("Current path:", title);
  const specificPath = `/courseDetails/${title}`; // Replace with the actual path
 
  
const userHandler = async() =>{
  
  const token = authUser?.token
  const response = await axios.get(`${URL}/progress/progressview/${title}`,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } )
setUserPregressDetails(response?.data?.progress?.moduleContentItemId ? response?.data?.progress?.moduleContentItemId : 0)
}
// const completed = data?.Contents?.map(course => {
//   // Find the index of the item with the specified id in each course's Items array
//   return  course?.Items.findIndex(item => item.id === userProgressDetails);
// });

const completed = data?.Contents?.flatMap(course =>
  course?.Items.findIndex(item => item.id === userProgressDetails)
)[0] ?? -1; 
const totalCompleted = completed + 1;

const percentage = (totalCompleted / total) * 100;
const radius = 90;
const circumference = 2 * Math.PI * radius;
const offset = circumference - (percentage / 100) * circumference;
 


useEffect(() => {
  if(location.pathname === specificPath ){
    userHandler();
  }
  

}, [location.pathname === specificPath ]);
  return (
    <div>
        <NavLink
  to="#" 
  className="mt-5 ml-[4%] flex items-center justify-between h-[250px] w-[50vw] bg-white   shadow-xl rounded-xl  dark:border-gray-700 dark:bg-gray-800 "
>
  <img 
    className="object-cover w-[30%] h-[100%] rounded-tl-xl rounded-bl-xl" 
    src={Assest} 
    alt="Noteworthy technology acquisitions 2021"
  />
  <div className="flex flex-col w-[60%] justify-between  leading-normal ">
    <h5 className="mb-2  text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
    {data.title}
    </h5>
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
      {data.description}
    {/* Currency trading? Forex trading? FX trading? Totally clueless about forex? Here's an introduction to the foreign exchange market. */}
    </p>
    <div className='flex justify-between'>
        
    </div>
    <NavLink  state={{courseID : "sothign"}} to={`/single-module-details/${data.id}`} className="flex items-center w-[50%] justify-center mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600">
          Start Module<FaArrowRight className="ml-2" />
        </NavLink>
  </div>
  <div>
    

  <div className='mr-[10%]'>
          <h2 className=" font-bold text-3xl mt-8 text-white text-center">Progress</h2>
          <div className=" flex flex-col items-center ">
            {/* <h1 className="text-4xl font-bold">Track Your Progress</h1>
            <p className="mb-4 mt-4 text-gray-700">
              Here you can see your progress. Keep up the good work!
            </p> */}
            <div className="relative mt-8">
              <svg
                className="transform -rotate-90 w-40 h-40"
                viewBox="0 0 200 200"
              >
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="#e5e7eb"
                  strokeWidth="15"
                  fill="none"
                />
                <circle
                  cx="100"
                  cy="100"
                  r={radius}
                  stroke="#3b82f6"
                  strokeWidth="15"
                  fill="none"
                  strokeDasharray={circumference}
                  strokeDashoffset={offset}
                  strokeLinecap="round"
                />
              </svg>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="text-2xl font-bold text-gray-700">
                  {userProgressDetails?.completed}
                </div>
                <div className="text-sm text-gray-500"> 
                {userProgressDetails?.total}</div>
              </div>
            </div>
          </div>
        </div>
  </div>
</NavLink>

    </div>
    
  )
}

export default MyCourseModule