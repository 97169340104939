import React, { useEffect, useState } from "react";
import CourseComponent from "../SubComponents/CousreComponent";
import { useParams } from "react-router-dom";

const SingleCourseDetails = () => {
  window.scroll(0, 0);
  return (
    <div className="  mt-[4.3%] w-[100%]">
      <CourseComponent />
    </div>
  );
};

export default SingleCourseDetails;
