import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import SwiperCore from 'swiper/core';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import AuthorOne from "../../assets/testimonials/author-01.jpg";
import AuthorTwo from "../../assets/testimonials/author-02.jpg";
import AuthorThree from "../../assets/testimonials/author-03.jpg";
import starImg from "../../assets/testimonials/icon-star.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import '../About/About.css'
import { fetchPublishedReview } from '../../api/reviewApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { FaUserCircle } from "react-icons/fa";




// install Swiper modules
SwiperCore.use([Navigation]);
const Testimonials = ({ data }) => {

  window.scroll(0, 0)
  const testimonials = [
    {
      text: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.’’",
      author: "Alex Johnson",
      role: "Founder @ MarketGenius",
      image: AuthorOne,
    },
    {
      text: "“Investors zon provides top-notch analysis and insights. The platform's user-friendly design makes it easy to stay on top of the markets.”",
      author: "Emily Davis",
      role: "Founder @ TradeSmart",
      image: AuthorTwo,
    },
    {
      text: "“The expertise and daily updates from Investors zon are valuable. It has transformed the way we approach Forex and Crypto trading.”",
      author: "Michael Lee",
      role: "Founder @ FinTech Innovations",
      image: AuthorThree,
    },
    {
      text: "“Investors zon is a game-changer. The detailed reports and strategic insights have significantly improved our investment decisions.”",
      author: "Sarah Thompson",
      role: "Founder @ WealthGrowers",
      image: AuthorOne,
    },
  ];
  const authUser = useAuthUser();
  const [reviewData,setReviewData]= useState();
  function truncateTo20Words(text) {
    // Split the text into an array of words
    const words = text.split(' ');
    
    // Slice the array to include only the first 20 words
    const truncatedWords = words.slice(0, 15);
    
    // Join the words back into a string
    const truncatedText = truncatedWords.join(' ')+ '..';
    
    return truncatedText;
  }

  const fetchReview =async()=>{
    const resp= await fetchPublishedReview(authUser?.token);
    setReviewData(resp?.data?.data)
  };
  useEffect(()=>{
    fetchReview()
  },[]);

  return (
    <section
      id="testimonials"
      className="overflow-hidden bg-gray-1 py-20 dark:bg-dark-2 md:py-[120px] "
    >
      <div className="container mx-auto">


        <div className="-mx-4 flex flex-wrap justify-center ">
          {data?.map((val, index) =>{ 
             
            return(
            <div key={index} className=" w-full sm:w-full px-4 ">
              <div className="mx-auto mb-[60px] max-w-[485px] text-center ">
                <span className="mb-2 block text-lg font-semibold text-primary">

                  {val.header}
                </span>
                <h2 className="mb-3 text-3xl font-bold mb-4 leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                  {val.subheader}
                </h2>



                <p className="text-base text-body-color dark:text-dark-6">{val.testimonial}</p>


              </div>
            </div>
          )})}
        </div>




        <div className="-m-5 position-relative ">
          <Swiper
            className="testimonial-carousel common-carousel p-5  "
            spaceBetween={10}
            slidesPerView={3}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {reviewData?.slice(0,3).map((testimonial, index) =>{
              
              return (
              <SwiperSlide key={index} className='swipper'>
                <div className="rounded-xl bg-white px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] h-[45vh] testinomial-width ">
                  <div className="mb-[18px] flex items-center gap-[2px]">
                    {[...Array(testimonial?.rating)].map((_, starIndex) => (
                      <img
                        key={starIndex}
                        src={starImg}
                        alt="star icon"
                      />
                    ))}
                  </div>

                  <p className="mb-6 text-base text-body-color dark:text-dark-6 h-[100px]">
                    {truncateTo20Words(testimonial.message)}
                  </p>

                  <a href="#" className="flex items-center gap-4">
                    <div className="h-[50px] w-[50px] ">
                      {/* <img
                        src={testimonial.image}
                        alt="author"
                        className="h-[50px] w-[50px] overflow-hidden rounded-full"
                      /> */}
                      <FaUserCircle size={50} />
                    </div>

                    <div>
                      <h3 className="text-sm font-semibold text-dark dark:text-white">
                        {testimonial?.userName}
                      </h3>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            )})}
            <div className='Swipper-button w-full  flex justify-center mt-4'>
              <div className="swiper-button-prev" style={{ color: 'black', marginRight: "10px" }}><IoIosArrowBack /></div>
              <div className="swiper-button-next" style={{ color: 'black' }}><IoIosArrowForward /></div>
            </div>
          </Swiper>


        </div>
      </div>
    </section>
  );
};

export default Testimonials;
