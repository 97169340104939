import React from 'react'

const PricyPolicy = () => {
  return (
    <div style={{ backgroundColor: "pink", height: "100vh", width: "100%" }}>
         <p style={{ color: "black", marginTop: "80px" }}>
          my our pricypolicy pages
        </p>
      
    </div>
  )
}

export default PricyPolicy
