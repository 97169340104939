import React from 'react'
import { SiExpertsexchange } from 'react-icons/si'
import "./Homepagecard.css"
const HomePageCard = ({ data }) => {
  return (
    <div>
    
        <div className="w-full  flex flex-wrap justify-between homepaage-div   ">

          {data.map((card, index) => (

            <div key={index}
              className="w-[400px] px-4 md:w-[400px] lg:w-[400px] main-homecard">
              <div className=" wow fadeInUp group mb-12 border overflow-hidden border-[#eaeff5] rounded-[20px] p-8 transition duration-300  hover:shadow-bottom inner-main" data-wow-delay=".1s">
                <div
                  className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
                >
                  <span
                    className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                  ></span>
                  <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {card.icon}

                  </svg>
                </div>
                <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
                  {card.heading}
                </h4>
                <p className="mb-8 text-body-color dark:text-dark-6 lg:mb-9 h-[160px] ">
                  {card.description}
                </p>

              </div>
            </div>
          ))}
        </div>

     
    </div>
  )
}

export default HomePageCard
