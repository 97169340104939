import React from 'react'
import Commbodiya from '../../assets/Forex-Trading-Glossary.jpg';

import SubscribeNow from '../SubscribeNowComp/SubscribeNow'
import Qutation from '../QutationComp/Qutation'
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import "./CurrencyParis.css"
import MarketTitle from '../TitleComp/MarketTitle';
import { FaCheckCircle } from 'react-icons/fa';
const ForexGlossary = () => {
  window.scroll(0,0)
  const data = [
    {
      header: "Overview:",
       descp: ` Understanding the terminology used in the FOREX market is crucial for any trader or investor. Our comprehensive FOREX glossary provides clear definitions and explanations of key terms and concepts to help you navigate the market with confidence.`,
    }, 
  ]
  const CurrencyDatatwo = [
    {
      header: `Ask Price:`,
      list: [{
        heading: "Definition: ",
        descp: "  The price at which a broker or dealer is willing to sell a currency pair. It is also known as the offer price."
      },
      {
        heading: "Example:",
        descp: "If the EUR/USD ask price is 1.2000, you can buy euros at this rate."
      },
      ]
    },
    {
      header: `Bid Price:`,
      list: [{
        heading: "Definition:  ",
        descp: " The price at which a broker or dealer is willing to buy a currency pair. It represents the highest price a buyer is willing to pay."
      },
      {
        heading: "Example: ",
        descp: "If the EUR/USD bid price is 1.1990, you can sell euros at this rate."
      },
      
      ]
    }, {
      header: `Spread:`,
      list: [{
        heading: "Definition:  ",
        descp: "  The difference between the bid and ask price of a currency pair. It is effectively the cost of trading and represents the broker's profit."
      },
      {
        heading: "Example: ",
        descp: "If the EUR/USD bid price is 1.1990 and the ask price is 1.2000, the spread is 0.0010 or 10 pips."
      },
      
      ]
    },
    {
      header: `Pip (Percentage in Point):`,
      list: [{
        heading: "Definition:  ",
        descp: " he smallest price movement in the FOREX market, usually equal to 0.0001 for most currency pairs. For pairs involving the Japanese yen, a pip is 0.01.."
      },
      {
        heading: "Example: ",
        descp: "If the EUR/USD moves from 1.2000 to 1.2005, it has moved 5 pips."
      },
     
      ]
    },
    {
      header: `Leverage:`,
      list: [{
        heading: "Definition:  ",
        descp: "  The use of borrowed capital to increase the potential return of an investment. In FOREX trading, leverage allows traders to control larger positions with a smaller amount of actual capital."
      },
      {
        heading: "Example: ",
        descp: "A leverage ratio of 100:1 means you can control a $100,000 position with just $1,000 of your own money."
      },
     
      ]
    },
    {
      header: `Margin :`,
      list: [{
        heading: "Definition:  ",
        descp: "  The amount of money required to open and maintain a leveraged position. It is a portion of the trader’s account balance set aside as a deposit."
      },
      {
        heading: "Example: ",
        descp: "If the margin requirement is 1%, to open a $100,000 position, you need $1,000 in your account."
      },

      ]
    },
    {
      header: ` Lot:`,
      list: [{
        heading: "Definition:  ",
        descp: "  A standard unit of measurement in FOREX trading. There are three types of lots: standard (100,000 units), mini (10,000 units), and micro (1,000 units)."
      },
      {
        heading: "Example: ",
        descp: "Trading one standard lot of EUR/USD means trading 100,000 euros."
      },
    
      ]
    },
    {
      header: `Currency Pair:`,
      list: [{
        heading: "Definition:  ",
        descp: " A quotation of two different currencies, with the value of one currency being quoted against the other. The first currency is the base currency, and the second is the quote currency."
      },
      {
        heading: "Example: ",
        descp: "n the EUR/USD pair, EUR is the base currency, and USD is the quote currency."
      },
     
      ]
    },
    
  ]
  const buttondata = [
    { title: "Get Commodity Insights", descp: "", button: "SIGN UP" }
  ]
  const CurrencyDatathree =[
    {
      header: `Cross Currency Pair:`,
      list: [{
        heading: "Definition:  ",
        descp: " Currency pairs that do not involve the US dollar. These pairs are often traded for their potential to offer more volatility and trading opportunities."
      },
      {
        heading: "Example: ",
        descp: "EUR/GBP and AUD/JPY are examples of cross currency pairs.."
      },
     
      ]
    },
    {
      header: ` Exotic Currency Pairs:`,
      list: [{
        heading: "Definition:  ",
        descp: "  Currency pairs that include one major currency and one currency from an emerging or smaller economy. They tend to be less liquid and more volatile."
      },
      {
        heading: "Example: ",
        descp: "USD/TRY (US Dollar/Turkish Lira) and EUR/HKD (Euro/Hong Kong Dollar) are exotic currency pairs."
      },
     
      ]
    },
    {
      header: `Stop Loss Order:`,
      list: [{
        heading: "Definition:  ",
        descp: " An order placed to close a position automatically at a predetermined price level to limit potential losses."
      },
      {
        heading: "Example: ",
        descp: "If you buy EUR/USD at 1.2000, you might place a stop loss order at 1.1950 to limit your loss to 50 pips."
      },
     
      ]
    },
    {
      header: `CTake Profit Order::`,
      list: [{
        heading: "Definition:  ",
        descp: "An order placed to close a position automatically at a predetermined price level to secure profits."
      },
      {
        heading: "Example: ",
        descp: "If you buy EUR/USD at 1.2000, you might place a take profit order at 1.2050 to secure a profit of 50 pips.."
      },
     
      ]
    },
    {
      header: `Volatility:`,
      list: [{
        heading: "Definition:  ",
        descp: "A measure of how much the price of a currency pair fluctuates over a given period. High volatility indicates large price swings, while low volatility indicates smaller price movements."
      },
      {
        heading: "Example: ",
        descp: "The GBP/USD pair is known for higher volatility compared to the EUR/USD pair."
      },
     
      ]
    },
    {
      header: `Liquidity:`,
      list: [{
        heading: "Definition:  ",
        descp: "The ability to buy or sell an asset without causing a significant impact on its price. In the FOREX market, higher liquidity means tighter spreads and better execution of trades."
      },
      {
        heading: "Example: ",
        descp: "The EUR/USD pair has high liquidity, making it easier to trade large volumes without affecting the price."
      },
     
      ]
    },
    {
      header: `Technical Analysis:`,
      list: [{
        heading: "Definition:  ",
        descp: "The study of historical price movements and trading volumes to predict future price movements using charts and technical indicators."
      },
      {
        heading: "Example: ",
        descp: "Common technical indicators include moving averages, Bollinger Bands, and the Relative Strength Index (RSI)."
      },
     
      ]
    },
    {
      header: `Fundamental Analysis:`,
      list: [{
        heading: "Definition:  ",
        descp: "The analysis of economic, financial, and geopolitical factors that influence currency prices. This includes studying interest rates, GDP growth, employment data, and political events."
      },
      {
        heading: "Example: ",
        descp: "An increase in US interest rates may strengthen the US dollar against other currencies."
      },
     
      ]
    },
    {
      header: `Bullish and Bearish:`,
      list: [{
        heading: "Definition:  ",
        descp: "Terms used to describe market trends. Bullish means prices are expected to rise, while bearish means prices are expected to fall."
      },
      {
        heading: "Example: ",
        descp: "If traders are bullish on the USD, they expect it to appreciate in value.."
      },
     
      ]
    },
    {
      header: `Order Types:`,
      list: [{
        heading: "Market Order:  ",
        descp: "An order to buy or sell immediately at the current market price."
      },
      {
        heading: "Limit Order: ",
        descp: "An order to buy or sell at a specified price or better."
      },
      {
        heading: "Stop Order: ",
        descp: "An order to buy or sell once the price reaches a specified level, typically used to trigger a trade."
      },
     
      ]
    },
    {
      header: `Hedging:`,
      list: [{
        heading: "Definition:  ",
        descp: "A strategy used to protect against potential losses by taking an offsetting position in a related asset."
      },
      {
        heading: "Example: ",
        descp: " If you are long EUR/USD and concerned about a potential decline, you might hedge by taking a short position in EUR/GBP."
      },
     
      ]
    },
    {
      header: `Arbitrage:`,
      list: [{
        heading: "Definition:  ",
        descp: "The practice of taking advantage of price differences between two or more markets to generate a profit with minimal risk."
      },
      {
        heading: "Example: ",
        descp: "Buying EUR/USD in one market at a lower price and simultaneously selling it in another market at a higher price."
      },
     
      ]
    },
    {
      header: `Slippage:`,
      list: [{
        heading: "Definition:  ",
        descp: "The difference between the expected price of a trade and the actual price at which the trade is executed, often occurring during periods of high volatility."
      },
      {
        heading: "Example: ",
        descp: "If you place a market order to buy EUR/USD at 1.2000 but it gets filled at 1.2005, you experience 5 pips of slippage."
      },
     
      ]
    },
    {
      header: `Leverage Ratio:`,
      list: [{
        heading: "Definition:  ",
        descp: "The ratio of the amount of borrowed capital to the amount of the trader's own capital. It indicates the degree of leverage used in trading."
      },
      {
        heading: "Example: ",
        descp: "A leverage ratio of 100:1 means for every $1 of your own money, you can trade $100."
      },
     
      ]
    },
    {
      header: `Margin Call:`,
      list: [{
        heading: "Definition:  ",
        descp: " A demand from a broker for additional funds or securities to bring a margin account up to the minimum maintenance margin. It occurs when the account value falls below the required margin."
      },
      {
        heading: "Example: ",
        descp: "If your account balance drops significantly due to losing trades, you may receive a margin call to deposit more funds."
      },
     
      ]
    },
    {
      header: `Swap:`,
      list: [{
        heading: "Definition:  ",
        descp: "An overnight interest fee paid or earned for holding a position overnight in the FOREX market. It is based on the interest rate differential between the two currencies in the pair.."
      },
      {
        heading: "Example: ",
        descp: "    •  If you hold a long position in a currency with a higher interest rate than the currency you are shorting, you may earn a positive swap."
      },
     
      ]
    },
  ]
  return (
    <div className='mt-[140px]'>
      <div className=" container mx-auto mt-24  relative ">
        <div className="relative bg-primary">
          <img
            src={Commbodiya}
            alt="Paris Financial District"
            className="w-full h-auto  currencyparis-image"
            style={{ height: "75vh" }}
          />
        </div>
      </div>       
      <div className=''>
      <MarketTitle data={data}/>
      </div>
      <div className="container mx-auto flex justify-between">
        <div className="w-[60%] currency-inner-content 	">
 {CurrencyDatatwo.map((val, index) => {
            return (
              <div>
                <h2 className=" mt-8 font-bold text-xl">{val.header}</h2>
                <p className="text-lg mb-2  w-full	text-base text-body-color dark:text-dark-6">{val.paragraph}</p>
                {val?.list?.map((val, index) => {
                  return (
                    <>
                    <h2 className="  font-bold text-lg" style={{ display: "inline" }} >{val?.heading}</h2>
                    <div className="flex justify-start w-[100%]">
                      <p className="w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-0.5" style={{ marginRight: "10px" }} /> </p>
                      <p className=" mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">
                        {val?.descp} </p>
                    </div>
                  </>
                  )
                })}
              </div>
            );
          })}
        </div>
        <div className="w-[40%] currency-banner	">
          <SubscribeNow />
          <Qutation /> 
        </div>
      </div>
      <div className="container mx-auto flex justify-between">
        <div className="w-[100%] currency-inner-content 	">
          {CurrencyDatathree.map((val, index) => {
            return (
              <div>
                <h2 className=" mt-8 font-bold text-xl">{val.header}</h2>
                <p className="text-lg mb-2  w-full	text-base text-body-color dark:text-dark-6">{val.paragraph}</p>
                {val?.list?.map((val, index) => {
                  return (
                    <>
                        <h2 className="  font-bold text-lg" style={{ display: "inline" }} >{val?.heading}</h2>
                        <div className="flex justify-start w-[100%]">
                          <p className="w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-0.5" style={{ marginRight: "10px" }} /> </p>
                          <p className=" mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">
                            {val?.descp} </p>
                        </div>
                      </>
                  )
                })}
              </div>
            );
          })}
        </div>
        </div>
      <div className="">
        <Buttoncard data={buttondata} />
      </div>
    </div>
  )
}
export default ForexGlossary
