import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { MdLock } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { fetchCryptoSignals, fetchFreeCryptoSignals, fetchFreeForexSignals } from '../../api/signalsAPI';
import image from "../../assets/dashboard.jpg";
import { FaSpinner } from "react-icons/fa";
import "./ForexSignalCart.css";
import NoData from "../Lottie Animation Comp/NoData";
import { IoMdCloseCircleOutline } from "react-icons/io";

const ForexSignalCart = () => {
  const [cardData, setSubCardData] = useState([]); // State to store fetched data
  const [loader, setLoader] = useState(true); 

  const options = { 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit',
    hour12: false // Use 24-hour format
  };

  const FreeCryptoSignals =async()=>{
    setLoader(true);
    const resp = await fetchFreeForexSignals();
    setSubCardData(resp.data.signals)
    setLoader(false);
    console.log("resp-crypto",resp.data.signals)
  };


  

  console.log("subCardData", cardData);   
  const [loading, setLoading] = useState(true);
  const loadingContainer = useRef(null);



  useEffect(()=>{
    FreeCryptoSignals();
  },[])
  

  const [isOpen, setIsOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const openModal = (content) => {
    setSelectedContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedContent(null);
  };
  return (
    <>
    {loader ? (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
        <FaSpinner className="animate-spin text-white" size={40} />
      </div>
    ) : cardData.length === 0 ? (
      <div className="flex justify-center items-center h-full">
                 {/* <div className="loader-container w-[25vw] " ref={loadingContainer}></div> */}
                 <NoData/>
      </div>
    ) : (
      <div className='flex flex-wrap  justify-start  '>
        {cardData.map((val, index) => (
          <div key={index} className=" main-forex m-auto flex items-center justify-start gap-40 mb-[40px] w-[70%] h-full" >
            <div className=" inner-free-forex group relative w-[47%] bg-white shadow-lg rounded-lg">
              <div className="flex justify-between w-full border-b-2">
              <img src={`https://investorszon.com${val.image}` || "https://placehold.co/600x400"} style={{  height: '200px' }} className="w-full" alt="crypto signal" />
              </div>
              <div className="flex justify-between w-full border-b-2 bg-blue-700">
                <div className="text-white text-center py-1 ml-4">{val.symbol}</div>
                <div className="text-white text-center py-1 mr-4">Buy</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Entry</div>
                <div className="text-center py-1 mr-4">{val.entry}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Type</div>
                <div className="text-center py-1 mr-4">{val.longShort}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Entry Date & Time</div>
                <div className="text-center py-1 mr-4">{new Date(val.entryDateTime).toLocaleDateString('en-US', options)}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Stop Loss</div>
                <div className="text-center py-1 mr-4">{val.stopLoss}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Stop Loss Hit</div>
                <div className="text-center py-1 mr-4">{val.stopLossHit}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 1</div>
                <div className="text-center py-1 mr-4">{val.takeProfit1}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 2</div>
                <div className="text-center py-1 mr-4">{val.takeProfit2}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 3</div>
                <div className="text-center py-1 mr-4">{val.takeProfit3}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Status</div>
                <div className="text-center py-1 mr-4">{val.status}</div>
              </div>
              <div className="flex justify-between w-full border-b-2">

                  <div className="text-center py-1 ml-4">Analysis </div>
                  <div className="text-center py-1 mr-4">
                    <button
                      onClick={() => openModal(val.comment)}
                      className="text-white bg-blue-500 w-[60px] h-[30px] rounded-lg"
                    >
                      View
                    </button>
                  </div>

                </div>
              {/* <div className="absolute inset-0 bg-blue-500 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-lg">
                <span className="text-white text-lg font-bold">Free Stocks Signal</span>
              </div> */}
            </div>
            <div className=" inner-paid-forex relative w-[47%] bg-white shadow-lg rounded-lg group">
              <div className="absolute bg-black top-0 left-0 h-full w-full" style={{ opacity: 0.5 }}>
                <div className="flex items-center justify-center h-full w-full"><MdLock size={170} /></div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <img src={image} className="w-full" alt="crypto signal" />
              </div>
              <div className="flex justify-between w-full border-b-2 bg-blue-700">
                <div className="text-center py-1 mr-4">XXXX</div>
                <div className="text-white text-center py-1 mr-4">Buy</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Entry</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Type</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Entry Date & Time</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Stop Loss</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Stop Loss Hit</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 1</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 2</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Take Profit 3</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Status</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="flex justify-between w-full border-b-2">
                <div className="text-center py-1 ml-4">Analysis</div>
                <div className="text-center py-1 mr-4">XXXX</div>
              </div>
              <div className="absolute inset-0 bg-blue-500 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
              <NavLink to={"/signals-subscription"} ><MdLock size={120} color="#fff" className="ml-4" />  <span className="text-white text-xl font-bold ">Subscribe Now</span></NavLink>
              </div>
            </div>
          </div>
        ))}
        {isOpen && (
            <div className="  fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
              <div className="relative bg-white  p-4 rounded-lg overflow-y-auto" style={{ minHeight: "20%", minWidth: "20%" }}>
                <h2 className="text-2xl font-bold mb-4">Analysis</h2>
                <p>{selectedContent}</p>
                <button onClick={closeModal} className="absolute top-2 right-2  text-white  rounded-lg"><IoMdCloseCircleOutline size={30} color="black" />
                </button>
              </div>
            </div>
          )}
      </div>
    )}
  </>
  );
}

export default ForexSignalCart;
