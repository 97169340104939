import React, { useState } from "react";

const Accordian = ({data}) => {
  return (
    <div>
     <section className="relative z-10  bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        {/* <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                FAQ
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                Any Questions? Look Here
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div> */}

        <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4 lg:w-full">
        {data.map((val,index)=>{
            return(
            <AccordionItem
            header={val.header}
            text={val.text}
            text1={val.text1}
            text2={val.text2}
              
           />
        ) })} 
            
          </div>
           
          {/* <div className="w-full px-4 lg:w-full">
            <AccordionItem
              header="Forex Broker Reviews"
              text="Imagine stepping into the exhilarating world of forex trading, where currencies from different nations are exchanged, and fortunes can be made or lost in moments. Picture yourself as a captain navigating through this vast ocean of opportunities, seeking the best routes to profitability. In such a dynamic environment, you need a reliable guide, and thats where forex broker reviews or forex trading reviews come in."
           text1="With hundreds of online forex brokers available, choosing the right one can be confusing. How can you determine the most suitable option for yourself? That’s where forex trading reviews become invaluable. They provide insightful information and opinions from other traders who have already tried these brokers."
           text2="this comprehensive guide, we’ll explore what makes a great forex broker stand out. We’ll evaluate their trading platforms, ease of use, available trading instruments, and customer support quality. By the end of this journey, you’ll be equipped with the knowledge to make informed decisions, ensuring your trading experience is not only exciting but also rewarding. Let the winds of knowledge guide you as you chart a course toward trading success!"
           />
            <AccordionItem
              header="Forex Trading and the Role of Forex Brokers"
              text="People who enjoy trading and investing have always been drawn to global financial markets. One of the most dynamic markets is forex trading, offering numerous opportunities for quick profits. When traders want to engage in forex trading, they need the assistance of forex brokers. This section will delve into the fundamentals of forex trading, the crucial role of forex brokers, and the importance of reliable forex broker reviews.."
            text1='Forex trading involves the exchange of various currencies from around the world. It’s like a vast marketplace where banks, corporations, governments, and individual traders exchange currencies. The exchange rates fluctuate constantly, driven by various economic and geopolitical factors.'
            />
            
       
            <AccordionItem
              header="The Crucial Role of Forex Brokers"
              text="Forex brokers act as intermediaries, connecting individual traders to the interbank market. Without brokers, accessing this large market would be challenging for individual traders. Brokers provide trading platforms, tools, and services to facilitate trading and profit from currency value fluctuations."
           text1='Brokers offer access to a wide range of currency pairs and other financial instruments, enabling traders to employ diverse trading strategies. They also offer leverage, allowing traders to control larger positions with a smaller amount of capital. While leverage can amplify profits, it also increases potential losses, making it crucial to use it wisely..'
           />
            <AccordionItem
              header="Why Forex Broker Reviews Matter"
              text="Choosing the right forex broker is critical to a trader’s success. However, with so many brokers claiming to be the best, deciding whom to trust can be daunting. This is where forex broker reviews become invaluable."
            text1='Forex trading reviews provide essential insights into a broker’s offerings, performance, customer service, regulatory compliance, and reputation. These reviews are typically written by experienced traders or industry experts, making them a trustworthy source of information.'
            />
            
          </div> */}
        </div>
      </div>
{/* 
      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#3056D3" stop-opacity="0.36" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}
    </section> 
    </div>
  )
}

export default Accordian
const AccordionItem = ({ header, text,text1,text2 }) => {
    const [active, setActive] = useState(false);
  
    const handleToggle = () => {
    //   event.preventDefault();
      setActive(!active);
    };
    return (
      <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2  sm:p-8 lg:px-6 xl:px-8">
        <button
          className={`faq-btn flex w-full text-left`}
          onClick={() => handleToggle()}
        >
          <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
            <svg
              className={`fill-primary stroke-primary duration-200 ease-in-out ${
                active ? "rotate-180" : ""
              }`}
              width="17"
              height="10"
              viewBox="0 0 17 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                fill=""
                stroke=""
              />
            </svg>
          </div>
  
          <div className="w-full">
            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
              {header}
            </h4>
          </div>
        </button>
  
        <div
          className={`pl-[62px] duration-200 ease-in-out ${
            active ? "block" : "hidden"
          }`}
        >
          <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
            {text}
          </p>
          <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
            {text1}
          </p>
          <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
            {text2}
          </p>
        </div>
      </div>
    );
  };