import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaRegCheckCircle, FaSpinner } from "react-icons/fa";
import { fetchBlogs } from '../../api/BlogApi';
import "./BlogCard.css";
import NoData from '../Lottie Animation Comp/NoData';

const BlogCard = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(-1); // Track hovered index, -1 means no hover

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    useEffect(() => {
        const loadBlogs = async () => {
            try {
                const data = await fetchBlogs();

                if (data) {
                    setBlogs(data);
                } else {
                    setError('Unexpected response format');
                }
            } catch (error) {
                setError('Failed to fetch announcements');
                console.error('Error fetching announcements:', error);
            } finally {
                setLoading(false);
            }
        };

        loadBlogs();

    }, []);


    return (
        <div>
            {loading ?
                (
                    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                        <FaSpinner className="animate-spin text-white" size={40} />
                    </div>
                )
                :
                (blogs?.blogs?.length > 0
                    ?
                    (
                        <div className="flex flex-wrap justify-center gap-4 w-full">
                            {blogs?.blogs?.map((val, index) =>{
                                 const blogContent = {content:val.body};
                                 const entryDateTime = val.publishedDate;
                                 const date = new Date(entryDateTime)
            
                                 const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' }
                                 const formattedDate = date.toLocaleDateString('en-GB', optionsDate)
            
                                 const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false }
                                 const formattedTime = date.toLocaleTimeString('en-GB', optionsTime)
            
                                 const formattedDateTime = `${formattedDate} @ ${formattedTime}`
                                return (
                                <div
                                    key={index}
                                    className="main-blog mb-10 w-[30%] border rounded-xl hover:shadow-lg relative"
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(-1)}
                                >
                                    <div className="mb-8 overflow-hidden rounded-t-xl flex justify-center">
                                        <img
                                        style={{height:"250px",width:"100%"}}
                                            src={val.image ? `https://investorszon.com${val.image}` : "https://placehold.co/600x400"}

                                        />
                                    </div>
                                    <div className="p-4 flex flex-col">
                                        {val.publishedDate &&
                                            <div className="w-fit  mb-5 inline-block  bg-primary px-2   text-xs font-semibold leading-loose text-white">
                                                {formattedDateTime}
                                            </div>
                                        }
                                        <h3 className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                                            {val.title}
                                        </h3>
                                        {/* <p className=" text-base text-body-color dark:text-dark-6"  style={{minHeight:"100px"}}>
                                        {truncateText(val.body, 10)}
                                        </p> */}
                                    </div>
                                    {hoveredIndex === index && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-blue-500 bg-opacity-50 rounded-xl">
                                            <NavLink state={blogContent} to={`/blog/${val.slug}`} className="bg-white text-blue-600 font-bold text-center py-2  w-[30%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">
                                            Read Blogs
                                            </NavLink>
                                        </div>
                                    )}
                                </div>
                            )})}
                        </div>
                    )
                    :
                    (
                        <NoData />
                    ))}
        </div>
    );
};

export default BlogCard;
