import React from 'react'
import NoData from '../Lottie Animation Comp/NoData'

const Discover = () => {
  return (
    <div className='mt-[10%]'>
      <NoData/>
    </div>
  )
}

export default Discover
