import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {
  fetchBuyModules,
  fetchUserCoursePurchaseStatus,
  getUserCompleteCourses,
  getUserCompletedModules,
  myCourses,
} from "../../api/CourseApi";
import MyCourseCard from "../SubComponents/MyCourseCard";
import NoData from "../Lottie Animation Comp/NoData";
import { FaSpinner } from "react-icons/fa";
import MyCertificateCard from "../SubComponents/MyCertificateCard";
import { fetchUserReview } from "../../api/reviewApi";
import MyCourseReviewCard from "../SubComponents/MyCourseReviewCard";
const DashboardSignalTabs = () => {
  const [activeTab, setActiveTab] = useState("Completed Courses");
  const [subscribedDetails, setSubscribedDetails] = useState();
  const authUser = useAuthUser();
  const [course, setCourse] = useState([]);
  const [module, setModule] = useState([]);
  const [completedModules, setCompletedModules] = useState();
  const [completedCourses, setCompletedCourses] = useState();
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState();
  const fetchCourse = async () => {
    setLoading(true);
    setLoader(true);
    if (!authUser) {
      return navigate("/signin");
    }
    const resp = await myCourses(authUser?.token);
    setCourse(resp.data);
    setLoader(false);
    setLoading(false);
  };
  const fetchCompletedModules = async () => {
    setLoader(true);
    const resp = await getUserCompletedModules(authUser?.token);
    setCompletedModules(resp.data);
    setLoader(false);
  };
  const fetchCompletedCourses = async () => {
    setLoader(true);
    const resp = await getUserCompleteCourses(authUser?.token);
    setCompletedCourses(resp.data);
    setLoader(false);
  };

  const fetchReviews = async () => {
    const response = await fetchUserReview(authUser?.token);
    setReviewData(response?.data?.reviews);
  };

  console.log("reviews", reviewData);
  const moduleReviews = reviewData?.filter((review) => review.courseId === null);
  const courseReviews = reviewData?.filter((review) => review.courseId !== null);

  useEffect(() => {
    switch (activeTab) {
      case "Completed Courses":
        fetchCompletedCourses();
        break;
      case "Completed Modules":
        fetchCompletedModules();
        break;
      default:
        break;
    }
  }, [activeTab]);
  const loadingContainer = useRef(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/NOCoursesAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);
  const renderContent = () => {
    switch (activeTab) {
      case "Completed Courses":
        return (
          <div className="">
            {loader ? (
              <div
                className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50"
                style={{ zIndex: 999999 }}
              >
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : completedCourses && completedCourses.length > 0 ? (
              <>
                <MyCertificateCard data={completedCourses} reviews={courseReviews} />
              </>
            ) : (
              <div className="flex justify-center ">
                <NoData />
              </div>
            )}
          </div>
        );
      case "Completed Modules":
        return (
          <div className="">
            {loader ? (
              <div
                className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50"
                style={{ zIndex: 999999 }}
              >
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : completedModules && completedModules.length > 0 ? (
              <MyCourseReviewCard data={completedModules} reviews={moduleReviews} />
            ) : (
              <div className="flex justify-center ">
                <NoData />
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    fetchReviews();
  }, []);
  return (
    <div>
      <ul className="flex flex-wrap justify-start text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400">
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Completed Courses")}
            className={`inline-block p-4 ${
              activeTab === "Completed Courses"
                ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
                : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
            }`}
          >
            Course Certificates
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Completed Modules")}
            className={`inline-block p-4 ${
              activeTab === "Completed Modules"
                ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
                : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
            }`}
          >
            Module Certificates
          </NavLink>
        </li>
      </ul>
      <div className="p-4">{renderContent()}</div>
    </div>
  );
};
export default DashboardSignalTabs;
