import React from 'react'
import Commbodiya from '../assets/commodity.webp'
import SubscribeNow from './SubscribeNowComp/SubscribeNow';
import Qutation from './QutationComp/Qutation';
import Buttoncard from './HomePageSimpleCard/Buttoncard';
import "../Components/ForexMarketPages/CurrencyParis.css"
import Title from './TitleComp/Title';
import Forexmarketcard from './ForexmarketCardComp/ForexMarketCard';
import MarketTitle from './TitleComp/MarketTitle';
import { FaCheckCircle } from 'react-icons/fa';

const CommodityMarket = () => {
  window.scroll(0, 0)
  const CurrencyDatatwo = [
    {
      header: `Key Commodities:`,
    },
    {
      header: `Gold:`,
      list: [{
        heading: "Overview: ",
        descp: "  Gold is a precious metal that has been used as a store of value for centuries. It is considered a safe-haven asset, often sought after during times of economic uncertainty."
      },
      {
        heading: "Market Dynamics:",
        descp: "Prices are affected by industrial demand, mining supply, and investment demand."
      },
      {
        heading: "Investment Insight:",
        descp: "Explore investment options such as physical silver, silver ETFs, and silver mining stocks."
      },
      ]
    },
    {
      header: `Silver:`,
      list: [{
        heading: "Overview:  ",
        descp: "  Silver is both an industrial metal and a precious metal. It is used in various industries, including electronics, solar energy, and medicine."
      },
      {
        heading: "Market Dynamics: ",
        descp: "Prices are affected by industrial demand, mining supply, and investment demand."
      },
      {
        heading: "Investment Insight: ",
        descp: " Explore investment options such as physical silver, silver ETFs, and silver mining stocks."
      },
      ]
    }, {
      header: `Oil:`,
      list: [{
        heading: "Overview:  ",
        descp: "  Silver is both an industrial metal and a precious metal. It is used in various industries, including electronics, solar energy, and medicine."
      },
      {
        heading: "Market Dynamics: ",
        descp: "Prices are affected by industrial demand, mining supply, and investment demand."
      },
      {
        heading: "Investment Insight: ",
        descp: "Explore investment options such as physical silver, silver ETFs, and silver mining stocks."
      },
      ]
    },
    {
      header: `Natural Gas:`,
      list: [{
        heading: "Overview:  ",
        descp: "  Natural gas is a vital energy source used for heating, electricity generation, and industrial processes. Its price is subject to seasonal demand fluctuations."
      },
      {
        heading: "Market Dynamics: ",
        descp: "PPrices are driven by weather conditions, production levels, storage inventories, and changes in demand."
      },
      {
        heading: "Investment Insight: ",
        descp: "Discover investment opportunities in natural gas through futures contracts, natural gas ETFs, and energy companies."
      },
      ]
    },



    {
      header: `Technical Analysis:`,
      list: [{
        heading: "Price Charts and Patterns:  ",
        descp: "  Study historical price movements and identify trends using charts and technical indicators such as moving averages, RSI, and MACD."
      },
      {
        heading: "Support and Resistance Levels: ",
        descp: "Recognize key price levels where commodities are likely to find support or encounter resistance."
      },

      ]
    },


  ]
  const buttondata = [
    { title: "Get Commodity Insights", descp: "", button: "SIGN UP" }
  ]
  const titledata = [
    { header: "Agricultural Commodities" }
  ]
  const forexdata = [
    {

      heading: "Overview:  ",
      description: "  Agricultural commodities include products such as wheat, corn, soybeans, coffee, and cotton. These are essential for food production and other industries."
    },
    {
      heading: "Market Dynamics: ",
      description: "Prices are influenced by weather conditions, planting and harvest cycles, government policies, and global demand."
    },
    {
      heading: "Investment Insight: ",
      description: "Learn about investing in agricultural commodities through futures contracts, agricultural ETFs, and agribusiness stocks."
    },


  ]
  const titledataone = [
    { header: "Fundamental Analysis" }
  ]
  const forexdataone = [
    {

      heading: "Supply and Demand Factors  ",
      description: "  Analyze the underlying factors driving supply and demand for different commodities."
    },
    {
      heading: "Economic Indicators ",
      description: "Understand how macroeconomic indicators such as GDP growth, inflation, and interest rates impact commodity prices."
    },
    {
      heading: "Seasonal Trends",
      description: "Explore how seasonal patterns affect commodity prices, particularly in agricultural markets."
    },


  ]
  const titledatatwo = [
    { header: "Weekly Forecasts and Strategic Insights" }
  ]
  const forexdatatwo = [
    {

      heading: "Market Outlook:  ",
      description: " Receive weekly forecasts highlighting key events, economic reports, and market trends to watch for the upcoming week."
    },
    {
      heading: "Investment Strategies: ",
      description: "Learn about various investment strategies such as long-term investing, short-term trading, and hedging techniques."
    },
    {
      heading: "Risk Management: ",
      description: "Discover methods to manage risk, including diversification, position sizing, and stop-loss orders."
    },

  ]
  const markettitledataone = [
    { header: "Overview", descp: " The commodity market involves the trading of primary goods such as metals, energy, and agricultural products. It plays a crucial role in the global economy by providing a platform for producers and consumers to hedge against price risks and for investors to diversify their portfolios. The commodity market is divided into two segments: the physical market, where commodities are bought and sold for immediate delivery, and the futures market, where contracts for future delivery are traded.    " }
  ]
  return (
    <div className='mt-[140px]'>
      <div className=" container mx-auto mt-[10%]  relative mb-6 ">
        <div className="relative bg-primary">
          <img
            src={Commbodiya}
            alt="Paris Financial District"
            className="w-full h-auto currencyparis-image"
            style={{ height: "75vh" }}
          />
        </div>
        <MarketTitle data={markettitledataone} />
      </div>
      <div className="container mx-auto flex gap-[2%] justify-between">
        <div className="w-[59%] currency-inner-content	">
          {CurrencyDatatwo.map((val, index) => {
            return (
              <div key={index}>
                <h2 className=" mt-8 font-bold text-xl">{val.header}</h2>
                <p className=" mb-2  w-full text-base text-body-color dark:text-dark-6	">{val.paragraph}</p>
                {val?.list?.map((val, index) => {
                  return (
                    <>
                      <h2 className="  font-bold text-lg" style={{ display: "inline" }} >{val?.heading}</h2>
                      <div className="Course-list flex justify-start w-[100%]">
                        <p className="Course-heading  w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-0.5" style={{ marginRight: "10px" }} /> </p>
                        <p className="course-paragraph mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">

                          {val?.descp} </p>
                      </div>
                    </>
                  )
                })}
              </div>
            );
          })}
        </div>
        <div className="w-[39%] currency-banner	">
          <SubscribeNow />
          <Qutation />
        </div>
      </div>
      <div className=' container mt-16'>
        <Title data={titledata} />
        <Forexmarketcard data={forexdata} />
        <Title data={titledataone} />
        <Forexmarketcard data={forexdataone} />
        <Title data={titledatatwo} />
        <Forexmarketcard data={forexdatatwo} />
      </div>
      <div className="">
        <Buttoncard data={buttondata} />
      </div>
    </div>
  )
}

export default CommodityMarket
