import React, {  useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { cancelSubscription, renewSubscription } from '../../api/signalsAPI';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { BsCalendar2Check } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignalCardDashboardComp = ({setLoading, signals, data }) => {
  const [showToast, setShowToast] = useState(false);
  console.log("signals", signals);
  const authUser = useAuthUser();
  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }
  const cancelHandler = async (id) => {
    setLoading(true)
    const CancelSubscription = await cancelSubscription(authUser?.token, id);
    if (CancelSubscription.status === 200) {
      // alert("subscription cancelled successfully")
      setShowToast(true); // Show toast when adding to cart
      toast.success("Subscription cancelled successfully", {
        position: "bottom-right",
        autoClose: 4800,
        });
        setTimeout(() => {
          setLoading(false);
        }, 5000)
    } else {
      setShowToast(true); // Show toast when adding to cart
      toast.error("Subscription  Already cancelled ", {
        position: "bottom-right",
        autoClose: 4800,});
        setTimeout(() => {
          setLoading(false);
        }, 5000)
    }
  };
  const submitHandler = async (id) => {
    const RenewSubscription = await renewSubscription(authUser?.token, id);
    if (RenewSubscription.status === 200) {
      alert(RenewSubscription?.data?.message)
    } else {
      alert("error")
    }
  }
  return (
    <>
      <div className=' gap-8 w-full m-auto pt-8   rounded-lg border pb-4'>
        {signals?.map((val, index) => {
          return (
            <>
              <div className="w-[80%] m-auto  bg-gray-100  rounded-lg p-6 mb-8 hover:shadow-lg">
                {/* First Section */}
                <div className=" w-[100%] flex justify-between items-center mb-4">
                  <div>
                    <h2 className="text-lg font-bold">{val?.SignalSubscription
                      ?.planName}</h2> {/* Header */}
                  </div>
                  <div>
                    {/* <h2 className="text-lg font-bold"> ${val?.SignalSubscription
                      ?.planPrice}</h2>  */}
                  </div>
                </div>
                <div >
                </div>
                <div className='flex flex-col w-[100%]'>
                  {Object.entries(val?.SignalSubscription?.planFeatures).map(([key, value], index) => {
                    return (
                      <div className="flex  flex-col  " key={index}>
                        {value > 0 && (
                          <div className="mr-4 flex items-center justify-start">
                            <FaCheckCircle color="green" className="mr-2 mt-1" /> {value} {/* Display the value */}
                          </div>
                        )}
                        <div>
                          {/* <p className="text-sm ml-4 text-gray-500" style={{ fontSize: '16px' }}>{value}</p>  */}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* Second Section */}
                <div>
                  {val?.SignalSubscription?.support === "Yes" ? <p className='flex items-center justify-start'> <FaCheckCircle color='green' className='mr-2 mt-1' /> Support</p> : <p className='flex items-center justify-start'> <IoMdCloseCircle color='red' className='mr-2' /> Support</p>}
                  {val?.SignalSubscription?.emailAlert === "Yes" ? <p className='flex items-center justify-start'> <FaCheckCircle color='green' className='mr-2 mt-1' /> Email</p> : <p className='flex items-center justify-start'> <IoMdCloseCircle color='red' className='mr-2' /> Email</p>}
                  {val?.SignalSubscription?.whatsappAlert === "Yes" ? <p className='flex items-center justify-start'> <FaCheckCircle color='green' className='mr-2 mt-1' />WhatsappAlert</p> : <p className='flex items-center justify-start'> <IoMdCloseCircle color='red' className='mr-2' /> WhatsappAlert</p>}
                  {val?.SignalSubscription?.dashboardSignal === "Yes" ? <p className='flex items-center justify-start'> <FaCheckCircle color='green' className='mr-2 mt-1' />Dashboard Signal</p> : <p className='flex items-center justify-start'> <IoMdCloseCircle color='red' className='mr-2' /> Dashboard Signal</p>}
                </div>
                {/* Third Section */}
              </div>
              <div className='border-t w-[90%] m-auto'>
                <div className='flex gap-5 justify-center pt-2'>
                  <div className='flex mb-2'>
                    <p className='text-gray-500 flex gap-2'><BsCalendar2Check size={15} className='text-gray-500 mt-1' /> Activation Date :                  </p>
                    <p className='pl-1'>{formatDate(val?.startDate)}</p>
                  </div>
                  <div className='flex '>
                    <p className='text-red-500 flex gap-2'><BsCalendar2Check size={15} className='text-red-500 mt-1' /> Expiration date :                </p>
                    {val?.endDate !== null &&
                      <p className='pl-1 text-red-500'> {formatDate(val?.endDate)}</p>}
                  </div>
                </div>
                <div className=" w-full flex  justify-center items-center gap-5 mt-4">
                  <button onClick={() => cancelHandler(val.subscriptionId)} className="flex  bg-red-600 w-[200px] hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg mr-2">
                    Cancel Subscription{/* First Button */}
                  </button>
                  <button disabled={new Date(val.endDate) > new Date()} onClick={() => submitHandler(val.subscriptionId)} className="flex   w-[200px]  hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg " style={{ background: new Date(val.endDate) > new Date() ? "gray" : "#16A34A" }}>
                    Renew Subscription {/* Second Button */}
                  </button>
                </div>
              </div>
              <div>
              </div>
            </>
          )
        })}
      </div>
      <ToastContainer />
    </>
  )
}

export default SignalCardDashboardComp
