import React, { useEffect, useState } from 'react';
import Testimonials from '../Testominal/Testominal';

import { useLocation, useParams } from 'react-router-dom';
import AccordionComp from './AccordionComp';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import { fetchModuleByModuleId, fetchModuleProgress, fetchPurchasedModule, fetchUserBuyCourseInfo } from '../../api/CourseApi';
import HeroMyModule from './HeroMyModule';
import HeroMyPurchased from './HeroMyPurchased';

const PurchasedModuleDetails = () => {
    const authUser = useAuthUser();
    const [moduleInfo,setModuleInfo]= useState();

    const { slug } = useParams();
    const [totalModuleItemsCount, setTotalModuleItemsCount] = useState();
  
    const location = useLocation();

    const id = location?.state?.id;
  
   
    
    const getPurchasedModule=async()=>{
        const resp = await fetchPurchasedModule(authUser?.token,id);
        console.log("required data",resp)
        setModuleInfo(resp.data)
      };
  
    useEffect(() => {
        getPurchasedModule();

    }, []);
  
  
  
  
    const titledata = [
      { subheader: "Testimonials" }
    ];
  
    console.log("moduleInfo",moduleInfo)
  return (
    <div className="bg-white mt-[5%]">
      <div className='bg-[#090E34] p-5'>
        <HeroMyPurchased data={moduleInfo} />
      </div>
      <div className='w-[100%]'>
        <AccordionComp id={id} setTotalModuleItemsCount={setTotalModuleItemsCount} isPurchased={moduleInfo?.purchased} moduleId={id} />
      </div>
      <div className='w-[100%]'>
        <Testimonials data={titledata} />
      </div>
      <div className='absolute top-[190px] right-[3%] z-20'>
        
      </div>
    </div>
  )
}

export default PurchasedModuleDetails