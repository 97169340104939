import React, { useEffect,  useState } from 'react'
import { FaCheckCircle } from "react-icons/fa";
import SubscribeNow from "./SubscribeNowComp/SubscribeNow";
import TradingViewWidget from './ForexMarketPages/StockWibget';
import Buttoncard from './HomePageSimpleCard/Buttoncard';
import "../Components/ForexMarketPages/CurrencyParis.css"
import EconomicCalenderWidget from './WidgetComp/EconomicCalenderwidget';
import SymbolWidget from './WidgetComp/SymbolWidget';
import { FaSpinner } from "react-icons/fa";
import Title from './TitleComp/Title';
import {  BsGraphUpArrow } from "react-icons/bs";
import HomePageCard from './HomePageSimpleCard/HomePageCard';
import MarketTitle from './TitleComp/MarketTitle';
import { VscGraph } from "react-icons/vsc";
import { TbZoomMoneyFilled } from "react-icons/tb";
import { SiSimpleanalytics } from "react-icons/si";
import { LiaIndustrySolid } from "react-icons/lia";
import { SiGooglecontaineroptimizedos } from "react-icons/si";
import { LuPackageSearch } from "react-icons/lu";
import { AiOutlineDollarCircle, AiOutlineSecurityScan } from "react-icons/ai";
import Accordian from './AccordianComp/Accordian';

const StockMarket = () => {
  window.scroll(0, 0)

  
  const CurrencyData = [

    {
      header: "Earnings Reports",
      text: " Analysis of quarterly and annual earnings releases, revenue growth, profit margins, and future guidance from major companies."
    },
    {
      header: "Economic Indicators",
      text: "Key metrics such as GDP growth, unemployment rates, inflation data, and consumer spending trends."
    },
    {
      header: "Mergers and Acquisitions",
      text: "Insights into corporate mergers, acquisitions, and strategic partnerships that can impact stock valuations."
    },
    {
      header: "Regulatory Changes ",
      text: "Updates on new regulations, tax policies, and government actions affecting industries and companies."
    },
    {
      header: "Global Events ",
      text: "Coverage of geopolitical events, trade agreements, and international developments that can cause market volatility."
    },


  ];
  const CurrencyDataone = [
    {
      title: "Major Stock Indices: ",
      paragraph: "The stock market is often measured by key indices that track the performance of specific groups of stocks. Understanding these indices helps investors gauge market trends and make informed decisions."

    },
    {
      header: `S&P 500:`,
      list: [
        {
          descp: ` The S&P 500 is a broad index that tracks 500 of the largest publicly traded companies in the US. It is widely regarded as a benchmark for the overall health of the US stock market. Major sectors include technology, healthcare, financials, and consumer discretionary.`,
        },
      ],
    },
    {
      header: `Dow Jones Industrial Average:`,
      list: [
        {
          descp: `The Dow Jones Industrial Average (DJIA) consists of 30 significant US companies across various industries. It is one of the oldest and most recognized stock indices, providing insights into the performance of major industrial and financial companies.`,
        },
      ],
    },
    {
      header: `NASDAQ Composite:`,
      list: [
        {
          descp: `The NASDAQ Composite Index includes over 3,000 companies listed on the NASDAQ stock exchange, with a strong emphasis on technology and biotechnology firms. It is a key indicator of the tech sector’s performance and innovation trends.`,
        },
      ],
    },
    {
      header: `FTSE 100:`,
      list: [
        {
          descp: `The FTSE 100 Index represents the 100 largest companies listed on the London Stock Exchange. It is a measure of the UK stock market's health and is influenced by global economic conditions, given the international nature of many listed companies.`,
        },
      ],
    },
    {
      title: "Analysis and Forecasts: ",
      paragraph: " Our expert analysts provide in-depth analysis and forecasts to help you navigate the stock market. Whether you are a long-term investor or a short-term trader, our insights will help you make informed decisions."

    },

  ];
  const buttondata = [
    { title: "Explore Stock Insights", descp: "", button: "SIGN UP" }
  ]

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false); // Set loader to false after some time (simulating loading completion)
    }, 2000); // Adjust timeout as per your needs
  }, []);
  const Homedata = [

    {
      icon: <VscGraph size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Price Charts and Patterns", description: "Study historical price movements, identify trends, and recognize chart patterns such as head and shoulders, double tops, and triangles."
    },
    {
      icon: <BsGraphUpArrow size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Technical Indicators", description: "Use indicators like moving averages, Bollinger Bands, Relative Strength Index (RSI), and MACD to identify potential entry and exit points."
    },
    {
      icon: <SiSimpleanalytics size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px", }} />,
      heading: " Volume Analysis ", description: "Analyze trading volume to confirm price trends and gauge market strength or weakness"
      // descp2: `By following these forex trading tips and investing time in learning, you'll be better equipped to navigate the forex market and achieve success as a trader. The forex market offers numerous opportunities for those willing to invest the time and effort to understand its complexities. With dedication, practice, and continuous learning, you can develop the skills needed to become a successful forex trader.`,
    },

  ]
  const titledataone = [
    { header: "Technical Analysis" }
  ]
  const Homedatatwo = [

    {
      icon: <TbZoomMoneyFilled size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Financial Statements", description: "Evaluate company performance through income statements, balance sheets, and cash flow statements. Focus on metrics like earnings per share (EPS), price-to-earnings (P/E) ratio, and return on equity (ROE)."
    },
    {
      icon: <LiaIndustrySolid size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Industry Analysis", description: "Understand the competitive landscape, market share, and growth prospects of different industries."
    },
    {
      icon: <SiGooglecontaineroptimizedos size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px", }} />,
      heading: " Economic Moats ", description: "Identify companies with sustainable competitive advantages that can protect their profits over the long term."
      // descp2: `By following these forex trading tips and investing time in learning, you'll be better equipped to navigate the forex market and achieve success as a trader. The forex market offers numerous opportunities for those willing to invest the time and effort to understand its complexities. With dedication, practice, and continuous learning, you can develop the skills needed to become a successful forex trader.`,
    },

  ]
  const titledatatwo = [
    { header: "Fundamental Analysis" }
  ]
  const Homedatathree = [

    {
      icon: <LuPackageSearch size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Market Outlook", description: "Receive weekly forecasts that highlight key events, economic reports, and market trends to watch for the upcoming week."
    },
    {
      icon: <AiOutlineDollarCircle size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px" }} />,
      heading: "Investment Strategies", description: "Learn about different investment strategies such as value investing, growth investing, dividend investing, and momentum trading."
    },
    {
      icon: <AiOutlineSecurityScan size={30} color="white" className="flex justify-center" style={{ marginLeft: "10px", }} />,
      heading: " Risk Management ", description: "Discover techniques to manage risk, diversify your portfolio, and protect your investments during market downturns"
      // descp2: `By following these forex trading tips and investing time in learning, you'll be better equipped to navigate the forex market and achieve success as a trader. The forex market offers numerous opportunities for those willing to invest the time and effort to understand its complexities. With dedication, practice, and continuous learning, you can develop the skills needed to become a successful forex trader.`,
    },

  ]
  const titledatathree = [
    { header: "Weekly Forecasts and Strategic Insights" }
  ]
  const markettitledataone = [
    { header: "Overview", descp: "The stock market is a crucial component of the global economy where shares of publicly listed companies are bought and sold. It serves as a platform for companies to raise capital and for investors to buy ownership stakes in businesses. The stock market is a barometer of economic health, reflecting investor sentiment and expectations about future growth. It offers opportunities for growth and income through dividends and capital appreciation." }
  ]
  const titledata =[
    { header: "Latest News and Trends", descp: "Stay ahead with the latest developments in the stock market. Our real-time updates cover a wide range of factors that influence stock prices, including." }

  ]
  return (
    <div className='mt-[140px]'>
      <div className=" container mx-auto mt-[10%]  relative ">
        
        <div className=" container h-[50vh] mt-4 currency-widget">

          {loader ? (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
              <FaSpinner className="animate-spin text-white" size={40} />
            </div>
          ) : (
            <TradingViewWidget />
          )
          }
        </div>
      </div>
      <div className='w-4/5 mx-auto mt-24' >
        



        <div className="container mx-auto mb-4 ">
          <MarketTitle data={markettitledataone} />

        </div>

        <div className="container mx-auto gap-[2%] flex justify-between stock-market">
          <div className="w-[59%] currency-inner-content	">

            {CurrencyDataone.map((val, index) => {
              return (
                <div key={index}>
                  <h2 className=" mt-8 font-bold text-xl">{val.title}</h2>
                  <h2 className=" mt-4 font-bold text-xl">{val.header}</h2>
                  <p className="mb-2  w-full text-base text-body-color dark:text-dark-6	">{val.paragraph}</p>
                  {val?.list?.map((val, index) => {
                    return (
                      <>
                    <div className="Course-list flex justify-start w-[100%]"> 
                   <p className="Course-heading w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center" style={{ marginRight: "10px" }} /> </p> 
                    <p className="course-paragraph mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	"> 
                    
                    {val?.descp} </p>
                    </div>
                    </>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <div className="w-[39%]  currency-banner	">
            <SubscribeNow />

          </div>
        </div>

        <div className="container mx-auto gap-[2%] flex justify- mt-4">
          <div className="w-[59%] currency-inner-content	">





            <div className="w-[100%] 	">
              <Title data={titledata}/>
              <Accordian data={CurrencyData} />

            </div>

          </div>

          <div className="w-2/5 currency-banner	">
            {/* <Qutation /> */}

            <h2 className=" banner-widget mt-4 mb-4 ">
              <SymbolWidget />
            </h2>

            <div className=" banner-widget	">
              <EconomicCalenderWidget />
            </div>


          </div>
        </div>
        <div className="container mx-auto flex  mt-4">
          <div className="w-[100%] currency-inner-content	">


            <Title data={titledataone} />
            <HomePageCard data={Homedata} />
            <Title data={titledatatwo} />
            <HomePageCard data={Homedatatwo} />
            <Title data={titledatathree} />
            <HomePageCard data={Homedatathree} />
          </div>


        </div>
        <div className="">
          <Buttoncard data={buttondata} />
        </div>


      </div>
    </div>
  )
}

export default StockMarket
