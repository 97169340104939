import React from 'react'
import Commbodiya from '../assets/header.png'
import market from "../assets/market.jpg";
import SubscribeNow from './SubscribeNowComp/SubscribeNow';
import Qutation from './QutationComp/Qutation';
import Buttoncard from './HomePageSimpleCard/Buttoncard';
import "../Components/ForexMarketPages/CurrencyParis.css"
import Title from './TitleComp/Title';
import Forexmarketcard from './ForexmarketCardComp/ForexMarketCard';
import MarketTitle from './TitleComp/MarketTitle';
import { FaCheckCircle } from 'react-icons/fa';


const CryptoMarket = () => {
  window.scroll(0, 0)
  const data = [
    {
      header: "Overview:",
      descp: ` The cryptocurrency market represents a revolutionary shift in the financial landscape, offering decentralized digital assets powered by blockchain technology. Cryptocurrencies are digital or virtual currencies that use cryptography for security and operate independently of a central bank. The market is known for its high volatility and potential for substantial returns, attracting a diverse range of participants, from institutional investors to individual traders.`,

    },

  ]
  const CurrencyDatatwo = [
    {
      header: `Latest News and Trends:`,
      paragraph:
        " Stay ahead with real-time updates on the rapidly evolving crypto market. Our news section covers: ",

      list: [{
        heading: "Regulatory Developments: ",
        descp: "Insights into global regulatory changes, government policies, and their impact on the crypto market."
      },
      {
        heading: "Technological Innovations: ",
        descp: "Updates on advancements in blockchain technology, new cryptocurrencies, and decentralized applications (DApps)."
      },
      {
        heading: "Market Sentiment:  ",
        descp: "Analysis of market sentiment through social media trends, investor behavior, and trading volumes."
      },
      {
        heading: "Major Events:   ",
        descp: "Coverage of significant events such as network upgrades, hard forks, partnerships, and announcements from major companies and projects."
      },
      ]
    },
    {
      header: `Key Cryptocurrencies:`,
    },
    {
      header: `Bitcoin (BTC):`,
      list: [{
        heading: "Overview: ",
        descp: "Bitcoin is the first and most widely recognized cryptocurrency, created by an anonymous entity known as Satoshi Nakamoto. It is often referred to as digital gold due to its store of value properties."
      },
      {
        heading: "Market Dynamics: ",
        descp: "Bitcoin’s price is influenced by factors such as supply and demand, investor sentiment, regulatory news, and macroeconomic trends."
      },
      {
        heading: "Investment Insight:  ",
        descp: "Learn about different ways to invest in Bitcoin, including buying directly through exchanges, investing in Bitcoin ETFs, and using Bitcoin wallets."
      },
      ]
    },
    {
      header: `Ethereum (ETH):`,
      list: [{
        heading: "Overview: ",
        descp: "Ethereum is a leading smart contract platform that enables developers to build decentralized applications (DApps). Its native currency, Ether, is used to power the network."
      },
      {
        heading: "Market Dynamics: ",
        descp: "Ethereum’s price is affected by network upgrades (such as Ethereum 2.0), the growth of decentralized finance (DeFi), and the overall adoption of smart contracts.."
      },
      {
        heading: "Investment Insight:  ",
        descp: "Explore investment options in Ethereum, including buying Ether on exchanges, staking Ether for rewards, and participating in DeFi protocols."
      },
      ]
    },
    {
      header: `Ripple (XRP):`,
      list: [{
        heading: "Overview: ",
        descp: " Ripple focuses on real-time global payments and aims to provide faster, more affordable cross-border transactions. XRP is the digital currency used within the Ripple network."
      },
      {
        heading: "Market Dynamics: ",
        descp: "Bitcoin’s price is influenced by factors such as supply and demand, investor sentiment, regulatory news, and macroeconomic trends."
      },
      {
        heading: "Investment Insight:  ",
        descp: "Understand how to invest in XRP, including buying on exchanges, using XRP for cross-border payments, and following Ripple’s corporate developments."
      },
      ]
    },







  ]
  const buttondata = [
    { title: "Get Crypto Updates", descp: "", button: "SIGN UP" }
  ]

  const titledata = [
    { header: "Litecoin (LTC)" }
  ]
  const forexdata = [
    {
      heading: "Overview: ",
      description: "Often referred to as the silver to Bitcoin’s gold, Litecoin offers faster transaction times and a different hashing algorithm. It was created by Charlie Lee to be a lighter version of Bitcoin."
    },
    {
      heading: "Market Dynamics: ",
      description: "Litecoin’s price is driven by technological developments, adoption rates, and market sentiment."
    },
    {
      heading: "Investment Insight:  ",
      description: "Discover investment opportunities in Litecoin, including buying LTC on exchanges and using it for everyday transactions."
    },



  ]
  const titledataone = [
    { header: "Stablecoins" }
  ]
  const forexdataone = [
    {
      heading: "Overview: ",
      description: "Stablecoins are cryptocurrencies designed to minimize price volatility by being pegged to a stable asset, such as the US dollar. They provide stability in the volatile crypto market."
    },
    {
      heading: "Popular Stablecoins: ",
      description: "Examples include Tether (USDT), USD Coin (USDC), and Dai (DAI)."
    },
    {
      heading: "Investment Insight:  ",
      description: "Learn how to use stablecoins for trading, earning interest through DeFi platforms, and hedging against market volatility."
    },


  ]
  const titledatatwo = [
    { header: "Analysis and Forecasts:", descp: "Our expert analysts provide in-depth analysis and forecasts to help you navigate the crypto market. Gain insights into market trends, price movements, and investment strategies." }
  ]
  const titledatatwoone = [
    { header: "Technical Analysis" }
  ]
  const forexdatatwo = [
    {
      heading: "Price Charts and Patterns: ",
      description: "Study historical price movements, identify trends, and recognize chart patterns such as head and shoulders, triangles, and flags."
    },
    {
      heading: " Technical Indicators: ",
      description: "Use indicators like moving averages, Bollinger Bands, RSI, and MACD to identify potential entry and exit points."
    },
    {
      heading: " Volume Analysis:  ",
      description: "Analyze trading volumes to confirm price trends and gauge market strength or weakness."
    },

  ]

  const titledatathree = [
    { header: "Fundamental Analysis" }
  ]
  const forexdatathree = [
    {
      heading: "On-Chain Metrics: ",
      description: "Examine on-chain data such as transaction volume, active addresses, hash rate, and network activity to assess the health of a cryptocurrency."
    },
    {
      heading: " Project Developments: ",
      description: "Stay informed about the latest developments, partnerships, and upgrades in major cryptocurrency projects."
    },
    {
      heading: "Market Sentiment:  ",
      description: "Gauge market sentiment through social media analysis, news coverage, and investor behavior."
    },

  ]
  const titledatafour = [
    { header: "Weekly Forecasts and Strategic Insights" }
  ]
  const forexdatafour = [
    {
      heading: "Market Outlook: ",
      description: "Receive weekly forecasts highlighting key events, economic reports, and market trends to watch for the upcoming week."
    },
    {
      heading: " Investment Strategies: ",
      description: "Learn about various investment strategies such as HODLing, swing trading, and yield farming."
    },
    {
      heading: "Risk Management:  ",
      description: "Discover methods to manage risk, including diversification, stop-loss orders, and position sizing."
    },
  ]
  return (
    <div className='mt-[140px]'>
      <div className=" container mx-auto mt-24  relative ">
        <div className="relative bg-primary">
          <img
            src={Commbodiya}
            alt="Paris Financial District"
            className="w-full h-auto currencyparis-image"
            style={{ height: "75vh" }}
          />

        </div>
      </div>
      <div className=''>


        <MarketTitle data={data} />




      </div>
      <div className="container mx-auto gap-[2%] flex justify-between">
        <div className="w-[59%] currency-inner-content	">


          {CurrencyDatatwo.map((val, index) => {
            return (
              <div key={index}>
                <h2 className=" mt-8 font-bold text-xl">{val.header}</h2>
                <p className="text-lg mb-2  w-full	text-base text-body-color dark:text-dark-6">{val.paragraph}</p>
                {val?.list?.map((val, index) => {
                  return (
                    <>
                      <h2 className="  font-bold text-lg" style={{ display: "inline" }} >{val?.heading}</h2>
                      <div className="flex justify-start w-[100%]">
                        <p className="w-[5%]"> <FaCheckCircle size={20} color="black" className="flex justify-center mt-0.5" style={{ marginRight: "10px" }} /> </p>
                        <p className=" mb-2  w-[95%] text-base text-body-color dark:text-dark-6 flex justify-between	">

                          {val?.descp} </p>
                      </div>
                    </>

                  )
                })}
              </div>
            );
          })}



        </div>


        <div className="w-[39%] currency-banner	">

          <SubscribeNow />
          <Qutation />
          <div className="relative rounded overflow-hidden">
            <img src={market} className="rounded" />
            <div className="absolute inset-0 bg-white opacity-50 rounded ">
              <span className=" flex justify-center text-4xl font-bold text-center mx-auto my-32	">
                Banner Add
              </span>
            </div>
          </div>
        </div>

      </div>
      <div className=' container'>
        <Title data={titledata} />
        <Forexmarketcard data={forexdata} />
        <Title data={titledataone} />
        <Forexmarketcard data={forexdataone} />
        <Title data={titledatatwo} />
        <Title data={titledatatwoone} />
        <Forexmarketcard data={forexdatatwo} />
        <Title data={titledatathree} />
        <Forexmarketcard data={forexdatathree} />
        <Title data={titledatafour} />
        <Forexmarketcard data={forexdatafour} />
      </div>
      <div className="">
        <Buttoncard data={buttondata} />

      </div>
    </div>
  )
}

export default CryptoMarket
