import React, { useState } from 'react';
import Logo from '../../assets/blog/blog-details-01.jpg';
import { FaUser, FaTachometerAlt,FaBookOpen } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const SubscribedSubscriptionCard = ({data}) => {
    const [activeTab, setActiveTab] = useState('profile');
    const authUser = useAuthUser()

  return (
    <div className='bg-white w-[400px] shadow-lg ' >
        <img className='w-full h-[200px] relative' src="https://placehold.co/600x400"/>
        {/* <h1 className='text-white font-bold absolute bottom-[4%] left-[30%]'>Preview This Course</h1> */}
        <div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px ">
          <li className="  w-[50%]">
            <a
              href="#"
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === 'profile'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('profile')}
            >
              <FaBookOpen className="inline-block mr-2" /> Course
            </a>
          </li>


{!authUser &&(
          <li className=" w-[50%]">
            <a
              href="#"
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === 'dashboard'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('dashboard')}
            >
              <FaTachometerAlt className="inline-block mr-2" /> Dashboard
            </a>
          </li>
        )}
{authUser &&(
          <li className=" w-[50%]">
            <NavLink
              to="/dashboard"
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === 'dashboard'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('dashboard')}
            >
              <FaTachometerAlt className="inline-block mr-2" /> Dashboard
            </NavLink>
          </li>
        )}

        </ul>
      </div>
      <div className="p-4">
        {activeTab === 'profile' && (
          <div>
            <h1 className='font-bold text-2xl'>{data?.title}</h1>
            <p className='mt-4'>{data?.description}</p>
            <button className='bg-blue-600 w-[100%] h-[40px] mt-4 text-white'><NavLink  to='/coursesubscriptionplan'>View Subscription Plan</NavLink></button>
            <p className='text-center text-gray-700 mt-4 text-sm'>Try now</p>
            <p className='text-center text-gray-700 mt-2 text-sm'>Cancel anytime</p>
            <div className='flex items-center justify-center mt-3'>
                <div className='bg-gray-600 h-[1px] w-[30%] mr-4'></div>
                <p className='mr-4 text-sm text-gray-600'>or</p>
                <div className='bg-gray-600 h-[1px] w-[30%]'></div>
            </div>
         
            <h1 className='font-bold text-2xl text-center'> $ {data?.price}</h1>
            <button className='border border-gray-400 w-[100%] h-[40px] mt-4 text-black font-bold'><NavLink to='/school-of-investors-zon'>Buy Now</NavLink> </button>
            
          </div>
        )}
        
        {activeTab === 'dashboard' && (
          !authUser &&
          <div>
            <NavLink to="/signin">
              <button className='bg-blue-600 w-[100%] h-[40px] mt-4 text-white' >Login</button>
              </NavLink>
          </div>
        )}
      </div>
    </div>
    </div>
  )
}

export default SubscribedSubscriptionCard
