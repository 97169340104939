import React from "react";
import Feature from "../Feature/Feature";

const Ourservices = () => {
  window.scroll(0,0)
  return (
    <>
    
      <Feature />
    </>
  );
};

export default Ourservices;
