import React from 'react'
import "./Featurecard.css"
const Featurecard = ({ data }) => {
  return (
    <div className='w-full flex flex-wrap main-feature-card '>
      {data?.map((val, index) => {
        return (
          <div className="w-[25%] main-feature  ">
            <div className="w-full  px-4 md:w-1/2 lg:w-full innerMain ">
              <div className="main-height wow fadeInUp group h-[500px]   border border-[#eaeff5] rounded-[20px] p-4 transition duration-300  hover:shadow-bottom" data-wow-delay=".1s">
                <div
                  className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-primary"
                >
                  <span
                    className="absolute left-0 top-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                  ></span>
                  <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {val.icon}
                  </svg>
                </div>
                <h4 className="mb-3 text-xl font-bold text-dark dark:text-white">
                  {val.title}
                  {/* Expert Analysis */}
                </h4>
                <p className="mb-8 text-body-color dark:text-dark-6 lg:mb-9 text-justify ">
                  {val.descp}
                  {/* Gain access to insights and strategies from seasoned financial experts, helping you make informed investment decisions. */}
                </p>

              </div>
            </div>

          </div>
        )
      })}

    </div>
  )
}

export default Featurecard
