import React, { useEffect, useState } from 'react';
import Testimonials from '../Testominal/Testominal';
import { useLocation, useParams } from 'react-router-dom';
import AccordionComp from './AccordionComp';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import { fetchModuleByModuleId,fetchPublicModuleByModuleId, fetchPurchasedModule, fetchUserBuyCourseInfo } from '../../api/CourseApi';
import SubscriptionModulecard from './SubscriptionModuleCard';
import HeroMyModule from './HeroMyModule';

const SingleModuleDetails = () => {
  const authUser = useAuthUser();
  const [isPurchased, setIsPurchased] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const { title } = useParams();
  const location = useLocation();
  const {state} = location;
  const [moduleHeaderInfo, setModuleHeaderList] = useState({});
  const [totalModuleItemsCount, setTotalModuleItemsCount] = useState();
  const titledata = [
    { subheader: "Testimonials" }
  ];
  const fetchModule = async () => {
    const resp = await fetchModuleByModuleId(authUser?.token,title);
    setModuleHeaderList(resp);
  };

  const fetchPublicModule = async () => {
    const resp = await fetchPublicModuleByModuleId(title);
    setModuleHeaderList(resp);
  };
  const fetchUserPurchaseInfo = async () => {
    try {
      const resp = await fetchUserBuyCourseInfo(authUser?.token, moduleHeaderInfo?.courseId);
      setIsPurchased(resp.isPurchased);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading to false once fetching is complete
    }
  };
  useEffect(() => {
    if(authUser){
    fetchModule();
    }if(!authUser){
      fetchPublicModule()
    }
  }, [title]);
  useEffect(() => {
    if (moduleHeaderInfo?.id && authUser?.token) {
      fetchUserPurchaseInfo();
    }
  }, [moduleHeaderInfo]);
  return (
    <div className="bg-white mt-[3.9%]">
      <div className='bg-[#090E34] p-5'>
        <HeroMyModule data={moduleHeaderInfo} isPurchased={isPurchased}  />
      </div>
      <div className='w-[100%]'>
        <AccordionComp id={state?.id} setTotalModuleItemsCount={setTotalModuleItemsCount} isPurchased={isPurchased} moduleId={title} info={moduleHeaderInfo} />
      </div>
      <div className='w-[100%]'>
        <Testimonials data={titledata} />
      </div>
      <div className='absolute top-[190px] right-[3%] z-20 '>
        {!loading && !isPurchased && <SubscriptionModulecard data={moduleHeaderInfo} />}
        {authUser === null && <SubscriptionModulecard data={moduleHeaderInfo} />}
      </div>
    </div>
  );
}

export default SingleModuleDetails;
