import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LuUserCheck2 } from "react-icons/lu";
import { GoQuestion } from "react-icons/go";
import { GoSignOut } from "react-icons/go";
import { FaCartPlus } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";
import { MdOutlineExplore } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { CartContext } from "../../../context/CartContext";
import { LuBook } from "react-icons/lu";
import { BsGraphUp } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
import "./Header.css";
import { getSignalNotifications } from "../../../api/CourseApi";
import { GrCertificate } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaAngleRight, FaAngleDown } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import "../../Nav/OldPNavbar/Header.css";
import investiologoo from "../../../assets/Headerlogo.png";
import { MdOutlineSegment } from "react-icons/md";
import { URL, WSURL } from "../../../config";

const Header = () => {
  const { cartCount, clearCart } = useContext(CartContext);
  const [dropdown, setDropdown] = useState(null);
  const [notifications, setNotifications] = useState();
  const [currentNotificationCount, setCurrentNotificationCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [lastInsertedId, setLastInsertedId] = useState();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const socketRef = React.useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [newSignalNotifications, setNewSignalNotifications] = useState();
  const [connected, setConnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [socket, setSocket] = useState(null);



  let timeoutId;
  const signOut = useSignOut();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const cartItems = cartCount();
  const dropdownRef = useRef(null);

  
  const reconnectInterval = 5000; // Reconnect every 5 seconds

  const state = { name: "My Signals" };
  const mobList = [
    {
      title: "Market",
      content: [
        {
          title: [
            { val: " Market", link: "/market" },
            { val: "Forex Market", link: "/forex-market" },
            { val: "Stock Market", link: "/stock-market" },
            { val: "Mutual Funds", link: "/mutual-fund" },
            { val: "Commodity Market", link: "/commodity-market" },
          ],
        },
      ],
    },
    {
      title: "Courses",
      content: [
        {
          title: [
            // { val: "STANDARD Account", link: "/trading/stdaccount" },
            { val: "Courses", link: "/school-of-investors-zon" },
            {
              val: "Course Subscription Plan",
              link: "/course-subscription-plan",
            },
            { val: "My Certificate ", link: "/my-certificates" },
          ],
        },
      ],
    },
    {
      title: "Signals",
      content: [
        {
          title: [
            { val: "Trading Signals", link: "/signals" },
            {
              val: "Free Crypto Forex & Stocks Signals",
              link: "/free-paid-crypto-signals",
            },
            { val: "Subscriptions Plan", link: "/signals-subscription" },
            { val: "Signals Blogs and Videos", link: "/signal-blog-video" },
            { val: "Signals Announcements", link: "/signal-annoucements" },
          ],
        },
      ],
    },
    {
      title: " Tools",
      content: [
        {
          title: [
            { val: "Tools", link: "/tools" },
            { val: "Market Insight", link: "/market-insight" },
            { val: "Econimic Calender", link: "/economic-calendar" },
            { val: "News And Analysis", link: "/news-and-analysis" },
            { val: "Trading Calculators", link: "/trading-calculator" },
            { val: "Market Heatmap", link: "/market-heatmap" },
          ],
        },
      ],
    },
    {
      title: "Investment",
      content: [
        {
          title: [
            {
              val: "Fund Raising",
              link: "https://investment.investorszon.com/",
            },
            {
              val: " Equity Investment",
              link: "https://investment.investorszon.com/",
            },
            { val: "Donate", link: "https://investment.investorszon.com/" },
            // { val: "Portfolio Management", link: "/portfolio-management" },
            // { val: "Withdrawal Options", link: "/withdrawal-options" },
          ],
        },
      ],
    },
    {
      title: "About",
      content: [
        {
          title: [
            { val: " Investors Zon", link: "/about-us" },
            { val: "About Investors Zon", link: "/about-investorszon" },
            { val: "Founders Investors Zon", link: "/founders-investorszon" },
            { val: " Contact Us", link: "/contact-us" },
          ],
        },
      ],
    },
  ];

  const handleCartClick = () => {
    navigate("/cart");
  };

  const signOutAction = () => {
    signOut();
    clearCart();
    navigate("/signin");
  };

  // Function to toggle dropdown
  const handleToggleDropdown = (dropdownName) => {
    if (dropdown === dropdownName) {
      setDropdown(null); // Close dropdown if already open
    } else {
      setDropdown(dropdownName); // Open dropdown
    }
  };

  // Function to close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(null); // Close dropdown if clicked outside of it
    }
  };

  const handleMouseEnter = (menu) => {
    clearTimeout(timeoutId);
    setDropdown(menu);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setDropdown(null);
    }, 300); // delay to make it smoother
  };

  const renderCart = () => (
    <div
      className="relative"
      onMouseEnter={() => handleMouseEnter("cart")}
      onMouseLeave={handleMouseLeave}
    >
      <FaCartPlus
        size={30}
        color="black"
        className="cursor-pointer"
        onClick={handleCartClick}
      />
      {cartItems > 0 && ( // Show notification badge if cartItems > 0
        <span className="absolute top-[-10px] right-0 -mt-1 -mr-4 bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-1">
          {cartItems}
        </span>
      )}
    </div>
  );
  const handleCloseDropdown = () => {
    handleToggleDropdown(null);
  };

  const fetchSignalNotifications = async () => {
    const resp = await getSignalNotifications(authUser?.token);
    if (resp?.response?.status === 404) {
      setIsSubscribed(false);
      return;
    }
    if (resp?.status === 200) {
      // setCurrentNotificationCount();
      setIsSubscribed(true);
      // setNotifications(resp?.data?.notifications);
      // setLastInsertedId(resp?.data?.lastInsertedId);
      // if (localStorage.getItem("notificationCount") == null) {
      //   setCurrentNotificationCount(resp?.data?.notifications?.length);
      // } else {
      //   setCurrentNotificationCount(
      //     resp?.data?.lastInsertedId -
      //       Number(localStorage.getItem("notificationCount"))
      //   );
      // }
    }
  };

  const dropdownHandler = () => {
    // alert("working");
    localStorage.setItem("notificationCount", Number(lastInsertedId));
    setDropdown(null);
    setCurrentNotificationCount(0);
  };

  const hamburgerHander = () => {
    setActiveLink(null);
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index === activeLink ? null : index); // Toggle active link
  };

  useEffect(() => {
    if (authUser) {
      fetchSignalNotifications();
    }
  }, [authUser]);

  // Add click outside listener when dropdown is open
  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const now = new Date();
  const today = now.toLocaleDateString("en-US");
  const lastWeek = new Date(now);
  lastWeek.setDate(now.getDate() - 7);

  // Get notifications for the current date
  const currentNotifications = notifications?.filter(
    (notification) =>
      new Date(notification.createdAt).toLocaleDateString("en-US") === today
  );

  // Get notifications from the last 7 days, excluding the current date
  const lastWeekNotifications = notifications?.filter((notification) => {
    const notificationDate = new Date(notification.createdAt);
    return (
      notificationDate.toLocaleDateString("en-US") >
        lastWeek.toLocaleDateString("en-US") &&
      notificationDate.toLocaleDateString("en-US") < today
    );
  });

  // Get older notifications (beyond the last 7 days)
  const olderNotifications = notifications?.filter((notification) => {
    const notificationDate = new Date(notification?.createdAt);
    return (
      notificationDate.toLocaleDateString("en-US") <=
      lastWeek.toLocaleDateString("en-US")
    );
  });

  


  const connectWebSocket = () => {
    const newSocket = new WebSocket('wss://investorszon.com');

    newSocket.onopen = () => {
      console.log('Connected to WebSocket server');
      setConnected(true);
      setReconnectAttempts(0); // Reset reconnect attempts on successful connection
    };

    newSocket.addEventListener ("message", (event) => {
      const data = JSON.parse(event.data);
      console.log("notifications-----websocket",data)
      setCurrentNotificationCount(data?.totalCount);
            setNotifications(data?.record)
            setLastInsertedId(data?.lastEnterId?.id);
            if (localStorage.getItem("notificationCount") == null) {
                setCurrentNotificationCount(data?.totalCount);
              } else {
                setCurrentNotificationCount(
                  data?.lastEnterId?.id -
                    Number(localStorage.getItem("notificationCount"))
                );
              }
            if (data && data?.record) {
              // Check if data has a message property before adding it to the state
              setMessages((prevMessages) => [...prevMessages, data.message]);
            }
    });
  

    newSocket.onclose = () => {
      console.log('Disconnected from WebSocket server');
      setConnected(false);
      attemptReconnect(); // Attempt to reconnect when disconnected
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      newSocket.close(); // Close the socket to trigger the onclose event
    };

    setSocket(newSocket);
  };

  const attemptReconnect = () => {
    if (reconnectAttempts < 5) {
      setTimeout(() => {
        console.log(`Attempting to reconnect... (Attempt ${reconnectAttempts + 1})`);
        setReconnectAttempts((prev) => prev + 1);
        connectWebSocket();
      }, 3000); // Wait 3 seconds before attempting to reconnect
    } else {
      console.error('Max reconnect attempts reached. Please refresh the page to retry.');
    }
  };

  useEffect(() => {
    // Connect to WebSocket on component mount
    if(isSubscribed){
    connectWebSocket();

    // Clean up WebSocket connection on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };}
  }, [isSubscribed]);

  return (
    <>
      <div className="header-block">
        <header className="bg-white-700 shadow h-[8vh] ud-header absolute left-0 top-0 z-40 flex w-full items-center  sticky">
          <div className="w-full bg-white-700 sticky ">
            <div className="container mx-auto  flex items-center justify-between ">
              {/* Left Side - Links */}
              <div className="flex space-x-4 w-[25%] mt-4">
                <NavLink
                  target="_blank"
                  to="http://www.blokistaworld.com/"
                  className="text-gray-700 hover:text-gray-900 flex"
                >
                  {" "}
                  <MdOutlineExplore size={25} color="blue" className="pr-2" />
                  Discover
                </NavLink>
                <NavLink
                  target="_blank"
                  to="https://investment.investorszon.com/campaigns"
                  className="text-gray-700 hover:text-gray-900 flex"
                >
                  <MdCampaign size={25} color="blue" className="pr-2" />
                  Start A Campaign
                </NavLink>
              </div>

              {/* Center - Logo */}
              <div className="flex-grow flex justify-center w-[50%] m-auto mt-2">
                <Link to="/">
                  <img
                    src={investiologoo}
                    alt="Logo"
                    className=" Logo pt-2 h-[80px] w-[250px]"
                  />
                </Link>
              </div>

              {/* Right Side - Buttons */}
              <div className="flex space-x-4 w-[25%] justify-end main-button-div">
                {!authUser && (
                  <div className="hidden sm:flex items-center mt-4 justify-between">
                    {renderCart()}
                    <Link
                      to="/signin"
                      className="loginBtn px-[22px] py-5 text-sm font-medium   text-white hover:opacity-70"
                    >
                      LOGIN
                    </Link>

                    <Link
                      to="/signup"
                      class="signUpBtn rounded-md bg-white bg-opacity-20 px-6 py-2  text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-100 hover:text-dark"
                    >
                      SIGN UP
                    </Link>
                  </div>
                )}

                {authUser && (
                  <div className="flex items-center space-x-4 mt-4">
                    <div
                      className="relative"
                      onMouseEnter={() => handleMouseEnter("wishlist")}
                      onMouseLeave={handleMouseLeave}
                    ></div>
                    {renderCart()}
                    <div className="relative">
                      <div
                        className="cursor-pointer w-[40px] h-[40px] rounded-full  flex items-center justify-center"
                        onClick={() => handleToggleDropdown("notification")}
                      >
                        <IoIosNotificationsOutline
                          size={30}
                          color="black"
                          className="cursor-pointer"
                        />
                        {currentNotificationCount > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              right: -5,
                              top: -8,
                              background: "red",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "white",
                              width: "23px",
                              height: "23px",
                              borderRadius: "20px",
                              textAlign: "center",
                            }}
                          >
                            {currentNotificationCount > 99 ? (
                              <div
                                style={{
                                  position: "relative",
                                  marginTop: "1px",
                                }}
                              >
                                99
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "-10px", // Adjust as necessary to position the "+" sign
                                    right: "-7px", // Adjust as necessary to position the "+" sign
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  +
                                </span>
                              </div>
                            ) : (
                              currentNotificationCount
                            )}
                          </div>
                        )}
                      </div>
                      {dropdown === "notification" && (
                        <div
                          ref={dropdownRef}
                          className="absolute z-50 right-0 mt-[17px] w-[350px]   bg-white border border-gray-200 shadow-lg rounded-b-md px-4 py-4 max-h-[400px] overflow-y-scroll"
                        >
                          <h2 className="font-semibold text-lg">
                            Notifications
                          </h2>
                          {notifications?.length === 0 ? (
                            <div className="text-center text-gray-500">
                              No notifications
                            </div>
                          ) : (
                            <>
                              <div style={{ borderBottom: "1px solid black" }}>
                                <h2 style={{ marginTop: "5px" }}>
                                  Current Notifications
                                </h2>

                                {currentNotifications?.length === 0 ||
                                currentNotifications === undefined ? (
                                  <div className="text-center text-gray-500">
                                    No notifications
                                  </div>
                                ) : (
                                  currentNotifications?.map((val, index) => {
                                    const dateObj = new Date(val.createdAt);

                                    const formattedDate = `${String(
                                      dateObj.getDate()
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getMonth() + 1
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getFullYear()
                                    ).slice(2)} @${String(
                                      dateObj.getHours()
                                    ).padStart(2, "0")}:${String(
                                      dateObj.getMinutes()
                                    ).padStart(2, "0")}`;
                                    return (
                                      <NavLink
                                        key={index}
                                        to="#"
                                        state={state}
                                        onClick={(e) => dropdownHandler()}
                                        className="block  py-2 h-[40px] text-gray-800 relative "
                                        style={{
                                          fontSize: "15px",
                                          color: "gray",
                                        }}
                                      >
                                        <div className="truncate h-[30px]">
                                          {val.title}
                                          <span className="absolute bottom-1  right-0 bg-white text-xs text-gray-500  py-1">
                                            {formattedDate}
                                          </span>
                                        </div>
                                      </NavLink>
                                    );
                                  })
                                )}
                              </div>
                              <div style={{ borderBottom: "1px solid black" }}>
                                <h2 style={{ marginTop: "15px" }}>
                                  Last Week Notifications
                                </h2>
                                {lastWeekNotifications?.length === 0 ||
                                lastWeekNotifications === undefined ? (
                                  <div className="text-center text-gray-500">
                                    No notifications
                                  </div>
                                ) : (
                                  lastWeekNotifications?.map((val, index) => {
                                    const dateObj = new Date(val.createdAt);

                                    const formattedDate = `${String(
                                      dateObj.getDate()
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getMonth() + 1
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getFullYear()
                                    ).slice(2)} @${String(
                                      dateObj.getHours()
                                    ).padStart(2, "0")}:${String(
                                      dateObj.getMinutes()
                                    ).padStart(2, "0")}`;
                                    return (
                                      <NavLink
                                        key={index}
                                        to="/dashboard"
                                        state={state}
                                        onClick={() => dropdownHandler()}
                                        style={{
                                          fontSize: "15px",
                                          color: "gray",
                                        }}
                                        className="block  py-2 h-[40px] text-gray-800 relative "
                                      >
                                        <div className="truncate h-[30px]">
                                          {val.title}
                                          <span className="absolute bottom-1  right-0 bg-white text-xs text-gray-500  py-1">
                                            {formattedDate}
                                          </span>
                                        </div>
                                      </NavLink>
                                    );
                                  })
                                )}
                              </div>
                              <div>
                                <h2 style={{ marginTop: "15px" }}>
                                  Earlier Notifications
                                </h2>
                                {olderNotifications?.length === 0 ||
                                olderNotifications === undefined ? (
                                  <div className="text-center text-gray-500">
                                    No notifications
                                  </div>
                                ) : (
                                  olderNotifications?.map((val, index) => {
                                    const dateObj = new Date(val.createdAt);

                                    const formattedDate = `${String(
                                      dateObj.getDate()
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getMonth() + 1
                                    ).padStart(2, "0")}-${String(
                                      dateObj.getFullYear()
                                    ).slice(2)} @${String(
                                      dateObj.getHours()
                                    ).padStart(2, "0")}:${String(
                                      dateObj.getMinutes()
                                    ).padStart(2, "0")}`;
                                    return (
                                      <NavLink
                                        key={index}
                                        to="/dashboard"
                                        state={state}
                                        onClick={() => dropdownHandler()}
                                        className="block  py-2 h-[40px] text-gray-800 relative "
                                        style={{
                                          fontSize: "15px",
                                          color: "gray",
                                        }}
                                      >
                                        <div className="truncate h-[30px]">
                                          {val.title}
                                          <span className="absolute bottom-1  right-0 bg-white text-xs text-gray-500  py-1">
                                            {formattedDate}
                                          </span>
                                        </div>
                                      </NavLink>
                                    );
                                  })
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="relative">
                      <div
                        className="h-10 w-10 rounded-full bg-dark flex items-center justify-center cursor-pointer"
                        onClick={() => handleToggleDropdown("user")}
                      >
                        <p className="h-10 w-10 text-white text-center pt-2">
                          {authUser?.name
                            ?.split(" ")
                            .map((name) => name.charAt(0).toUpperCase())
                            .join("")}
                        </p>
                      </div>
                      {dropdown === "user" && (
                        <div
                          ref={dropdownRef}
                          className="absolute right-0 mt-[17px] w-72 bg-white border border-gray-200 shadow-lg rounded-b-md py-2"
                        >
                          <div className="flex items-center px-4 py-2">
                            {/* <img className="h-10 w-10 rounded-full" src="/path-to-profile-image.jpg" alt="Profile" /> */}
                            <div className="h-10 w-10 rounded-full bg-dark flex items-center justify-center">
                              <p className="h-10 w-10 text-white text-center pt-2">
                                {authUser?.name
                                  ?.split(" ")
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join("")}
                              </p>
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 font-semibold">
                                {authUser?.name}
                              </p>
                              <p className="text-gray-600 text-sm">
                                {authUser?.email}
                              </p>
                            </div>
                          </div>
                          <hr className="border-gray-200 my-1" />
                          <NavLink
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <LuUserCheck2 className="inline mr-2" />
                            Dashboard
                          </NavLink>
                          <NavLink
                            to="/my-courses"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <LuBook className="inline mr-2" />
                            My Courses
                          </NavLink>
                          <NavLink
                            to="my-subscribed-courses-list"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <LuBook className="inline mr-2" />
                            My Subscribed Courses
                          </NavLink>
                          <NavLink
                            to="/purchased-modules-list"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <LuBook className="inline mr-2" />
                            My Modules
                          </NavLink>
                          <NavLink
                            state={"My Certificates"}
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <GrCertificate className="inline mr-2" />
                            My Certificates
                          </NavLink>
                          <NavLink
                            state={"My Signals"}
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <BsGraphUp className="inline mr-2" />
                            My Signals
                          </NavLink>
                          <NavLink
                            state={"My Subscriptions"}
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <IoIosNotificationsOutline className="inline mr-2" />
                            My Subscriptions
                          </NavLink>
                          <NavLink
                            state={"OrderHistory"}
                            to="/dashboard"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <MdManageHistory className="inline mr-2" /> My
                            Transaction
                          </NavLink>
                          <NavLink
                            to="#"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                            onClick={handleCloseDropdown}
                          >
                            <GoQuestion className="inline mr-2" />
                            Help
                          </NavLink>
                          <hr className="border-gray-200 my-1" />
                          <button
                            onClick={() => {
                              signOutAction();
                              handleCloseDropdown();
                            }}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            <GoSignOut className="inline mr-2" /> Sign out
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className="new-nav-bar ">
        <div className=" image-div flex-grow flex justify-center w-[50%] m-auto mt-2">
          <Link to="/">
            <img
              src={investiologoo}
              alt="Logo"
              className=" image pt-2 h-[9vh] w-[100%]"
            />
          </Link>
        </div>
        <div className="mobile-ham">
          <div className="ham-wrapper" onClick={hamburgerHander}>
            {isOpen ? <RxCross2 size={30} /> : <MdOutlineSegment size={30} />}
          </div>
          {authUser && (
            <div className=" absolute top-0 right-12 flex items-center space-x-4 mt-4 justify-end ">
              <div
                className="relative"
                onMouseEnter={() => handleMouseEnter("wishlist")}
                onMouseLeave={handleMouseLeave}
              ></div>
              {renderCart()}
              {/* <div className="relative">
                <div
                  className="cursor-pointer w-[40px] h-[40px] rounded-full  flex items-center justify-center"
                  onClick={() => handleToggleDropdown("notification")}
                >
                  <IoIosNotificationsOutline
                    size={30}
                    color="black"
                    className="cursor-pointer"
                  />
                  {currentNotificationCount > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: -5,
                        top: -8,
                        background: "red",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "white",
                        width: "23px",
                        height: "23px",
                        borderRadius: "20px",
                        textAlign: "center",
                      }}
                    >
                      {currentNotificationCount > 99 ? (
                        <div style={{ position: "relative", marginTop: "1px" }}>
                          99
                          <span
                            style={{
                              position: "absolute",
                              top: "-10px", // Adjust as necessary to position the "+" sign
                              right: "-7px", // Adjust as necessary to position the "+" sign
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            +
                          </span>
                        </div>
                      ) : (
                        currentNotificationCount
                      )}
                    </div>
                  )}
                </div>
                {dropdown === "notification" && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-50 right-0 mt-[8px] w-[350px]   bg-white border border-gray-200 shadow-lg rounded-b-md px-4 py-4 max-w-[250px] max-h-[400px] overflow-y-scroll"
                  >
                    <h2 className="font-semibold text-lg">Notifications</h2>
                    {notifications === undefined ? (
                      <div className="text-center text-gray-500">
                        No notifications
                      </div>
                    ) : (
                      notifications?.map((val, index) => {
                        const dateObj = new Date(val.createdAt);

                        const formattedDate = `${String(
                          dateObj.getDate()
                        ).padStart(2, "0")}-${String(
                          dateObj.getMonth() + 1
                        ).padStart(2, "0")}-${String(
                          dateObj.getFullYear()
                        ).slice(2)} @${String(dateObj.getHours()).padStart(
                          2,
                          "0"
                        )}:${String(dateObj.getMinutes()).padStart(2, "0")}`;
                        return (
                          <NavLink
                            key={index}
                            to="/dashboard"
                            state={state}
                            onClick={() =>
                              dropdownHandler(notifications?.length)
                            }
                            className="block  py-2 h-[40px] text-gray-800 relative "
                          >
                            <div className="truncate h-[30px]">
                              {val.title}
                              <span className="absolute bottom-1  right-0 bg-white text-xs text-gray-500  py-1">
                                {formattedDate}
                              </span>
                            </div>
                          </NavLink>
                        );
                      <div className="text-center text-gray-500">No notifications</div>
                    ) : (

                      notifications?.map((val, index) => {
                        const dateObj = new Date(val.createdAt);

                        const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getFullYear()).slice(2)} @${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
                        return (

                          <NavLink
                            key={index}
                            to="/dashboard"
                            state={state}
                            onClick={() => dropdownHandler(notifications?.length)}
                            className="block  py-2 h-[40px] text-gray-800 relative "

                          >
                            <div className="truncate h-[30px]">
                              {val.title}
                              <span className="absolute bottom-1  right-0 bg-white text-xs text-gray-500  py-1">
                                {formattedDate}
                              </span>
                            </div>
                          </NavLink>


                        )
                      })
                    )}
                  </div>
                )}
              </div> */}
            </div>
          )}
        </div>
        {isOpen && (
          <>
            <div className="mobile-addr mt-[12%]">
              {mobList.map((val, index) => {
                return (
                  <>
                    <div
                      className="link-div"
                      onClick={() => handleLinkClick(index)}
                    >
                      <p className=" font-bold text-xl ">{val.title}</p>
                      {activeLink === index ? (
                        <FaAngleDown color="#0d1350" />
                      ) : (
                        <FaAngleRight />
                      )}
                    </div>
                    {activeLink === index && (
                      <div className="link-content">
                        {val.content.map((section, secIndex) => (
                          <div className="" key={secIndex}>
                            <p className="fusin-hddr  ">{section.header}</p>
                            {section.title.map((title, titleIndex) => (
                              <div className="title-link-div text-gray-500 pl-2">
                                <NavLink
                                  to={title.link}
                                  onClick={hamburgerHander}
                                  key={titleIndex}
                                >
                                  {title.val}
                                </NavLink>{" "}
                                <MdKeyboardDoubleArrowRight color="#0d1350" />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                );
              })}
              <div className="  flex flex-col space-x-4 w-full ">
                <NavLink
                  to="https://investment.investorszon.com/"
                  className=" fusin-hddr ml-4 text-gray-700  font-extrabold  hover:text-gray-900 flex"
                >
                  {" "}
                  Discover
                </NavLink>
                <NavLink
                  to="https://investment.investorszon.com/campaigns"
                  className=" fusin-hddr mt-2 text-gray-700 font-bold hover:text-gray-900 flex"
                >
                  Start A Campaign
                </NavLink>
              </div>

              {!authUser && (
                <div className="w-[50%] m-auto flex justify-center align-center flex-col mt-5">
                  <div className="relative flex justify-evenly w-[100%] m-auto ">
                    <div>
                      <button
                        className=" h-12 w-[80px] text-center text-lg font-semibold pb-4 mb-2"
                        onClick={hamburgerHander}
                      >
                        {" "}
                        <NavLink className=" " to="/signin">
                          LOGIN
                        </NavLink>
                      </button>
                    </div>
                    <div>
                      <NavLink to="/cart" onClick={hamburgerHander}>
                        {" "}
                        <FaCartPlus
                          size={30}
                          color="black"
                          className="cursor-pointer w "
                        />
                      </NavLink>
                    </div>
                  </div>

                  <button
                    className="text-lg font-semibold w-[100px] h-12 ml-10 mb-4 flex justify-center items-center bg-blue-700 text-white rounded-lg"
                    onClick={hamburgerHander}
                  >
                    <NavLink className=" " to="/signup">
                      SIGN UP
                    </NavLink>
                  </button>
                  <button className="  w-[180px] h-[45px] rounded-lg bg-blue-700 flex items-center justify-center text-white">
                    <NavLink to="https://investment.investorszon.com/">
                      Invest with us{" "}
                    </NavLink>
                  </button>
                </div>
              )}
              {authUser && (
                <div className=" absolute bottom-20 top-18 flex items-center space-x-4 mt-4 justify-end ">
                  <div
                    className="relative"
                    onMouseEnter={() => handleMouseEnter("wishlist")}
                    onMouseLeave={handleMouseLeave}
                  ></div>

                  <div className="relative">
                    <div
                      className="h-10 w-full rounded-full flex items-center justify-center cursor-pointer"
                      onClick={() => handleToggleDropdown("user")}
                    >
                      <div className="flex items-center py-2">
                        <div className="h-10 w-10 rounded-full bg-dark flex items-center justify-center">
                          <p className="h-10 w-10 text-white text-center pt-2">
                            {authUser?.name
                              ?.split(" ")
                              .map((name) => name.charAt(0).toUpperCase())
                              .join("")}
                          </p>
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 font-semibold">
                            {authUser?.name}
                          </p>
                          <p className="text-gray-600 text-sm">
                            {authUser?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    {dropdown === "user" && (
                      <div
                        ref={dropdownRef}
                        className="absolute left-0 bottom-12 w-72 bg-white"
                        style={{ width: "355px" }}
                      >
                        <button
                          onClick={() => {
                            signOutAction();
                            handleCloseDropdown();
                          }}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          <GoSignOut className="inline mr-2" /> Sign out
                        </button>
                        <hr className="border-gray-200 my-1" />
                        <NavLink
                          to="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <GoQuestion className="inline mr-2" />
                          Help
                        </NavLink>
                        <NavLink
                          state={"OrderHistory"}
                          to="/dashboard"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <MdManageHistory className="inline mr-2" /> My
                          Transaction
                        </NavLink>
                        <NavLink
                          state={"My Subscriptions"}
                          to="/dashboard"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <IoIosNotificationsOutline className="inline mr-2" />
                          My Subscriptions
                        </NavLink>
                        <NavLink
                          state={"My Signals"}
                          to="/dashboard"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <BsGraphUp className="inline mr-2" />
                          My Signals
                        </NavLink>
                        <NavLink
                          state={"My Certificates"}
                          to="/dashboard"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <GrCertificate className="inline mr-2" />
                          My Certificates
                        </NavLink>
                        <NavLink
                          to="/purchased-modules-list"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <LuBook className="inline mr-2" />
                          My Modules
                        </NavLink>
                        <NavLink
                          to="my-subscribed-courses-list"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <LuBook className="inline mr-2" />
                          My Subscribed Courses
                        </NavLink>
                        <NavLink
                          to="/my-courses"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <LuBook className="inline mr-2" />
                          My Courses
                        </NavLink>
                        <NavLink
                          to="/dashboard"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                          onClick={handleCloseDropdown}
                        >
                          <LuUserCheck2 className="inline mr-2" />
                          Dashboard
                        </NavLink>
                        <hr className="border-gray-200 my-1" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
