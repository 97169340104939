import React from 'react'
import Marketinsight from "../../assets/MarketInsight.jpeg"
import Title from '../TitleComp/Title'
import { VscGraph } from "react-icons/vsc";
import { FaStar,FaSearch } from "react-icons/fa";
import { HiSupport } from "react-icons/hi";
import Buttoncard from '../HomePageSimpleCard/Buttoncard';
import { FaTelegramPlane } from "react-icons/fa";
import Testimonials from '../Testominal/Testominal';
import TopStories from '../WidgetComp/TopStoriesWidget';
import Featurecard from '../FeatureCardComp/Featurecard';
import "./Marketinsight.css"    

const NewsAndAnalysis = () => {
    const data = [
        {header: "Stay Informed, Make Informed Decisions", descp1: `Stay informed about the latest market developments with INVESTORS ZON News and Analysis. Our platform provides access to breaking news, market updates, and expert commentary from leading financial news sources around the world. Whether you're a casual investor or a seasoned trader, our News and Analysis keep you informed about the factors driving market movements and trends.` }
    ]
    const FourTrading = [
        { header: "Key Features of News and Analysis" }
    ]
      const datatwo = [
        { icon: <VscGraph size={40} color='white' className='text-white' />, title: "Real-time News Feeds", descp: "Access real-time news feeds from top financial news sources, including Reuters, Bloomberg, CNBC, and more. Our platform aggregates news from multiple sources, ensuring that you get the latest updates and developments as they happen." },
        { icon: <FaStar size={40} color='white' className='text-white' />, title: "Market Updates ", descp: "Stay up-to-date on market trends, price movements, and key events with our market updates. Our platform provides comprehensive coverage of global financial markets, including forex, stocks, commodities, and cryptocurrencies, allowing you to stay informed about the factors impacting asset prices. " },
        { icon: <FaSearch size={40} color='white' className='text-white' />, title: "Expert Commentary ", descp: "Gain insights from industry experts and analysts who provide commentary and analysis on market trends, economic events, and geopolitical developments. Our expert commentary offers valuable insights and perspectives to help you understand market dynamics and make informed trading decisions." },
        { icon: <HiSupport size={40} color='white' className='text-white' />, title: "Customizable Alerts", descp: "Set up customizable alerts to receive notifications for important news events and market updates. Whether you're interested in specific asset classes, market sectors, or economic indicators, our customizable alerts keep you informed about the events that matter most to you." },
      ]
      const buttondata = [
        {title:" Join INVESTORS ZON and Unlock the Potential of News and Analysis Today ",descp:"Stay informed and make informed decisions with CW BANK's News and Analysis. Whether you're looking for breaking news, market updates, or expert commentary, our platform provides the information you need to stay ahead of the curve and capitalize on market opportunities.",icon: <FaTelegramPlane  size={20} />,button:"    SIGN UP"}
      ]
    return (
        <div>
            <div className=" container mx-auto mt-[8%]  relative ">
                <div className="relative mb-6">
                    <img
                        src={Marketinsight}
                        alt="Paris Financial District "
                        className="w-full h-auto "
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <h1 className=" inner-text text-6xl font-bold text-white p-4">
                        News and Analysis
                        </h1>
                    </div>
                </div>
                {data.map((val, index) => {
                    return (
                        <div className="container mx-auto mb-4 ">
                        <h2 className=" font-bold text-2xl  mb-4">
                            {val.header}
                        </h2>
                        <p className="text-base text-body-color dark:text-dark-6 mb-6">
                            {val.descp1}
                        </p>
                    </div>
                    )
                })}
                <div className=" flex justify-center">
                    <TopStories />
                </div >
                <div className='mt-4'>
                    <Title data={FourTrading} />
                    <Featurecard data={datatwo} />
                    <Buttoncard data={buttondata} />
                    <Testimonials/>
                </div>
            </div>
        </div>
    )
}
export default NewsAndAnalysis
