import React, { useEffect, useRef } from 'react';

const EconomicCalendarWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const widgetContainer = widgetRef.current;

    if (widgetContainer) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
      script.innerHTML = JSON.stringify({
        "colorTheme": "light",
        "isTransparent": false,
        "width": "520",
        "height": "550",
        "locale": "en",
        "importanceFilter": "-1,0,1",
        "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
      });

      widgetContainer.appendChild(script);

      return () => {
        if (widgetContainer.contains(script)) {
          widgetContainer.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div className="tradingview-widget-container" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default EconomicCalendarWidget;
