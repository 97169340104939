import React from 'react'
import HomePageCard from './HomePageSimpleCard/HomePageCard'
import { BsGraphUp } from "react-icons/bs";
import Title from './TitleComp/Title';
import { SiExpertsexchange } from "react-icons/si";
import { GiFlatPlatform } from "react-icons/gi";
import { FaUserFriends } from "react-icons/fa";
import { LuBadgeDollarSign } from "react-icons/lu";
import { MdOutlineCastForEducation } from "react-icons/md";
import { TbDeviceDesktopSearch } from "react-icons/tb";
import { PiFediverseLogoThin } from "react-icons/pi";
import { CgSupport } from "react-icons/cg";
import { RiShakeHandsLine } from "react-icons/ri";
const AboutInvestorszon = () => {
  window.scroll(0, 0)
  const AboutInvestorszondata = [
    { icon: <BsGraphUp size={30} color='#ffff' className='text-center' />, heading: "Cutting-Edge Trading Platform", description: "Our state-of-the-art trading platform is designed to cater to the needs of both novice and experienced traders. With intuitive navigation, advanced charting tools, real-time data, and seamless execution." },
    { icon: <MdOutlineCastForEducation size={30} color='#ffff' className='text-center' />, heading: "Comprehensive Educational Resources", description: "We believe that knowledge is the key to successful trading. That’s why we offer an extensive library of educational resources, including webinars, tutorials, articles, and market analysis." },
    { icon: <TbDeviceDesktopSearch size={30} color='#ffff' className='text-center' />, heading: " Expert Market Analysis", description: "Stay ahead of the market with our expert analysis and insights. Our team of financial analysts provides in-depth market research, daily updates, and strategic trading ideas to keep you informed and ready to seize opportunities as they arise." },
    { icon: <PiFediverseLogoThin size={30} color='#ffff' className='text-center' />, heading: " Diverse Range of Tradable Instruments", description: "At InvestorsZon, we offer access to a wide array of tradable instruments, including forex, commodities, indices, stocks, and cryptocurrencies. Our diverse offerings ensure that you can diversify your portfolio and explore various market." },
    { icon: <CgSupport size={30} color='#ffff' className='text-center' />, heading: "Reliable Customer Support", description: "We pride ourselves on our exceptional customer support. Our dedicated team is available 24/5 to assist you with any queries or issues you may have. Whether you need technical assistance or have questions about trading, we’re here to help." },
    { icon: <RiShakeHandsLine size={30} color='#ffff' className='text-center' />, heading: "Commitment to Security and Regulation", description: "Your security is our top priority. We adhere to strict regulatory standards and employ advanced security measures to protect your funds and personal information. You can trade with confidence, knowing that your assets are safe with us." },
  ]
  const AboutInvestors = [
    { header: " What We Offer" }
  ]
  const AboutInvestorszonTitle = [
    { header: "Our Values" }
  ]
  return (
    <div className=' container'>
      <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20 " style={{ marginTop: '150px' }}>
        <span className="mb-2 block text-lg font-semibold text-primary">
          Investors Zon
        </span>
        <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
          What We Learn
        </h2>
        <p className="text-base text-body-color dark:text-dark-6">
          There are many variations of passages of Lorem Ipsum available
          but the majority have suffered alteration in some form.
        </p>
      </div>
      <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/gFb3ns6/image-1.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/rfHFq15/image-2.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="relative z-10 my-4">
                    <img
                      src="https://i.ibb.co/9y7nYCD/image-3.jpg"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                    <span className="absolute -right-7 -bottom-7 z-[-1]">
                      <svg
                        width={134}
                        height={106}
                        viewBox="0 0 134 106"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="1.66667"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 1.66667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 16.3333 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 31 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 45.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3334"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 60.3334 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 88.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 117.667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 74.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 103 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy={104}
                          r="1.66667"
                          transform="rotate(-90 132 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 31 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 103 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 132 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="74.6673"
                          r="1.66667"
                          transform="rotate(-90 1.66667 74.6673)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 16.3333 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 31 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 31 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 45.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 60.3333 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 60.3333 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 88.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 88.6667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 117.667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 117.667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 74.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 74.6667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 103 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 103 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 132 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 132 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 31 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 31 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 60.3333 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 60.3333 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 88.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 88.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 117.667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 117.667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 74.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 74.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 103 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 103 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 132 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 132 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 1.66667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 16.3333 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 31 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={31}
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 31 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 45.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 60.3333 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 88.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 117.667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 74.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 103 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={103}
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 103 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 132 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx={132}
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 132 1.66683)"
                          fill="#3056D3"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-lg font-semibold text-primary">
                  Who We Are
                </span>
                <h2 className="mb-5 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                  Welcome to InvestorsZon.
                </h2>
                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                  InvestorsZon is a premier online financial services platform founded by a team of seasoned traders, financial analysts, and tech enthusiasts. With decades of combined experience in the financial markets, our team is passionate about creating a space where traders of all levels can access reliable information, advanced trading tools, and exceptional customer support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Title data={AboutInvestors} />
      <HomePageCard data={AboutInvestorszondata} />
      <Title data={AboutInvestorszonTitle} />
      <div className=" main-feature-div -mx-4 flex align-center flex-wrap Feature-gap">
        <div className=" feature-div w-full px-4 md:w-1/2 lg:w-1/4">
          <div className=" wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".1s">
            <div
              className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
            >
              <span
                className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
              ></span>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <SiExpertsexchange size={30} color='#ffff' className='text-center' />
              </svg>
            </div>
            <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              Integrity
            </h4>
            <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[220px]">
              We conduct our business with the highest level of integrity, ensuring transparency and honesty in all our interactions. Trust is the foundation of our relationship with our users.
            </p>
          </div>
        </div>
        <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
          <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".15s">
            <div
              className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
            >
              <span
                className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
              ></span>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <GiFlatPlatform size={40} color='#ffff' className='text-center' />
              </svg>
            </div>
            <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px] ">
              Innovation              </h4>
            <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[220px]">
              We are committed to continuous innovation, always seeking to improve our platform and services to meet the evolving needs of our users. We embrace new technologies and ideas to stay at the forefront of the industry.
            </p>
          </div>
        </div>
        <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
          <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".2s">
            <div
              className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
            >
              <span
                className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
              ></span>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <FaUserFriends size={30} color='#ffff' className='text-center' />
              </svg>
            </div>
            <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              Excellence              </h4>
            <p className=" text-body-color dark:text-dark-6 lg:mb-9 h-[220px]">
              Excellence is at the core of everything we do. From our customer service to our trading tools, we strive to deliver the best possible experience for our users.
            </p>
          </div>
        </div>
        <div className="feature-div w-full px-4 md:w-1/2 lg:w-1/4">
          <div className="wow fadeInUp group  border border-[#eaeff5] rounded-[20px] p-10 transition duration-300  hover:shadow-bottom" data-wow-delay=".25s">
            <div
              className="relative z-10 mb-10 flex h-[53px] w-[53px] items-center justify-center rounded-[14px] bg-primary"
            >
              <span
                className="absolute left-0 top-0 -z-[1] mb-8 flex h-[55px] w-[55px] rotate-[25deg] items-center justify-center rounded-[14px] bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
              ></span>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <LuBadgeDollarSign size={40} color='#ffff' className='text-center' />
              </svg>
            </div>
            <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px]">
              Community              </h4>
            <p className="mb-8 text-body-color dark:text-dark-6 lg:mb-9 h-[220px]">
              We believe in building a strong and supportive community of traders and investors. Through our forums, social media channels, and events, we foster a collaborative environment where knowledge and experiences are shared.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutInvestorszon
