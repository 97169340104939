import React, { useEffect, useState } from 'react'
import Testimonials from '../Testominal/Testominal';
import FeaturedReview from './FeaturedReview';
import CourseDescription from './CourseDescription';
import RequirementsComp from './RequirementsComp';
import CourseContent from './CourseContent';
import CourseIncludes from './CourseIncludes';
import WhatYouLearn from './WhatYouLearn';
import LearningBootcamp from './HeroCourseModule';
import Subscriptioncard from './Subscriptioncard';
import CourseModule from './CourseModule';
import { useParams } from 'react-router-dom';
import AccordionComp from './AccordionComp';
import { fetchModuleByModuleId } from '../../api/CourseApi';

const MyCurseDetails = ({data}) => {
  window.scroll(0,0)
    const {title} = useParams();
    
    const [moduleHeaderInfo,setModuleHeaderList]= useState({});
    console.log("paramsincourseetails",title);
    const fetchModule = async()=>{
      const resp = await fetchModuleByModuleId(title);
      setModuleHeaderList(resp)
    };

    const [totalModuleItemsCount,setTotalModuleItemsCount] = useState();
    window.scroll(0,0);
    useEffect(()=>{
      fetchModule()
    },[]);

    console.log("moduleItemsCount",totalModuleItemsCount)
  return (
    <div>
       <div className="bg-white mt-[140px]">
        <div className='bg-[#090E34]  p-5'>
          {/* <div className='ml-[3%]'>
      <BreadcrumbComp/>
      </div> */}
      <LearningBootcamp data={moduleHeaderInfo} total={totalModuleItemsCount}/>
      </div>
      {/* <WhatYouLearn/> */}
      {/* <CourseIncludes/> */}
      {/* <CourseContent/> */}
      <div className='w-[100%]'>
        
          
      <AccordionComp id={title} setTotalModuleItemsCount={setTotalModuleItemsCount} />
      
      
          
      </div>
      {/* <RequirementsComp/> */}
      {/* <CourseDescription/> */}
      <div className='w-[100%]'>
      <Testimonials/>
      </div>
      
    </div>
    </div>
  )
}

export default MyCurseDetails
