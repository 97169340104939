import React, { useEffect, useRef, useState } from "react";
import CousreCard from "../SubComponents/CousreCard";
import HeaderComp from "../SubComponents/HeaderComp";
import Pricing from "../Pricing/Pricing";
import Testominal from "../Testominal/Testominal";
import { fetchPublishedCourses } from "../../api/CourseApi";
import { FaSpinner } from "react-icons/fa";
import NoData from "../Lottie Animation Comp/NoData";

// Loader component
const Loader = () => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
  </div>
);

const Courses = () => {
  const headerData = {
    header: "Our Courses",
    title: "Start Your Learning",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.",
  };
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchCourses = async () => {
    setLoading(true);
    try {
      const resp = await fetchPublishedCourses();
      setCourses(resp);
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCourses();
  }, []);
 

  return (
    <div className="mt-[70px]">
      <section className="bg-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]">
        <div className="container mx-auto">
          <HeaderComp data={headerData} />
          <div className="mt-[3%] mb-[3%] w-[100%]">
            {loading ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (courses && courses.length > 0 ? (
              <CousreCard data={courses} />
            ) : (
              <NoData/>
            ))}
          </div>

          <Pricing />
          <Testominal />
        </div>
      </section>
    </div>
  );
};

export default Courses;
