import React, { useEffect, useState } from 'react';
import logo from "../../assets/investors zon logo new.png";
import Signature from "../../assets/signature.png";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { downloadCourseCertificate } from '../../api/CourseApi';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import NoData from '../Lottie Animation Comp/NoData';
import { FaSpinner } from 'react-icons/fa';
import {EmailShareButton,
  FacebookShareButton,TwitterIcon,FacebookIcon,LinkedinIcon,LinkedinShareButton} from 'react-share';

const Certificate = () => {
  const { id } = useParams();
  const authUser = useAuthUser();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true); // State for loader

  const downloadPDF = () => {
    const input = document.getElementById('certificate');

    html2canvas(input, { scale: 2 })  // Adjust scale for better quality
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 200; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Adjust the image size and position to fit the page
        const pageHeight = pdf.internal.pageSize.getHeight();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const xOffset = (pageWidth - imgWidth) / 2;
        const yOffset = (pageHeight - imgHeight) / 2;

        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
        pdf.save('certificate.pdf');
      });
  };

  const getCourseCertificate = async () => {
    try {
      setLoading(true); // Set loading to true when making API call
      const resp = await downloadCourseCertificate(authUser?.token, id);
      setUserDetails(resp.data);
    } catch (error) {
      console.error('Error fetching certificate:', error);
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  };
  

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const completionDate = formatDate(userDetails?.userCertificate?.createdAt);
  const currentDate = new Date();
  const formattedCurrentDate = formatDate(currentDate);

  useEffect(() => {
    window.scroll(0, 0)
    getCourseCertificate();
  }, []);

  if (loading) {
    // Display loader while loading
    return <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
    <FaSpinner className="animate-spin text-white" size={40} />
  </div>
  }

  return (
    <div className='mt-[10%] '>
      <div className='container'>
        <div id="certificate" className=" relative max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-8 border border-gray-300 mb-10">
          <div className="text-center">
            <img src={logo} alt="Logo" className="ml-48 h-16 mb-4" />
            <p className="text-xl text-gray-500 mb-8">This is to certify that</p>
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">{userDetails?.user?.name}</h2>
            <p className="text-lg text-gray-500 mb-8">has successfully completed the course</p>
            <h3 className="text-2xl font-semibold text-gray-700 mb-4">{userDetails?.course?.title}</h3>
            <p className="text-lg text-gray-500 mb-8">on</p>
            <p className="text-xl text-gray-700 font-semibold">{completionDate}</p>
          </div>
          <div className="flex justify-between mt-12">
            <div className="text-left">
              <img className='w-[60px] h-[30px] m-auto' src={Signature} alt='signature' />
              <div className="mt-2 w-40 border-t-2 border-gray-300"></div>
              <p className="text-gray-700 font-semibold">Global Head,Investors zon </p>
            </div>
            <div className="text-right">
              <p className="text-gray-700 font-semibold">{formattedCurrentDate}</p>
              <div className="mt-2 w-40 border-t-2 border-gray-300"></div>
              <p className="text-gray-700 font-semibold">Date</p>
            </div>
          </div>
          <div className='absolute top-0 left-0 flex gap-2  m-1'>
            <h3 className="text-sm font-semibold text-gray-700 ml-1">Certificate No. </h3>
            <p className="text-sm  text-gray-800 "> {userDetails?.userCertificate?.number}</p>
          </div>
        </div>

        <div className="text-end mt-8 mb-8">
          <button
            onClick={downloadPDF}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg"
          >
            Download Now
          </button>
        </div>
        <div className="Demo__some-network">
        <LinkedinShareButton url={`https://investorszon.com/share-my-certificate/${userDetails?.userCertificate?.number}`} className="Demo__some-network__share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
      </div>


    </div>
  );
};

export default Certificate;
