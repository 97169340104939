import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaBookOpen } from "react-icons/fa";
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import image from "../../assets/about/about-image-02.jpg";
import "../SubComponents/MarketCourseCard.css"
const MarketCourseCard = ({data}) => {
   
    return (
  //     
  
  <div>
  <div className={`flex ${data.length === 1 ? 'justify-center' : 'justify-evenly'} gap-0.5 flex-wrap market-card`}>
    {data?.map((val, index) => (
      <div key={index} className={`inner-card w-[32%]  border hover:shadow-md mb-[4%] rounded-[5px]  ${data.length === 1 ? 'md:w-1/2 lg:w-1/3' : ''}`}>
        <div className="wow fadeInUp group mb-5 h-[100%] relative" data-wow-delay=".1s">
          <div className="mb-8 overflow-hidden rounded-t-[5px] relative h-[230px] ">
            <NavLink to={val.href} className="block h-[100%]">
              <img
                src={val.img}
                alt="image"
                className="image-div w-full h-[100%] transition group-hover:rotate-6 group-hover:scale-125"
              />
            </NavLink>
          </div>
          <div>
            <h3 className="w-[80%] m-auto flex items-center justify-center">
              <NavLink
                href={val.href}
                className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white dark:hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl"
              >
                {val.title}
              </NavLink>
            </h3>
            <p className="max-w-[80%] m-auto text-center text-body-color dark:text-dark-6 text-justify content-justify  ">
              <a href={val.href} className="block">
                {val.description}
              </a>
            </p>
            
          </div>
          <div className="absolute  inset-0 bg-blue-500 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
            <NavLink to={val.href} className="bg-white text-blue-600 font-bold text-center py-2  w-[30%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">Explore
            </NavLink>
          </div>
        </div>
      </div>
    ))}
  </div>
  </div>
    )
  }
  

export default MarketCourseCard
