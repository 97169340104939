import React, { useEffect, useState } from "react";

import CourseSubscriptionCard from "./CourseSubscriptionCardComp/CourseSubscriptioncard";
import {
  fetchUserCoursePurchaseStatus,
  mySignalSubscription,
} from "../api/CourseApi";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { FaSpinner } from "react-icons/fa";
import NoData from "./Lottie Animation Comp/NoData";
import Title from "./TitleComp/Title";

const CourseSubscription = () => {
  window.scroll(0, 0);
  const authUser = useAuthUser();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingUserSubscriptions, setLoadingUserSubscriptions] =
    useState(true);
  const [userSubscriptions, setUserSubscriptions] = useState({});

  const fetchSubscriptions = async () => {
    setLoadingSubscriptions(true);
    try {
      const resp = await mySignalSubscription();
      setSubscriptions(resp);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoadingSubscriptions(false);
    }
  };

  const fetchUserSubscriptions = async () => {
    setLoadingUserSubscriptions(true);
    try {
      const resp = await fetchUserCoursePurchaseStatus(authUser?.token);
      console.log("respo", resp);
      if (resp.status === 200  && resp?.data?.data) {
        setUserSubscriptions(resp?.data?.data);
      } else {
        console.log("****else error*****", resp);
      }
    } catch (error) {
      console.log("error", error);
      console.error("Error fetching user subscriptions:", error);
    } finally {
      setLoadingUserSubscriptions(false);
    }
  };
  const loading = loadingSubscriptions || loadingUserSubscriptions;
  const Featuretitlesone = [
    {
      title: "Subscription",
      header: "My Subscription",
      descp: "View and manage your subscription. You can cancel anytime.",
    },
  ];

  useEffect(() => {
    fetchSubscriptions();
    fetchUserSubscriptions();
  }, [authUser]);

  return (
    <div className="mt-[90px]">
     
      <div className="container mt-4 mb-4">
      <Title data={Featuretitlesone} />
        {loading ? (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <FaSpinner className="animate-spin text-white" size={40} />
          </div>
        ) : subscriptions && subscriptions.length > 0 ? (
          <CourseSubscriptionCard
            data={subscriptions}
            userSubscriptions={userSubscriptions}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default CourseSubscription;
