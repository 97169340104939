import React from 'react';
import "./CourseSubscriptioncard.css"
import { BsCalendar2Check } from 'react-icons/bs';

const SubscriptionCardCourse = ({ data }) => {
  const dateEnd = data?.endDate;
  const DateFormatter = (data) => {
    const entryDateTime = data
    const date = new Date(entryDateTime)
    const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' }
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate)
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false }
    const formattedTime = date.toLocaleTimeString('en-GB', optionsTime)
    const formattedDateTime = `${formattedDate} @ ${formattedTime}`
    return formattedDateTime
  }
  const isLifetime = () => {
    const currentDate = new Date();
    const seventyYearsLater = new Date(currentDate.setFullYear(currentDate.getFullYear() + 70));
    return new Date(data?.endDate) > seventyYearsLater;
  };
  return (
    <div className='flex flex-wrap border rounded-lg  main-CourseSubcard'>
      <div className='relative   px-4 mt-4 mb-8   inner-CourseSubcard'>
        <div className='pb-4 bg-gray-100 mb-2   hover:shadow-lg    rounded-lg overflow-hidden'>
          <div className='px-6 text-center'>
            <h3 className='text-2xl font-bold text-primary pt-3' style={{ textTransform: 'capitalize' }}>
              {data?.planType}
            </h3>
            <span className='inline-block bg-yellow-200 text-yellow-800 text-sm font-semibold px-2 py-1 my-4 rounded mb-4'>
              {"Your Current Plan"}
            </span>
          </div>
          <div className='px-6 flex justify-center items-center'>
            <button
              className='Course-SubCard-btn block w-[20vw] text-center bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary-dark transition duration-300'
              style={{ background: "green" }}
              disabled={true}
            >{data?.planDurationType === 'onetime' ? 'Already Purchased' :
              "Already Subscribed"}
            </button>
          </div>
        </div>
      </div>
      <div className='flex gap-5 justify-center items-center w-[90%] m-auto  border-t pt-2 mb-4'>
        <div className='flex mb-2 pt-[0.5%]'>
          <p className='text-gray-500 flex gap-2'><BsCalendar2Check size={15} className='text-gray-500 mt-1' /> Activation Date :                  </p>
          <p className='pl-1'>{DateFormatter(data?.startDate)}</p>
        </div>
        <div className='flex '>
          <p className='text-red-500 flex gap-2'><BsCalendar2Check size={15} className='text-red-500 mt-1' /> Expiration date :                </p>
          {data?.endDate !== null &&
            <p className='pl-1 text-red-500'>  {isLifetime ? 'Lifetime' : DateFormatter(data?.endDate)}</p>}
        </div>
      </div>
    </div>
  );
};
export default SubscriptionCardCourse;
