import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchBuyModules, fetchUserCoursePurchaseStatus, getUserCompleteCourses, getUserCompletedModules, myCourses } from "../../api/CourseApi";
import MyCourseCard from "../SubComponents/MyCourseCard";
import NoData from "../Lottie Animation Comp/NoData";
import MyModuleCardComp from "../SubComponents/MyModuleCardComp";
import { FaSpinner } from "react-icons/fa";

const DashboardTabs = () => {
  const [activeTab, setActiveTab] = useState("Purchased Courses");
  const [subscribedDetails, setSubscribedDetails] = useState();
  const authUser = useAuthUser();
  const [course, setCourse] = useState([]);
  const [module, setModule] = useState([]);
  const [completedModules, setCompletedModules] = useState();
  const [completedCourses, setCompletedCourses] = useState();
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const fetchCourse = async () => {
    setLoading(true);
    setLoader(true);
    if (!authUser) {
      return navigate('/signin');
    }
    const resp = await myCourses(authUser?.token);
    setCourse(resp.data);
    setLoader(false);
    setLoading(false);
  };
  const fetchUserModules = async () => {
    setLoader(true);
    const resp = await fetchBuyModules(authUser?.token);
    setModule(resp.data);
    setLoader(false);
  };
  const fetchUserSubscribeInfo = async () => {
    setLoader(true);
    const resp = await fetchUserCoursePurchaseStatus(authUser?.token);
    setSubscribedDetails(resp.data.data);
    setLoader(false);
  };
  const fetchCompletedModules = async () => {
    setLoader(true);
    const resp = await getUserCompletedModules(authUser?.token);
    setCompletedModules(resp.data)
    setLoader(false);
  };
  const fetchCompletedCourses = async () => {
    setLoader(true);
    const resp = await getUserCompleteCourses(authUser?.token);
    setCompletedCourses(resp.data)
    setLoader(false);
  };
  // useEffect(() => {
  //   fetchUserSubscribeInfo();
  // }, []);
  useEffect(() => {
    switch (activeTab) {
      case "Purchased Courses":
        fetchCourse();
        break;
      case "Purchased Modules":
        fetchUserModules();
        break;
      case "Completed Courses":
        fetchCompletedCourses();
        break;
      case "Completed Modules":
        fetchCompletedModules();
        break;
      default:
        break;
    }
  }, [activeTab]);
  const loadingContainer = useRef(null);
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: loadingContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../Lottie Animation Comp/NOCoursesAnimation.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, [loading]);
  const renderContent = () => {
    switch (activeTab) {
      case "Purchased Courses":
        return (
          <div className="">
            {loader ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (
              course && course.length > 0 ? (
                <MyCourseCard data={course} />
              ) : (
                <div className="flex justify-center ">
                  <div className="loader-container w-[25vw]" ref={loadingContainer}></div>
                </div>)
            )}
          </div>
        );
      case "Purchased Modules":
        return (
          <div className="">
            {loader ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (module && module.length > 0 ? (
              <MyModuleCardComp data={module} />
            ) : (
              <div className="flex justify-center ">
                <NoData />
              </div>)
            )}
          </div>
        );
      case "Completed Courses":
        return (
          <div className="">
            {loader ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (
              completedCourses && completedCourses.length > 0 ? (
                <MyCourseCard data={completedCourses} />
              ) : (
                <div className="flex justify-center ">
                  <NoData />
                </div>)
            )}
          </div>
        );
      case "Completed Modules":
        return (
          <div className="">
            {loader ? (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                <FaSpinner className="animate-spin text-white" size={40} />
              </div>
            ) : (
              completedModules && completedModules.length > 0 ? (
                <MyCourseCard data={completedModules} />
              ) : (
                <div className="flex justify-center ">
                  <NoData />
                </div>)
            )}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <ul className="flex flex-wrap justify-start text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400">
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Purchased Courses")}
            aria-current={activeTab === "Purchased Courses" ? "page" : undefined}
            className={`inline-block p-4 ${activeTab === "Purchased Courses"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Purchased Courses
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Purchased Modules")}
            className={`inline-block p-4 ${activeTab === "Purchased Modules"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Purchased Modules
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Completed Courses")}
            className={`inline-block p-4 ${activeTab === "Completed Courses"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Completed Courses
          </NavLink>
        </li>
        <li className="me-2">
          <NavLink
            to="#"
            onClick={() => setActiveTab("Completed Modules")}
            className={`inline-block p-4 ${activeTab === "Completed Modules"
              ? "text-blue-600 bg-gray-100 dark:bg-blue-600 dark:text-white"
              : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-white-300"
              }`}
          >
            Completed Modules
          </NavLink>
        </li>
      </ul>
      <div className="p-4">{renderContent()}</div>
    </div>
  );
};

export default DashboardTabs;
