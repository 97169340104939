import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
const NoData = () => {

    const loadingContainer = useRef(null);

    useEffect(() => {
      const animation = lottie.loadAnimation({
        container: loadingContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("./NOCoursesAnimation.json"),
      });
      return () => {
        lottie.destroy();
      };
    }, []);
  return (
    <div>
      <div className="flex justify-center ">
                <div className="loader-container w-[25vw] " ref={loadingContainer}></div>
              </div>
    </div>
  )
}

export default NoData
