import React, { createContext, useState } from 'react';

// Create Context
const LoaderContext = createContext();

// Create Provider Component
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
