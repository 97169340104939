import React from 'react'
import "./Card.css"
const Card = ({ data }) => {
  return (
    <div>
      {data.map((val, index) =>
        <div key={index} className='main-card-div flex justify-between	ml-4 mb-4 border-b border-gray-300 mb-2	'>
          <div className='mb-4 innermain-card' >

            <img src={val.img} alt="My Image" className="	w-[80px] h-[80px] rounded-[80px]" />
          </div>
          <div className='w-3/4 inner-main-descp'>
            <h2 className='text-gray-500'>{val.description}</h2>
            <p className=' font-bold'>{val.name}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Card
