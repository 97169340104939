import React from 'react'

const WhatIsForex = () => {
  window.scroll(0,0)
  const points = [
    "Foundation of Forex: Start with the basics, including understanding currency pairs, market dynamics, and key terminology. This foundational knowledge is crucial for making informed trading decisions.",
    "Technical Analysis: Mastering technical analysis is essential. This involves studying charts, recognizing patterns, and using indicators to predict future price movements.",
    "Fundamental Analysis: Learn to analyze economic indicators, geopolitical events, and news releases. These factors can significantly impact currency values and help traders make long-term predictions.",
    "Risk Management: Effective risk management strategies are vital. This includes setting stop-loss orders, managing leverage, and ensuring that you never risk more than you can afford to lose on a single trade.",
    "Trading Strategies: Develop and test various trading strategies, such as day trading, swing trading, and scalping. It's important to find a strategy that aligns with your personality and risk tolerance.",
    "Demo Trading: Utilize demo accounts offered by brokers to practice trading without risking real money. This helps in building confidence and refining your strategies.",
    "Psychological Discipline: Trading psychology plays a significant role in success. Learn to manage emotions, stay disciplined, and stick to your trading plan, especially during volatile market conditions.",
    "Continuous Learning: The forex market is constantly evolving. Stay updated with the latest trends, tools, and techniques by reading books, attending webinars, and following reputable forex blogs and forums.",
    "Broker Selection: Choose a reliable and regulated forex broker that offers competitive spreads, robust trading platforms, and excellent customer support.",
    "Community and Mentorship: Engage with the trading community through forums and social media. Consider finding a mentor or joining a trading group to gain insights and learn from experienced traders."
  ];

  return (
    <div className="p-6 bg-gray-100 text-gray-800 mt-[70px]">
      <h1 className="text-2xl font-bold mb-4">Learning Forex Trading</h1>
      <ul className="list-disc list-inside space-y-2">
        {points.map((point, index) => (
          <li key={index} className="leading-relaxed">{point}</li>
        ))}
      </ul>
    </div>
  );
}

export default WhatIsForex