import React, { useState } from 'react';
import "./Subscribenow.css"
import axios from 'axios';
import { URL } from '../../config';
import { IoCloseCircleOutline } from 'react-icons/io5';
import ErrorLottie from '../Lottie Animation Comp/ErrorLottie';
import LottieGreenCheck from '../Lottie Animation Comp/LottieGreenCheck';
const SubscribeNow = () => {

    const [email, setEmail] = useState('');
    const [success, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      try {
        const resp = await axios.post(`${URL}/subscribe`, {
          email: email
        });
        if (resp.status === 201) {
          setIsSuccess(true);
          setSuccessMsg(resp.data.message);
        } else {
          setIsSuccess(true);
          setSuccessMsg("Message Sending Failed");
        }
      } catch (error) {
        setIsSuccess(true);
        setSuccessMsg("You have already subscribed .");
      }
    };
  return (
    <div>
       <div className="main flex flex-col items-center justify-center h-[90vh] bg-gray-100 p-4 ">
      <div className="inner-mains bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Join our news!</h2>
        <h2 className="text-1xl mb-4 text-center">Enter your email to receive our latest news.</h2>

        <form onSubmit={handleSubmit} className="flex flex-col">
          <label htmlFor="email" className=" input-field  mb-2 font-medium">Email address</label>
          <input
            type="email"
            id="email"
            className=" input-field mb-4 p-3  border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            type="submit"
            className="  subscribe-now inline-block rounded-md border border-transparent bg-primary px-7 py-3 text-base font-medium text-white transition hover:bg-[#0BB489]"
          >
            Subscribe
          </button>
          <h2 className="text-1xl mb-4 mt-2 text-center">Don't worry, we don't spam.</h2>

        </form>
      </div>
    </div>
    {success && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="Sigin-in-verify-code w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
            <IoCloseCircleOutline onClick={() => { setIsSuccess(false) }} size={30} style={{ float: "right", cursor: "pointer" }} />
            <div className=" flex flex-col items-center justify-center  text-white  mt-2">
              <h2 className="text-2xl text-black font-semibold text-center">{successMsg}</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {successMsg !== "User subscribed successfully" ? <ErrorLottie /> : <LottieGreenCheck />}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscribeNow
