import React, { useEffect, useRef } from 'react';

const MarketHeatmapwidget = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js';
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "650",
      currencies: [
        "EUR",
        "USD",
        "JPY",
        "GBP",
        "CHF",
        "AUD",
        "CAD",
        "NZD",
        "CNY"
      ],
      isTransparent: false,
      colorTheme: "light",
      locale: "en",
      backgroundColor: "#fff"
    });

    const currentContainer = containerRef.current;

    if (currentContainer) {
      currentContainer.appendChild(script);
    }

    return () => {
      if (currentContainer && currentContainer.contains(script)) {
        currentContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={containerRef}>
      <div className="tradingview-widget-container__widget"></div>
      {/* 
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div> 
      */}
    </div>
  );
};

export default MarketHeatmapwidget;
