import React, { useState, useEffect } from 'react';
import { FaCookieBite } from "react-icons/fa";
import Cookies from 'js-cookie';
import "./Cookies.css";
import { NavLink } from 'react-router-dom';

const CookiesComponent = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(Cookies.get('cookiesAccepted') === 'true');


  useEffect(() => {
    // Check if user has previously accepted cookies
    if (Cookies.get('cookiesAccepted') === 'true') {
      hideBanner();
    }
  }, []); // Empty dependency array ensures this runs only once on component mount


  const acceptCookies = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 365 }); // Set cookie for 1 year
    setAcceptedCookies(true);
    hideBanner();
  };


  const declineCookies = () => {
    Cookies.set('cookiesAccepted', 'false', { expires: 365 }); // Set cookie for 1 year
    setAcceptedCookies(false);
    hideBanner();
    // Additional actions for declining cookies (e.g., disable tracking features)
  };


  const hideBanner = () => {
    const banner = document.getElementById('cookiesBanner');
    if (banner) {
      banner.style.display = 'none';
    }
  };


  if (acceptedCookies) {
    return null; // If cookies are accepted, do not display the banner
  }

  return (
    <div>
      <div className="fixed bottom-10  left-0 w-[70%] h-[80px] p-4 bg-blue-700 dark:bg-dark  dark:border-dark-500 z-50 main-cookies"id="cookiesBanner">
          <section className="container mx-auto">
            <div className="flex flex-wrap items-center justify-between rounded-lg border-stroke bg-gray-2  dark:border-dark-3 dark:bg-dark-2 xs:px-10 md:px-8 lg:px-10">
              <div className="text-center Cookies-icon ">
                <FaCookieBite size={40} color='white' />
              </div>
              <div className="w-full md:w-7/12 lg:w-2/3">
                <div className="mb-6 md:mb-0">
                  {/* <h4 className="mb-1 text-xl font-semibold text-dark dark:text-white xs:text-2xl md:text-xl lg:text-2xl">
                    We use cookies
                  </h4> */}
                  <p className="text-white text-body-color dark:text-dark-6">
                    
                 

                  We use cookies to enhance your experience. By continuing to visit this site(<NavLink to="/cookie-privacy-policy" className="text-blue"> Cookies policy</NavLink>) you accept our use of cookies.
                  

                  </p>
                </div>
                
               
              </div>
              <div className="flex  items-center space-x-3 md:justify-end">
                  <button
                    className="inline-flex items-center justify-center rounded-md bg-white px-7 py-3 text-center text-black font-medium  hover:bg-gray-200"
                    onClick={acceptCookies}
                  >
                    Accept
                  </button>
                  <button
                    className="inline-flex items-center justify-center rounded-md bg-white px-7 py-3 text-center text-black font-medium  shadow-1 hover:bg-gray-200 hover:text-black "
                    onClick={declineCookies}
                  >
                    Decline
                  </button>
                </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default CookiesComponent
