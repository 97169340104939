import React from 'react'
import Title from '../TitleComp/Title'
import BlogCard from './BlogCard'
import Video from './VideoCard'
const SignalBlogVideo = () => {
    const signaldata = [
        {title:"Blog", header:"Our Blogs", descp:"Explore our blogs for insights on Forex, crypto, and stocks. Stay informed with the latest trends and expert analysis. "}
    ]
    const signaldatatwo = [
        {title:"Video", header:"Our Videos", descp:"Watch our video blogs for expert insights on Forex, crypto, and stocks. Stay updated with the latest trends and analysis."}
    ]
  return (
    <>
    <div className=' container mt-[8%]'>
      <Title data={signaldata}/>
      <BlogCard />
      <Title data={signaldatatwo}/>
<Video />
    </div>
    </>
  )
}
export default SignalBlogVideo
