import React from 'react'
import Title from '../TitleComp/Title'
import CookiePrivacy from './PrivacypolicyComp/CookiePrivacy'

const TermstoService = () => {
    const titledata = [
        {header:"Terms of Service", descp:`Welcome to INVESTORS ZON. These Terms of Use ("Terms") govern your access to and use of our websites, products, and services (collectively, the "Services"). By accessing or using our Services, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from accessing or using our Services.`}
    ]
    const cookiesdata=[
        {header:"1. Acceptance of Terms", descp:`By accessing or using Investors Zon Services, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree with these Terms, please cease using our Services immediately.`},
        {header:"2. Eligibility", descp:`To use our Services, you must be at least 18 years old or of legal age in your jurisdiction. By using our Services, you affirm that you meet this eligibility requirement.`},
        {header:"3. Account Registration", descp:`Creating an Account: To access certain features of our Services, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update this information as necessary to maintain its accuracy.

Account Security: You are responsible for keeping your account password confidential and for all activities that occur under your account. You agree to notify us immediately if you become aware of any unauthorized use of your account or any other security breach.`},
        {header:"4. Use of Services", descp:`Permitted Use: You agree to use our Services solely for lawful purposes and in accordance with these Terms. You are responsible for your use of the Services and for any content you provide.

Prohibited Use: You agree not to use the Services in any manner that violates any applicable federal, state, local, or international law or regulation. Additionally, you agree not to:

Use the Services for any unauthorized or fraudulent purpose.
Engage in any activity that could damage, disable, overburden, or impair the Services or interfere with others' use of the Services.
Access or attempt to access any content or areas of the Services that you are not authorized to access.
Use automated means or forms of scraping or data extraction to access, query, or collect data from the Services.`},
        {header:"5. Intellectual Property", descp:`Ownership: Blokista World and its licensors hold all rights, title, and interest in and to the Services, including all intellectual property rights. You agree not to remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights notices included with or accompanying the Services.

Limited License: Subject to your compliance with these Terms, Blokista World grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal, non-commercial use.`},
        {header:"6. User Content", descp:`Responsibility for User Content: You are solely responsible for any content you post, upload, or otherwise make available through the Services. You represent and warrant that you have all necessary rights to provide such content and that your content does not infringe or violate the rights of any third party.

License to User Content: By posting or uploading content to the Services, you grant Blokista World a worldwide, non-exclusive, royalty-free, fully paid, sublicensable, and transferable license to use, reproduce, distribute, create derivative works of, display, and perform your content in connection with the Services and Blokista World’s business.






`},
        {header:"7. Third-Party Services and Content", descp:`Our Services may include links to third-party websites, services, or resources that are not owned or controlled by Blokista World. We are not responsible for the content, policies, or practices of any third-party websites or services. Any interactions or transactions with advertisers found on or through our Services, including payment, delivery of goods, and any other terms (such as warranties), are solely between you and the advertisers.`},
        {header:"8. Termination", descp:`Termination by You: You may terminate your account at any time by following the instructions provided within the Services or by contacting us directly.

Termination by Us: We may terminate or suspend your account and access to the Services immediately, without prior notice or liability, for any reason, including if you violate these Terms.`},
        {header:"9. Disclaimer of Warranties", descp:`The Services are provided "as is" and "as available," without any warranties, either express or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance. Blokista World does not guarantee that the Services will be uninterrupted, secure, or error-free, nor does it warrant that any defects will be corrected.`},
        {header:"10. Limitation of Liability", descp:`Under no circumstances shall Blokista World, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages. This includes, but is not limited to, loss of profits, data, use, goodwill, or other intangible losses arising from: (i) your use or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or personal information stored therein; (iii) any interruption or cessation of transmission to or from the Services; (iv) any bugs, viruses, trojan horses, or similar issues transmitted to or through our Services by third parties; and/or (v) any errors or omissions in any content, or any loss or damage resulting from your use of any content posted, emailed, transmitted, or otherwise made available via the Services, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not Blokista World was advised of the possibility of such damages.`},
        {header:"11. Indemnification", descp:`You agree to defend, indemnify, and hold harmless Blokista World, along with its affiliates, licensors, and service providers.`},
    ]
  return (
    <div>
      <div className=' container mt-[10%]'>
        <Title data={titledata}/>
        <CookiePrivacy data={cookiesdata}/>
        </div>
    </div>
  )
}

export default TermstoService
