import React, { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import Toast from '../Toast/Toast';
import { CartContext } from '../../context/CartContext';
import { buyCourse } from '../../api/CourseApi';
import ConfirmModal from '../Modals/confirmed';
import ReviewModal from '../DashboardComp/ReviewModal';
import ShowReviewModal from '../DashboardComp/ShowReviewModal';
import { ToastContainer } from 'react-toastify';
import ReviewModuleModal from '../DashboardComp/ReviewModuleModal';
import { CgKey } from 'react-icons/cg';
import ShowReviewModuleModal from '../DashboardComp/ShowReviewModuleModal';
const MyCourseCard = ({ data ,reviews}) => {

  console.log("+++++++++++++++",data,reviews)
  const { addToCart } = useContext(CartContext);
  const [showToast, setShowToast] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const updatedModules = data?.map((course) => {
    const isReviewed = reviews?.filter((review) => review.moduleId === course.moduleId).length > 0;
    return { ...course, isReviewed };
  });

  console.log("update-modules",updatedModules)
  const authUser = useAuthUser()
  const navigate = useNavigate();
 console.log("module-reviewss",reviews)
  const truncateDescription = (description, wordLimit) => {
    const words = description?.split(' ');
    if (words?.length <= wordLimit) {
      return description;
    }
    return words?.slice(0, wordLimit).join(' ') + '...';
  };
  const handlePurchaseCourse =  async(id) => {
    console.log("id", id)
    if(!authUser){
      navigate("/Signin")
    }else{
      const response = await buyCourse(id, authUser.token);
      handlePurchase();
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleViewModal = () => {
    setIsViewModalOpen(true);
  };
  const handleViewClosedModal = () => {
    setIsViewModalOpen(false);
  };
  const handleClosedModal = () => {
    setIsModalOpen(false);
  };
  const handlePurchase = () => {
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
    // Call any additional function here
    navigate("/dashboard")
    console.log('Modal closed and additional function called');
  };
  return (
    <>
      <div className={`flex  ${data.length === 1 ? 'justify-center' : 'justify-evenly'} flex-wrap gap-1`}>
        {updatedModules?.map((val, index) => {
          console.log(val, "!!!!!!!!!!")
  const someState = { id: val.id };   
          return (
          <div key={index} className={`w-1/3 shadow-md mb-[4%] px-2 ${data.length === 1 ? 'md:w-1/2 lg:w-1/3' : ''}`}>
            <div className="wow fadeInUp group mb-5 relative" data-wow-delay=".1s">
              <div className="mb-8 overflow-hidden rounded-[5px] relative h-[230px]">
                <NavLink to={val?.Course?.href} className="block">
                  <img
                    src={val?.image ? `https://investorszon.com${val?.image}`: "https://placehold.co/600x400"}
                    alt="image"
                    className="w-full h-[100%] transition group-hover:rotate-6 group-hover:scale-125"
                  />
                </NavLink>
              </div>
              <div>
                <h3 className="w-[80%] m-auto flex items-center justify-center">
                  <a
                    href={val?.Course?.href}
                    className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white dark:hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl"
                  >
                    {val.title}
                  </a>
                </h3>
                <p className="max-w-[80%] m-auto text-center text-body-color dark:text-dark-6">
                  <a href={val?.Course?.href} className="block">
                  {truncateDescription(val.description, 5)}
                  </a>
                </p>
              </div>
              <div className="absolute inset-0 bg-blue-500 bg-opacity-75 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
                <NavLink state={someState} to={`/single-course-details/${val.slug}`} className="bg-white text-blue-600 font-bold text-center py-2  w-[30%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110"> View
                </NavLink>
              </div>      
            </div>
         
            {/* <div className='flex justify-end mb-2'>
                {val?.isReviewed ? 
                <button
                className="bg-green-600 w-[80%] m-auto text-white py-2 px-4 rounded-md"
                onClick={handleViewModal}
              >
                View Review
              </button>
               :<button
                className="bg-green-600 w-[80%] m-auto text-white py-2 px-4 rounded-md"
                onClick={handleOpenModal}
              >
                Review Module
              </button>
        }
              <ReviewModuleModal data={data}  isOpen={isModalOpen} onClose={handleClosedModal} />
              <ShowReviewModuleModal id={data[0]?.moduleId  } reviews={reviews}  isOpen={isViewModalOpen} onClose={handleViewClosedModal} />
              <ToastContainer />
              </div>
            */}
          </div>
          )
        })}
      </div>
      {/* Render toast when showToast is true */}
      <Toast
        message={"Course added to cart!"}
        type={"success"}
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ConfirmModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        message="You have successfully purchased a course!"
      />
    </>
  );
};
export default MyCourseCard;
