import React, { useEffect, useRef } from 'react';

const SymbolWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const widgetContainer = widgetRef.current;

    if (widgetContainer) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
      script.innerHTML = JSON.stringify({
        "symbol": "NASDAQ:AAPL",
        "width": "520",
        "locale": "en",
        "colorTheme": "light",
        "isTransparent": false
      });

      widgetContainer.appendChild(script);

      return () => {
        if (widgetContainer.contains(script)) {
          widgetContainer.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget" ref={widgetRef}></div>
    </div>
  );
};

export default SymbolWidget;
