import "./App.css";
import React, { useState } from "react";
import Header from "./Components/Header/Header";
import Feature from "./Components/Feature/Feature";
import About from "./Components/About/About";
import Cta from "./Components/CTA/Cta";
import Pricing from "./Components/Pricing/Pricing";
import Faq from "./Components/Faq/Faq";
import Team from "./Components/Team/Team";
import Contact from "./Components/Contact/Contact";
import Brand from "./Components/Brand/Brand";
import Homepage from "./Components/Homepage/Homepage";
import { Route, Routes } from "react-router-dom";
import Testominal from "./Components/Testominal/Testominal";
import Layout from "./Components/Common/Common";
import Signin from "./Components/Signin/Signin";
import Signup from "./Components/Signup/Signup";
import Ourmission from "./Components/Ourmission/Ourmission";
import Ourservices from "./Components/Ourservices/Ourservices";
import Whyrisefx from "./Components/Whyrisefx/Whyrisefx";
import StandardAccount from "./Components/StandardAccount/StandardAccount";
import ClassicAccount from "./Components/ClassicAccount/ClassicAccount";
import ProAccount from "./Components/PROAccount/ProAccount";
import VipAccount from "./Components/VipAccount/VipAccount";

import Overview from "./Components/Overview/Overview";
import BenifitsOurJoinPathner from "./Components/BenifitOurJoinPatner/BenifitsOurJoinPathner";
import ContactUs from "./Components/ContactUs/ContactUs";
import PricyPolicy from "./Components/PricyPolicy/PricyPolicy";
import Courses from "./Components/Blog/Blog";
import ForexMarket from "./Components/ForexMarket";
import SignalGenerations from "./Components/TradingSignalsPages/SignalGenerations";
import TradingSignal from "./Components/Pricing/Pricing";
import ForexLearning from "./Components/SingleCourseDetails/SingleCourseDetails";
import StockMarket from "./Components/StockMarket";
import MutualFund from "./Components/MutualFund";
import CommodityMarket from "./Components/CommodityMarket";
import CurrencyParis from "./Components/ForexMarketPages/CurrencyParis";
import TradingStrategies from "./Components/ForexMarketPages/TradingStrategies";
import MarketAnalysis from "./Components/ForexMarketPages/MarketAnalysis";
import RiskManagemnet from "./Components/ForexMarketPages/RiskManagemnet";
import EconomicCalender from "./Components/ForexMarketPages/EconomicCalender";
import TradingPlatform from "./Components/ForexMarketPages/TradingPlatform";
import TechnicalIndicators from "./Components/ForexMarketPages/technicalIndicators";
import ForexGlossary from "./Components/ForexMarketPages/ForexGlossary";
import ReferdStock from "./Components/StockMarketPages/ReferdStock";
import BlueChipStock from "./Components/StockMarketPages/BlueChipStock";
import GrowthStock from "./Components/StockMarketPages/GrowthStock";
import ValueStock from "./Components/StockMarketPages/ValueStock";
import DividentStock from "./Components/StockMarketPages/DividentStock";
import CommonStocks from "./Components/StockMarketPages/CommonStocks";
import ByAssestsClass from "./Components/MutualFundPages/ByAssestsClass";
import ByInvestmnet from "./Components/MutualFundPages/ByInvestmnet";
import ByStructure from "./Components/MutualFundPages/ByStructure";
import Environment from "./Components/CommodityPages/Environment";
import Energy from "./Components/CommodityPages/Energy";
import Metal from "./Components/CommodityPages/Metal";
import Agriculture from "./Components/CommodityPages/Agriculture";
import WhatIsForex from "./Components/WhatIsForex";
import UserDashoard from "./Components/AfterLoginNavbar/UserDashoard";
import Dashboard from "./Components/DashboardComp/Dashboard";
import Notfound from "./Components/404notfound";
import CourseDetails from "./Components/SubComponents/singleModuleDetails";
import PreviewCourse from "./Components/SubComponents/PreviewCourse";
import TradeForex from "./Components/SubComponents/TradeForex";
import AboutInvestorszon from "./Components/AboutInvestorZon";
import CourseSubscription from "./Components/CourseSubscription";
import Cart from "./Components/cart/Cart";
import Signals from "./Components/Signals";
import MyCourses from "./Components/SubComponents/MyCourses";
import MyCourseModule from "./Components/SubComponents/MyCourses-CourseModules";
import MyCurseDetails from "./Components/SubComponents/MyCurseDetails";
import MyCourse from "./Components/Blog/MyCourse";
import CryptoMarket from "./Components/CryptoMarket";
import SignalSubscription from "./Components/SubComponents/SignalSubscription";
import ForgotPassword from "./Components/ForgotPassword";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import SingleModuleDetails from "./Components/SubComponents/singleModuleDetails";
import SingleCourseDetails from "./Components/SingleCourseDetails/SingleCourseDetails";
import Certificate from "./Components/CertificateComp/Certificate";
import PurchasedModules from "./Components/ModulesComp/PurchasedModules";
import PurchasedModuleDetails from "./Components/SubComponents/PurchasedModuleDetails";
import ModuleCertificate from "./Components/CertificateComp/ModuleCertificate";
import MyCertificate from "./Components/CertificateComp/MyCertificate";
import SubscribedCourses from "./Components/SubscribedCourses/SubscribedCourses";
import SubscribedModules from "./Components/SubscribedCourses/SubscribedModules";
import SubscribedModulesItems from "./Components/SubscribedCourses/SubscribedModulesItems";
import SubscribedModulesItemsContent from "./Components/SubscribedCourses/SubscribedModulesItemsContent";
import MarketInsight from "./Components/ToolsComp/MarketInsight";
import EconomicCalendar from "./Components/ToolsComp/EconomicCalendar";
import NewsAndAnalysis from "./Components/ToolsComp/NewsAndAnalysis";
import TradingCalculator from "./Components/ToolsComp/TradingCalculator";
import MarketHeatmap from "./Components/ToolsComp/MarketHeatmap";
import MySubscribedCourseList from "./Components/MySubscribedCoursesList/MySubscribedCourses";
import PreviewSubscribedCourse from "./Components/SubComponents/PreviewSubscribedCourse";
import SinglePurchasedModuleDetails from "./Components/SubComponents/SinglePurchasedModuleDetails";
import FreePaidSignals from "./Components/FreePaidSignals/FreePaidSignals";
import SignalBlogVideo from "./Components/SignalBlog&videoComp/SignalBlogVideo";
import SignalAnnoucement from "./Components/SignalAnnoucement/SignalAnnoucement";
import Tools from "./Components/ToolsComp/Tools";
import Discover from "./Components/DiscoverComp/Discover";
import AboutUs from "./Components/AboutusComp/AboutUs";
import Founderinvestorzon from "./Components/FounderInvestorZonComp/FounderInvestorZon";
import BlogComp from "./Components/SubComponents/BlogComp";
import VideoBlog from "./Components/SubComponents/VideoBlog";
import AnnouncementBlog from "./Components/SubComponents/AnnoumentBlog";
import CookiesandPrivacy from "./Components/Footer/CookiesandPrivacy";
import TermstoService from "./Components/Footer/TermstoService";
import VerifyCertificate from "./Components/CertificateComp/VerifyCertificate";
import { LoaderProvider } from "./Components/Common/LoaderContext";
import SubscribeCertificate from "./Components/CertificateComp/SubscriptionCertificate";
import ShareMyCertificate from "./Components/CertificateComp/ShareCertificate";



function App() {

  return (
    <>
    <LoaderProvider>
      <Layout>
        <Routes>
        <Route path={'/dashboard'} element={
            <RequireAuth fallbackPath={'/signin'} >
              <Dashboard/>
             </RequireAuth>
          }/>
        <Route path={'/previewCourse/:slug'} element={
            <RequireAuth fallbackPath={'/signin'} >
              <PreviewCourse/>
            </RequireAuth>
          }/>

<Route path={'/previewSubscribedCourse/:slug'} element={
            <RequireAuth fallbackPath={'/signin'} >
              <PreviewSubscribedCourse/>
            </RequireAuth>
          }/>
          <Route path="/" element={<Homepage />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/Feature" element={<Feature />} />
          <Route path="/About" element={<About />} />
          <Route path="/Cta" element={<Cta />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/Testominal" element={<Testominal />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/school-of-investors-zon" element={<Courses />} />
          <Route path="/cart" element={<Cart/>} />
          <Route path="/Brand" element={<Brand />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/Ourmission" element={<Ourmission />} />
          <Route path="/Ourservices" element={<Ourservices />} />
          <Route path="/Whyrisfirstefx" element={<Whyrisefx />} />
          <Route path="/StandardAccount" element={<StandardAccount />} />
          <Route path="/ClassicAccount" element={<ClassicAccount />} />
          <Route path="/ProAccount" element={<ProAccount />} />
          <Route path="/VipAccount" element={<VipAccount />} />
        
          <Route path="/Overview" element={<Overview />} />
          <Route path="/BenifitsOurJoinPathner" element={<BenifitsOurJoinPathner />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/PricyPolicy" element={<PricyPolicy />} />
          <Route path="/single-module-details/:title" element={<SingleModuleDetails/>} />
          <Route path="/single-purchased-module-details/:title" element={<SinglePurchasedModuleDetails/>} />
          <Route path="/what-is-forex" element={<WhatIsForex />} />
          <Route path="/previewCourse/:title" element={<PreviewCourse/>} />
          <Route path="/forex-Market" element={<CurrencyParis />} />
          <Route path="/stock-market" element={<StockMarket />} />
          <Route path="/mutual-fund" element={<MutualFund />} />
          <Route path="/commodity-market" element={<CommodityMarket />} />
          <Route path="/single-course-details/:id" element={<SingleCourseDetails />} />

          <Route path="/signal-generations" element={<SignalGenerations />} />
          <Route path="/trading-signal" element={<TradingSignal />} />

          {/* Forex Maket Routes */}
          <Route path="/market" element={<ForexMarket />} />

          
          <Route path="/tradingstrategies" element={<TradingStrategies />} />
          <Route path="/marketanalysis" element={<MarketAnalysis />} />
          <Route path="/riskmanagement" element={<RiskManagemnet />} />
          <Route path="/economiccalender" element={<EconomicCalender />} />
          <Route path="/technicalindicators" element={<TechnicalIndicators />} />
          <Route path="/tradingplatform" element={<TradingPlatform />} />
          <Route path="/forex-glossary" element={<ForexGlossary />} />
          <Route path="/crypto-market" element={<CryptoMarket />} />
          
          {/* Stock Market Routes */}
          <Route path="/referdstock" element={<ReferdStock />} />
          <Route path="/bluechipstock" element={<BlueChipStock />} />
          <Route path="/growthstock" element={<GrowthStock />} />
          <Route path="/valuestock" element={<ValueStock />} />
          <Route path="/dividentstock" element={<DividentStock />} />
          <Route path="/commonstocks" element={<CommonStocks />} />

          {/* Mutual Fund Routes */}
          <Route path="/byassestsclass" element={<ByAssestsClass />} />
          <Route path="/byinvestmnet" element={<ByInvestmnet />} />
          <Route path="/bystructure" element={<ByStructure />} />

          {/*  Commodity Routes */}
          <Route path="/environment" element={<Environment />} />
          <Route path="/agriculture" element={<Agriculture />} />
          <Route path="/metal" element={<Metal />} />
          <Route path="/energy" element={<Energy />} />


          {/*  Dashboard Routes */}
          <Route path="/userdashboard" element={<UserDashoard />} />
            <Route path="/dashboard" element={<Dashboard  />} />

 {/*  404 not found Routes */}
            <Route path="*" element={<Notfound  />} />
            <Route path="/about-investorszon" element={<AboutInvestorszon  />} />
            <Route path="/course-subscription-plan" element={<CourseSubscription  />} />

            <Route path="/signals" element={<Signals  />} />
            <Route path="/signals-subscription" element={<SignalSubscription  />} />
          
           <Route path="/my-course/:id" element={<MyCourses />} />
            <Route path="/my-coursesmodules" element={<MyCourseModule />} />
            <Route path="/my-modules" element ={<MyCurseDetails/>}/>
            <Route path="/my-courses" element ={<MyCourse/>}/>

            <Route path="/my-certificate/:id" element ={<Certificate/>}/>
            <Route path="/my-subscription-certificate/:id" element ={<SubscribeCertificate/>}/>
            <Route path="/my-module-certificate/:id" element ={<ModuleCertificate/>}/>
           


           <Route path="/purchased-modules-list"  element={<PurchasedModules/>}/>
          <Route path="/purchased-module-details/:id" element={<PurchasedModuleDetails/>} />

            <Route path="/my-certificates" element ={<MyCertificate/>}/>
            <Route path="/my-course-certificate" element ={<Certificate/>}/>
            <Route path="/my-module-certificate" element ={<ModuleCertificate/>}/>


            {/* <Route path="/my-subscribed-courses" element ={<SubscribedCourses/>}/> */}
            <Route path="/my-subscribed-modules/:id" element ={<SubscribedModules/>}/>
            <Route path="/my-subscribed-modules-Items/:id" element ={<SubscribedModulesItems/>}/>
            <Route path="/my-subscribed-modules-Items-Content" element ={<SubscribedModulesItemsContent/>}/>

{/* Tools Routes  */}
<Route path="/market-insight" element ={<MarketInsight/>}/>
<Route path="/economic-calendar" element ={<EconomicCalendar/>}/>
<Route path="/news-and-analysis" element ={<NewsAndAnalysis/>}/>
<Route path="/trading-calculator" element ={<TradingCalculator/>}/>
<Route path="/market-heatmap" element ={<MarketHeatmap/>}/>



            <Route path='my-subscribed-courses-list' element={<MySubscribedCourseList/>} />

            <Route path="/free-paid-crypto-signals"  element={<FreePaidSignals/>} />
            <Route path="/signal-blog-video"  element={<SignalBlogVideo/>} />
            <Route path="/signal-annoucements"  element={<SignalAnnoucement/>} />
            <Route path="/tools"  element={<Tools/>} />
            <Route path="/all-projects"  element={<Discover/>} />
            <Route path='/about-us' element={<AboutUs/>} /> 
            <Route path='/founders-investorszon' element={<Founderinvestorzon/>} /> 
            <Route path="/blog/:slug" element={<BlogComp/>}/>
            <Route path="/video-blog/:slug" element={<VideoBlog/>}/>
            <Route path="/annoucement-blog/:slug" element={<AnnouncementBlog/>}/>
            <Route path="/cookie-privacy-policy" element={<CookiesandPrivacy/>}/>
            <Route path="/terms-of-service" element={<TermstoService/>}/>
            <Route path="/my-certificate-verify-certificate" element={<VerifyCertificate/>}/>
            <Route path="/share-my-certificate/:number" element={<ShareMyCertificate/>}/>
        </Routes>
      </Layout>
      </LoaderProvider>





    </>
  );
}

export default App;
