import React, { useEffect, useState } from 'react';
import Testimonials from '../Testominal/Testominal';
import FeaturedReview from './FeaturedReview';
import CourseDescription from './CourseDescription';
import RequirementsComp from './RequirementsComp';
import CourseContent from './CourseContent';
import CourseIncludes from './CourseIncludes';
import WhatYouLearn from './WhatYouLearn';
import HeroCourseModule from './HeroCourseModule';
import Subscriptioncard from './Subscriptioncard';
import CourseModule from './CourseModule';
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useParams } from 'react-router-dom';
import { URL } from '../../config';
const MyCourses = () => {
  window.scroll(0,0)
    const [courseListtitle, setCourseListtitle ]= useState([]);
  const [moduleList,setModuleList]= useState([])
  const authUser = useAuthUser()
  const {id} =useParams();
  const fetchCourseTitle= async() =>{

    const response = await axios.get(`${URL}/courses/${id}`,{
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
      },
    });
    
    setCourseListtitle(response.data)
  }

  const fetchModules= async() =>{
      
    const response = await axios.get(`${URL}/modules-by-cid/${id}`,{
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
      },
    });
   
    setModuleList(response.data)
  }
  useEffect(()=>{
    fetchCourseTitle()
    fetchModules()
  },[id])



  return (
    <div className='mt-[110px]'>
       <div className="bg-white">
        <div className='bg-[#090E34]  p-5'>
          {/* <div className='ml-[3%]'>
      <BreadcrumbComp/>
      </div> */}
      <HeroCourseModule data={courseListtitle}/>
      </div>
      <div className='  w-[100%]   	'>
      <h2 className='text-start text-3xl font-bold ml-32 pt-12'>Various Modules in this Course</h2>
      <section className="relative z-20 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
    

      <div className="container mx-auto">
        {moduleList.map((val,index)=>{
          return  <CourseModule data={val} />
        })}
        
        
        </div>
      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#3056D3" stop-opacity="0.36" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
        
        
    
      </div>
      {/* <CourseIncludes/> */}

      {/* <WhatYouLearn/>
      <CourseIncludes/>
      <CourseContent/>
      <RequirementsComp/>
      <CourseDescription/>*/}
       {/* <FeaturedReview/>  */}
      <div className='w-[100%]'>
       
      <Testimonials/>
      </div>
     
    </div>
    </div>
  )
}

export default MyCourses
