import React from 'react'

const CookiePrivacy = ({data}) => {
  return (
    <div >
  {data.map((val, index)=>{
        return(
            <div>
            <h2 className='font-bold text-2xl mb-4'>{val.header}</h2>
         <p className='text-gray-500 mb-6'>{val.descp}</p>
         
        </div>
        )
       
    })}
    </div>
  
   
  )
}

export default CookiePrivacy
