import React from 'react'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Qutation = () => {
  return (
    <div>
      <div className=" bg-gray-150 py-24	flex justify-center">
      <div className="bg-gray p-8 rounded-lg shadow-md w-full max-w-2xl text-center">
        <p className="text-xl font-semibold italic text-gray-800 mb-4">
        <FaQuoteLeft className="text-black-400 text-4xl mb-4 inline-block" />
        </p>
        <p className="text-lg font-medium text-gray-600">
        Offering diverse financial services, innovative solutions, and seamless transactions, revolutionizing the global financial landscape and customer experience.        </p>
      </div>
    </div>
    </div>
  )
}

export default Qutation
