import React, {  useState } from "react";
import {
  FiUser,
  FiList,
  FiShoppingBag,
} from "react-icons/fi";
import { GrCertificate } from "react-icons/gr";
import { CiSettings } from "react-icons/ci";
import UserDashoard from "../AfterLoginNavbar/UserDashoard";
import DashboardTabs from "./DashboardTabs";
import DashbooardOrderHistory from "./DashbooardOrderHistory";
import DashboardSetting from "./DashboardSetting";
import DashboardSignal from "./DashboardSignal";
import DashboardMySubscription from "./DashboardMySubscriptions";
import { useLocation } from "react-router-dom";
import { BsGraphUpArrow } from "react-icons/bs";
import { LiaCentos } from "react-icons/lia";
import NoData from "../Lottie Animation Comp/NoData";
import "./Dashboard.css";
import DashboardSignalTabs from "./DashboardCetificate";
import { GrTransaction } from "react-icons/gr";


const Dashboard = () => {
  window.scroll(0, 0)
  const location = useLocation();
  console.log(location)
  const state = location.state;
  const [selectedItem, setSelectedItem] = useState(state?.name? state?.name : location.state || "MyProfile");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  return (
    <div className="mt-[80px] Dashboard">
      <div className="  flex h-fit  w-[84%]  p-0 justify-between   m-auto mt-[5%] mb-[2%]">
        <div className="main-dashboard bg-white-800 h-[90vh] w-[23%] shadow-xl 	 " style={{ background: "rgb(229 233 243)" }}>
          <ul>
            <li
              onClick={() => handleItemClick("MyProfile")}
              className={` dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "MyProfile" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <FiUser className="Dashboard-icon mr-4" />
              <span className="Dashboard-text">My Profile</span>
            </li>
            <li
              onClick={() => handleItemClick("Courses")}
              className={` dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "Courses" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <FiList className="Dashboard-icon mr-4" />
              <span className="Dashboard-text" >  My Courses </span>
            </li>
            <li
              onClick={() => handleItemClick("My Signals")}
              className={`dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "My Signals" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <BsGraphUpArrow className="Dashboard-icon mr-4" />
              <span className="Dashboard-text">    My Signals  </span>
            </li>
            <li
              onClick={() => handleItemClick("My Certificates")}
              className={`dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "My Certificates" ? "bg-blue-700 text-white" : ""
                }`}
>  
              <GrCertificate className="Dashboard-icon mr-4" />
              <span className="Dashboard-text">    My Certificates  </span>
            </li>
            <li
              onClick={() => handleItemClick("My Subscriptions")}
              className={`dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "My Subscriptions" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <LiaCentos className="Dashboard-icon mr-4" />
              <span className="Dashboard-text">My Subscriptions </span>
            </li>
            <li
              onClick={() => handleItemClick("OrderHistory")}
              className={` dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "OrderHistory" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <GrTransaction className="Dashboard-icon mr-4" />
              <span className="Dashboard-text"> My Transaction </span>
            </li>
           
            <li
              onClick={() => handleItemClick("Setting")}
              className={`dashboard-list flex items-center py-4 px-6 hover:bg-blue-700 hover:text-white cursor-pointer border-b border-gray-300 ${selectedItem === "Settings" ? "bg-blue-700 text-white" : ""
                }`}
            >
              <CiSettings className=" Dashboard-icon mr-4" />
              <span className="Dashboard-text">Settings</span>
            </li>
          </ul>
        </div>
        <div className="   bg-gray-100 w-[76%] h-[100%] pb-4 ">
          {selectedItem === "MyProfile" && (
            <div className="  ">
              <h2 className="text-xl w-[95%] m-auto mt-3 pl-4 font-semibold mb-4  ">
                {/* {selectedItem} */}
              </h2>
              <div className="w-[95%] m-auto">
                <UserDashoard />
              </div>
            </div>
          )}
          {selectedItem === "Courses" && (
            <div className="  ">
              <div className="w-[95%] m-auto mt-4 bg-white rounded-lg px-4 py-4 ">
                <DashboardTabs />
              </div>
            </div>
          )}
          {selectedItem === "My Certificates" && (
            <div className="  ">
              <div className="w-[95%] m-auto mt-4 bg-white rounded-lg px-4 py-4 ">
                <DashboardSignalTabs />
              </div>
            </div>
          )}
          {selectedItem === "Funding" && (
            <div className="  ">
              <div className="w-[95%] m-auto">
                <NoData/>
              </div>
            </div>
          )}
          {selectedItem === "My Subscriptions" && (
            <div className="  ">
              <div className="w-[95%] m-auto mt-4 bg-white rounded-lg px-4 py-4">
                <DashboardMySubscription />
              </div>
            </div>
          )}
          {selectedItem === "My Signals" && (
            <div className="  ">
              <div className="w-[95%] m-auto mt-4 bg-white rounded-lg px-4 py-4">
                <DashboardSignal />
              </div>
            </div>
          )}
          {selectedItem === "Subscription" && (
            <div className="  ">
              <div className="w-[95%] m-auto">
                <div className='space-y-12 max-w-4xl  mt-2 mx-auto mb-9 p-6 bg-white shadow-lg rounded-lg'>
                  <h1>You don’t have any active subscriptions</h1>
                </div>
              </div>
            </div>
          )}
          {selectedItem === "OrderHistory" && (
            <div className="  ">
              <div className="w-[95%] m-auto">
                <DashbooardOrderHistory />
              </div>
            </div>
          )}
          
          {selectedItem === "Setting" && (
            <div className="  ">
              <div className="w-[95%] m-auto">
                <DashboardSetting />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
