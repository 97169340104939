import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Toast from "../Toast/Toast";
import { CartContext } from "../../context/CartContext";
import { buyCourse, buyModule } from "../../api/CourseApi";
import ConfirmModal from "../Modals/confirmed";
import "./CourseCard.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CourseCard = ({ data }) => {
  const { addToCart, cart } = useContext(CartContext);
  const [showToast, setShowToast] = useState(false);
  const [showAlreadyToast,setShowAlreadyToast] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [courseToBuy,setCourseToBuy] = useState();
  const [message, setMessage] = useState("");
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [disabledCartIndexes, setDisabledCartIndexes] = useState([]);

  const handleAddToCart = (course, index) => {
    if (!disabledCartIndexes.includes(index)) {
      addToCart(course);
      setDisabledCartIndexes([...disabledCartIndexes, index]);
      setShowToast(true); // Show toast when adding to cart
      toast.success("Course added to cart!", {
        position: "top-right",
        autoClose: 5000,
        
       
        });
    }
  };

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const handlePurchaseCourse = async (val) => {
    if (!authUser) {
      navigate("/signin");
    } else {
      setCourseToBuy(val)
      // const response = await buyCourse(id, authUser?.token);
      // setMessage(response.data.message);
      handlePurchase();
    }
  };

  const handlePurchase = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    navigate("/dashboard");
  };

  const isInCart = (courseId) => {
    return cart.some((item) => item.id === courseId);
  };

  return (
    <>
      <div className={` flex ${data.length === 1 ? "justify-center" : "justify-evenly"} flex-wrap gap-1`}>
        {data?.map((val, index) =>{ 
           const someState = { id: val.id };
          return(
          <div key={index} className={`Main-Course w-[30%] shadow-md mb-[4%]  ${data.length === 1 ? "md:w-1/2 lg:w-1/3" : ""}`}>
            <div className=" wow fadeInUp group mb-5 relative" data-wow-delay=".1s">
              <div className="mb-8 overflow-hidden rounded-[5px] relative h-[170px] w-full ">
                <NavLink to={val.href} className="block h-[100%] ">
                  <img
                    src={val.image ? `https://investorszon.com${val.image}`: "https://placehold.co/600x400"}
                    alt="image"
                    className="w-full h-[100%] transition group-hover:rotate-6 group-hover:scale-125"
                  />
                </NavLink>
              </div>
              <div>
                <h3 className="w-[80%] m-auto flex items-center justify-center">
                  <a
                    href={val.href}
                    className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white dark:hover:text-primary sm:text-2xl lg:text-xl xl:text-2xl"
                  >
                    {truncateDescription(val.title, 3)} 
                  </a>
                </h3>
                <p className="max-w-[80%] h-[80px] m-auto text-center text-body-color dark:text-dark-6">
                  <a href={val.href} className="block">
                    {truncateDescription(val.description, 4)}
                  </a>
                </p>
              </div>
              <div className=" cursor-pointer flex flex-col gap-4 absolute inset-0 bg-gray-50 bg-opacity-75 pb-[150px] flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-150 rounded-[5px]">
                <NavLink
                  to={`/single-course-details/${val.slug}`}
                  state={someState}

                  className="bg-blue-600 text-white text-center py-2 w-[30%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110"
                >
                  Explore
                </NavLink>
                <NavLink
                  to={`/course-subscription-plan`}
                  className="bg-blue-600 text-white text-center py-2 w-[30%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110"
                >
                  Subscribe
                </NavLink>
              </div>
            </div>

            <div className="flex items-center justify-between w-[100%] m-auto mt-3 mb-3 rounded-lg main-card-button">
              <div className="">
                <p className="font-semibold text-blue-700 px-3 rounded py-2">
                  $ {val.price}
                </p>
              </div>
              <div className="mr-2 flex items-center justify-start">
                <button
                  onClick={() => handlePurchaseCourse(val)}
                  className="buy-button bg-[#1B44C8] w-[4vw] text-white px-3 rounded py-2"
                >
                  Buy{" "}
                </button>

                <FaCartPlus
                  style={{ marginLeft: "10px", opacity: disabledCartIndexes.includes(index) ? 0.5 : 1 }}
                  size={30}
                  color={isInCart(val.id) ? "gray" : "#1B44C8"}
                  
                  className="cursor-pointer"
                  onClick={() => isInCart(val.id) ? setShowAlreadyToast(true) : handleAddToCart(val, index)}
                />
              </div>
            </div>
          </div>
        )})}
      </div>
      {/* <Toast
        message={"Course added to cart!"}
        type={"success"}
        showToast={showToast}
        setShowToast={setShowToast}
      /> */}

<Toast
        message={"Course already added to cart!"}
        type={"error"}
        showToast={showAlreadyToast}
        setShowToast={setShowAlreadyToast}
      />
      <ConfirmModal
      setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        message={message}
        data={courseToBuy}
        type={'course'}
      />
      <ToastContainer />
    </>
  );
};

export default CourseCard;
