import React, { useEffect, useState } from 'react';
import HeroCourseModule from '../SubComponents/HeroCourseModule';
import CourseModule from '../SubComponents/CourseModule';
import Testimonials from '../Testominal/Testominal';
import Subscriptioncard from '../SubComponents/Subscriptioncard';
import axios from 'axios';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useParams,useLocation } from 'react-router-dom';
import { URL } from '../../config';
import { fetchCourseProgress, fetchModulesByCourseId, fetchSubscribedCourseFullDetails, fetchSubscribedModulesByCourseId, fetchUserBuyCourseInfo, mySubscribedCourses, updateModuleProgress } from '../../api/CourseApi';
import SubscribeCourseModule from './SubscribeCourseModule';
import SubscribedSubscriptionCard from './SubscribedSubscriptionCard';
import HeroSubscribedModule from '../SubComponents/HeroSubscribedModule';
const   SubscribedModules = () => {
    const [Courseprice, setCourseprice] = useState([]);
  const [courseListtitle, setCourseListtitle] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [percentage, setPercentage] = useState();


  const [courseInfo,setCourseList] = useState();

  const [isStarted,setIsStarted]= useState(false)

  const authUser = useAuthUser();
  const { id } = useParams();
  const location = useLocation();
  
  const {state} = location;
  if (state?.id !== undefined && state?.id !== null) {
    const moduleState = localStorage.getItem('moduleState');
    if (moduleState === null || moduleState !== state?.id) {
      localStorage.setItem('moduleState', state?.id);
    }
  }
  console.log("Sattwe==================",state)

  const fetchModules = async()=>{
    const resp = await fetchSubscribedModulesByCourseId(authUser?.token,state?.id ? state?.id :localStorage.getItem('moduleState'));
    setCourseList(resp)
  };

const [courseData,setCourseData] = useState()
  const fetchCourseTitle = async () => {
    const response = await axios.get(`${URL}/courses/${id}`, {
      headers: {
        Authorization: `Bearer ${authUser?.token}`,
      },
    });
    setCourseprice(response.data);
    setCourseListtitle(response.data);
  };


const myCourses = async()=>{
  const resp = await mySubscribedCourses(authUser?.token);
  console.log("&*&*&*&*&*&*&*&*&*&*&*&*&*",resp)
  setCourseData(resp)
}
  



  useEffect(() => {
    fetchCourseTitle();
    fetchModules();
    myCourses();
    // fetchUserPurchaseInfo();
  }, [state?.id,isStarted]);

  

  let updatedModuleData;
  if(courseInfo){

   updatedModuleData = courseInfo.map((item, index) => ({
    ...item,
    rank: index + 1,
  }));

}


console.log("courseInfo",courseInfo)


  const titleone = [
    { subheader: "Testimonials" }
  ];

  const calculateCourseCompletion = (modules) => {
    const totalModules = modules?.length;
    const totalCompletion = modules?.reduce((sum, module) => {
      const moduleCompletion = module?.progressPercentage || 0; // Ensure progress is handled correctly
      return sum + moduleCompletion;
    }, 0);
    return Math.round(totalCompletion / totalModules);
  };

  const courseCompletionPercentage = calculateCourseCompletion(courseInfo);



  console.log("courseData",courseInfo)

  return (
    <div className='mt-[4.5%]'>
    <div className="bg-white">
      <div className='bg-[#090E34] p-5'>
       
        <HeroSubscribedModule data={courseData} courseCompletionPercentage={courseCompletionPercentage}/>
        
      </div>
      <div className='w-[100%]'>
        <h2 className='Various text-start text-3xl font-bold ml-32 pt-12'>Various Modules in this Course</h2>
        <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
          <div className="container mx-auto">
            {updatedModuleData?.map((val, index, key) => {
              return <SubscribeCourseModule key={index} data={val} setIsStarted={setIsStarted} state={state?.id} />;
              
            })}
          </div>
          <div className="absolute bottom-0 right-0 -z-10">
            <svg
              width="1440"
              height="886"
              viewBox="0 0 1440 886"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="1308.65"
                  y1="1142.58"
                  x2="602.827"
                  y2="-418.681"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.36" />
                  <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
                  <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
      </div>
      <div className='w-[100%]'>
        <Testimonials data={titleone} />
        
      </div>
      <div className='Subscription absolute top-[190px] right-[3%] z-20'>
        {!loading && !isPurchased && <SubscribedSubscriptionCard data={courseListtitle} />}
        
      </div>
    </div>
    </div>
  )
}

export default SubscribedModules
