import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Logo from '../../assets/0-3.jpeg';
import { Button } from "@mui/material";
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchUserProgress } from '../../api/CourseApi';

const SubscribedModulesItemsContent = () => {
    const {id} = useParams();
    window.scroll(0,0)
    const [userCompleteDetails, setUserCompleteDetails]=useState([])
    const progressHandler = async()=>{
     const resp = await fetchUserProgress(authUser?.token,id);
     console.log("api resp",resp)
     if(resp){
      setUserCompleteDetails(resp)
     }
    }
    const authUser = useAuthUser()
    
    useEffect(() => {
      // progressHandler();
    }, []);



    
  return (
    <div className='w-full relative mt-[140px]'>
            <div className='mt-[70px] p-5 w-[60%]'>
        <h1 className='text-5xl font-bold mt-5'>Learn Forex</h1>
        <h1 className='mt-5 mb-5'>What is forex?</h1>
        <p className='mt-4 mb-4'>Quite simply, it’s the global financial market that allows one to trade currencies.</p>
        <p className='mt-4 mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid quidem architecto repellat maxime veritatis nostrum, mollitia doloremque dolores reiciendis nobis, sequi vitae tempore. Totam sequi blanditiis, minus accusamus explicabo, illum officiis, consequatur iusto dolorem assumenda deleniti delectus. Facere magnam dicta, similique asperiores autem debitis et harum odio doloribus cum nesciunt?</p>
        <img src={Logo} />
        <Button style={{ marginRight: "10px" }} className="h-[6vh] w-[10vw] mt-12" variant="contained" onClick={progressHandler} >Complete  </Button>

    </div>
    <div className='fixed right-[3%] top-[135px]  h-[300px] w-[400px] shadow-xl '>
     <div className='h-full'>
        <h1 className='font-bold flex items-center justify-center text-2xl h-[20%] bg-gray-300'>Preschool</h1>
     <div className='h-[80%] overflow-y-scroll'>
     {[1,2].map((val,index)=>{
            return <>
       <h1 className='text-2xl font-bold ml-[3%] mt-2'>What is Forex?</h1>
              <NavLink className="text-blue-900 ml-[3%] mt-3 block  m-auto" to='/previewCourse/What is Forex'  >What Is Traded In Forex?</NavLink>
       <NavLink className="text-blue-900 ml-[3%] mt-3 block  m-auto" to={`/previewCourse/How do you Trade Forex`}  >How do you trade Forex?</NavLink>
       <NavLink className="text-blue-900 ml-[3%] mt-3 block  m-auto" to='#'  >Buying And Selling Currency Pairs?</NavLink>
       <NavLink className="text-blue-900 ml-[3%] mt-3 block  m-auto" to='#'  >Forex Market Size And Liquidity?</NavLink>

            </>
        })}
      
     </div>

     </div>
    </div>
    </div>

  )
}

export default SubscribedModulesItemsContent
