import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import CousreCard from "../SubComponents/CousreCard";
import HeaderComp from "../SubComponents/HeaderComp";
import Pricing from "../Pricing/Pricing";
import Faq from "../Faq/Faq";
import Testominal from "../Testominal/Testominal";
import Cta from "../CTA/Cta";
import { fetchPublishedCourses, fetchSubscribedCourses, myCourses } from "../../api/CourseApi";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useNavigate } from "react-router-dom";
import MyCourseCard from '../SubComponents/MyCourseCard';
import NOCoursesAnimation from '../Lottie Animation Comp/NOCoursesAnimation';
import MySubscribedCourseCard from "../SubscribedCourses/MySubscribedCourseCard";
import NoData from "../Lottie Animation Comp/NoData";

const MySubscribedCourseList = () => {
  window.scroll(0,0)
    const headerData = {
        header: "My Subscribed  Courses",
        title: "View Your Learning",
        description:
          "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.",
      };
    
      const [course, setCourse] = useState([]);
      const [loading, setLoading] = useState(true);
      const authUser = useAuthUser()
      const navigate = useNavigate();
    
      const fetchCourse = async () => {
        setLoading(true);
        if(!authUser){
          return navigate('/signin');
        }
        const resp = await fetchSubscribedCourses(authUser?.token);
        setCourse(resp.data);
        console.log("my-susbcription",resp)
        setLoading(false);
      };
    
      useEffect(() => {
        fetchCourse();
      }, []);


      const loadingContainer = useRef(null);
      useEffect(() => {
        const animation = lottie.loadAnimation({
          container: loadingContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: require("../Lottie Animation Comp/NOCoursesAnimation.json"),
        });
        return () => {
          lottie.destroy();
        };
      }, [loading]);

  return (
    <div className="mt-[70px]">
      <section className="bg-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]">
        <div className="container mx-auto">
          <HeaderComp data={headerData} />
          <div className="mt-[3%] mb-[3%] w-[100%]">
            {loading ? (
               <div className="flex justify-center">
               <div className="loader-container w-[25vw] " ref={loadingContainer}></div>
            
           </div>
            ) : (course && course.length > 0 ? (
           
              <MySubscribedCourseCard data={course} />
            ) : (
             
              <div className="flex justify-center">
               <NoData/>
               
              </div>
            )
              
            )}
          </div>
          {/* <Cta /> */}
          {/* <Faq /> */}
          <Pricing />
          <Testominal />
        </div>
      </section>
    </div>
  )
}

export default MySubscribedCourseList;
