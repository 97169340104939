import axios from 'axios';
import { URL } from '../config';

export const fetchAnnouncements = async () => {
    try {
        const response = await axios.get(`${URL}/view-signal-announcements`);
        return response.data;
    } catch (error) {
        console.error('Error fetching blogs:', error);
        throw error;
    }
}
export const fetchBlogs = async () => {
    try {
        const response = await axios.get(`${URL}/view-blog-public`);
        return response.data;
    } catch (error) {
        console.error('Error fetching blogs:', error);
        throw error;
    }
}




export const fetchVideo = async () => {
    try {
        const response = await axios.get(`${URL}/view-video-signal`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Video:', error);
        throw error;
    }
}


export const Verifycertificate = async (data) => {
    try {
        const response = await axios.post(`${URL}/verify-certificate`,{certificateNumber: data});
        return response.data;
    } catch (error) {
        console.error('Error form verify certificate:', error);
        throw error;
    }
}
