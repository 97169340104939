import React, { useState, useEffect } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { getUserDetails, mySubscriptions } from "../../api/CourseApi";
import "./UserDashboard.css"
const UserDashoard = () => {
  window.scroll(0, 0)
  const [userDetails, setUserDetails] = useState([])
  const authUser = useAuthUser()

  const userHandler = async () => {
    const response = await getUserDetails(authUser?.token)
    setUserDetails(response.data)
  }
  console.log("userDetails",userDetails)
  useEffect(() => {
    userHandler();
  }, []);
  return (
    <div className=" w-full   ">
      <div className="max-w-5xl  mt-5 mb-3  p-6 bg-white shadow-lg rounded-lg ">
        <div className="mb-8 flex w-[100%] items-center justify-evenly main-userdashboard">
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Name
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={userDetails?.name}
                className='userdashboard-input-field w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.72 12.886a4.167 4.167 0 0 1 2.947-1.22h6.666a4.167 4.167 0 0 1 4.167 4.167v1.666a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v1.666a.833.833 0 1 1-1.667 0v-1.666a4.17 4.17 0 0 1 1.22-2.947ZM10 3.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.166 2.5a4.167 4.167 0 1 1 8.333 0 4.167 4.167 0 0 1-8.333 0Z"
                    opacity={0.8}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Email
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={userDetails?.email}
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity={0.8} fillRule="evenodd" clipRule="evenodd" fill="#9CA3AF">
                    <path d="M3.334 4.167A.838.838 0 0 0 2.501 5v10c0 .456.377.833.833.833h13.333a.838.838 0 0 0 .834-.833V5a.838.838 0 0 0-.834-.833H3.334ZM.834 5c0-1.377 1.123-2.5 2.5-2.5h13.333c1.377 0 2.5 1.123 2.5 2.5v10c0 1.377-1.123 2.5-2.5 2.5H3.334a2.505 2.505 0 0 1-2.5-2.5V5Z" />
                    <path d="M.985 4.522a.833.833 0 0 1 1.16-.205l7.856 5.499 7.855-5.5a.833.833 0 1 1 .956 1.366l-8.333 5.833a.833.833 0 0 1-.956 0L1.19 5.682a.833.833 0 0 1-.205-1.16Z" />
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="mb-8 flex w-[100%]  items-center justify-evenly main-userdashboard">
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Phone Number
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={`${userDetails?.phoneCC===null ? "00":userDetails?.phoneCC} ${userDetails?.phoneNumber}`}
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5 2H5.5C4.675 2 4 2.675 4 3.5V20.5C4 21.325 4.675 22 5.5 22H18.5C19.325 22 20 21.325 20 20.5V3.5C20 2.675 19.325 2 18.5 2ZM17 19H7V4H17V19ZM12 21C11.45 21 11 20.55 11 20C11 19.45 11.45 19 12 19C12.55 19 13 19.45 13 20C13 20.55 12.55 21 12 21Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Gender
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={userDetails?.gender}
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.72 12.886a4.167 4.167 0 0 1 2.947-1.22h6.666a4.167 4.167 0 0 1 4.167 4.167v1.666a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v1.666a.833.833 0 1 1-1.667 0v-1.666a4.17 4.17 0 0 1 1.22-2.947ZM10 3.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.166 2.5a4.167 4.167 0 1 1 8.333 0 4.167 4.167 0 0 1-8.333 0Z"
                    opacity={0.8}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="mb-8 flex w-[100%] items-center justify-evenly main-userdashboard">
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Address
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={userDetails?.address}
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 2C8.13401 2 5 5.13401 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13401 15.866 2 12 2ZM12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="rbt-form-group w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Country
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={userDetails?.country}
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity={0.8}>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.0007 2.50065C5.85852 2.50065 2.50065 5.85852 2.50065 10.0007C2.50065 14.1428 5.85852 17.5007 10.0007 17.5007C14.1428 17.5007 17.5007 14.1428 17.5007 10.0007C17.5007 5.85852 14.1428 2.50065 10.0007 2.50065ZM0.833984 10.0007C0.833984 4.93804 4.93804 0.833984 10.0007 0.833984C15.0633 0.833984 19.1673 4.93804 19.1673 10.0007C19.1673 15.0633 15.0633 19.1673 10.0007 19.1673C4.93804 19.1673 0.833984 15.0633 0.833984 10.0007Z"
                      fill="#9CA3AF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.833984 9.99935C0.833984 9.53911 1.20708 9.16602 1.66732 9.16602H18.334C18.7942 9.16602 19.1673 9.53911 19.1673 9.99935C19.1673 10.4596 18.7942 10.8327 18.334 10.8327H1.66732C1.20708 10.8327 0.833984 10.4596 0.833984 9.99935Z"
                      fill="#9CA3AF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.50084 10.0008C7.55796 12.5632 8.4392 15.0301 10.0006 17.0418C11.5621 15.0301 12.4433 12.5632 12.5005 10.0008C12.4433 7.43845 11.5621 4.97153 10.0007 2.95982C8.4392 4.97153 7.55796 7.43845 7.50084 10.0008ZM10.0007 1.66749L9.38536 1.10547C7.16473 3.53658 5.90275 6.69153 5.83417 9.98346C5.83392 9.99503 5.83392 10.0066 5.83417 10.0182C5.90275 13.3101 7.16473 16.4651 9.38536 18.8962C9.54325 19.069 9.76655 19.1675 10.0007 19.1675C10.2348 19.1675 10.4581 19.069 10.6159 18.8962C12.8366 16.4651 14.0986 13.3101 14.1671 10.0182C14.1674 10.0066 14.1674 9.99503 14.1671 9.98346C14.0986 6.69153 12.8366 3.53658 10.6159 1.10547L10.0007 1.66749Z"
                      fill="#9CA3AF"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="mb-8 flex w-[100%] items-center justify-evenly main-userdashboard">
          <div className="rbt-form-group  w-[40%] inner-main-userdashboard">
            <label className='mb-[10px] block text-base font-medium text-dark dark:text-white'>
              Whatsapp
            </label>
            <div className="relative">
              <input
                type='text'
                placeholder='Active Input'
                disabled
                value={
                  !userDetails?.whatsappCC && !userDetails?.whatsAppNumber 
                    ? "Not Provided" 
                    : `${userDetails?.whatsappCC || ''} ${userDetails?.whatsAppNumber || ''}`.trim()
                }
                className='w-full rounded-md  dark:border-dark-3 py-[10px]  pr-3 pl-12 text-dark-6  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 dark:disabled:bg-dark-4 dark:disabled:border-dark-4'
              />
              <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5 2H5.5C4.675 2 4 2.675 4 3.5V20.5C4 21.325 4.675 22 5.5 22H18.5C19.325 22 20 21.325 20 20.5V3.5C20 2.675 19.325 2 18.5 2ZM17 19H7V4H17V19ZM12 21C11.45 21 11 20.55 11 20C11 19.45 11.45 19 12 19C12.55 19 13 19.45 13 20C13 20.55 12.55 21 12 21Z"
                    fill="#9CA3AF"  
                  />
                </svg>
              </span>
            </div>

          </div>
          <div className="rbt-form-group  w-[40%] inner-main-userdashboard">
            </div>

        </div>

      </div>
    </div>
  );
};

export default UserDashoard;
