import React, { useState, useEffect } from 'react';
import { fetchAnnouncements } from '../../api/BlogApi';
import "./BlogCard.css"
import NoData from '../Lottie Animation Comp/NoData';
import { FaSpinner } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const AnnouncementCard = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadAnnouncements = async () => {
            try {
                const data = await fetchAnnouncements();
                console.log('Fetched data:', data); // Log the fetched data
                if (data) {
                    setAnnouncements(data?.announcements);
                } else {
                    setError('Unexpected response format');
                }
            } catch (error) {
                setError('Failed to fetch announcements');
                console.error('Error fetching announcements:', error);
            } finally {
                setLoading(false);
            }
        };

        loadAnnouncements();

    }, []);

    if (loading) {
        return <div><div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
        <FaSpinner className="animate-spin text-white" size={40} />
    </div></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    const truncateDescription = (description, wordLimit) => {
        const words = description.split(" ");
        if (words.length <= wordLimit) {
          return description;
        }
        return words.slice(0, wordLimit).join(" ") + "...";
      };
    return (
        <div>
            {loading ? (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50" style={{ zIndex: 999999 }}>
                    <FaSpinner className="animate-spin text-white" size={40} />
                </div>
            ) : (announcements && announcements.length > 0 ? (
                <div className="flex flex-wrap justify-center gap-4 w-full mb-4">
                    {announcements?.map((val, index) =>{ 
                                 const blogContent = {content:val.body}
                        return(
                        <div key={index} className="main-blog mb-10 w-[30%] border rounded-xl hover:shadow-lg relative group">
                            <div className="mb-8 overflow-hidden rounded-t-xl">
                                <img
                                    src={val.image ? `https://investorszon.com${val.image}` : "https://placehold.co/600x400"}
                                    alt="Announcement"
                                    style={{ width: "100%", height: "200px", objectFit: "cover" }}
                                />
                            </div>
                            <div className="p-4 flex flex-col">
                                <span className="mb-5 w-fit inline-block rounded bg-primary px-4 py-1 text-center text-xs font-semibold leading-loose text-white">
                                    {val.link}
                                </span>
                                <h3 className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                                    {val.title}
                                </h3>
                                {/* <p className="text-base text-body-color dark:text-dark-6">
                                {truncateDescription(val.body, 10)}
                                </p> */}
                            </div>
                            <div
                    className="absolute inset-0 flex justify-center bg-blue-500 bg-opacity-50 hover:rounded-lg items-center opacity-0 transition-opacity duration-300 hover:opacity-100 cursor-pointer"
                  >
                    <NavLink  state={blogContent} to={`/annoucement-blog/${val.slug}`} className="bg-white text-blue-600 font-bold text-center py-2  w-[50%] rounded-[30px] transform transition-transform duration-300 group-hover:scale-110">
                    Check Annoucements
                                            </NavLink>
                  </div>
                        </div>
                    )})}
                </div>
            ) : (
                <NoData />
            ))}
        </div>
    );
}

export default AnnouncementCard;
