import React from 'react'
import CurrencyParis from '../ForexMarketPages/CurrencyParis'

const Agriculture = () => {
  window.scroll(0,0)
  return (
    <div>
             <CurrencyParis/> 

    </div>
  )
}

export default Agriculture
