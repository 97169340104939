import React from 'react'
import { FaTelegramPlane } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const Buttoncard = ({data}) => {
  return (
    <div>
      {data.map((val,index)=>{
        return(
<section key={index} className="py-20 lg:py-[120px] bg-white dark:bg-dark">
  <div className="container mx-auto px-4">
    <div className="relative z-10 overflow-hidden rounded bg-primary py-12 px-8 md:p-[70px]">
      <div className="flex flex-wrap items-center justify-between -mx-4">
        <div className="w-full px-4 lg:w-[70%] mb-8 lg:mb-0">
          <h2 className="mb-6 text-3xl font-bold leading-tight text-white sm:mb-8 sm:text-[40px]/[48px] lg:mb-0">
            <span className="block mb-2"> 
              {val.title}
              {/* <span className='ml-10'>{val?.title1}</span> */}
            </span>
           
          </h2>
          <span className="block mb-4 text-base font-medium text-white">
            {val.descp}
          </span>
        </div>
        <div className="w-full px-4 lg:w-[30%]">
          <div className="flex justify-center lg:justify-end">
            <NavLink
              to="/signup"
              className="inline-flex py-3 text-base font-medium text-black transition rounded-md bg-white px-7 hover:bg-opacity-90 hover:bg-blue-500 hover:text-white"
            >
              {/* <span className='mr-4'>{val.icon}</span> */}
              <FaTelegramPlane size={20} className='mr-4'/>
              {val.button}
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <span className="absolute top-0 left-0 z-[-1]">
          <svg
            width="189"
            height="162"
            viewBox="0 0 189 162"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="16"
              cy="-16.5"
              rx="173"
              ry="178.5"
              transform="rotate(180 16 -16.5)"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-157"
                y1="-107.754"
                x2="98.5011"
                y2="-106.425"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" stop-opacity="0.07" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute bottom-0 right-0 z-[-1]">
          <svg
            width="191"
            height="208"
            viewBox="0 0 191 208"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="173"
              cy="178.5"
              rx="173"
              ry="178.5"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-3.27832e-05"
                y1="87.2457"
                x2="255.501"
                y2="88.5747"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" stop-opacity="0.07" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </div>
  </div>
</section>

        )
      })}
      
    </div>
  )
}

export default Buttoncard
