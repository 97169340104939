import lottie from "lottie-web";
import React, { useEffect, useRef,useState } from "react";
import { PiMonitorPlayFill } from "react-icons/pi";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchCompleteItemId, fetchModuleContentByModuleId, fetchModuleItemsById, fetchUserProgressContentItemId, fetchUserSubscriptionProgressContentItemId, setStatusSkip, setStatusView } from "../../api/CourseApi";
import { Button } from "@mui/material";
import { FaBook } from "react-icons/fa6";
import { MdLock } from "react-icons/md";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import BuyAnimation from "../Lottie Animation Comp/BuyAnimation";


const AccordionItem = ({ header, id,setTotalModuleItemsCount,isPurchased=true,IdToFind }) => {

  const [active, setActive] = useState(false);
  const [moduleItemData, setModuleItemData] = useState([]);
  const [completeId, setCompleteId] = useState(0);
  const [moduleItemList, setModuleItemList] = useState([]);
  const [total, setTotal] = useState(0);
  const authUser = useAuthUser();
  const fetchModuleItemsInfo = async () => {
    const resp = await fetchModuleItemsById(authUser?.token,id);
    setModuleItemData(resp);
    setTotalModuleItemsCount(resp.length);
    setModuleItemList(resp);
  };


  const fetchContentItem = async () => {
    const data = await fetchModuleItemsById(authUser?.token,id);
    setModuleItemList(data);
    setTotal(data.length);
  };

  // const getCompletedItemId = async () => {
  //   const resp = await fetchCompleteItemId(id);
  //   setCompleteId(resp?.moduleContentItemId || 0);
  // };

  useEffect(() => {
    fetchModuleItemsInfo();
    // fetchContentItem();
    // getCompletedItemId();
  }, []);

  let completeIndex = moduleItemList.findIndex(item => item.id === completeId);
  let completed = total - (completeIndex + 1);
  if (completed === 0) {
    completed = total;
  } else if (completeId === 0) {
    completed = 0;
  }
  const percentage = (completed / total) * 100;
  const radius = 90;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  const navigate = useNavigate();
  const handleToggle = () => {
    setActive(!active);
  };

  // const skipHandler = async (itemId) => {
  //   await setStatusSkip(itemId);
  // };

  const viewHandler = async (val) => {
    console.log("-------------val--------------------",val)
    // await setStatusView(itemId);
    navigate(`/previewSubscribedCourse/${val.slug}`,{ state: { id:val?.id } })
  };

  // const viewHandlers = async (itemId,title) => {
  //   // await setStatusView(itemId);
  //   navigate(`/previewCourse/${title}`)
  //   // alert ("Start")
  // };


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  const loadingContainer = useRef(null);
  const getStatus = (itemId, currentIndex, data) => {
    const paramIndex = data.findIndex(item => item.id === IdToFind);
    
    if (currentIndex <= paramIndex) {
      return 'done';
    } else if (currentIndex === paramIndex + 1) {
      return 'start';
    } else {
      return 'disabled';
    }
  };



    useEffect(() => {
      const animationInstance = lottie.loadAnimation({
        container: loadingContainer.current,
        renderer: "svg",
        loop: true, // Ensure the animation loops
        autoplay: true,
        animationData: require("../Lottie Animation Comp/BuyAnimation.json"),
      });
  
      return () => {
        animationInstance.destroy(); // Cleanup animation on component unmount
      };
    }, []);
  return (
    <div className="flex items-center w-[100%] justify-between">
      <div className=" mb-4 w-[80%] rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
        <button className=" faq-btn flex w-full text-left" onClick={handleToggle}>
          <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
            <svg
              className={`fill-primary stroke-primary duration-200 ease-in-out ${active ? "rotate-180" : ""}`}
              width="17"
              height="10"
              viewBox="0 0 17 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                fill=""
                stroke=""
              />
            </svg>
          </div>
          <div className="w-full ">
            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">{header}</h4>
          </div>
        </button>

        <div className={`pl-[62px] duration-200 ease-in-out ${active ? "block" : "hidden"}`}>
          <div className="p-5">
            {moduleItemData.map((val, index) => {
               console.log("&&&&&&&&&&&&&&&&&&&&&&", getStatus(val.id, index, moduleItemData))
              return(
              <div key={index} className="flex items-center justify-between mb-3 bg-white-600">
              
                <p className="text-lg mr-4 flex items-center justify-start">  <FaBook className="mr-4 text-blue-800" />{val.title}</p>
                <div className=" flex ">
               {!isPurchased && <Button style={{ marginRight: "10px" }} className="h-[4vh] w-[8vw]" variant="contained" onClick={handleClickOpen} > <MdLock size={30}  className=" mr-2"/> Unlock  </Button>}
               <Button
              style={{
                marginRight: '10px',
                background: getStatus(val.id, index, moduleItemData) === "done" ? 'blue' : 
                 getStatus(val.id, index, moduleItemData) === "start"?"green":
                'gray',
              }}
              className="h-[4vh] w-[6vw]"
              variant="contained"
              disabled={getStatus(val.id, index, moduleItemData) === "disabled"}
              onClick={() => viewHandler(val)}
            >
              {getStatus(val.id, index, moduleItemData) === "done" ? 'View' : getStatus(val.id, index, moduleItemData) === "start" ? 'Start' : "Disabled"}
            </Button>
{open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-1/4 flex flex-col items-center justify-center">
            <div className="p-4 border-b w-full">
              <h2 className="text-lg font-semibold">Unlock Module </h2>
            </div>
            <div className="  w-[200px] flex items-center justify-center">
            <div className="loader-container  " ref={loadingContainer}></div>
            
            </div>
            <div className="p-4 w-full border-t flex justify-end">
              <button
                className="bg-gray-200 text-gray-700 rounded px-4 py-2 mr-2"
                onClick={handleClose}
              >
                Dismiss
              </button>
              <button
                className="bg-blue-500 text-white rounded px-4 py-2"
                onClick={handleClose}
              >
                <NavLink to="/signIn">
                Buy Now
                </NavLink>
               
              </button>
            </div>
          </div>
        </div>
      )}
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      
    </div>
  );
};

const SubscribedAccordianComp = ({ id,setTotalModuleItemsCount,isPurchased=true }) => {

  const [moduleContentList, setModuleContentList] = useState([]);
  const [idToFind,setIdToFind] = useState();

  const authUser = useAuthUser();
  const fetchModuleItems = async () => {
    const resp = await fetchModuleContentByModuleId(authUser?.token,id);
    setModuleContentList(resp);
  };
  const fetchItemIds = async () => {
    try {
      const resp = await fetchUserSubscriptionProgressContentItemId(authUser?.token, id);
      if (resp.status === 400 && resp.message === "Progress not found") {
        console.error("Error: Progress not found");
        // Handle the error appropriately, e.g., set an error state or show a message to the user
      } else {
        console.log("itemIds", resp);
        setIdToFind(resp.moduleContentItemId);
      }
    } catch (error) {
      console.error("Error fetching item IDs:", error);
      // Handle any other errors that might occur, e.g., network issues
    }
  };

  useEffect(() => {
    fetchModuleItems();
    fetchItemIds();
  }, [id]);

  return (
    <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] text-center lg:mb-20">
              <h2 className="mb-4 text-3xl text-left font-bold text-dark dark:text-white sm:text-[40px]/[48px]">Module Content</h2>
              <p className="text-base text-left w-[60%] text-body-color dark:text-dark-6">
                This course offers a comprehensive overview of key concepts, theories, and practical applications in the subject area. It covers fundamental principles, advanced techniques, and real-world examples, ensuring students gain a solid understanding and hands-on experience. Suitable for beginners and advanced learners.
              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4 w-[65%]">
          <div className="px-4">
            {moduleContentList?.map((val, index) => (
              <AccordionItem 
              key={index} 
              header={val.title} 
              id={val.id} 
              setTotalModuleItemsCount={setTotalModuleItemsCount} 
              isPurchased={isPurchased} 
              IdToFind={idToFind}
                 />
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg width="1440" height="886" viewBox="0 0 1440 886" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.36" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default SubscribedAccordianComp;
