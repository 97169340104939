import React, { useEffect, useState } from 'react'
import { NavLink, useParams ,useNavigate, useLocation} from 'react-router-dom'
import Logo from '../../assets/0-3.jpeg';
import { Button } from "@mui/material";
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchAllDetailsUsingContentItemId, fetchModuleContentInfoByItemId, fetchModuleItemIdStatus, fetchUserProgress, fetchUserProgressContentItemId } from '../../api/CourseApi';

const AnnouncementBlog = () => {
    const { slug } = useParams();
    window.scroll(0, 0)
    const location = useLocation();
    const { content } = location.state || {};


    console.log("content",content)
    
            

    

    const authUser = useAuthUser();


    

    return (
        <div className='w-full relative mt-[140px]'>
            <div className='mt-[70px] p-5 w-[90%] m-auto'>
             <div dangerouslySetInnerHTML={{ __html: content }} />
            {/*{!isCompleted && (
                    <Button style={{ marginRight: "10px" }} className="h-[6vh] w-[10vw] mt-12" variant="contained" onClick={progressHandler}>Complete</Button>
                )} */}
            </div>
        </div>
    );
}

export default AnnouncementBlog;
