import React from 'react'
import "./ForexMarketCard.css"
const Forexmarketcard = ({ data }) => {
  return (
    <div>

      <div className="w-full  flex flex-wrap justify-center main-forex-market ">

        {data.map((card, index) => {
          return (
            <div key={index}
              className="w-[300px]  px-4 md:w-[300px] lg:w-[300px] main-homepagecard">
              <div className=" h-[400px] wow fadeInUp group mb-12 border overflow-hidden border-[#eaeff5] rounded-[20px] p-8 transition duration-300  hover:shadow-bottom inner-mains" data-wow-delay=".1s">

                <h4 className="mb-8 text-xl font-bold text-dark dark:text-white h-[50px] forex-header ">
                  {card.heading}
                </h4>
                <p className="mb-8 text-body-color dark:text-dark-6 lg:mb-9 h-[160px] forex-decp">
                  {card.description}
                </p>

              </div>
            </div>
          )

        })}
      </div>


    </div>
  )
}

export default Forexmarketcard
