import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../assets/0-3.jpeg';
import { Button } from "@mui/material";
import axios from "axios";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { fetchAllDetailsUsingContentItemId, fetchSubscribedModuleContentInfoByItemId, fetchUserProgress, fetchUserSubsriptionProgress } from '../../api/CourseApi';
const PreviewSubscribedCourse = () => {
    const {slug} = useParams();
    const [content, setContent] = useState();
    
    const location = useLocation();
    const navigate =useNavigate();
    const { id } = location.state || {};
    window.scroll(0,0)
    const [userCompleteDetails, setUserCompleteDetails]=useState([])
    const [details, setDetails] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const progressHandler = async()=>{
     const resp = await fetchUserSubsriptionProgress(authUser?.token,id,userCompleteDetails.moduleContentId,userCompleteDetails.moduleId,userCompleteDetails.courseId);

     console.log("respp----",resp)
     if (resp.status === 200) {
      const someState = { id: userCompleteDetails.moduleId };
      {userCompleteDetails?.IsModulePurchased ?  navigate(`/purchased-module-details/${userCompleteDetails?.moduleId}`, { state: someState }):
      navigate(`/my-subscribed-modules-Items/${userCompleteDetails?.moduleSlug}`, { state: someState })}
  }
     console.log("api resp",resp)
    
    }
    const authUser = useAuthUser()
    const getDetails =async()=>{
     const resp = await fetchAllDetailsUsingContentItemId(authUser?.token,slug);

     console.log("1111111111111111",resp)
     setUserCompleteDetails(resp.data)
     
    }
    const getFullDetails = async () => {
      const resp = await fetchSubscribedModuleContentInfoByItemId(authUser?.token, id);
      setDetails(resp);
  };
    useEffect(() => {
      getDetails();
      getFullDetails();

    }, []);
    useEffect(() => {
      if (details.length > 0) {
          const Completed = details.filter((val) => val.id === Number(id));
          setIsCompleted(Completed[0]?.completed);
          setContent(Completed[0]?.content);
      }
  }, [details, id]);

    const someState = {id:userCompleteDetails?.moduleId}




    
  return (
    <div className='w-full relative mt-[140px]'>
            <div className='mt-[70px] p-5 w-[60%]'>
                <div dangerouslySetInnerHTML={{ __html: content }} />
        {!isCompleted && (
                    <Button style={{ marginRight: "10px" }} className="h-[6vh] w-[10vw] mt-12" variant="contained" onClick={progressHandler}>Complete</Button>
                )}

    </div>
    <div className='fixed right-[3%] top-[150px]  h-[300px] w-[400px] shadow-xl '>
     <div className='h-full'>
        <h1 className='font-bold flex items-center justify-center text-2xl h-[20%] bg-gray-300'>{userCompleteDetails?.moduleTitle}</h1>
     <div className='h-[80%] overflow-y-scroll'>
     {details?.map((val,index)=>{
      let textColor = 'text-gray-500';
  if (val?.id === id) {
    console.log("++++++++++++++",val?.title)
    textColor = 'text-black-600'; // Set text color to red for indices smaller than the first completed index
  }

  return (
    <React.Fragment key={index}>
        <p className={`${textColor} ml-[3%] mt-3 block  m-auto`} >{val?.title}</p>
    </React.Fragment>
)
        })}
      
     </div>

     </div>
    </div>
    </div>

  )
}

export default PreviewSubscribedCourse