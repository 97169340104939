import React, { useState } from 'react';
import Logo from '../../assets/blog/blog-details-01.jpg';
import { FaUser, FaTachometerAlt, FaBookOpen } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { buyModule } from '../../api/CourseApi';
import "./Subscriptioncard.css";
import { IoCloseCircleOutline, IoCloseSharp } from "react-icons/io5";
import { MdError } from "react-icons/md";
import ErrorLottie from '../Lottie Animation Comp/ErrorLottie';
import LottieGreenCheck from '../Lottie Animation Comp/LottieGreenCheck';
import ConfirmModal from '../Modals/confirmed';

const SubscriptionModulecard = ({data}) => {
    const [activeTab, setActiveTab] = useState('profile');
    const [isToast,setIsToast]= useState(false);
    const [toastMessage,setToastMessage]= useState();
  const [isModalVisible, setModalVisible] = useState(false);

    const authUser = useAuthUser();
    const navigate = useNavigate();


    const clickHandler =()=>{
            navigate('/dashboard')
    };

    const buyHandler= async(moduleId)=>{
        const resp = await buyModule(moduleId,authUser?.token);
        if(resp.status === 200){
          setIsToast(true)
            // alert(resp?.data?.message);
            setToastMessage(resp?.data?.message)
            // navigate('/purchased-modules-list')
        }else{
          setIsToast(true)
          setToastMessage(resp?.data?.error)
            // alert(resp?.data?.error)
        }
    };

    const handlePurchaseCourse = async (val) => {
      if (!authUser) {
        navigate("/signin");
      } else {
        handlePurchase();
      }
    };

    const handlePurchase = () => {
      setModalVisible(true);
    };
    const handleCloseModal = () => {
      setModalVisible(false);
      // navigate("/dashboard");
    };

  return (
    <div className='main-subscriptioncard bg-white w-[400px] shadow-lg ' >
        <img className='w-full h-[200px] relative' src={data?.image ? `https://investorszon.com${data?.image}`: "https://placehold.co/600x400"}/>
        <div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px ">
          <li className="  w-[50%]">
            <a
              href="#"
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === 'profile'
                  ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('profile')}
            >
              <FaBookOpen className="inline-block mr-2" /> Course
            </a>
          </li>


            {!authUser && (
              <li className=" w-[50%]">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'dashboard'
                      ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }`}
                  onClick={() => setActiveTab('dashboard')}
                >
                  <FaTachometerAlt className="inline-block mr-2" /> Dashboard
                </a>
              </li>
            )}
            {authUser && (
              <li className=" w-[50%]">
                <NavLink
                  to="/dashboard"
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'dashboard'
                      ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }`}
                  onClick={() => setActiveTab('dashboard')}
                >
                  <FaTachometerAlt className="inline-block mr-2" /> Dashboard
                </NavLink>
              </li>
            )}

          </ul>
        </div>
        <div className="p-4">
          {activeTab === 'profile' && (
            <div>
              <h1 className='font-bold text-2xl'>{data?.title}</h1>
              <p className='mt-4'>{data?.description}</p>
              <button className='bg-blue-600 w-[100%] border border-gray-400 h-[40px] mt-4 text-white  hover:bg-white hover:text-black text-semibold'><NavLink to='/course-subscription-plan'>View Subscription Plan</NavLink></button>
              <p className='text-center text-gray-700 mt-4 text-sm'>Try now</p>
              <p className='text-center text-gray-700 mt-2 text-sm'>Cancel anytime</p>
              <div className='flex items-center justify-center mt-3'>
                <div className='bg-gray-600 h-[1px] w-[30%] mr-4'></div>
                <p className='mr-4 text-sm text-gray-600'>or</p>
                <div className='bg-gray-600 h-[1px] w-[30%]'></div>
              </div>

              <h1 className='font-bold text-2xl text-center'> $ {data?.price}</h1>

              {authUser && <button className='border border-gray-400 w-[100%] h-[40px] mt-4 text-black font-bold hover:bg-blue-500 hover:text-white' onClick={() => handlePurchaseCourse()} >Buy Module</button>}

              {!authUser && <button className='border border-gray-400 w-[100%] h-[40px] mt-4 text-black font-bold hover:bg-blue-500 hover:text-white' onClick={() => navigate('/signin')} >Buy Module</button>}


            </div>
          )}

          {activeTab === 'dashboard' && (
            !authUser &&
            <div>
              <NavLink to="/signin">
                <button className='bg-blue-600 w-[100%] h-[40px] mt-4 text-white' >Login</button>
              </NavLink>
          </div>
        )}

      </div>

      {isToast && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <div className="w-[40vw] h-[45vh] mx-auto bg-white p-6 rounded-lg shadow-lg">
              <IoCloseCircleOutline onClick={()=> {setIsToast(false)} } size={30} style={{float:"right",cursor:"pointer"}}/>
              <div className=" flex flex-col items-center justify-center  text-white  mt-2">             
                <h2 className="text-2xl text-black font-semibold text-center">{toastMessage}</h2>
               </div>
               <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  { toastMessage !== "Thank you for purchasing module" ? <ErrorLottie/> : <LottieGreenCheck/>}
                  </div>
                 {  toastMessage === "Thank you for purchasing module" &&<div style={{display:'flex',alignItems:"center",justifyContent:'center'}}> <button className='bg-green-500 text-white p-2 rounded' onClick={clickHandler}>View Module</button></div>}
            </div>
          </div>
        )}
    </div>
    <ConfirmModal
      setModalVisible={setModalVisible}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        data={data}
        type={'module'}
      />
    </div>
  )
}

export default SubscriptionModulecard;