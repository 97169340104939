import React, { useEffect, useRef } from 'react';

const BannerWidget = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
    script.async = true;
    script.innerHTML = `
      {
        "width": "100%",
        "height": "90vh",
        "currencies": [
          "EUR",
          "USD",
          "JPY",
          "GBP",
          "CHF",
          "AUD",
          "CAD",
          "NZD"
        ],
        "isTransparent": false,
        "colorTheme": "light",
        "locale": "en",
        "backgroundColor": "#ffffff"
      }
    `;

    const currentContainer = containerRef.current;

    if (currentContainer) {
      currentContainer.appendChild(script);
    }

    return () => {
      if (currentContainer && currentContainer.contains(script)) {
        currentContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ textAlign: '-webkit-center' }}
      className="billbord"
    >
      {/* Placeholder for the widget */}
    </div>
  );
};

export default BannerWidget;
