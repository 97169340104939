import React, { useEffect, useState } from 'react';
import Testimonials from '../Testominal/Testominal';
import FeaturedReview from './FeaturedReview';
import CourseDescription from './CourseDescription';
import RequirementsComp from './RequirementsComp';
import CourseContent from './CourseContent';
import CourseIncludes from './CourseIncludes';
import WhatYouLearn from './WhatYouLearn';
import HeroCourseModule from './HeroCourseModule';
import Subscriptioncard from './Subscriptioncard';
import CourseModule from './CourseModule';
import { useLocation, useParams } from 'react-router-dom';
import AccordionComp from './AccordionComp';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import { fetchModuleByModuleId, fetchModulePercentage, fetchModuleProgress, fetchPurchasedModule, fetchUserBuyCourseInfo } from '../../api/CourseApi';
import SubscriptionModulecard from './SubscriptionModuleCard';
import HeroMyModule from './HeroMyModule';

const SinglePurchasedModuleDetails = () => {
  const location = useLocation();
  console.log("location",location)
  const authUser = useAuthUser();
  const [isPurchased, setIsPurchased] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [percentage, setPercentage] = useState();

  const { title } = useParams();
  const [moduleHeaderInfo, setModuleHeaderList] = useState({});
  const [totalModuleItemsCount, setTotalModuleItemsCount] = useState();

  const fetchModule = async () => {
    const resp = await fetchModuleByModuleId(title);
    setModuleHeaderList(resp);
  };

  const fetchUserPurchaseInfo = async () => {
    try {
      const resp = await fetchUserBuyCourseInfo(authUser?.token, moduleHeaderInfo?.courseId);
      setIsPurchased(resp.isPurchased);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading to false once fetching is complete
    }
  };

  // const fetchModuleProgressApi = async () => {
  //   const resp = await fetchModuleProgress(authUser?.token, moduleHeaderInfo?.id);
  //   console.log("response", resp?.data?.moduleProgress);
  //   setPercentage(resp?.data?.moduleProgress);
  // };
  const getPurchasedModule=async()=>{
    const resp = await fetchPurchasedModule(authUser?.token,title);
    console.log("required data",resp)
  };

  const fetchModulePercent =async()=>{
    const resp = await fetchModulePercentage(authUser?.token,title);
    console.log("****************************UIUI",resp.data.percentage)
    setPercentage(resp.data.percentage)
  }
   

  useEffect(() => {
    fetchModule();
   
  }, [title]);

  useEffect(() => {
    if (moduleHeaderInfo?.id) {
      fetchUserPurchaseInfo();
    }
  }, [moduleHeaderInfo]);

  // useEffect(() => {
  //   if (isPurchased) {
  //     fetchModuleProgressApi();
  //   }
  // }, [isPurchased]);

  useEffect(()=>{
    if(authUser){
    getPurchasedModule();
    fetchModulePercent();
    }
  },[])

  const titledata = [
    { subheader: "Testimonials" }
  ];
  console.log("module-header",moduleHeaderInfo)


  return (
    <div className="bg-white mt-[10%]">
      <div className='bg-[#090E34] p-5'>
        <HeroMyModule data={moduleHeaderInfo}  />
      </div>
      <div className='w-[100%]'>
        <AccordionComp id={title} setTotalModuleItemsCount={setTotalModuleItemsCount} isPurchased={isPurchased} moduleId={title} />
      </div>
      <div className='w-[100%]'>
        <Testimonials data={titledata} />
      </div>
      <div className='absolute top-[190px] right-[3%] z-20'>
        {!loading && !isPurchased && <SubscriptionModulecard data={moduleHeaderInfo} />}
        
      </div>
    </div>
  );
}

export default SinglePurchasedModuleDetails;
