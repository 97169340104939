import React from 'react'
import CurrencyParis from '../ForexMarketPages/CurrencyParis'

const Energy = () => {
  window.scroll(0,0)
  return (
    <div>
             <CurrencyParis/> 

    </div>
  )
}

export default Energy
