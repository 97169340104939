import React, {  useState } from 'react'
import CryptoSignalCart from '../SubscriptionCardPerform/CrytproSignalCart';
import ForexSignalCart from '../SubscriptionCardPerform/ForexSignalCart';
import StocksSignalCart from '../SubscriptionCardPerform/StocksSignalCart';
import "./FreePaidSignals.css"
const FreePaidSignals = () => {
    const [activeButton, setActiveButton] = useState(0); // State to track active button index
  const handleButtonClick = (index) => {
    setActiveButton(index); // Update active button index on click
  };
  return (
    <div className='main mt-[4%]' >
        <div className=' container  '>
        <section className=" lg:pb-20 lg:pt-[120px] dark:bg-dark">
          <h2 className='m-auto mb-5  mt-5 text-center font-bold leading-tight text-dark dark:text-white sm:text-[40px] sm:leading-[1.2'> Open Signal’s</h2>
          <div className="flex justify-center ">
            <div className="tab w-[30%] main-tabs inline-flex items-center overflow-hidden rounded-lg border border-stroke dark:border-dark-3">
              <button
                className={`w-[33.33%] border-r border-stroke px-4 py-3 text-base font-medium text-dark hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white ${activeButton === 0 ? 'bg-blue-500 text-white' : ''
                  }`}
                onClick={() => handleButtonClick(0)}
              >
                Crypto
              </button>
              <button
                className={`w-[33.33%] border-r border-stroke px-4 py-3 text-base font-medium text-dark hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white ${activeButton === 1 ? 'bg-blue-500 text-white' : ''
                  }`}
                onClick={() => handleButtonClick(1)}
              >
                Forex
              </button>
              <button
                className={`w-[33.33%] px-4 py-3 text-base font-medium text-dark last-of-type:border-r-0 hover:bg-gray-2 hover:text-primary dark:border-dark-3 dark:text-white ${activeButton === 2 ? 'bg-blue-500 text-white' : ''
                  }`}
                onClick={() => handleButtonClick(2)}
              >
                Stock
              </button>
            </div>
          </div>
        </section>
        {activeButton === 0  && <CryptoSignalCart  /> }
        {activeButton === 1  && <ForexSignalCart/> }
        {activeButton === 2  &&  <StocksSignalCart/> }
      </div>
    </div>
  )
}

export default FreePaidSignals