import React from 'react'

const Title = ({data}) => {
  return (
    <div>
        {data.map((val, index)=>{
            return(
                <div class="-mx-4 flex flex-wrap">
                <div class="w-full px-4">
                  <div class="mx-auto mb-12 max-w-[485px] text-center lg:mb-[70px]">
                    <span class="mb-2 mt-8 block text-lg font-semibold text-primary">
                        {val.title}
                      {/* Features */}
                    </span>
                    <h2
                      class="mb-3 text-2xl   font-bold text-dark dark:text-white sm:text-3xl md:text-[30px] md:leading-[1.2]"
                    >
                        {val.header}
                   {/* Main Features of Investors zon */}
                    </h2>
                    <p class="text-base text-body-color dark:text-dark-6 text-justify">
                        {val.descp}
                    {/* Investors zon offers a diverse range of features designed to meet all your financial needs, from trading and investing to education and analysis. Here are some of our standout features: */}
                    </p>
                  </div>
                </div>
              </div>
            )
        })}
     
    </div>
  )
}

export default Title
